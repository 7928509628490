import React from "react";
import { ArrowRightAlt } from "@mui/icons-material";
import { Box, TextareaAutosize, Typography, useTheme } from "@mui/material";

import { Period } from "hooks/periodIndex";
import { usePeriodNameFormatter } from "hooks/usePeriodNameFormatter";

import { PeriodSummary, PlanBackupSummary } from "src/store/api/generatedApi";
import {
  deployPlanFormSlice,
  selectSMSBackupPlanMessagedIds,
  selectSMSPeriodMessagedIds,
  selectSMSPlanFormBackupSMSMessage,
  selectSMSPlanFormPeriodSMSMessage,
} from "src/store/slices/deployPlanFormSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";

type Props = {
  periods: PeriodSummary[];
  backups: PlanBackupSummary[];
  formPlanId: number;
};

type PeriodSMSProps = {
  planId: number;
  period: number;
  periodName: string;
};

type BackupSMSProps = {
  formPlanId: number;
  backupPlanId: number;
  backupName: string;
  periodName: string;
};

type TextareaProps = {
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const Textarea = ({ value, onChange }: TextareaProps) => {
  return <TextareaAutosize value={value} onChange={onChange} maxRows={100} />;
};

const MessageBox = ({ children }: React.PropsWithChildren) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        textarea: {
          padding: 2,
          fontFamily: theme.typography.fontFamily,
          resize: "none",
          borderColor: theme.palette.grey[200],
          borderRadius: 1,
        },
      }}
    >
      {children}
    </Box>
  );
};

const PeriodSMS = ({ planId, period, periodName }: PeriodSMSProps) => {
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) =>
    selectSMSPlanFormPeriodSMSMessage(state, planId, period)
  );
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      deployPlanFormSlice.actions.setSMSPeriodMessage([
        planId,
        event.target.value,
        period,
      ])
    );
  };
  return (
    <MessageBox>
      <Typography
        variant="h6"
        sx={{ display: "flex", flexWrap: "wrap", fontWeight: 900 }}
      >
        {periodName}
      </Typography>
      <Textarea value={message} onChange={handleOnChange} />
    </MessageBox>
  );
};

const BackupSMS = ({
  formPlanId,
  backupName,
  backupPlanId,
  periodName,
}: BackupSMSProps) => {
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) =>
    selectSMSPlanFormBackupSMSMessage(state, formPlanId, backupPlanId)
  );
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      deployPlanFormSlice.actions.setSMSBackupMessage([
        formPlanId,
        event.target.value,
        backupPlanId,
      ])
    );
  };
  return (
    <MessageBox>
      <Typography variant="h6" sx={{ display: "flex", flexWrap: "wrap" }}>
        {periodName}
        <ArrowRightAlt />
        <Typography
          variant="subtitle2"
          component="span"
          sx={{
            alignSelf: "flex-end",
            fontWeight: 900,
          }}
        >
          {backupName}
        </Typography>
      </Typography>
      <Textarea value={message} onChange={handleOnChange} />
    </MessageBox>
  );
};

export const SMSForm = ({ periods, backups, formPlanId }: Props) => {
  const messagedPeriods = useAppSelector((state) =>
    selectSMSPeriodMessagedIds(state, formPlanId)
  );
  const messagedBackupPlanIds = useAppSelector((state) =>
    selectSMSBackupPlanMessagedIds(state, formPlanId)
  );
  const periodNameFormatter = usePeriodNameFormatter();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {messagedPeriods !== undefined
        ? periods.map((period) => (
            <>
              {messagedPeriods.includes(period.period.toString()) ? (
                <PeriodSMS
                  planId={formPlanId}
                  period={period.period}
                  periodName={periodNameFormatter(period.period as Period, {
                    startTimestamp: period.start_timestamp,
                    endTimestamp: period.end_timestamp,
                    name: period.name,
                  })}
                />
              ) : null}
              {period.period === 1
                ? backups
                    .filter(
                      (
                        backup: PlanBackupSummary
                      ): backup is Omit<PlanBackupSummary, "plan_id"> & {
                        plan_id: number;
                      } => backup.plan_id !== null
                    )
                    .filter((backup) =>
                      messagedBackupPlanIds.includes(backup.plan_id.toString())
                    )
                    .map((backup) => (
                      <BackupSMS
                        key={backup.plan_id}
                        formPlanId={formPlanId}
                        backupName={backup.display_name}
                        backupPlanId={backup.plan_id}
                        periodName={periodNameFormatter(
                          period.period as Period,
                          {
                            startTimestamp: period.start_timestamp,
                            endTimestamp: period.end_timestamp,
                            name: period.name,
                          }
                        )}
                      />
                    ))
                : null}
            </>
          ))
        : null}
    </Box>
  );
};
