import { Replay } from "@mui/icons-material";
import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import React from "react";

import { useTenant } from "hooks/settings";

import {
  DeploymentType,
  generatedApi,
  Latest,
  Next,
  useGetDeployedPlanMixesQuery,
  useUpdateAndDeployPlanMixesMutation,
} from "src/store/api/generatedApi";
import { steelGradeSearchSlice } from "src/store/slices/steelGradeSearchSlice";
import { AppDispatch, useAppDispatch } from "src/store/store";

import { useTenantTranslation } from "hooks/formatters";
import { disableEditEventParam, enableEditEventParam } from "./Table";

type Props = {
  mixId: number;
  steelGradeIds: number[];
  deploymentType: Exclude<DeploymentType, "backup" | "edited_backup">;
  planId: number;
  startAt: Date;
  endAt: Date;
  planTimeframe: Latest | Next;
  view: "backup" | "supervisor" | "deploy";
};

type BodyProps = {
  mixId: number;
  deploymentType: Exclude<DeploymentType, "backup" | "edited_backup">;
  planId: number;
  startAt: Date;
  endAt: Date;
  planTimeframe: Latest | Next;
  reversionDeploymentType: "standard";
  broadDeploymentTypes: ["standard", "edited_standard"];
  reversionTargetMixId: number;
  view: "backup" | "supervisor" | "deploy";
};

const useHandleOnSubmit = (
  reversionTargetMixId: number,
  mixId: number,
  deploymentType: DeploymentType,
  reversionDeploymentType: DeploymentType,
  deploymentTypes: DeploymentType[],
  planTimeframe: Latest | Next,
  startAt: Date,
  endAt: Date,
  planId: number,
  view: "backup" | "supervisor" | "deploy"
) => {
  const tenantName = useTenant();
  const dispatch = useAppDispatch();
  const [
    doUpdateAndDeployPlanMixesMutation,
    updateAndDeployPlanMixesMutationResult,
  ] = useUpdateAndDeployPlanMixesMutation({ fixedCacheKey: "__revert__" });

  const callback = React.useCallback(() => {
    return doSubmit(
      reversionTargetMixId,
      mixId,
      deploymentType,
      reversionDeploymentType,
      deploymentTypes,
      planTimeframe,
      startAt,
      endAt,
      planId,
      doUpdateAndDeployPlanMixesMutation,
      dispatch,
      tenantName,
      view
    );
  }, [
    reversionTargetMixId,
    mixId,
    reversionDeploymentType,
    deploymentTypes,
    planTimeframe,
    startAt,
    endAt,
    planId,
    doUpdateAndDeployPlanMixesMutation,
    dispatch,
    tenantName,
    view,
  ]);

  return [callback, updateAndDeployPlanMixesMutationResult] as const;
};

const doSubmit = async (
  reversionTargetMixId: number,
  mixId: number,
  deploymentType: DeploymentType,
  reversionDeploymentType: DeploymentType,
  deploymentTypes: DeploymentType[],
  planTimeframe: Latest | Next,
  startAt: Date,
  endAt: Date,
  planId: number,
  doUpdate: ReturnType<typeof useUpdateAndDeployPlanMixesMutation>[0],
  dispatch: AppDispatch,
  tenantName: string,
  view: "backup" | "supervisor" | "deploy"
) => {
  const mixes = await doUpdate({
    tenantName,
    planId,
    deployedPlanMixUpdates: {
      period: 1,
      mixes: {},
      replacements: {
        [mixId]: reversionTargetMixId,
      },
      deployment_type: deploymentType,
      start_at: startAt.toISOString(),
      end_at: endAt.toISOString(),
    },
  }).unwrap();

  dispatch(
    generatedApi.util.updateQueryData(
      "getDeployedPlanMixes",
      {
        tenantName,
        deploymentTypes,
        planId: planTimeframe,
        period: 1,
      },
      () => mixes
    )
  );

  await dispatch(
    generatedApi.endpoints.getDeployedPlanMixes.initiate(
      {
        tenantName,
        deploymentTypes: [reversionDeploymentType],
        planId: planTimeframe,
        period: 1,
      },
      { forceRefetch: true }
    )
  );

  dispatch(steelGradeSearchSlice.actions.copy([planId, view, mixes.plan_id]));
};

export const RevertableMixButton = ({
  planId,
  mixId,
  startAt,
  endAt,
  deploymentType,
  planTimeframe,
  steelGradeIds,
  view,
}: Props) => {
  const tenantName = useTenant();

  const broadDeploymentTypes = React.useMemo((): [
    "standard",
    "edited_standard",
  ] => {
    switch (deploymentType) {
      case "edited_standard":
      case "standard":
        return ["standard", "edited_standard"];
    }
  }, [deploymentType]);

  const reversionDeploymentType = React.useMemo((): "standard" => {
    switch (deploymentType) {
      case "edited_standard":
      case "standard":
        return "standard";
    }
  }, [deploymentType]);

  const reversionTargetPlan = useGetDeployedPlanMixesQuery({
    tenantName,
    planId: planTimeframe,
    period: 1,
    deploymentTypes: [reversionDeploymentType],
  });

  if (reversionTargetPlan.isSuccess) {
    const reversionTargetMixId = reversionTargetPlan.data.mixes.find(
      (mix) =>
        mix.steel_grade_ids.every((id) => steelGradeIds.includes(id)) &&
        steelGradeIds.every((id) => mix.steel_grade_ids.includes(id))
    )?.mix_id;
    if (reversionTargetMixId !== undefined && reversionTargetMixId !== mixId) {
      return (
        <Body
          planId={planId}
          mixId={mixId}
          startAt={startAt}
          endAt={endAt}
          deploymentType={deploymentType}
          planTimeframe={planTimeframe}
          reversionDeploymentType={reversionDeploymentType}
          reversionTargetMixId={reversionTargetMixId}
          broadDeploymentTypes={broadDeploymentTypes}
          view={view}
        />
      );
    }
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant="body2Mono" sx={{ width: 60 }}>
          <Skeleton variant="text" />
        </Typography>
      </Box>
    );
  }
};

const Body = ({
  planId,
  mixId,
  startAt,
  endAt,
  deploymentType,
  planTimeframe,
  reversionTargetMixId,
  reversionDeploymentType,
  broadDeploymentTypes,
  view,
}: BodyProps) => {
  const theme = useTheme();
  const tenantName = useTenant();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const gridAPI = useGridApiContext();

  const [doSubmit, submissionResult] = useHandleOnSubmit(
    reversionTargetMixId,
    mixId,
    deploymentType,
    reversionDeploymentType,
    broadDeploymentTypes,
    planTimeframe,
    startAt,
    endAt,
    planId,
    view
  );

  const reversionPlanMixesQueryState =
    generatedApi.endpoints.getDeployedPlanMixes.useQueryState({
      tenantName,
      deploymentTypes: [reversionDeploymentType],
      planId: planTimeframe,
      period: 1,
    });

  const isLoading =
    reversionPlanMixesQueryState.isFetching || submissionResult.isLoading;

  const handleConfirm = async () => {
    setIsSubmitting(true);
    gridAPI.current.publishEvent("stateChange", disableEditEventParam);
    await doSubmit();
    setIsSubmitting(false);
    gridAPI.current.publishEvent("stateChange", enableEditEventParam);
  };

  const { t } = useTenantTranslation();

  return (
    <Box>
      <Button
        onClick={handleConfirm}
        disabled={isSubmitting || isLoading}
        variant="outlined"
        color="primary"
        size="small"
        startIcon={
          <Replay
            sx={{
              ...(isSubmitting
                ? {
                    animation: "spin 800ms infinite linear",
                    fill: theme.palette.primary.main,
                  }
                : {}),
              "@keyframes spin": {
                from: {
                  transform: "rotate(360deg)",
                },
                to: {
                  transform: "rotate(0deg)",
                },
              },
            }}
          />
        }
        sx={{
          minWidth: "100px",
          textTransform: "none",
          fontWeight: 500,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        }}
      >
        {t("reset")}
      </Button>
    </Box>
  );
};
