import { Box, Typography } from "@mui/material";

import {
  DeploymentType,
  Latest,
  Next,
  SteelGradePlanItem,
} from "src/store/api/generatedApi";

import { MixPriceDiff } from "./MixPriceDiff";
import { RevertableMixButton } from "./RevertableMix";
import { useTenantTranslation } from "hooks/formatters";

type Props = {
  planId: number;
  mixId: number;
  steelGradeIds: number[];
  deploymentType: Exclude<DeploymentType, "backup" | "edited_backup">;
  startAt: Date;
  endAt: Date;
  planTimeframe: Latest | Next;
  view: "backup" | "supervisor" | "deploy";
  steelGradeProduction: SteelGradePlanItem[];
  period: number;
  originalPrice: number;
  originalBasketsMaterialMasses: Record<number, Record<number, number>>;
};

export const SupervisorActionCell = ({
  planId,
  mixId,
  steelGradeIds,
  deploymentType,
  startAt,
  endAt,
  planTimeframe,
  view,
  steelGradeProduction,
  period,
  originalPrice,
  originalBasketsMaterialMasses,
}: Props) => {
  const { t } = useTenantTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 0.5,
        justifyContent: "space-between",
        height: "100%",
        minHeight: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 0.5,
          flexShrink: 0,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "text.secondary",
            fontSize: "0.75rem",
            fontWeight: 400,
          }}
        >
          {t("mix")}: {mixId}
        </Typography>
        <MixPriceDiff
          planId={planId}
          mixId={mixId}
          steelGradeProduction={steelGradeProduction}
          period={period}
          originalPrice={originalPrice}
          originalBasketsMaterialMasses={originalBasketsMaterialMasses}
        />
      </Box>
      <Box sx={{ flexShrink: 0, marginBottom: 1 }}>
        <RevertableMixButton
          view={view}
          mixId={mixId}
          planId={planId}
          deploymentType={deploymentType}
          startAt={startAt}
          endAt={endAt}
          planTimeframe={planTimeframe}
          steelGradeIds={steelGradeIds}
        />
      </Box>
    </Box>
  );
};
