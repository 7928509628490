import {
  Box,
  ButtonBase,
  Checkbox,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { ProductionTable } from "components/plan/table/production/ProductionTable";
import { useTenantTranslation } from "hooks/formatters";
import { Period } from "hooks/periodIndex";
import { useTenant } from "hooks/settings";
import { usePeriodNameFormatter } from "hooks/usePeriodNameFormatter";
import React from "react";
import {
  ChefGroup,
  MaterialRead,
  PeriodSummary,
  PlanBackupSummary,
  ProductGroup,
  useGetPlanQuery,
} from "src/store/api/generatedApi";
import {
  deployPlanFormSlice,
  selectDeployBackupPlanSelected,
  selectDeployPlanFormIsSubmitting,
  selectDeployPlanPeriodSelected,
  selectIsBackupPlanPreviewed,
  selectIsDeployPlanPreviewed,
} from "src/store/slices/deployPlanFormSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";

type Props = {
  preview: { planId: number; period: number; title: string } | null;
  periods: PeriodSummary[];
  backups: PlanBackupSummary[];
  planId: number;
  productGroups: ProductGroup[] | null;
  chefGroups: ChefGroup[];
  materials: MaterialRead[];
};

type BackupProps = {
  title: string;
  planId: number;
  backupPlanId: number;
  backupPeriod: number;
};

type PreviewTableProps = {
  planId: number;
  period: number;
  productGroups: ProductGroup[] | null;
  chefGroups: ChefGroup[];
  materials: MaterialRead[];
};

const Backup = ({ title, planId, backupPlanId, backupPeriod }: BackupProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const isSelected = useAppSelector((state) =>
    selectDeployBackupPlanSelected(state, planId, backupPlanId)
  );

  const isPreviewed = useAppSelector((state) =>
    selectIsDeployPlanPreviewed(state, planId, {
      planId: backupPlanId,
      period: backupPeriod,
    })
  );

  const isSubmitting = useAppSelector((state) =>
    selectDeployPlanFormIsSubmitting(state, planId)
  );

  const handleOnChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      dispatch(
        deployPlanFormSlice.actions.addBackup([
          planId,
          { planId: backupPlanId, period: backupPeriod },
        ])
      );
      dispatch(
        deployPlanFormSlice.actions.setPreview([
          planId,
          {
            planId: backupPlanId,
            period: backupPeriod,
            title,
          },
        ])
      );
    } else {
      dispatch(
        deployPlanFormSlice.actions.removeBackup([
          planId,
          { planId: backupPlanId, period: backupPeriod },
        ])
      );
    }
  };

  const handleOnClick = () => {
    dispatch(
      deployPlanFormSlice.actions.setPreview([
        planId,
        {
          planId: backupPlanId,
          period: backupPeriod,
          title,
        },
      ])
    );
  };

  return (
    <>
      <Checkbox
        checked={isSelected}
        onChange={handleOnChange}
        disabled={isSubmitting}
        sx={{ gridColumn: "4", padding: 0, paddingLeft: 0 }}
      />
      <ButtonBase
        onClick={handleOnClick}
        sx={{
          justifySelf: "flex-start",
          textAlign: "left",
          gridColumn: "5 / -1",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            textAlign: "left",
            fontWeight: isPreviewed
              ? 900
              : theme.typography.subtitle2.fontWeight,
          }}
        >
          {title}
        </Typography>
      </ButtonBase>
    </>
  );
};

const PeriodWithBackups = ({
  backups,
  planId,
  periodNumber,
  periodName,
}: {
  backups: (Omit<PlanBackupSummary, "plan_id"> & { plan_id: number })[];
  planId: number;
  periodNumber: number;
  periodName: string;
}) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isSelected = useAppSelector((state) =>
    selectDeployPlanPeriodSelected(state, planId, periodNumber)
  );
  const isPreviewed = useAppSelector((state) =>
    selectIsDeployPlanPreviewed(state, planId, {
      planId,
      period: periodNumber,
    })
  );
  const isSubmitting = useAppSelector((state) =>
    selectDeployPlanFormIsSubmitting(state, planId)
  );
  const isBackupPreviewed = useAppSelector((state) =>
    selectIsBackupPlanPreviewed(
      state,
      planId,
      backups.map((backup) => backup.plan_id),
      periodNumber
    )
  );

  const handleOnChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      dispatch(
        deployPlanFormSlice.actions.addPeriod({ planId, period: periodNumber })
      );
      dispatch(
        deployPlanFormSlice.actions.setPreview([
          planId,
          {
            planId,
            period: periodNumber,
            title: "",
          },
        ])
      );
    } else {
      dispatch(
        deployPlanFormSlice.actions.removePeriod({
          planId,
          period: periodNumber,
        })
      );
    }
  };

  const handleOnClick = () => {
    dispatch(
      deployPlanFormSlice.actions.setPreview([
        planId,
        {
          planId,
          period: periodNumber,
          title: "",
        },
      ])
    );
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content 4px 4px min-content 1fr min-content",
        gridAutoRows: "min-content",
        alignItems: "center",
        justifyContent: "start",
        gap: 1,
      }}
    >
      <Checkbox
        sx={{ padding: 0 }}
        checked={isSelected}
        onChange={handleOnChange}
        disabled={isSubmitting}
      />
      <ButtonBase
        onClick={handleOnClick}
        sx={{ gridColumn: "2/-2", justifySelf: "flex-start" }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: isPreviewed ? 900 : theme.typography.h6.fontWeight,
          }}
        >
          {periodName}
        </Typography>
      </ButtonBase>
      {backups.length > 0 ? (
        <>
          <Typography
            variant="subtitle2"
            sx={{
              gridColumn: "3/-1",
              fontWeight: isBackupPreviewed
                ? 900
                : theme.typography.subtitle2.fontWeight,
            }}
          >
            {t("backups")}
          </Typography>
          {backups.map((backup) => {
            return (
              <Backup
                key={backup.plan_id}
                title={backup.display_name}
                planId={planId}
                backupPeriod={periodNumber}
                backupPlanId={backup.plan_id}
              />
            );
          })}
        </>
      ) : null}
    </Box>
  );
};

const PreviewTable = ({
  planId,
  period,
  productGroups,
  chefGroups,
  materials,
}: PreviewTableProps) => {
  const tenantName = useTenant();
  const { currentData: plan } = useGetPlanQuery({
    tenantName,
    planId,
  });
  if (plan) {
    return (
      <ProductionTable
        key={`${plan.id}.${period}`}
        sx={{ width: "100%" }}
        period={period}
        productGroups={productGroups}
        chemistryGroups={chefGroups}
        productGroupMixMaterialSummaries={
          plan.summary.product_group_mix_material_summary
        }
        productGroupMixSummaries={plan.summary.product_group_mix_summary}
        mixMaterialSummaries={plan.summary.mix_material_summary}
        mixSummaries={plan.summary.mix_summary}
        periodMaterialSummaries={plan.summary.period_material_summary}
        materials={materials}
        mixBasketSummaries={plan.summary.mix_basket_summary}
      />
    );
  } else {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "150px repeat(8, 80px)",
          gridTemplateRows: "35px 90px repeat(10, 35px)",
          gap: 0.5,
        }}
      >
        <Skeleton variant="rectangular" sx={{ gridColumn: "1/3" }} />
        <Skeleton variant="rectangular" sx={{ gridColumn: "6/8" }} />
        <Skeleton variant="rectangular" sx={{ gridColumn: "8/10" }} />
        {new Array(99).fill(null).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={index} variant="rectangular" sx={{ height: "100%" }} />
        ))}
      </Box>
    );
  }
};

export const DeployForm = ({
  preview,
  periods,
  backups,
  planId,
  materials,
  chefGroups,
  productGroups,
}: Props) => {
  const periodNameFormatter = usePeriodNameFormatter();
  const dispatch = useAppDispatch();

  React.useLayoutEffect(() => {
    const [period] = periods;
    if (!preview && period) {
      dispatch(
        deployPlanFormSlice.actions.addPeriod({ planId, period: period.period })
      );
      dispatch(
        deployPlanFormSlice.actions.setPreview([
          planId,
          {
            planId,
            period: period.period,
            title: periodNameFormatter(period.period as Period, {
              startTimestamp: period.start_timestamp,
              endTimestamp: period.end_timestamp,
              name: period.name,
            }),
          },
        ])
      );
    }
  }, [periods, preview, dispatch, planId]);

  return (
    <Box
      sx={{
        display: "grid",
        flexDirection: "row",
        height: "100%",
        columnGap: 0,
        rowGap: 1,
        gridTemplateColumns: "256px 1fr",
        gridTemplateRows: "minmax(0, 1fr)",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gridColumn: "1",
          columnGap: 6,
          rowGap: 2,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          minHeight: 0,
          paddingRight: 4,
          overflow: "scroll",
        }}
      >
        {periods
          .filter((period) => period.is_deployable)
          .map((period) => (
            <PeriodWithBackups
              key={period.period}
              backups={
                period.period === 1
                  ? backups.filter(
                      (
                        backup: PlanBackupSummary
                      ): backup is Omit<PlanBackupSummary, "plan_id"> & {
                        plan_id: number;
                      } => backup.plan_id !== null
                    )
                  : []
              }
              planId={planId}
              periodNumber={period.period as Period}
              periodName={periodNameFormatter(period.period as Period, {
                startTimestamp: period.start_timestamp,
                endTimestamp: period.end_timestamp,
                name: period.name,
              })}
            />
          ))}
      </Box>
      {preview ? (
        <PreviewTable
          planId={preview.planId}
          period={preview.period}
          productGroups={productGroups}
          chefGroups={chefGroups}
          materials={materials}
        />
      ) : null}
    </Box>
  );
};
