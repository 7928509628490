import { debounce, Typography, useTheme } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";
import { useCallback } from "react";
import {
  saveQuotes,
  selectHasUnsavedChanges,
} from "src/store/slices/purchasingPlanTableEditSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";

const SAVE_DEBOUNCE_MS = 3000;

type Props = {
  planId: number;
};

export const PurchasingPlanSaveStatus = ({ planId }: Props) => {
  const { t } = useTenantTranslation();
  const tenantName = useTenant();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const hasUnsavedChanges = useAppSelector(selectHasUnsavedChanges);

  const save = useCallback(() => {
    void dispatch(saveQuotes({ planId, tenantName }));
  }, [dispatch, planId, tenantName]);

  const debouncedSave = useCallback(debounce(save, SAVE_DEBOUNCE_MS), [save]);

  if (hasUnsavedChanges) {
    debouncedSave();
  }

  return (
    <Typography
      sx={{
        marginLeft: "auto !important",
        color: theme.palette.text.secondary,
      }}
    >
      {hasUnsavedChanges ? t("saving") : t("saved")}
    </Typography>
  );
};
