import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { defaultLanguage, languages } from "../../constants";
import { loadLanguage } from "../../store/persistence";
import { changeLanguage } from "../../store/slices/settings";

import { useLanguage } from "hooks/settings";
import { useAppDispatch } from "store/store";
import untypedTranslations from "../../translations_data.json";

const typedTranslations: {
  [key: string]: { [shortLanguage: string]: string } | string;
} = untypedTranslations;

/**
 * Build a translations dictionary for a particular language.
 *
 * This maps the translation key (the language-invariant descriptor of the piece
 * of text) to the version of that text in the given language.
 */
const buildTranslations = (
  shortLanguage: string
): { [key: string]: string } => {
  const result: { [key: string]: string } = {};

  for (const key in typedTranslations) {
    const translationsValue = typedTranslations[key]!;

    // Some values (eg. numeric ones) only have one translation
    if (typeof translationsValue === "string") {
      result[key] = translationsValue;
    } else {
      result[key] = translationsValue[shortLanguage] ?? key;
    }
  }

  return result;
};

void i18n.use(initReactI18next).init({
  resources: Object.fromEntries(
    Object.keys(languages).map((language) => [
      language,
      Object.assign({
        translation: buildTranslations(language.slice(0, 2).toUpperCase()),
      }),
    ])
  ),
  lng: loadLanguage() ?? defaultLanguage,
  fallbackLng: defaultLanguage,
  fallbackNS: "translation",
  interpolation: {
    escapeValue: false,
  },
});

i18n.use({
  type: "postProcessor",
  name: "capitalise",
  process: function (value: string) {
    if (typeof value !== "string" || !value.length) return value;
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
});

export const SelectLocale = ({ hideLabel }: { hideLabel?: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const language = useLanguage();

  function handleChange(event: SelectChangeEvent) {
    const language = event.target.value;
    dispatch(changeLanguage(language));
  }

  return (
    <FormControl fullWidth size="small">
      {!hideLabel && <InputLabel>{t("language")}</InputLabel>}
      <Select
        value={language}
        label={hideLabel ? undefined : t("language")}
        onChange={(event) => handleChange(event)}
      >
        {Object.keys(languages).map((locale) => (
          <MenuItem key={locale} value={locale}>
            {languages[locale]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
