import {
  useInventory,
  useMaterialPhysics,
  useMaterials,
  usePeriods,
  useSearch,
} from "contexts/search/provider";
import {
  SearchMaterialPhysics,
  SearchMaterials,
  SyncedContextData,
} from "contexts/search/context";
import { Loaded, liftLoadedState } from "models/loaded";
import { InventoryItem, SearchPeriod } from "store/api/generatedApi";

export type InventoryDependencies = {
  materials: SearchMaterials;
  materialPhysics: SearchMaterialPhysics;
  inventory: InventoryItem[];
  searchContext: SyncedContextData;
  periods: SearchPeriod[];
};

export const useInventoryDependencies = (): Loaded<InventoryDependencies> => {
  const [inventory] = useInventory();
  const [periods] = usePeriods();
  const materials = useMaterials();
  const materialPhysics = useMaterialPhysics();
  const { context } = useSearch();
  return liftLoadedState({
    materials,
    materialPhysics,
    inventory,
    periods,
    searchContext: context,
  });
};
