import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Box, Icon, Stack, Typography, useTheme } from "@mui/material";
import { useLocalStorageState } from "components/videoLabelling/helpers";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { LabelProbabilitiesPlot } from "./plots/labelProbabilities";
import { LabelSourcesPlot } from "./plots/labelSourcesPlot";
import { ScaleWeightsPlot } from "./plots/scaleWeights";
import { ScrapTrackingSettings, ScrapTrackingSettingsPage } from "./settings";
import {
  ScaleWeightDropEventsPlot,
  VisionModelDropEventsPlot,
} from "./plots/dropEvents";
import { HeatSummariesPlot } from "./plots/heatSummaries";
import { HeatSummaryFocusContent } from "./focus/heatSummary";
import { ReactNode, useState } from "react";
import {
  useViewportControls,
  ViewportControlButtons,
} from "./viewportControls";
import { ScrapCarCyclesPlot } from "./plots/scrapCarCycles";
import { LoadingStatusPlot } from "./plots/loadingStatus";

export const ScrapTrackingPage = () => {
  const [settings, setSettings] = useLocalStorageState<ScrapTrackingSettings>(
    "scrap-tracking-settings",
    {}
  );
  const [focusHeatId, setFocusHeatId] = useState<string | null>(null);

  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", height: 1, overflowY: "hidden" }}>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          borderRight: `1px solid ${theme.palette.common.black}`,
          backgroundColor: theme.palette.grey[50],
        }}
      >
        <Box sx={{ padding: 2 }}>
          <ScrapTrackingSettingsPage
            settings={settings}
            setSettings={setSettings}
          />
        </Box>
      </Box>
      <Box sx={{ flex: 5, overflowY: "auto" }}>
        <Box sx={{ padding: 2 }}>
          <VerticalPointer>
            <ScrapTrackingPlots
              settings={settings}
              setFocusHeatId={setFocusHeatId}
            />
          </VerticalPointer>
        </Box>
      </Box>
      {focusHeatId != null ? (
        <Box sx={{ flex: 2, overflowY: "auto" }}>
          <Box
            sx={{
              flex: 2,
              overflowY: "auto",
              borderLeft: `1px solid ${theme.palette.common.black}`,
              backgroundColor: theme.palette.grey[50],
              height: 1,
            }}
          >
            <Box sx={{ padding: 2 }}>
              <HeatSummaryFocusContent heatId={focusHeatId} key={focusHeatId} />
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

const VerticalPointer = ({ children }: { children: ReactNode }) => {
  const [mousePosition, setMousePosition] = useState<{ x: number } | null>({
    x: 10,
  });
  const theme = useTheme();
  return (
    <Stack
      sx={{ position: "relative", height: 1 }}
      onMouseMove={(event) =>
        setMousePosition({
          x: event.clientX - event.currentTarget.getBoundingClientRect().left,
        })
      }
      onMouseLeave={() => setMousePosition(null)}
    >
      {children}

      {mousePosition == null ? null : (
        <Box
          sx={{
            width: "0.5px",
            height: 1,
            position: "absolute",
            left: mousePosition.x,
            top: 0,
            backgroundColor: theme.palette.common.black,
            pointerEvents: "none",
          }}
        />
      )}
    </Stack>
  );
};

const ScrapTrackingPlots = ({
  settings,
  setFocusHeatId,
}: {
  settings: ScrapTrackingSettings;
  setFocusHeatId?: (focusHeatId: string) => void;
}) => {
  const { start, end, setStart, onRelayout } = useViewportControls();

  return (
    <Stack sx={{ padding: 4 }} gap={2}>
      <ViewportControlButtons end={end} setStart={setStart} />

      {Boolean(settings.dropProbability) ||
      Boolean(settings.labelProbability) ? (
        <LabelProbabilitiesPlot
          start={start}
          end={end}
          onRelayout={onRelayout}
          settings={settings}
        />
      ) : null}

      {settings.scaleWeightDropEvents ? (
        <ScaleWeightDropEventsPlot
          start={start}
          end={end}
          onRelayout={onRelayout}
        />
      ) : null}

      {settings.visionModelDropEvents ? (
        <VisionModelDropEventsPlot
          start={start}
          end={end}
          onRelayout={onRelayout}
        />
      ) : null}

      {settings.scaleWeights ? (
        <ScaleWeightsPlot start={start} end={end} onRelayout={onRelayout} />
      ) : null}

      {settings.loadingStatus ? (
        <LoadingStatusPlot start={start} end={end} onRelayout={onRelayout} />
      ) : null}

      {settings.heatSummaries ? (
        <HeatSummariesPlot
          start={start}
          end={end}
          onRelayout={onRelayout}
          setFocusHeatId={setFocusHeatId}
        />
      ) : null}

      {settings.scrapCarCycles ? (
        <ScrapCarCyclesPlot start={start} end={end} onRelayout={onRelayout} />
      ) : null}

      {settings.labelSources ? (
        <LabelSourcesPlot start={start} end={end} onRelayout={onRelayout} />
      ) : null}
    </Stack>
  );
};

export const PredictionsLegend = ({
  labels,
}: {
  labels: { label: string; colour: string; probability: number }[];
}) => {
  const { format } = useNumberSerialiser({ decimalPlaces: 1 });

  return (
    <Stack>
      {labels.map(({ label, probability, colour }, index) => (
        <Stack direction="row" key={label}>
          <Icon sx={{ mr: 2 }}>
            <HorizontalRuleIcon sx={{ color: colour }} />
          </Icon>
          <Typography sx={{ width: 70, fontSize: 20 }}>
            {format(probability * 100)}%
          </Typography>
          <Typography
            sx={{ fontSize: 20, fontWeight: index === 0 ? 800 : 400 }}
          >
            {label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
