import { Layout, Shape } from "plotly.js";
import { ChunkData } from "./helpers";

export type Point<T = number> = { timestamp: Date; value: T };

export const linePlot = (points: { timestamp: Date; value: number }[]) => ({
  x: points.map((point) => point.timestamp),
  y: points.map((point) => point.value),
  type: "scatter" as const,
  mode: "lines" as const,
});

export const defaultLayout = (): Partial<Layout> => ({
  yaxis: { range: [0, 1], fixedrange: true },
  dragmode: "pan",
  height: 300,
  margin: { t: 0, b: 50, r: 0, l: 50 },
  showlegend: false,
});

export const loadingChunks = <T,>(chunks: ChunkData<T>[]): Partial<Shape>[] =>
  chunks
    .filter((chunk) => chunk.value.status !== "success")
    .map((segment) => ({
      type: "rect",
      xref: "x",
      yref: "paper",
      x0: segment.start,
      x1: segment.end,
      y0: 0,
      y1: 1,
      fillcolor: "grey",
      opacity: 0.05,
      layer: "below",
      line: { width: 0 },
    }));

export const generateColour = (text: string) => {
  // Simple hash function (FNV-1a hash)
  let hash = 2166136261;
  for (let i = 0; i < text.length; i++) {
    hash ^= text.charCodeAt(i);
    hash *= 16777619;
  }

  const r = (hash >> 16) & 255;
  const g = (hash >> 8) & 255;
  const b = hash & 255;

  return `rgb(${r}, ${g}, ${b})`;
};
