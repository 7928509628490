import { OpenInNew } from "@mui/icons-material";
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import { SummaryStat } from "components/common/summaryStat";
import { useTenantTranslation } from "hooks/formatters";
import theme from "src/theme";
import { OptimisationStatus } from "store/api/generatedApi";

type Props = {
  displayName: string;
  status: OptimisationStatus;
  displayCostPerTonne: number | null;
  searchId: number;
  planId: number | null;
};

export const BackupCard = ({
  displayName,
  status,
  displayCostPerTonne,
  searchId,
  planId,
}: Props) => {
  const { t } = useTenantTranslation();

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: theme.palette.background.default,
        p: 2,
        border: 1,
        borderRadius: 1,
        "&:hover": {
          boxShadow: (theme) =>
            `2px 3px 0px 0px #000,inset 5px 0px 0px 0px ${theme.palette.primary.main};`,
          cursor: "pointer",
        },
        borderColor: theme.palette.common.black,
        transition: "box-shadow 0.2s ease-in-out",
      }}
    >
      <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
        <Typography variant="h6" gutterBottom>
          {displayName}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          {status === "succeeded" ? (
            <SummaryStat
              label={t("tappedPrice")}
              value={displayCostPerTonne}
              decimalPlaces={2}
              units="specific_cost"
              hideDivider
            />
          ) : (
            <Stack>
              <Typography variant="body1Mono">
                <Skeleton width={60} />
              </Typography>
              <Typography variant="body2">
                <Skeleton width={125} />
              </Typography>
            </Stack>
          )}
          <Button
            variant="outlined"
            loading={
              status === "not_started" || status === "edited" || planId === null
            }
            loadingPosition="end"
            endIcon={<OpenInNew />}
            color="primary"
            sx={{ textDecoration: "none" }}
            onClick={() => {
              if (planId) {
                const newParams = new URLSearchParams();
                newParams.set("search", searchId.toString());
                newParams.set("plan", planId.toString());
                window.open(
                  `${window.location.pathname}?${newParams.toString()}`,
                  "_blank"
                );
              }
            }}
          >
            {t("viewPlan")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
