import { useTheme } from "@mui/material";
import { SteelGrade } from "src/store/api/generatedApi";
import {
  selectSearchedSteelGradeIds,
  steelGradeSearchSlice,
} from "src/store/slices/steelGradeSearchSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";

import { MultiSelect } from "../inputs/multiSelect";

import { useTenantTranslation } from "hooks/formatters";

type Props = {
  steelGrades: SteelGrade[];
  planId: number;
  view: "supervisor" | "deploy" | "backup";
};

export const SteelGradesSelect = ({ steelGrades, planId, view }: Props) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();

  const dispatch = useAppDispatch();

  const selectedSteelGradeIds = useAppSelector((state) =>
    selectSearchedSteelGradeIds(state, planId, view)
  );

  const handleSetSelectedSearchSteelGradeIds = (steelGrades: SteelGrade[]) => {
    dispatch(
      steelGradeSearchSlice.actions.setSteelGrades([
        planId,
        steelGrades.map((steelGrade) => steelGrade.id),
        view,
      ])
    );
  };

  return (
    <MultiSelect<SteelGrade, number>
      sx={{
        minWidth: 250,
        minHeight: 40,
        marginRight: "auto",

        ".MuiAutocomplete-tag": {
          backgroundColor: theme.palette.grey[600],
          color: theme.palette.common.white,
          svg: {
            "&:hover": {
              fill: theme.palette.grey[600],
              path: {
                fill: theme.palette.common.white,
              },
            },
          },
        },
      }}
      options={steelGrades}
      getOptionId={(steelGrade) => steelGrade.id}
      getOptionLabel={(steelGrade) => steelGrade.name}
      values={selectedSteelGradeIds.map(
        (id) => steelGrades.find((steelGrade) => steelGrade.id === id)!
      )}
      setValues={handleSetSelectedSearchSteelGradeIds}
      label={t("searchForSteelGrade")}
      small
    />
  );
};
