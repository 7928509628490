import {
  Box,
  Button,
  Divider,
  PopoverProps,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { WarningAmber } from "@mui/icons-material";
import { Period } from "src/hooks/periodIndex";
import { SearchPeriod } from "src/store/api/generatedApi";
import React from "react";
import { SteelGradesId } from "contexts/search/context";
import { t } from "i18next";
import { TitledPopoverTemplate } from "../titledTemplate";
import { usePeriods } from "contexts/search/provider";
import { usePeriodNameFormatter } from "hooks/usePeriodNameFormatter";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";

type Props = {
  productId: SteelGradesId;
  periods: SearchPeriod[];
} & PopoverProps;

type ProductPeriod = {
  period: Period;
  originalHeatCount: number;
  currentHeatCount: number;
  type: "product";
};

type GroupPeriod = {
  period: Period;
  type: "group";
};

type PeriodSchedule = ProductPeriod | GroupPeriod;

const createInitialState = (
  periods: SearchPeriod[],
  id: SteelGradesId
): PeriodSchedule[] => {
  return periods.map((period, index) => {
    const {
      production_plan: { steel_grade_items },
    } = period;
    if (steel_grade_items) {
      const steelGrade = steel_grade_items.find(
        (item) => item.steel_grade_id === id
      );
      return {
        type: "product",
        period: (index + 1) as Period,
        originalHeatCount: steelGrade?.num_heats ?? 0,
        currentHeatCount: steelGrade?.num_heats ?? 0,
      };
    } else {
      return {
        type: "group",
        period: (index + 1) as Period,
      };
    }
  });
};

const getOriginalTotal = (periodSchedules: ProductPeriod[]) =>
  periodSchedules.reduce(
    (acc, { originalHeatCount }) => acc + originalHeatCount,
    0
  );
const getCurrentTotal = (periodicHeats: ProductPeriod[]) =>
  periodicHeats.reduce(
    (acc, { currentHeatCount }) => acc + currentHeatCount,
    0
  );

const getProductSchedules = (periodSchedules: PeriodSchedule[]) =>
  periodSchedules.filter(
    (periodSchedule): periodSchedule is ProductPeriod =>
      periodSchedule.type === "product"
  );

const getDiffColor = (diff: number, theme: Theme): string => {
  switch (true) {
    case diff > 0:
      return theme.palette.success.main;
    case diff < 0:
      return theme.palette.error.main;
    default:
      return theme.palette.text.primary;
  }
};

const useUpdateProductPeriods = () => {
  const [, updatePeriods] = usePeriods();
  return (productId: SteelGradesId, productPeriods: ProductPeriod[]) => {
    updatePeriods((periods) => {
      return periods.map((period, index) => {
        const productPeriod = productPeriods.find(
          (productPeriod) => productPeriod.period === index + 1
        );
        if (productPeriod) {
          return {
            ...period,
            production_plan: {
              ...period.production_plan,
              steel_grade_items: [
                ...(period.production_plan.steel_grade_items ?? []).filter(
                  (item) => item.steel_grade_id !== productId
                ),
                {
                  steel_grade_id: productId,
                  num_heats: productPeriod.currentHeatCount,
                },
              ],
            },
          };
        } else {
          return period;
        }
      });
    });
  };
};

export const ProductHeatsPopover = ({
  productId,
  periods,
  ...popoverProps
}: Props) => {
  const theme = useTheme();
  const formatPeriodName = usePeriodNameFormatter();

  const updateProductPeriods = useUpdateProductPeriods();

  const [periodSchedules, setPeriodSchedules] = React.useState<
    PeriodSchedule[]
  >(createInitialState(periods, productId));

  const serialiser = useNumberSerialiser({
    decimalPlaces: 0,
    min: 0,
    default: { text: "", value: null },
  });

  const totalDiff =
    getCurrentTotal(getProductSchedules(periodSchedules)) -
    getOriginalTotal(getProductSchedules(periodSchedules));

  const updatePeriodSchedule = (period: Period, heatCount: number) => {
    setPeriodSchedules((periodSchedules) => {
      const index = periodSchedules.findIndex(
        (periodSchedule) => periodSchedule.period === period
      );
      if (index !== -1) {
        const productPeriod = periodSchedules[index]!;
        if (productPeriod.type === "product") {
          return [
            ...periodSchedules.slice(0, index),
            {
              type: "product",
              period,
              originalHeatCount: productPeriod.originalHeatCount,
              currentHeatCount: heatCount,
            },
            ...periodSchedules.slice(index + 1),
          ];
        }
      }
      return periodSchedules;
    });
  };

  return (
    <TitledPopoverTemplate title={t("move heats")} {...popoverProps}>
      <Box
        display="grid"
        columnGap={2}
        rowGap={1}
        gridTemplateColumns="max-content auto auto 1fr"
        alignItems="center"
        width={400}
      >
        {periodSchedules.map(({ period, ...periodSchedule }) => {
          return (
            <>
              <Typography variant="h6" sx={{ gridColumn: 1 }}>
                {formatPeriodName(period)}
              </Typography>
              {periodSchedule.type === "product" ? (
                <>
                  <ValidatedTextField
                    value={periodSchedule.currentHeatCount}
                    setValue={(heatCount) =>
                      updatePeriodSchedule(period, heatCount ?? 0)
                    }
                    serialiser={serialiser}
                    textFieldProps={{
                      sx: {
                        gridColumn: 2,
                      },
                      InputProps: {
                        sx: {
                          width: 100,
                          fontSize: theme.typography.body1Mono.fontSize,
                          fontWeight: theme.typography.body1Mono.fontWeight,
                          lineHeight: theme.typography.body1Mono.lineHeight,
                          fontFamily: theme.typography.body1Mono.fontFamily,
                        },
                      },
                    }}
                  />

                  {(() => {
                    const diff =
                      periodSchedule.currentHeatCount -
                      periodSchedule.originalHeatCount;
                    return (
                      <Typography
                        sx={{ gridColumn: 3, color: getDiffColor(diff, theme) }}
                        variant="body1Mono"
                        fontWeight="bold"
                      >
                        {diff > 0 ? "+" : ""}
                        {diff}
                      </Typography>
                    );
                  })()}
                </>
              ) : (
                <Stack alignItems="center" flexDirection="row" gap={1}>
                  <WarningAmber />
                  <Typography>{t("productGroups")}</Typography>
                </Stack>
              )}
            </>
          );
        })}
        <Divider sx={{ gridColumn: "1/-1" }} />
        <Typography variant="h6" sx={{ gridColumn: 1 }}>
          {t("total")}
        </Typography>
        <Typography
          variant="body1Mono"
          fontWeight="bold"
          sx={{ gridColumn: 2 }}
        >
          {getCurrentTotal(getProductSchedules(periodSchedules))}
        </Typography>
        <Typography
          sx={{ gridColumn: 3, color: getDiffColor(totalDiff, theme) }}
          variant="body1Mono"
          fontWeight="bold"
        >
          {totalDiff > 0 ? "+" : ""}
          {totalDiff}
        </Typography>
        <Button
          size="small"
          sx={{ gridColumn: "1/-1", justifySelf: "end" }}
          variant="contained"
          onClick={() =>
            updateProductPeriods(
              productId,
              getProductSchedules(periodSchedules)
            )
          }
        >
          {t("apply")}
        </Button>
      </Box>
    </TitledPopoverTemplate>
  );
};
