import { Button } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { Period } from "hooks/periodIndex";
import { usePeriodNameFormatter } from "hooks/usePeriodNameFormatter";
import React from "react";
import { PeriodSummary } from "src/store/api/generatedApi";
import {
  deployPlanFormSlice,
  selectDeployFormSelectedBackupPlanIds,
  selectDeployFormSelectedPeriods,
} from "src/store/slices/deployPlanFormSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";

type Props = {
  formPlanId: number;
  allPeriods: [PeriodSummary, ...PeriodSummary[]];
  allBackupPlanIds: number[];
};

export const SelectAllButton = ({
  formPlanId,
  allPeriods,
  allBackupPlanIds,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTenantTranslation();
  const periodNameFormatter = usePeriodNameFormatter();
  const selectedPeriods = useAppSelector((state) =>
    selectDeployFormSelectedPeriods(state, formPlanId)
  );
  const selectedBackupPlanIds = useAppSelector((state) =>
    selectDeployFormSelectedBackupPlanIds(state, formPlanId)
  );

  const handleOnClick = () => {
    dispatch(
      deployPlanFormSlice.actions.addAll([
        formPlanId,
        allBackupPlanIds,
        allPeriods.map((period) => period.period),
      ])
    );
    const [{ period, start_timestamp, end_timestamp, name }] = allPeriods;
    dispatch(
      deployPlanFormSlice.actions.setPreview([
        formPlanId,
        {
          planId: formPlanId,
          period: period,
          title: periodNameFormatter(period as Period, {
            name,
            startTimestamp: start_timestamp,
            endTimestamp: end_timestamp,
          }),
        },
      ])
    );
  };

  const isDisabled = React.useMemo(() => {
    return (
      allPeriods.every((period) => selectedPeriods.includes(period.period)) &&
      allBackupPlanIds.every((planId) => selectedBackupPlanIds.includes(planId))
    );
  }, [selectedPeriods, selectedBackupPlanIds, allPeriods, allBackupPlanIds]);

  return (
    <Button onClick={handleOnClick} variant="contained" disabled={isDisabled}>
      {t("selectAll")}
    </Button>
  );
};
