import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { PlanBackupSummary } from "store/api/generatedApi";
import { BackupCard } from "./backupCard";

export const BackupModal = ({
  open,
  close,
  backups,
}: {
  open: boolean;
  close: () => void;
  backups: PlanBackupSummary[];
}) => {
  const { t } = useTenantTranslation();

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          // Fix to always be "full height" (i.e. 90vh)
          maxHeight: "60em",
          height: "90vh",
          width: "90vw",
        },
      }}
    >
      <DialogTitle>{t("suggestedBackups")}</DialogTitle>

      <DialogContent sx={{ mt: 6, overflow: "auto" }}>
        <Stack direction="column" spacing={2}>
          {backups.map((backup) => (
            <BackupCard
              key={backup.session_id}
              displayName={backup.display_name}
              status={backup.status}
              displayCostPerTonne={backup.display_cost_per_tonne}
              searchId={backup.search_id}
              planId={backup.plan_id}
            />
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
