import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loaded, mapLoaded, mapLoadedUnpack } from "models/loaded";
import { SyncedSearchState, useSyncedSearchState } from "./search";
import {
  SearchChemicalElements,
  SearchChemistryGroups,
  SearchMaterialChemistry,
  SearchMaterialPhysics,
  SearchMaterials,
  SearchProductGroups,
  SearchSteelGrades,
  SyncedContextData,
} from "./context";
import {
  BasketRead,
  InventoryItem,
  MiscParams,
  PhysicalParameters,
  ObtainableBundleItem,
  SearchPeriodProductionPlan,
  SearchPeriod,
  TargetBasketItem,
  TargetInventoryItem,
  SteelGradePlanItem,
  ProductGroupPlanItem,
} from "src/store/api/generatedApi";
import { Period } from "hooks/periodIndex";

const SearchContext = createContext<SyncedSearchState | undefined>(undefined);

const blankPeriod: SearchPeriod = {
  name: null,
  is_deployable: false,
  start_timestamp: null,
  end_timestamp: null,
  electrical_energy_price: 0,
  production_plan: { product_group_items: null, steel_grade_items: [] },
  obtainable_bundles: [],
  target_inventory: [],
  target_baskets: [],
  material_exclusivity: [],
  suppress_mix_material_exclusivity_constraints: false,
  suppress_min_feasible_mass_constraints: false,
  optimisation_objective_weighting: 1.0,
};

export const ProvideSearch = ({ children }: { children: ReactNode }) => {
  const value = useSyncedSearchState();
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearch = (): SyncedSearchState => {
  const search = useContext(SearchContext);

  if (search == null) {
    throw new Error("No search context has been provided");
  } else {
    return search;
  }
};

export const useSearchName = (): Loaded<string> =>
  mapLoaded(useSearch().parameters.client, ({ session_name }) => session_name);

export const useRunSearch = () => {
  const {
    results: { run },
    parameters: { server },
  } = useSearch();
  const serverSearchId = mapLoadedUnpack(server, ({ id }) => id);

  const [requested, setRequested] = useState(false);
  const ready = serverSearchId != null;

  // Wait for syncing to finish before running the search
  useEffect(() => {
    if (requested && ready) {
      setRequested(false);
      run(serverSearchId);
    }
  }, [requested, ready]);

  return () => setRequested(true);
};

const useSearchContext = <T,>(selector: (context: SyncedContextData) => T) =>
  mapLoaded(useSearch().context, selector);

export const useProductGroupsId = () =>
  useSearchContext(({ productGroupsId }) => productGroupsId);
export const useSteelGradesId = () =>
  useSearchContext(({ steelGradesId }) => steelGradesId);
export const useMaterialsId = () =>
  useSearchContext(({ materialsId }) => materialsId);
export const useMaterialPhysicsId = () =>
  useSearchContext(({ materialPhysicsId }) => materialPhysicsId);

export const useMaterials = (): Loaded<SearchMaterials> =>
  useSearchContext(({ materials }) => materials);

export const useMaterialPhysics = (): Loaded<SearchMaterialPhysics> =>
  useSearchContext(({ materialPhysics }) => materialPhysics);

export const useMaterialChemistry = (): Loaded<SearchMaterialChemistry> =>
  useSearchContext(({ materialChemistry }) => materialChemistry);

export const useProductGroups = (): Loaded<SearchProductGroups> =>
  useSearchContext(({ productGroups }) => productGroups);

export const useChemistryGroups = (): Loaded<SearchChemistryGroups> =>
  useSearchContext(({ chemistryGroups }) => chemistryGroups);

export const useSteelGrades = (): Loaded<SearchSteelGrades> =>
  useSearchContext(({ steelGrades }) => steelGrades);

export const useChemicalElements = (): Loaded<SearchChemicalElements> =>
  useSearchContext(({ chemicalElements }) => chemicalElements);

export const useBaskets = (): Loaded<BasketRead[]> =>
  useSearchContext(({ baskets }) => baskets);

export const useHasDefaultSets = () =>
  useSearchContext(({ hasDefaultSets }) => hasDefaultSets);

export const useMiscParams = () => {
  const { client, setClient } = useSearch().parameters;
  return [
    mapLoaded(client, ({ misc_params }) => misc_params),
    (update: (miscParams: MiscParams) => MiscParams) =>
      setClient((current) => ({
        ...current,
        misc_params: update(current.misc_params),
      })),
  ] as const;
};

export const usePhysicalParameters = () => {
  const { client, setClient } = useSearch().parameters;
  return [
    mapLoaded(client, ({ physical_parameters }) => physical_parameters),
    (update: (physicalParameters: PhysicalParameters) => PhysicalParameters) =>
      setClient((current) => ({
        ...current,
        physical_parameters: update(current.physical_parameters),
      })),
  ] as const;
};

export const useInventory = () => {
  const { client, setClient } = useSearch().parameters;
  return [
    mapLoaded(client, ({ inventory }) => inventory),
    (update: (inventory: InventoryItem[]) => InventoryItem[]) =>
      setClient((current) => ({
        ...current,
        inventory: update(current.inventory),
      })),
  ] as const;
};

export const useTargetBaskets = (period: Period) => {
  const { client, setClient } = useSearch().parameters;
  const periodIndex = period - 1;
  return [
    mapLoaded(
      client,
      ({ periods }) => periods[periodIndex]?.target_baskets ?? []
    ),
    (update: (targetBaskets: TargetBasketItem[]) => TargetBasketItem[]) =>
      setClient((current) => ({
        ...current,
        periods: current.periods.map((period) => ({
          ...period,
          target_baskets: update(period.target_baskets),
        })),
      })),
  ] as const;
};

export const usePeriod = (period: Period) => {
  const { client, setClient } = useSearch().parameters;
  const periodIndex = period - 1;
  return [
    mapLoaded(client, ({ periods }) => periods[periodIndex] ?? blankPeriod),
    (update: (period: SearchPeriod) => SearchPeriod) =>
      setClient((current) => {
        const periods = [...current.periods];
        periods[periodIndex] = update(
          current.periods[periodIndex] ?? blankPeriod
        );
        return { ...current, periods };
      }),
  ] as const;
};

export const useProductionSchedule = () => {
  const [period, setPeriod] = usePeriod(1 as Period);
  return [
    mapLoaded(period, ({ production_plan }) => production_plan),
    (
      update: (
        productionPlan: SearchPeriodProductionPlan
      ) => SearchPeriodProductionPlan
    ) =>
      setPeriod((current) => ({
        ...current,
        production_plan: update(current.production_plan),
      })),
  ] as const;
};

// Backend can't use tagged unions, but that's a helpful way of representing
// production plans on the frontend
export type ProductionPlan =
  | { type: "steelGrade"; items: SteelGradePlanItem[] }
  | { type: "productGroup"; items: ProductGroupPlanItem[] }
  | { type: "empty"; items: [] };

export const convertSearchPeriodProductionPlan = (
  productionPlan: SearchPeriodProductionPlan
): ProductionPlan =>
  (productionPlan.steel_grade_items ?? []).length > 0
    ? { type: "steelGrade", items: productionPlan.steel_grade_items ?? [] }
    : (productionPlan.product_group_items ?? []).some(
        (item) => item.num_heats > 0
      )
    ? {
        type: "productGroup",
        items: productionPlan.product_group_items ?? [],
      }
    : { type: "empty", items: [] };

export const useProductionPlan = (periodIndex: Period) => {
  const [period, setPeriod] = usePeriod(periodIndex);

  const convertToBackendFormat = (
    productionPlan: ProductionPlan
  ): SearchPeriodProductionPlan =>
    productionPlan.type === "steelGrade"
      ? { steel_grade_items: productionPlan.items, product_group_items: null }
      : productionPlan.type === "productGroup"
      ? { steel_grade_items: null, product_group_items: productionPlan.items }
      : {
          steel_grade_items: null,
          product_group_items: null,
        };

  return [
    mapLoaded(period, ({ production_plan }) =>
      convertSearchPeriodProductionPlan(production_plan)
    ),
    (update: (productionPlan: ProductionPlan) => ProductionPlan) =>
      setPeriod((current) => ({
        ...current,
        production_plan: convertToBackendFormat(
          update(convertSearchPeriodProductionPlan(current.production_plan))
        ),
      })),
  ] as const;
};

export const useObtainableBundles = (periodIndex: Period) => {
  const [period, setPeriod] = usePeriod(periodIndex);
  return [
    mapLoaded(period, ({ obtainable_bundles }) => obtainable_bundles),
    (
      update: (
        obtainableBundles: ObtainableBundleItem[]
      ) => ObtainableBundleItem[]
    ) =>
      setPeriod((current) => ({
        ...current,
        obtainable_bundles: update(current.obtainable_bundles),
      })),
  ] as const;
};

export const useTargetInventory = (period: Period) => {
  const [searchPeriod, setSearchPeriod] = usePeriod(period);
  return [
    mapLoaded(searchPeriod, ({ target_inventory }) => target_inventory),
    (
      update: (targetInventory: TargetInventoryItem[]) => TargetInventoryItem[]
    ) =>
      setSearchPeriod((current) => ({
        ...current,
        target_inventory: update(current.target_inventory),
      })),
  ] as const;
};

export const useServerIds = () => {
  const search = useSearch().parameters.server;
  return {
    searchId: mapLoadedUnpack(search, ({ id }) => id),
    sessionId: mapLoadedUnpack(search, ({ session_id }) => session_id),
  };
};

export const usePeriods = () => {
  const { client, setClient } = useSearch().parameters;
  return [
    mapLoaded(client, ({ periods }) => periods),
    (update: (periods: SearchPeriod[]) => SearchPeriod[]) =>
      setClient((current) => ({
        ...current,
        periods: update(current.periods),
      })),
  ] as const;
};
