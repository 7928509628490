import { useTenant } from "hooks/settings";
import i18next from "i18next";
import { createContext, ReactNode, useContext, useEffect } from "react";
import { languages } from "src/constants";
import { Tenant, useGetTenantMetadataQuery } from "src/store/api/generatedApi";
import { changeLanguage } from "src/store/slices/settings";
import { useAppDispatch } from "src/store/store";
import { useIsMeticulous } from "hooks/permissions";

const TenantContext = createContext<Tenant | undefined>(undefined);

export const ProvideTenantData = ({ children }: { children: ReactNode }) => {
  const tenantName = useTenant();
  const { data: tenantData, isFetching } = useGetTenantMetadataQuery({
    tenantName,
  });
  const dispatch = useAppDispatch();
  const isMeticulous = useIsMeticulous();

  useEffect(() => {
    if (tenantData) {
      // Only use the default tenant language if running in testing.
      if (isMeticulous) {
        dispatch(changeLanguage(tenantData.default_language));
      }

      // Add translation overrides to i18next resource bundles.
      const translationOverrides = Object.fromEntries(
        Object.entries(tenantData.translation_overrides).map(([key, value]) => [
          key,
          "$t(" + value + ")",
        ])
      );
      Object.keys(languages).forEach((language) =>
        i18next.addResourceBundle(language, tenantName, translationOverrides)
      );
    }
  }, [tenantData, tenantName, dispatch]);

  return tenantData === undefined || isFetching ? null : (
    <TenantContext.Provider value={tenantData}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenantData = (): Tenant => {
  const tenantData = useContext(TenantContext);

  if (tenantData === undefined) {
    throw new Error("No tenant data has been provided");
  } else {
    return tenantData;
  }
};
