import {
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import {
  PeriodSummary,
  PlanBackupSummary,
  useGetPlanQuery,
} from "store/api/generatedApi";

import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";
import { resolvedBackupStatuses, useBackups } from "hooks/useBackups";
import {
  deployPlanFormSlice,
  selectDeployPlanForm,
  selectDeployPlanFormCheckedCount,
  selectDeployPlanPreview,
} from "src/store/slices/deployPlanFormSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";

import { DeployForm } from "./deployForm";
import { SMSForm } from "./smsForm";
import { Finished } from "./finished";
import { CloseButton } from "./closeButton";
import { SendSMSButton } from "./sendSMSButton";
import { SyncWithLevel2Button } from "./syncWithLevel2Button";
import { BackButton } from "./backButton";
import { CancelButton } from "./cancelButton";
import { SelectAllButton } from "./selectAllButton";
import { useSearch } from "contexts/search/provider";

type WrapperProps = {
  planId: number;
  doClose: () => void;
};

type Props = {
  open: boolean;
  doClose: () => void;
  planId: number;
};

export const SyncAndSMSPlanModal = ({ planId, open, doClose }: Props) => {
  const { t } = useTenantTranslation();
  const preview = useAppSelector((state) =>
    selectDeployPlanPreview(state, planId)
  );
  const deployCount = useAppSelector((state) =>
    selectDeployPlanFormCheckedCount(state, planId)
  );

  return (
    <Dialog
      open={open}
      onClose={doClose}
      fullWidth
      maxWidth="xl"
      sx={{
        "& .MuiDialog-paper": {
          // Fix to always be "full height" (i.e. 90vh)
          height: "90vh",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography variant="h5" sx={{ height: 32 }}>
          {t("deployPlan")}
        </Typography>
        {deployCount > 0 ? (
          <Chip
            label={deployCount}
            size="medium"
            sx={{ marginRight: "auto" }}
          />
        ) : null}
        {preview ? <Typography variant="h5">{preview.title}</Typography> : null}
      </DialogTitle>
      <DataWrapper planId={planId} doClose={doClose} />
    </Dialog>
  );
};

export const DataWrapper = ({ planId, doClose }: WrapperProps) => {
  const tenantName = useTenant();
  const dispatch = useAppDispatch();
  const deployPlanForm = useAppSelector((state) =>
    selectDeployPlanForm(state, planId)
  );
  const backups = useBackups(planId);
  const plan = useGetPlanQuery({ tenantName, planId });
  const { context } = useSearch();

  React.useEffect(() => {
    if (deployPlanForm === undefined) {
      dispatch(deployPlanFormSlice.actions.setDeployForm(planId));
    }
  }, [planId, deployPlanForm, dispatch]);

  if (
    context.status === "success" &&
    deployPlanForm !== undefined &&
    plan.isSuccess &&
    backups.isSuccess &&
    backups.data.backups.every((backup) =>
      resolvedBackupStatuses.includes(backup.status)
    )
  ) {
    switch (deployPlanForm.type) {
      case "deploy_form": {
        return (
          <>
            <DialogContent>
              <DeployForm
                preview={deployPlanForm.preview}
                periods={plan.data.summary.period_summary}
                backups={backups.data.backups}
                planId={planId}
                chefGroups={context.data.chemistryGroups.byIndex}
                productGroups={context.data.productGroups.byIndex}
                materials={context.data.materials.byIndex}
              />
            </DialogContent>
            <DialogActions>
              {plan.data.summary.period_summary[0] ? (
                <SelectAllButton
                  formPlanId={planId}
                  allPeriods={
                    plan.data.summary.period_summary as [
                      PeriodSummary,
                      ...PeriodSummary[],
                    ]
                  }
                  allBackupPlanIds={backups.data.backups
                    .filter(
                      (
                        backup: PlanBackupSummary
                      ): backup is PlanBackupSummary & { plan_id: number } =>
                        backup.plan_id !== null
                    )
                    .map(({ plan_id }) => plan_id)}
                />
              ) : null}
              <CancelButton planId={planId} doClose={doClose} />
              <SyncWithLevel2Button formPlanId={planId} />
            </DialogActions>
          </>
        );
      }
      case "sms_form": {
        return (
          <>
            <DialogContent>
              <SMSForm
                periods={plan.data.summary.period_summary}
                formPlanId={planId}
                backups={backups.data.backups}
              />
            </DialogContent>
            <DialogActions>
              <BackButton planId={planId} />
              <CancelButton planId={planId} doClose={doClose} />
              <SendSMSButton formPlanId={planId} />
            </DialogActions>
          </>
        );
      }
      case "finished_form": {
        return (
          <>
            <DialogContent>
              <Finished />
            </DialogContent>
            <DialogActions>
              <CloseButton formPlanId={planId} doClose={doClose} />
            </DialogActions>
          </>
        );
      }
    }
  }
  return (
    <DialogContent>
      <CircularProgress />
    </DialogContent>
  );
};
