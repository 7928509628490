import { Stack } from "@mui/material";
import { SummaryStat } from "components/common/summaryStat";
import {
  selectOverallAverageCost,
  selectTotalOrderQuantity,
  selectTotalUnfilledDemand,
} from "src/store/slices/purchasingPlanTableEditSlice";
import { useAppSelector } from "src/store/store";

export const PurchasingPlanStats = () => {
  const totalOrderQuantity = useAppSelector(selectTotalOrderQuantity);
  const overallAverageCost = useAppSelector(selectOverallAverageCost);
  const totalUnfilledDemand = useAppSelector(selectTotalUnfilledDemand);
  return (
    <Stack direction="row" gap={4}>
      <SummaryStat
        label="totalOrderQuantity"
        value={totalOrderQuantity}
        decimalPlaces={0}
        units="mass"
      />
      <SummaryStat
        label="averageCost"
        value={overallAverageCost}
        decimalPlaces={2}
        units="specific_cost"
      />
      <SummaryStat
        label="totalUnfilledDemand"
        value={totalUnfilledDemand}
        decimalPlaces={0}
        units="mass"
        hideDivider
      />
    </Stack>
  );
};
