import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTenant } from "hooks/settings";
import { Loaded, loadedEndpoint } from "models/loaded";
import {
  PriceSeries,
  useGetStrategyDashboardPricesQuery,
} from "src/store/api/generatedApi";
import {
  GroupedPriceSeries,
  PriceSource,
  PriceTimeSeries,
  PriceTimeSeriesKey,
} from "../types";

dayjs.extend(utc);

export const useStrategyDashboardPrices = (): Loaded<GroupedPriceSeries> => {
  const loadedRawData = loadedEndpoint(
    useGetStrategyDashboardPricesQuery({ tenantName: useTenant() })
  );
  if (loadedRawData.status === "success") {
    return {
      status: "success",
      data: buildGroupedPriceSeries(loadedRawData.data.series),
      fetching: loadedRawData.fetching,
    };
  }
  return loadedRawData;
};

const buildGroupedPriceSeries = (series: PriceSeries[]): GroupedPriceSeries => {
  const stringKeyKeyMap: Record<string, PriceTimeSeriesKey> = {};
  const stringKeyValuesMap: Record<string, PriceTimeSeries> = {};

  for (const item of series) {
    const key: PriceTimeSeriesKey = {
      priceSource: item.price_source,
      materialName: item.material_name,
    };
    const stringKey = JSON.stringify(key);
    stringKeyKeyMap[stringKey] = key;

    const value = stringKeyValuesMap[stringKey] ?? {
      validFrom: [],
      specificPrice: [],
    };
    value.validFrom.push(dayjs.utc(item.price_valid_from));
    value.specificPrice.push(item.specific_price);
    stringKeyValuesMap[stringKey] = value;
  }

  const values: [PriceTimeSeriesKey, PriceTimeSeries][] = Object.entries(
    stringKeyKeyMap
  ).map(([stringKey, key]) => [key, stringKeyValuesMap[stringKey]!]);

  const availableMaterialNames = new Set<string>(
    series.map((item) => item.material_name)
  );

  const availablePriceSources = new Set<PriceSource>(
    series.map((item) => item.price_source)
  );

  return {
    items: values,
    availableMaterialNames,
    availablePriceSources,
  };
};
