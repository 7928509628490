import {
  Permission,
  useGetUsersQuery,
  UserPermission,
  UserReadAll,
  useUpdateUserMutation,
} from "store/api/generatedApi";
import {
  Alert,
  Container,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
} from "@mui/material";
import { isFetchBaseQueryError } from "helpers";
import { MultiSelect } from "src/components/common/inputs/multiSelect";
import { Fetching } from "src/components/common/loading/fetching";
import { PERMISSION_VALUES } from "components/users/permissions.ts";

export const UserList = ({
  tenant,
  onError,
}: {
  tenant: string;
  onError: (message: string) => void;
}) => {
  const { data: users, isFetching: isUsersFetching } = useGetUsersQuery({
    tenantName: tenant,
  });

  if (!tenant) {
    return (
      <Container>
        <Alert severity="warning">Please select a tenant.</Alert>
      </Container>
    );
  }

  return (
    <Fetching fetching={isUsersFetching}>
      <List>
        {users?.map((user) => (
          <UserListItem
            key={`${tenant}/${user.uid}`}
            tenant={tenant}
            user={user}
            onError={onError}
          />
        ))}
      </List>
    </Fetching>
  );
};

const UserListItem = ({
  tenant,
  user,
  onError,
}: {
  tenant: string;
  user: UserReadAll;
  onError: (message: string) => void;
}) => {
  const [userUpdate] = useUpdateUserMutation();

  const handleAdminChange = () => {
    userUpdate({
      tenantName: tenant,
      userUpdate: {
        uid: user.uid,
        is_admin: !user.is_admin,
      },
    })
      .unwrap()
      .catch((err) => {
        if (isFetchBaseQueryError(err)) {
          const status = user.is_admin ? "enabled" : "disabled";
          onError(
            `Couldn't set admin ${status} for ${user.email} (status code: ${err.status})`
          );
        }
      });
  };

  const handleEnabledChange = () => {
    userUpdate({
      tenantName: tenant,
      userUpdate: {
        uid: user.uid,
        is_enabled: !user.is_enabled,
      },
    })
      .unwrap()
      .catch((err) => {
        if (isFetchBaseQueryError(err)) {
          const status = user.is_enabled ? "enabled" : "disabled";
          onError(
            `Couldn't set ${user.email} ${status} (status code: ${err.status})`
          );
        }
      });
  };

  const setPermissions = (permissions: Permission[]) => {
    userUpdate({
      tenantName: tenant,
      userUpdate: {
        uid: user.uid,
        permissions: { [tenant]: permissions },
      },
    })
      .unwrap()
      .catch((err) => {
        if (isFetchBaseQueryError(err)) {
          onError(`Couldn't set user permissions`);
        }
      });
  };

  return (
    <ListItem key={user.uid}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
        spacing={1}
      >
        <ListItemText primary={user.name} secondary={user.email} />
        <Stack direction="row" spacing={1}>
          <PermissionsDropdown
            permissions={user.permissions}
            setPermissions={setPermissions}
          />

          <FormControlLabel
            label="Admin"
            control={
              <Switch checked={user.is_admin} onChange={handleAdminChange} />
            }
          />

          <FormControlLabel
            label="Enabled"
            control={
              <Switch
                checked={user.is_enabled}
                onChange={handleEnabledChange}
              />
            }
          />
        </Stack>
      </Stack>
    </ListItem>
  );
};
const PermissionsDropdown = ({
  permissions,
  setPermissions,
}: {
  permissions: UserPermission[];
  setPermissions: (permissions: Permission[]) => void;
}) => {
  return (
    <MultiSelect<Permission, Permission>
      options={Array.from(PERMISSION_VALUES)}
      getOptionId={(permission) => permission}
      getOptionLabel={(permission) => permission}
      values={permissions.map((permission) => permission.permission)}
      label="Permissions"
      setValues={(permissions) => setPermissions(permissions)}
    />
  );
};
