import { groupBy, sorted } from "helpers";
import { Period } from "hooks/periodIndex";
import { useMemo } from "react";
import {
  ChefGroup,
  MaterialRead,
  MixMaterialSummary,
  MixSummary,
  ProductGroup,
  ProductGroupMixMaterialSummary,
  ProductGroupMixSummary,
} from "src/store/api/generatedApi";
import { getMixSummaryName } from "src/utils/getMixSummaryName";
import { getMixSummaryNumber } from "src/utils/getMixSummaryNumber";

export type PlanTableOptions = {
  productGroups: boolean;
  percentages: boolean;
  forecast: boolean;
  steelGrades: string[];
};

export type ProductionContent = {
  groups: PlanGroup[];
  mixes: PlanMixes;
};

export type PlanMix = {
  materials: Record<number, number>;
  total: number;
  maximum: number;
  total_price: number;
  materials_price: number;
  electrical_energy_price: number;
};

export type PlanMixes = Record<number, Record<number, PlanMix>>;

export type PlanGroup = {
  groupId: number;
  mixId: number;
  multipleMixes: boolean;
  heats: number;
};

export type PlanTableMaterial =
  | { material: number; summary?: undefined }
  | { summary: "total" }
  | { summary: "price" };

export type ConsumptionKey =
  | "inventoryInitial"
  | "consumption"
  | "inventoryFinal"
  | "obtainableRequired"
  | "obtainableRemaining";

export const useProductionMixes = (
  dependentMaterialsByName: Record<string, MaterialRead>,
  groupingByName: Record<string, ProductGroup> | Record<string, ChefGroup>,
  mixMaterialSummary: ProductGroupMixMaterialSummary[] | MixMaterialSummary[],
  mixSummaries: ProductGroupMixSummary[] | MixSummary[],
  period: Period
): PlanMixes => {
  return useMemo(() => {
    const summaries = mixMaterialSummary.filter(
      (item) => item.period === period
    );

    const mixes = groupBy(
      summaries,
      (summary) => groupingByName[getMixSummaryName(summary)]!.id!,
      (groupItems) =>
        groupBy(
          groupItems,
          (item) => getMixSummaryNumber(item),
          (mixItems) => {
            const materials = groupBy(
              mixItems,
              (item) => dependentMaterialsByName[item.material]!.id,
              (materialItem) => materialItem[0]!.recipe_mass
            );
            const firstMixItem = mixItems[0]!;

            return {
              materials,
              ...summarise(materials),
              total_price:
                mixSummaries.find((item) => {
                  const { period } = firstMixItem;
                  return (
                    item.period === period &&
                    getMixSummaryName(item) ===
                      getMixSummaryName(firstMixItem) &&
                    getMixSummaryNumber(item) ===
                      getMixSummaryNumber(firstMixItem)
                  );
                })?.display_cost_per_tonne ?? 0,
              materials_price:
                mixSummaries.find((item) => {
                  const { period } = firstMixItem;
                  return (
                    item.period === period &&
                    getMixSummaryName(item) ===
                      getMixSummaryName(firstMixItem) &&
                    getMixSummaryNumber(item) ===
                      getMixSummaryNumber(firstMixItem)
                  );
                })?.display_cost_per_tonne_materials ?? 0,
              electrical_energy_price:
                mixSummaries.find((item) => {
                  const { period } = firstMixItem;
                  return (
                    item.period === period &&
                    getMixSummaryName(item) ===
                      getMixSummaryName(firstMixItem) &&
                    getMixSummaryNumber(item) ===
                      getMixSummaryNumber(firstMixItem)
                  );
                })?.display_cost_per_tonne_electrical_energy ?? 0,
            };
          }
        )
    );

    return mixes;
  }, [
    dependentMaterialsByName,
    groupingByName,
    mixMaterialSummary,
    mixSummaries,
    period,
  ]);
};

export const useProductionGroups = (
  mixes: PlanMixes,
  mixSummaries: ProductGroupMixSummary[] | MixSummary[],
  groupingByName: Record<string, ProductGroup> | Record<string, ChefGroup>,
  period: Period
) => {
  return useMemo(() => {
    const groups = sorted(Object.entries(mixes), (item) =>
      parseInt(item[0])
    ).flatMap((group) =>
      sorted(Object.keys(group[1]), parseInt)
        .map((mix) => ({
          groupId: parseInt(group[0]),
          mixId: parseInt(mix),
          multipleMixes: Object.keys(group[1]).length > 1,
        }))
        // Add in the number of heats for this group
        .map((group) => ({
          ...group,
          heats:
            mixSummaries
              .filter((item) => item.period === period)
              .find(
                (item) =>
                  getMixSummaryNumber(item) === group.mixId &&
                  groupingByName[getMixSummaryName(item)]!.id === group.groupId
              )?.num_heats ?? 0,
        }))
    );

    return groups;
  }, [groupingByName, mixSummaries, mixes, period]);
};

const summarise = (materials: { [material: number]: number }) => {
  return {
    total: Object.values(materials).reduce((left, right) => left + right, 0),
    maximum: Math.max(...Object.values(materials)),
  };
};
