import React from "react";
import { Button } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";

import { useAppDispatch } from "src/store/store";
import { deployPlanFormSlice } from "src/store/slices/deployPlanFormSlice";

type Props = {
  formPlanId: number;
  doClose: () => void;
};

export const CloseButton = ({ formPlanId, doClose }: Props) => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const handleOnClick = React.useCallback(() => {
    doClose();
    dispatch(deployPlanFormSlice.actions.clear(formPlanId));
  }, [formPlanId, doClose]);
  return (
    <Button variant="contained" onClick={handleOnClick}>
      {t("close")}
    </Button>
  );
};
