export const MATERIAL_COLOURS: { [material: string]: string } = {
  lift_Mix: "rgba(248,73,197,255)",
  lift_Reclaim: "rgba(222,122,225,255)",
  lift_Uncommon: "rgba(68,11,133,255)",
  "lift_Cast Iron Borings": "rgba(80,132,4,255)",
  lift_Rebar: "rgba(132,17,35,255)",
  lift_Shredded: "rgba(16,216,2,255)",
  lift_Turnings: "rgba(62,81,185,255)",
  lift_Unknown: "rgba(162,208,117,255)",
  lift_unknown: "rgba(162,208,117,255)",
  "lift_Tire Wire": "rgba(63,196,109,255)",
  drop: "rgba(205,58,63,255)",
  move: "rgba(224,80,101,255)",
};
