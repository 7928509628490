import { Grid, Typography } from "@mui/material";
import { LoadedContent } from "components/common/loading/loadedContent";
import { TabSpec, TitledTabs } from "components/common/titledTabs";
import { useTenantTranslation } from "hooks/formatters";
import { useState } from "react";
import { useStrategyDashboardConsumption } from "./consumption/consumptionHooks";
import { ConsumptionTab } from "./consumption/ConsumptionTab";
import { useStrategyDashboardFailureRate } from "./failureRate/failureRateHooks";
import { FailureRateTab } from "./failureRate/FailureRateTab";
import { useStrategyDashboardInventory } from "./inventory/inventoryHooks";
import { InventoryTab } from "./inventory/InventoryTab";
import { useStrategyDashboardPrices } from "./prices/pricesHooks";
import { PricesTab } from "./prices/PricesTab";

export const StrategyDashboardPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTenantTranslation();

  // We only show tabs if there is data for them
  const tabSpecs: TabSpec[] = [
    {
      title: t("inventory"),
      key: "inventory",
      content: (
        <LoadedContent data={useStrategyDashboardInventory()} disableFetching>
          {(data) => <InventoryTab groupedInventorySeries={data} />}
        </LoadedContent>
      ),
    },
    {
      title: t("consumption"),
      key: "consumption",
      content: (
        <LoadedContent data={useStrategyDashboardConsumption()} disableFetching>
          {(data) => <ConsumptionTab groupedConsumptionSeries={data} />}
        </LoadedContent>
      ),
    },
    {
      title: t("failureRate"),
      key: "failureRate",
      content: (
        <LoadedContent data={useStrategyDashboardFailureRate()} disableFetching>
          {(data) => <FailureRateTab groupedFailureRateSeries={data} />}
        </LoadedContent>
      ),
    },
    {
      title: t("prices"),
      key: "prices",
      content: (
        <LoadedContent data={useStrategyDashboardPrices()} disableFetching>
          {(data) => <PricesTab groupedPriceSeries={data} />}
        </LoadedContent>
      ),
    },
  ];

  return (
    <Grid container sx={{ px: 0, py: 4, height: "100%", width: "100%" }}>
      <Grid item xs={0.5} />
      <Grid
        item
        xs={11}
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Typography variant="h1">{t("strategyDashboard")}</Typography>
        <TitledTabs
          tabSpecs={tabSpecs}
          tabIndex={tabIndex}
          onChange={setTabIndex}
        />
      </Grid>
      <Grid item xs={0.5} />
    </Grid>
  );
};
