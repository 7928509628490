import { Box, Typography } from "@mui/material";
import { RefreshPlanSnackbar } from "./RefreshPlanSnackbar";
import { DeploymentType, Latest, Next } from "src/store/api/generatedApi";
import { useTenantTranslation } from "hooks/formatters";

type Props = {
  planTimeframe: Latest | Next;
  deploymentGroup: "standard" | "backup";
  deploymentTypes: DeploymentType[];
};

export const PlanNotFound = ({
  planTimeframe,
  deploymentGroup,
  deploymentTypes,
}: Props) => {
  const { t } = useTenantTranslation();
  return (
    <>
      <Box sx={{ display: "grid", placeItems: "center", flexGrow: 1 }}>
        <Typography variant="subtitle1">{t("planNotFound")}</Typography>
      </Box>
      <RefreshPlanSnackbar
        planTimeframe={planTimeframe}
        deploymentTypes={deploymentTypes}
        deploymentGroup={deploymentGroup}
        currentPlanId={null}
      />
    </>
  );
};
