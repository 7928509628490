import { Grid } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { useEffect, useState } from "react";
import { TableHeader } from "../TableHeader";
import { GroupedPriceSeries } from "../types";
import { PricesPlot } from "./PricesPlot";
import { PricesTable } from "./PricesTable";

type PricesTabProps = {
  groupedPriceSeries: GroupedPriceSeries;
};

export const PricesTab = ({ groupedPriceSeries }: PricesTabProps) => {
  const { t } = useTenantTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    // set the default date to the latest date in the series
    setSelectedDate(getDefaultSelectedDate(groupedPriceSeries));
  }, [groupedPriceSeries]);

  return (
    <div>
      <Grid container spacing={1} mt={2} mx={0}>
        <Grid item xs={7} ml={0}>
          <PricesPlot
            priceSeries={groupedPriceSeries}
            setSelectedDate={setSelectedDate}
          />
        </Grid>
        <Grid item xs={5}>
          <TableHeader
            titlePrefix={t("pricesOn")}
            subtitleText={t("priceTableSubtitleText")}
            selectedDate={selectedDate}
            formatDateMonthly={false}
          />
          <PricesTable
            priceSeries={groupedPriceSeries}
            selectedDate={selectedDate}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const getDefaultSelectedDate = (
  groupedPriceSeries: GroupedPriceSeries
): Date | null => {
  const defaultSelectedDate = groupedPriceSeries.items
    .map(
      (item) =>
        item[1].validFrom[item[1].validFrom.length - 1]?.toDate() ?? null
    )
    .reduce((max, current) => {
      if (max === null) {
        return current;
      }
      if (current === null) {
        return max;
      }
      return current > max ? current : max;
    }, null);

  return defaultSelectedDate ?? null;
};
