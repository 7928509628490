import React, { useCallback } from "react";
import { ArrowForward } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import dayjs from "dayjs";

import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";
import { useSearchId } from "hooks/search";

import {
  useMaterials,
  useMiscParams,
  useProductionSchedule,
} from "contexts/search/provider";

import { PlanId } from "components/common/boundary/PlanId";
import { EditableTable } from "components/common/plan/EditableTable";
import { EditableTableSkeleton } from "components/common/plan/EditableTableSkeleton";

import {
  generatedApi,
  MaterialRead,
  NamedPlanMixes,
  OptimisationResultSetSummary,
  PlanMixUpdate,
  SearchContextData,
  useGetPlanMixesQuery,
  useUpdatePlanMixesMutation,
} from "src/store/api/generatedApi";
import { steelGradeSearchSlice } from "src/store/slices/steelGradeSearchSlice";
import { useAppDispatch } from "src/store/store";

import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";

type ContentProps = {
  mixes: NamedPlanMixes;
  materials: MaterialRead[];
  steelGradeColumnWidth: number;
  setPlanId: (planId: PlanId) => void;
  toNextState: () => void;
  planSummary: OptimisationResultSetSummary;
  context: SearchContextData;
};

export const ApprovePlanContent = ({
  mixes,
  materials,
  steelGradeColumnWidth,
  setPlanId,
  toNextState,
  planSummary,
  context,
}: ContentProps) => {
  const tenantName = useTenant();
  const searchId = useSearchId();
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const [doUpdatePlanMixesMutation] = useUpdatePlanMixesMutation();

  const [getResult] = generatedApi.useLazyGetResultQuery();

  const handleOnSubmit = useCallback(
    async (editedMixes: Record<string, PlanMixUpdate>) => {
      const { data: updated_plan, error } = await doUpdatePlanMixesMutation({
        tenantName,
        planId: mixes.plan_id,
        planMixUpdates: {
          period: 1,
          mixes: editedMixes,
          replacements: {},
        },
      });
      if (error === undefined && updated_plan !== undefined) {
        dispatch(
          steelGradeSearchSlice.actions.copy([
            mixes.plan_id,
            "deploy",
            updated_plan.plan_id,
          ])
        );

        if (searchId === null) {
          throw new Error(
            `Search id should not be null in context with plan id ${mixes.plan_id}`
          );
        }

        await getResult({ searchId, tenantName });
        setPlanId(updated_plan.plan_id as PlanId);
      }
    },
    [
      searchId,
      doUpdatePlanMixesMutation,
      tenantName,
      mixes,
      setPlanId,
      dispatch,
    ]
  );

  const startAt = mixes.start_at ? new Date(mixes.start_at) : new Date();
  const endAt = mixes.end_at
    ? new Date(mixes.end_at)
    : dayjs(startAt).add(1, "day").toDate();

  const materialInventory = React.useMemo(() => {
    return typeSafeObjectFromEntries(
      planSummary.period_material_summary
        .filter((material) => material.period === 1)
        .map((material) => [material.material_id, material.initial_inventory])
    );
  }, [materials, planSummary]);

  const steelGrades = React.useMemo(() => {
    const steelGradeIds = new Set(
      mixes.mixes.flatMap((mix) => mix.steel_grade_ids)
    );
    return context.steel_grades.filter((steelGrade) =>
      steelGradeIds.has(steelGrade.id)
    );
  }, [mixes, context]);

  return (
    <Stack sx={{ minHeight: 0, display: "flex", gap: 1 }}>
      <Button
        sx={{ alignSelf: "end" }}
        onClick={toNextState}
        endIcon={<ArrowForward />}
      >
        {t("approveAndNext")}
      </Button>
      <EditableTable
        visualPrice="full"
        canClickSteelGrade={false}
        steelGrades={steelGrades}
        planId={mixes.plan_id}
        disableEdit={false}
        mixes={mixes.mixes}
        materials={materials}
        materialInventory={materialInventory}
        steelGradeColumnWidth={steelGradeColumnWidth}
        onSubmit={handleOnSubmit}
        startAt={startAt}
        endAt={endAt}
        showNumberOfHeats
        deploymentDetails={null}
        view="deploy"
        showInventoryAndConsumption
      />
    </Stack>
  );
};

type Props = {
  toNextState: () => void;
  planId: PlanId;
  setPlanId: (planId: PlanId) => void;
};

export const ApprovePlan = ({ toNextState, planId, setPlanId }: Props) => {
  const tenantName = useTenant();
  const [productionSchedule] = useProductionSchedule();
  const [miscParams] = useMiscParams();
  const materials = useMaterials();
  const planMixes = useGetPlanMixesQuery({ tenantName, planId, period: 1 });

  if (
    productionSchedule.status === "success" &&
    miscParams.status === "success" &&
    planMixes.data &&
    materials.status === "success"
  ) {
    return (
      <ApprovePlanContent
        mixes={planMixes.data}
        materials={materials.data.byIndex}
        context={planMixes.data.context}
        planSummary={planMixes.data.summary}
        steelGradeColumnWidth={210}
        setPlanId={setPlanId}
        toNextState={toNextState}
      />
    );
  } else {
    return <EditableTableSkeleton />;
  }
};
