import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { createPlanUpdate } from "components/purchasing/utils";
import equal from "fast-deep-equal";
import {
  QuoteQuantityRow,
  Row,
} from "src/components/purchasing/plan/PurchasingPlanTable";
import { generatedApi, PurchasingPlanMaterial } from "../api/generatedApi";
import { AppDispatch, RootState } from "../store";

interface SaveQuotesArgs {
  planId: number;
  tenantName: string;
}

export const saveQuotes = createAsyncThunk<
  Row[],
  SaveQuotesArgs,
  { dispatch: AppDispatch; state: RootState }
>("purchasingPlanTableEdits/saveQuotes", async (saveQuotes, thunkApi) => {
  const editedRows = thunkApi.getState().purchasingPlanTableEdits.editedRows;
  await thunkApi.dispatch(
    generatedApi.endpoints.addQuotes.initiate({
      tenantName: saveQuotes.tenantName,
      purchasingPlanId: saveQuotes.planId,
      purchasingPlanQuotesAdd: createPlanUpdate(editedRows),
    })
  );
  return editedRows;
});

type PurchasingPlanTableEdits = {
  planId: number | null;
  originalRows: Row[];
  editedRows: Row[];
  materials: PurchasingPlanMaterial[];
};

const initialState: PurchasingPlanTableEdits = {
  planId: null,
  originalRows: [],
  editedRows: [],
  materials: [],
};

export const purchasingPlanTableEditsSlice = createSlice({
  name: "purchasingPlanTableEdits",
  initialState,
  reducers: {
    set(
      state,
      {
        payload: [planId, rows, materials],
      }: PayloadAction<[number, Row[], PurchasingPlanMaterial[]]>
    ) {
      state.planId = planId;
      state.originalRows = rows;
      state.editedRows = rows;
      state.materials = materials;
    },
    setEditedRows(state, { payload: rows }: PayloadAction<Row[]>) {
      state.editedRows = rows;
    },
    quoteAdded(state, { payload: rows }: PayloadAction<Row[]>) {
      state.editedRows = rows;
      state.originalRows = rows;
    },
    clear(state) {
      state.planId = null;
      state.originalRows = [];
      state.editedRows = [];
      state.materials = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveQuotes.fulfilled, (state, action) => {
      state.originalRows = action.payload;
    });
  },
});

const createAppSelector = createSelector.withTypes<RootState>();

export const selectPurchasingPlanTableEdits = (state: RootState) =>
  state.purchasingPlanTableEdits;

export const selectEditedRows = createAppSelector(
  [selectPurchasingPlanTableEdits],
  (purchasingPlanTableEdits): Row[] => purchasingPlanTableEdits.editedRows
);

export const selectHasUnsavedChanges = createAppSelector(
  [selectPurchasingPlanTableEdits],
  (purchasingPlanTableEdits): boolean =>
    !equal(
      purchasingPlanTableEdits.originalRows,
      purchasingPlanTableEdits.editedRows
    )
);

export const selectTotalOrderQuantity = createAppSelector(
  [selectPurchasingPlanTableEdits],
  (purchasingPlanTableEdits): number => {
    return purchasingPlanTableEdits.editedRows
      .filter((row): row is QuoteQuantityRow => row.type === "quote_quantity")
      .reduce((acc, row) => {
        return (
          acc +
          Object.values(row.quantities)
            .filter((quote): quote is number => quote !== null)
            .reduce((quoteAcc, quote) => quoteAcc + quote, 0)
        );
      }, 0);
  }
);

export const selectTotalUnfilledDemand = createAppSelector(
  [selectPurchasingPlanTableEdits],
  (purchasingPlanTableEdits): number => {
    const totalDemand = purchasingPlanTableEdits.materials.reduce(
      (acc, material) => {
        return acc + Math.max(0, material.demand - material.current_inventory);
      },
      0
    );

    const totalQuoteVolume = purchasingPlanTableEdits.editedRows
      .filter(
        (dataGridRow): dataGridRow is QuoteQuantityRow =>
          dataGridRow.type === "quote_quantity"
      )
      .reduce((acc, row) => {
        return (
          acc +
          Object.values(row.quantities)
            .filter((quote): quote is number => quote !== null)
            .reduce((quoteAcc, quote) => quoteAcc + quote, 0)
        );
      }, 0);

    return totalDemand - totalQuoteVolume;
  }
);

export const selectOverallAverageCost = createAppSelector(
  [selectPurchasingPlanTableEdits],
  (purchasingPlanTableEdits): number => {
    const totalOrderQuantity = purchasingPlanTableEdits.editedRows
      .filter((row): row is QuoteQuantityRow => row.type === "quote_quantity")
      .reduce((acc, row) => {
        return (
          acc +
          Object.values(row.quantities)
            .filter((quote): quote is number => quote !== null)
            .reduce((quoteAcc, quote) => quoteAcc + quote, 0)
        );
      }, 0);

    if (totalOrderQuantity === 0) {
      return 0;
    }

    const quantityRows = purchasingPlanTableEdits.editedRows.filter(
      (row): row is QuoteQuantityRow => row.type === "quote_quantity"
    );

    const priceRows = purchasingPlanTableEdits.editedRows.filter(
      (
        row
      ): row is Row & {
        type: "quote_price";
        quotePrices: Record<string, number>;
      } => row.type === "quote_price"
    );

    const totalCost = quantityRows.reduce((acc, quantityRow) => {
      const matchingPriceRow = priceRows.find(
        (priceRow) => priceRow.quoteId === quantityRow.quoteId
      );

      if (matchingPriceRow) {
        Object.entries(quantityRow.quantities).forEach(
          ([materialName, quantity]) => {
            const price = matchingPriceRow.prices[materialName] ?? 0;
            if (quantity && price) {
              return (acc += price * quantity);
            }
          }
        );
      }
      return acc;
    }, 0);

    return totalCost > 0
      ? Number((totalCost / totalOrderQuantity).toFixed(2))
      : 0;
  }
);
