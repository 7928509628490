import { Period } from "./periodIndex";
import { usePeriodTabTranslation } from "./formatters";
import React from "react";

type Metadata = {
  name: string | null;
  startTimestamp: string | null;
  endTimestamp: string | null;
};

export const usePeriodNameFormatter = () => {
  const placeholderFormatter = usePeriodTabTranslation();
  const formatter = React.useCallback(
    (index: Period, metadata?: Metadata) => {
      const placeholder = placeholderFormatter(index);
      const left =
        metadata?.startTimestamp === null
          ? null
          : metadata?.startTimestamp.split("T")[0] ?? null;
      const right =
        metadata?.endTimestamp === null
          ? null
          : metadata?.endTimestamp.split("T")[0] ?? null;

      return (
        metadata?.name ??
        (metadata?.endTimestamp !== null || metadata?.startTimestamp !== null
          ? `${left ?? ""} - ${right ?? ""}`
          : placeholder)
      );
    },
    [placeholderFormatter]
  );
  return formatter;
};
