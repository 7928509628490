import { Grid } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { useEffect, useState } from "react";
import {
  ChemicalSampleLocation,
  ChemicalSamplePositionType,
  TimeAggregation,
} from "src/store/api/generatedApi";
import { SelectBox } from "../Selectbox";
import { TableHeader } from "../TableHeader";
import { FailureRateMetric, GroupedFailureRateSeries } from "../types";
import { FailureRatePlot } from "./FailureRatePlot";
import { FailureRateTable } from "./FailureRateTable";

type FailureRateTabProps = {
  groupedFailureRateSeries: GroupedFailureRateSeries;
};

export const FailureRateTab = ({
  groupedFailureRateSeries,
}: FailureRateTabProps) => {
  const { t } = useTenantTranslation();

  const [selectedTimeAggregation, setSelectedTimeAggregation] =
    useState<TimeAggregation | null>(null);
  const [selectedMetric, setSelectedMetric] =
    useState<FailureRateMetric>("failureRate");
  const [selectedSampleLocation, setSelectedSampleLocation] =
    useState<ChemicalSampleLocation | null>(null);
  const [selectedSamplePositionType, setSelectedSamplePositionType] =
    useState<ChemicalSamplePositionType | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    // when the groupedFailureRateSeries changes, set the default values
    // for the select boxes
    setSelectedTimeAggregation(
      getDefaultTimeAggregation(
        groupedFailureRateSeries.availableTimeAggregations
      )
    );
    setSelectedSampleLocation(
      getDefaultSampleLocation(
        groupedFailureRateSeries.availableSampleLocations
      )
    );
    setSelectedSamplePositionType(
      getDefaultSamplePositionType(
        groupedFailureRateSeries.availableSamplePositionTypes
      )
    );
  }, [groupedFailureRateSeries]);

  useEffect(() => {
    // when one of the select boxes changes, set the default date
    // to the latest date in the series
    setSelectedDate(
      getDefaultSelectedDate(
        groupedFailureRateSeries,
        selectedTimeAggregation,
        selectedSampleLocation,
        selectedSamplePositionType
      )
    );
  }, [
    groupedFailureRateSeries,
    selectedTimeAggregation,
    selectedSampleLocation,
    selectedSamplePositionType,
  ]);

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Grid item>
          <SelectBox
            availableOptions={
              groupedFailureRateSeries.availableTimeAggregations
            }
            selectedOption={selectedTimeAggregation}
            onOptionChange={setSelectedTimeAggregation}
            id="time-aggregation-select"
            labelId="time-aggregation-label"
            label={t("frequency")}
          />
        </Grid>
        <Grid item>
          <SelectBox<FailureRateMetric>
            availableOptions={
              new Set<FailureRateMetric>(["failureRate", "numFailures"])
            }
            selectedOption={selectedMetric}
            onOptionChange={setSelectedMetric}
            id="metric-select"
            labelId="metric-label"
            label={t("metric")}
          />
        </Grid>
        <Grid item>
          <SelectBox
            availableOptions={groupedFailureRateSeries.availableSampleLocations}
            selectedOption={selectedSampleLocation}
            onOptionChange={setSelectedSampleLocation}
            id="sample-location-select"
            labelId="sample-location-label"
            label={t("sampleLocation")}
          />
        </Grid>
        <Grid item>
          <SelectBox
            availableOptions={
              groupedFailureRateSeries.availableSamplePositionTypes
            }
            selectedOption={selectedSamplePositionType}
            onOptionChange={setSelectedSamplePositionType}
            id="sample-position-type-select"
            labelId="sample-position-type-label"
            label={t("samplePositionType")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={2} mx={0}>
        <Grid item xs={6} ml={0}>
          <FailureRatePlot
            failureRateSeries={groupedFailureRateSeries}
            selectedTimeAggregation={selectedTimeAggregation}
            selectedMetric={selectedMetric}
            selectedSampleLocation={selectedSampleLocation}
            selectedSamplePositionType={selectedSamplePositionType}
            setSelectedDate={setSelectedDate}
          />
        </Grid>
        <Grid item xs={6}>
          <TableHeader
            titlePrefix={t("failuresFrom")}
            selectedDate={selectedDate}
            formatDateMonthly={selectedTimeAggregation === "monthly"}
            subtitleText={t("failureRateTableSubtitleText")}
          />
          <FailureRateTable
            groupedFailureRateSeries={groupedFailureRateSeries}
            selectedDate={selectedDate}
            selectedTimeAggregation={selectedTimeAggregation}
            selectedSampleLocation={selectedSampleLocation}
            selectedSamplePositionType={selectedSamplePositionType}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const getDefaultTimeAggregation = (
  availableTimeAggregations: Set<TimeAggregation>
) => {
  return availableTimeAggregations.has("monthly")
    ? "monthly"
    : Array.from(availableTimeAggregations)[0] ?? null;
};

const getDefaultSampleLocation = (
  availableSampleLocations: Set<ChemicalSampleLocation>
) => {
  return Array.from(availableSampleLocations)[0] ?? null;
};

const getDefaultSamplePositionType = (
  availableSamplePositionTypes: Set<ChemicalSamplePositionType>
) => {
  return Array.from(availableSamplePositionTypes)[0] ?? null;
};

const getDefaultSelectedDate = (
  groupedFailureRateSeries: GroupedFailureRateSeries,
  selectedTimeAggregation: TimeAggregation | null,
  selectedSampleLocation: ChemicalSampleLocation | null,
  selectedSamplePositionType: ChemicalSamplePositionType | null
) => {
  const defaultSelectedDateString = groupedFailureRateSeries.values
    .filter(
      (item) =>
        item[0].timeAggregation == selectedTimeAggregation &&
        item[0].sampleLocation == selectedSampleLocation &&
        item[0].samplePositionType == selectedSamplePositionType
    )
    .map((item) => item[1].date[item[1].date.length - 1] ?? null)
    .reduce((max, current) => {
      if (max === null) {
        return current;
      }
      if (current === null) {
        return max;
      }
      return current > max ? current : max;
    }, null);
  return defaultSelectedDateString ? new Date(defaultSelectedDateString) : null;
};
