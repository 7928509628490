import { useTheme } from "@mui/material/styles";
import * as React from "react";

type BarCellProps = {
  value: number;
  maxValue: number;
  formattedValue: string;
  color: string;
};

export const BarCell = React.memo(function ProgressBar({
  value,
  maxValue,
  formattedValue,
  color,
}: BarCellProps) {
  const theme = useTheme();
  const barWidthPercent = value > 0 ? (value / maxValue) * 100 : 0;

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: 26,
        borderRadius: 2,
      }}
    >
      <div
        style={{
          position: "absolute",
          lineHeight: "24px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "8px",
          zIndex: 1,
        }}
      >
        {formattedValue}
      </div>
      <div
        style={{
          maxWidth: `${barWidthPercent}%`,
          backgroundColor: color,
          height: "100%",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 2,
        }}
      />
    </div>
  );
});
