import React from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  DeploymentType,
  Latest,
  MaterialRead,
  NamedPlanMix,
  Next,
  PlanMixUpdate,
  SteelGrade,
} from "src/store/api/generatedApi";

import { useTenantTranslation } from "hooks/formatters";

import { resetEventParam, Row, Table } from "./Table";
import { cleanBaskets, isMixEqual, makeRows, mergeRowsAndMix } from "./utils";
import { useAppDispatch, useAppSelector, useAppStore } from "src/store/store";
import equal from "fast-deep-equal";
import {
  planMixTableEditsSlice,
  selectPlanMixTableEdits,
} from "src/store/slices/planMixesTableEditSlice";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { SteelGradesSelect } from "./SteelGradesSelect";
import { selectSearchedSteelGradeIds } from "src/store/slices/steelGradeSearchSlice";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import { HistoryRounded } from "@mui/icons-material";
import { PlanAncestors } from "../planHistory/PlanAncestors";

export type BaseProps = {
  planId: number;
  steelGrades: SteelGrade[];
  materials: MaterialRead[];
  materialInventory: Record<number, number>;
  mixes: NamedPlanMix[];
  steelGradeColumnWidth: number;
  disableEdit: boolean;
  canClickSteelGrade: boolean;
  onSubmit: (updatedMixes: Record<string, PlanMixUpdate>) => Promise<void>;
  visualPrice: "full" | "diff";
  showNumberOfHeats: boolean;
  deploymentDetails:
    | [Exclude<DeploymentType, "backup" | "edited_backup">, Latest | Next]
    | null;
  view: "backup" | "supervisor" | "deploy";
  showInventoryAndConsumption: boolean;
};

type TimeRangedProps = {
  startAt: Date;
  endAt: Date;
} & BaseProps;

type TimeDeployedProps = {
  deployedAt: Date;
} & BaseProps;

type Props = TimeRangedProps | TimeDeployedProps;

type RowTableProps = Props & { originalRows: Row[]; editedRows: Row[] };

export const createPlanMixUpdates = (
  originalMixes: NamedPlanMix[],
  newMixes: NamedPlanMix[]
): Record<string, PlanMixUpdate> => {
  const originalMixesById = typeSafeObjectFromEntries(
    originalMixes.map((mix) => [mix.mix_id, mix])
  );
  const editedMixes = newMixes.filter((mix) => {
    const originalMix = originalMixesById[mix.mix_id];
    if (originalMix === undefined) {
      return true;
    } else {
      return !isMixEqual(mix, originalMix);
    }
  });
  return editedMixes.reduce((mixesUpdate, mix) => {
    const { steel_grade_production, period, baskets, mix_id } = mix;

    const cleanedBaskets = cleanBaskets(baskets);

    return {
      ...mixesUpdate,
      [mix_id]: {
        steel_grade_production,
        period,
        baskets: cleanedBaskets,
      },
    };
  }, {});
};

export const EditableTable = ({
  mixes,
  planId,
  steelGrades,
  ...props
}: Props) => {
  const dispatch = useAppDispatch();
  const planEdits = useAppSelector((state) =>
    selectPlanMixTableEdits(state, planId)
  );
  if (!planEdits) {
    dispatch(
      planMixTableEditsSlice.actions.set([
        planId,
        makeRows(planId, mixes, steelGrades),
      ])
    );
    return null;
  } else {
    const { originalRows, editedRows } = planEdits;
    return (
      <RowsTable
        mixes={mixes}
        planId={planId}
        steelGrades={steelGrades}
        originalRows={originalRows}
        editedRows={editedRows}
        {...props}
      />
    );
  }
};

export const RowsTable = ({
  planId,
  steelGrades,
  materials,
  materialInventory,
  steelGradeColumnWidth,
  disableEdit,
  onSubmit,
  visualPrice,
  canClickSteelGrade,
  showNumberOfHeats,
  deploymentDetails,
  view,
  showInventoryAndConsumption,
  originalRows,
  editedRows,
  mixes,
  ...props
}: RowTableProps) => {
  const gridApiRef = useGridApiRef();
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const selectedSteelGradeIds = useAppSelector((state) =>
    selectSearchedSteelGradeIds(state, planId, view)
  );

  const handleOnTableChange = React.useCallback(
    (editedRows: Row[]) => {
      dispatch(planMixTableEditsSlice.actions.update([planId, editedRows]));
    },
    [planId]
  );

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleOnCancel = React.useCallback(() => {
    dispatch(planMixTableEditsSlice.actions.clear(planId));
    if (gridApiRef.current) {
      gridApiRef.current.updateRows(originalRows);
      gridApiRef.current.publishEvent("stateChange", resetEventParam);
    }
  }, [dispatch, planId, originalRows]);

  const handleOnSubmit = async () => {
    const planEdits = selectPlanMixTableEdits(store.getState(), planId);
    if (planEdits !== undefined) {
      setIsSubmitting(true);
      const submitMixes = mixes.map((mix) => {
        return mergeRowsAndMix(planEdits.editedRows, mix);
      });
      const updatedMixes = createPlanMixUpdates(mixes, submitMixes);
      await onSubmit(updatedMixes);
      setIsSubmitting(false);
      dispatch(planMixTableEditsSlice.actions.clear(planId));
    }
  };

  const hasEdited = !disableEdit && !equal(editedRows, originalRows);
  const isSubmitDisabled = !hasEdited || isSubmitting;

  const tableMaterials = React.useMemo(() => {
    return materials
      .filter((material) => material.addition_location === "basket")
      .sort(
        (materialA, materialB) =>
          materialA.deployment_ordering - materialB.deployment_ordering
      )
      .map((material) => {
        return {
          id: material.id,
          name: material.name,
          inventory: materialInventory[material.id] ?? null,
        };
      });
  }, [materials]);

  const timeString: string = (() => {
    if ("deployedAt" in props) {
      return `${t("lastDeployedOn")} ${dayjs(props.deployedAt).format(
        "MMM D YYYY HH:mm"
      )}`;
    } else {
      return `${dayjs(props.startAt).format("MMM D YYYY HH:mm")} - ${dayjs(
        props.endAt
      ).format("MMM D YYYY HH:mm")}`;
    }
  })();

  const tableStartAt = "deployedAt" in props ? props.deployedAt : props.startAt;
  const tableEndAt = "deployedAt" in props ? null : props.endAt;

  const tooltipContent = (
    <Box
      sx={{
        maxHeight: "60vh",
        overflowY: "auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        minWidth: 300,
      }}
    >
      <PlanAncestors planId={planId} />
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          my: 1,
          alignItems: "center",
          gap: 1,
          display: "grid",
          height: 40,
          gridTemplateColumns:
            "min-content minmax(min-content, 1fr) repeat(3, min-content)",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {timeString}
        </Typography>
        <SteelGradesSelect
          view={view}
          planId={planId}
          steelGrades={steelGrades}
        />
        <Box>
          <Tooltip
            title={tooltipContent}
            placement="bottom"
            arrow
            enterDelay={100}
            leaveDelay={500}
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    bgcolor: "background.paper",
                    color: "text.primary",
                    p: 0,
                    maxWidth: "none",
                  },
                },
              },
            }}
          >
            <IconButton size="small">
              <HistoryRounded fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Button
          disabled={isSubmitDisabled}
          onClick={handleOnCancel}
          color="primary"
          variant="text"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {t("discardChanges")}
        </Button>
        <LoadingButton
          loading={isSubmitting}
          disabled={!hasEdited}
          onClick={handleOnSubmit}
          color="primary"
          variant="contained"
          sx={{ whiteSpace: "nowrap" }}
        >
          {t("saveChanges")}
        </LoadingButton>
      </Box>
      <Table
        apiRef={gridApiRef}
        visualPrice={visualPrice}
        canClickSteelGrade={canClickSteelGrade}
        steelGradeColumnWidth={steelGradeColumnWidth}
        disableEdit={isSubmitting || disableEdit}
        rows={editedRows}
        materials={tableMaterials}
        selectedSteelGrades={selectedSteelGradeIds}
        onChange={handleOnTableChange}
        showNumberOfHeats={showNumberOfHeats}
        deploymentDetails={deploymentDetails}
        startAt={tableStartAt}
        endAt={tableEndAt}
        view={view}
        showInventoryAndConsumption={showInventoryAndConsumption}
      />
    </>
  );
};
