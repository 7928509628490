import { Dash, Layout, MarkerSymbol } from "plotly.js";
import {
  ChemicalElementSymbol,
  MaterialGroupName,
  MaterialGroupingName,
} from "src/store/api/generatedApi";

export const fractionTickFormatting = ",.1%";
export const generalTickFormatting = "~s";
export const chemicalElementColours = new Map<ChemicalElementSymbol, string>([
  ["Cu", "#f0ac00"],
  ["Sn", "#e159ff"],
  ["Ni", "#13bfcf"],
  ["Cr", "#838feb"],
  ["Mo", "#3dd600"],
]);

const materialColours = [
  "#838feb",
  "#e159ff",
  "#13bfcf",
  "#f0ac00",
  "#3dd600",
  "#ff6459",
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
  "#aec7e8",
  "#ffbb78",
  "#98df8a",
  "#ff9896",
  "#c5b0d5",
  "#c49c94",
  "#f7b6d2",
  "#c7c7c7",
  "#dbdb8d",
  "#9edae5",
];

const lineDashes: Dash[] = [
  "solid",
  "dot",
  "dash",
  "longdash",
  "dashdot",
  "longdashdot",
];
const markerSymbols: MarkerSymbol[] = [
  "circle",
  "square",
  "diamond",
  "triangle-up",
  "triangle-down",
  "triangle-right",
];

interface MaterialGroupAndGrouping {
  groupingName: MaterialGroupingName;
  groupName: MaterialGroupName;
}

export const buildMaterialColours = (
  materialGroupAndGroupings: MaterialGroupAndGrouping[]
): Map<string, string> => {
  const groupingCounters = new Map<MaterialGroupingName, number>();
  const map = new Map<string, string>();

  for (const { groupingName, groupName } of materialGroupAndGroupings) {
    const counter = groupingCounters.get(groupingName) ?? 0;
    const key = JSON.stringify({ groupingName, groupName });
    if (map.has(key)) {
      continue;
    }
    const colour = materialColours[counter % materialColours.length]!;
    map.set(key, colour);
    groupingCounters.set(groupingName, counter + 1);
  }
  return map;
};

export const getMaterialColour = (
  materialColours: Map<string, string>,
  groupingName: MaterialGroupingName,
  groupName: MaterialGroupName
) => {
  const key = JSON.stringify({ groupingName, groupName });
  return materialColours.get(key);
};

export const getLineDash = (index: number) => {
  return lineDashes[index % lineDashes.length];
};

export const getMarkerSymbol = (index: number) => {
  return markerSymbols[index % markerSymbols.length];
};

export const defaultLayout: Partial<Layout> = {
  height: 700,
  hovermode: "x unified",
  margin: {
    t: 10,
    r: 0,
  },
  legend: {
    x: 0.5,
    y: -0.05,
    orientation: "h",
    xanchor: "center",
    yanchor: "top",
    groupclick: "toggleitem",
  },
  autosize: true,
};

export const setSelectedDateFromPlotClick = (
  setSelectedDate: (date: Date) => void
): ((event: Plotly.PlotMouseEvent) => void) => {
  return (event: Plotly.PlotMouseEvent) => {
    if (event.points && event.points.length > 0) {
      const point = event.points[0];
      if (point?.x) {
        const clickedDate = new Date(point.x as string);
        setSelectedDate(clickedDate);
      }
    }
  };
};
