import { Button, Stack, Typography, Checkbox, useTheme } from "@mui/material";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNotifyStatus } from "contexts/status";
import { ViewRailcars } from "./railcars";
import { useGetVideoUrlQuery } from "src/store/api/generatedApi";
import { SelectVideo } from "./selectVideo";
import { loadedEndpoint, mapLoadedUnpack } from "models/loaded";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTenant } from "hooks/settings";
import { useSearchParams } from "react-router";
import { LabelVideo } from "./labelling";
import { useLocalStorageState } from "./helpers";
import { JumpToDefaultFrameIndex, useVideoControls } from "./videoControls";

export const VideoLabellingPage = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const notifyStatus = useNotifyStatus();
  const tenant = useTenant();
  const theme = useTheme();

  const [width, setHeight] = useLocalStorageState("videoLabellingHeight", 1200);
  const [speed, setSpeed] = useLocalStorageState("videoLabellingSpeed", 1);
  const [downsampled, setDownsampled] = useLocalStorageState(
    "videoLabellingDownsampled",
    true
  );
  const [flip, setFlip] = useLocalStorageState("videoLabellingFlip", false);
  useHotkeys(";", () => setFlip((current) => !current));

  const [statusText, setStatusText] = useState<string | null>(null);
  const [videoNativeWidth, setVideoNativeWidth] = useState<number>(0);
  const [videoNativeHeight, setVideoNativeHeight] = useState<number>(0);

  const defaultVideoKey = useSearchParams()[0].get("video-key") ?? null;

  const heightSerialiser = useNumberSerialiser();
  const speedSerialiser = useNumberSerialiser({ min: 0 });

  const controls = useVideoControls(videoRef.current, speed, downsampled);
  const renderedStatusText =
    controls === null
      ? ""
      : controls.frameIndex >= controls.totalFrames && controls.totalFrames > 0
      ? "Vido finshd"
      : statusText;

  const nothing = () => null;

  const [isDrawing, setIsDrawing] = useState(false);
  const [centerPoint, setCenterPoint] = useState({ x: 0, y: 0 });
  const bboxSizes = [224, 299, 384];
  const [bboxSize, setBboxSize] = useState(224);
  const [showOverlay, setShowOverlay] = useState(false);

  const getLocalBboxSize = () => {
    const boundingRect = videoRef.current?.getBoundingClientRect();
    if (!boundingRect) return 0;
    const scaleX = videoNativeWidth / boundingRect.width;
    return bboxSize / scaleX;
  };

  const nextBboxSize = () => {
    const currentIndex = bboxSizes.indexOf(bboxSize ?? 224);
    const nextIndex = (currentIndex + 1) % bboxSizes.length;
    setBboxSize(bboxSizes[nextIndex] ?? 224);
  };

  const handleDrawBBox = () => {
    setIsDrawing(true);
    setShowOverlay(false);
    setCenterPoint({ x: 0, y: 0 });
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowOverlay(false);
    if (!isDrawing || !videoRef.current) return;

    const rect = videoRef.current.getBoundingClientRect();
    setCenterPoint({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
    setShowOverlay(true);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDrawing || !videoRef.current) return;

    const rect = videoRef.current.getBoundingClientRect();
    setCenterPoint({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleMouseUp = () => {
    if (!isDrawing || !videoRef.current) return;

    // Convert from local coordinates to native video coordinates
    const boundingRect = videoRef.current.getBoundingClientRect();

    const scaleX = videoNativeWidth / boundingRect.width;
    const scaleY = videoNativeHeight / boundingRect.height;

    const startX = centerPoint.x - bboxSize / 2;
    const startY = centerPoint.y - bboxSize / 2;
    const bboxNativeX = startX * scaleX;
    const bboxNativeY = startY * scaleY;
    const bboxNativeSize = bboxSize;
    const bboxLocalSize = bboxSize / scaleX;

    console.log(
      `\n\nLocal BBox coordinates: \nx=${startX.toFixed(
        0
      )}, \ny=${startY.toFixed(0)}, \nwidth=${bboxLocalSize.toFixed(
        0
      )}, \nheight=${bboxLocalSize.toFixed(0)}`
    );
    console.log(
      `Native BBox coordinates: \n"x"=${bboxNativeX.toFixed(
        0
      )}, \n"y"=${bboxNativeY.toFixed(0)}, \n"width"=${bboxNativeSize.toFixed(
        0
      )}, \n"height"=${bboxNativeSize.toFixed(
        0
      )}, \n"target_width"=${bboxNativeSize.toFixed(
        0
      )}, \n"target_height"=${bboxNativeSize.toFixed(0)}`
    );

    setIsDrawing(false);
    //setShowOverlay(false);
  };

  useHotkeys("=", () => {
    const frameIndex = parseFloat(prompt("Jump to frame index:") ?? "");
    if (!isNaN(frameIndex) && controls) {
      controls.setFrameIndex(frameIndex);
    }
  });

  useHotkeys("up", controls?.toggle ?? nothing);
  useHotkeys("left", controls?.skipBackward ?? nothing);
  useHotkeys("right", controls?.skipForward ?? nothing);

  const [videoKey, setVideoKey] = useState<string | null>(defaultVideoKey);
  const [camera, setCamera] = useState<number>(2);
  const videoUrl = mapLoadedUnpack(
    loadedEndpoint(
      useGetVideoUrlQuery(
        videoKey === null
          ? skipToken
          : { tenantName: tenant, videoKey, camera, downsampled }
      )
    ),
    (url) => url
  );

  const rotation = 180 + (downsampled ? 180 : 0) + (flip ? 0 : 180);

  return (
    <>
      <Stack direction="row" alignItems="center" gap={2} p={1}>
        <SelectVideo
          videoKey={videoKey}
          setVideoKey={setVideoKey}
          camera={camera}
          setCamera={setCamera}
        />

        <Stack direction="row" alignItems="center" gap={1} sx={{ width: 150 }}>
          <Typography>Height</Typography>
          <ValidatedTextField
            value={width}
            setValue={setHeight}
            serialiser={heightSerialiser}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} sx={{ width: 150 }}>
          <Typography>Speed</Typography>
          <ValidatedTextField
            value={speed}
            setValue={setSpeed}
            serialiser={speedSerialiser}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ width: 150, cursor: "pointer" }}
          onClick={() => setDownsampled((current) => !current)}
        >
          <Typography sx={{ userSelect: "none" }}>Downsampled</Typography>
          <Checkbox
            checked={downsampled}
            size="small"
            sx={{ padding: 0, margin: 0 }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ width: 150, cursor: "pointer" }}
          onClick={() => setFlip((current) => !current)}
        >
          <Typography sx={{ userSelect: "none" }}>Flip</Typography>
          <Checkbox
            checked={flip}
            size="small"
            sx={{ padding: 0, margin: 0 }}
          />
        </Stack>

        {videoKey === null ? (
          <Button disabled>Railcars</Button>
        ) : (
          <ViewRailcars timestamp={videoKey} />
        )}

        {!isDrawing && !showOverlay && (
          <Button variant="contained" onClick={handleDrawBBox} color="primary">
            Draw BBox
          </Button>
        )}
        {isDrawing ? (
          <Button
            variant="contained"
            onClick={handleDrawBBox}
            color="secondary"
          >
            Drawing...
          </Button>
        ) : null}
        {!isDrawing && showOverlay ? (
          <>
            <Button
              variant="contained"
              onClick={handleDrawBBox}
              color="secondary"
            >
              Hide
            </Button>
            <Button
              variant="contained"
              onClick={nextBboxSize}
              color="secondary"
            >
              Size
            </Button>
          </>
        ) : null}
      </Stack>

      <Stack
        sx={{ width: width, marginLeft: "auto", marginRight: "auto" }}
        direction="row"
        justifyContent="center"
        position="relative"
      >
        {controls ? (
          <JumpToDefaultFrameIndex setFrameIndex={controls.setFrameIndex} />
        ) : null}
        <div
          style={{ position: "relative" }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <video
            ref={videoRef}
            src={videoUrl ?? undefined}
            style={{
              height: "100%",
              width: "100%",
              display: "block",
              transform: `rotate(${rotation}deg)`,
              transformOrigin: "center",
            }}
            onEnded={() =>
              notifyStatus({ type: "info", text: "Video finished" })
            }
            onLoadedMetadata={() => {
              if (videoRef.current) {
                setVideoNativeWidth(videoRef.current.videoWidth);
                setVideoNativeHeight(videoRef.current.videoHeight);
              }
            }}
          />
          {showOverlay ? (
            <div
              style={{
                position: "absolute",
                left: centerPoint.x - getLocalBboxSize() / 2,
                top: centerPoint.y - getLocalBboxSize() / 2,
                width: getLocalBboxSize(),
                height: getLocalBboxSize(),
                border: `2px solid ${theme.palette.primary.main}`,
                pointerEvents: "none",
              }}
            >
              <Typography
                sx={{
                  fontSize: 24,
                  color: theme.palette.primary.main,
                  position: "absolute",
                  bottom: 0,
                  left: 10,
                }}
              >
                {bboxSize}
              </Typography>
            </div>
          ) : null}
        </div>
        {renderedStatusText === null ? null : (
          <Typography
            sx={{
              position: "absolute",
              fontSize: 140,
              top: "50%",
              transform: "translateY(-50%)",
              color: theme.palette.data.red,
            }}
          >
            {renderedStatusText}
          </Typography>
        )}
      </Stack>

      {controls !== null && videoKey !== null ? (
        <LabelVideo
          key={videoKey}
          videoKey={videoKey}
          controls={controls}
          setStatusText={setStatusText}
          width={width}
        />
      ) : null}
    </>
  );
};
