import { Close } from "@mui/icons-material";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { LoadedHeatSummaryTable } from "components/scrapTracking/focus/heatSummaryTable";
import { useTenant } from "hooks/settings";
import { loadedEndpoint, mapLoaded } from "models/loaded";
import { Fragment } from "react/jsx-runtime";
import { useGetHeatSummaryQuery } from "src/store/api/generatedApi";
import { LoadingBucket } from "../loadsView";

export const HeatBreakdown = ({
  heatId,
  setHeatId,
}: {
  heatId: string;
  setHeatId: (heatId: string | null) => void;
}) => {
  const heatSummary = loadedEndpoint(
    useGetHeatSummaryQuery({ tenantName: useTenant(), heatId })
  );
  return (
    <Stack gap={2} sx={{ height: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Heat {heatId}</Typography>
        <IconButton onClick={() => setHeatId(null)}>
          <Close />
        </IconButton>
      </Stack>

      <Stack
        sx={{ overflowY: "auto", paddingBottom: 4 }}
        gap={2}
        alignItems="center"
      >
        <Stack sx={{ width: 0.85, gap: 2 }}>
          <LoadedHeatSummaryTable
            chargeSummaries={mapLoaded(
              heatSummary,
              (loadedHeatSummary) => loadedHeatSummary?.charges ?? null
            )}
          />
        </Stack>

        {[1, 2, 3].map((charge) => (
          <Fragment key={charge}>
            <Divider sx={{ opacity: 0.2 }} />
            <Stack sx={{ width: 0.85 }}>
              <LoadingBucket
                key={charge}
                title={`Charge ${charge}`}
                missingText="Not loaded"
                chargeSummary={mapLoaded(
                  heatSummary,
                  (loadedCharges) =>
                    (loadedCharges.charges ?? [])[charge - 1] ?? null
                )}
              />
            </Stack>
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
