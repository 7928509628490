import { Skeleton } from "@mui/material";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useSummarisePlanMixesMutation } from "src/store/api/generatedApi";

import { useSummaryMutation } from "./useSummaryMutation";

type Props = {
  planId: number;
  mixId: number;
};

type BodyProps = {
  mixId: number;
  mutationResult: ReturnType<typeof useSummaryMutation>[1];
};

const Body = ({ mutationResult, mixId }: BodyProps) => {
  switch (mutationResult.status) {
    case QueryStatus.rejected:
    case QueryStatus.uninitialized:
      return null;
    case QueryStatus.pending:
      return <Skeleton width={25} />;
    case QueryStatus.fulfilled: {
      const mix = mutationResult.data.mixes.find((mix) => mix.mix_id === mixId);
      if (mix) {
        return mix.dri?.mass.toFixed(0);
      }
    }
  }
};

export const DRICell = ({ planId, mixId }: Props) => {
  const [, summarisePlanMixesMutationResult] = useSummarisePlanMixesMutation({
    fixedCacheKey: [planId, mixId].join("."),
  });

  return (
    <Body mixId={mixId} mutationResult={summarisePlanMixesMutationResult} />
  );
};
