import { Divider, Stack, Typography } from "@mui/material";
import { LabelledSwitch } from "components/search/input/inputEditors/suppressConstraints";

export type ScrapTrackingSettings = Partial<{
  dropProbability: boolean;
  labelProbability: boolean;

  scaleWeightDropEvents: boolean;
  visionModelDropEvents: boolean;

  scaleWeights: boolean;
  loadingStatus: boolean;

  heatSummaries: boolean;
  scrapCarCycles: boolean;

  labelSources: boolean;
}>;

export const ScrapTrackingSettingsPage = ({
  settings,
  setSettings,
}: {
  settings: ScrapTrackingSettings;
  setSettings: (settings: ScrapTrackingSettings) => void;
}) => {
  return (
    <Stack gap={1}>
      <Typography sx={{ mb: 1 }} variant="h2">
        Tracking Dashboard
      </Typography>

      <LabelledSwitch
        label="Drop probability"
        value={Boolean(settings.dropProbability)}
        setValue={(update) =>
          setSettings({
            ...settings,
            dropProbability: update(Boolean(settings.dropProbability)),
          })
        }
      />
      <LabelledSwitch
        label="Label probability"
        value={Boolean(settings.labelProbability)}
        setValue={(update) => {
          return setSettings({
            ...settings,
            labelProbability: update(Boolean(settings.labelProbability)),
          });
        }}
      />

      <Divider sx={{ opacity: 0.2 }} />

      <LabelledSwitch
        label="Scale weight drop events"
        value={Boolean(settings.scaleWeightDropEvents)}
        setValue={(update) => {
          return setSettings({
            ...settings,
            scaleWeightDropEvents: update(
              Boolean(settings.scaleWeightDropEvents)
            ),
          });
        }}
      />

      <LabelledSwitch
        label="Vision model drop events"
        value={Boolean(settings.visionModelDropEvents)}
        setValue={(update) => {
          return setSettings({
            ...settings,
            visionModelDropEvents: update(
              Boolean(settings.visionModelDropEvents)
            ),
          });
        }}
      />

      <Divider sx={{ opacity: 0.2 }} />

      <LabelledSwitch
        label="Scale weights"
        value={Boolean(settings.scaleWeights)}
        setValue={(update) =>
          setSettings({
            ...settings,
            scaleWeights: update(Boolean(settings.scaleWeights)),
          })
        }
      />

      <LabelledSwitch
        label="Scrap car loading status"
        value={Boolean(settings.loadingStatus)}
        setValue={(update) => {
          return setSettings({
            ...settings,
            loadingStatus: update(Boolean(settings.loadingStatus)),
          });
        }}
      />

      <Divider sx={{ opacity: 0.2 }} />

      <LabelledSwitch
        label="Heat summaries"
        value={Boolean(settings.heatSummaries)}
        setValue={(update) =>
          setSettings({
            ...settings,
            heatSummaries: update(Boolean(settings.heatSummaries)),
          })
        }
      />

      <LabelledSwitch
        label="Scrap car cycles"
        value={Boolean(settings.scrapCarCycles)}
        setValue={(update) =>
          setSettings({
            ...settings,
            scrapCarCycles: update(Boolean(settings.scrapCarCycles)),
          })
        }
      />

      <Divider sx={{ opacity: 0.2 }} />

      <LabelledSwitch
        label="Label sources"
        value={Boolean(settings.labelSources)}
        setValue={(update) =>
          setSettings({
            ...settings,
            labelSources: update(Boolean(settings.labelSources)),
          })
        }
      />
    </Stack>
  );
};
