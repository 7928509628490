import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import {
  DataGridPremium,
  GridColDef,
  GridPinnedColumnFields,
  GridPinnedRowsProp,
} from "@mui/x-data-grid-premium";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import {
  ChefGroup,
  MaterialRead,
  MixBasketSummary,
  ProductGroup,
} from "src/store/api/generatedApi";
import { TitleColumnHeader } from "../common/TitleColumnHeader";
import { AggregationCell, VisualisedCell } from "./ProductionCell";
import { Group, Measure } from "./ProductionContext";
import { ScrapRowHeader } from "./ScrapRowHeader";
import { PlanGroup, PlanMix, PlanMixes } from "./productionContent";

import { useTenantData } from "contexts/tenant";
import type { JSX } from "react";
import { BaseHeatHeader, HeatHeader } from "./HeatHeader";

type RowData = Record<number, Record<number, PlanMix & { id: number }>>;

type AggregationRowId =
  | "total_mass"
  | "total_price"
  | "materials_price"
  | "electrical_energy_price";

type ScrapRow = {
  id: number | AggregationRowId;
  scrap: MaterialRead;
  data: RowData;
};

type Props = {
  materials: MaterialRead[];
  measure: Measure;
  planGroups: PlanGroup[];
  planMixes: PlanMixes;
  grouping: Record<number, ProductGroup> | Record<number, ChefGroup>;
  steelGrades: Set<string>;
  chemistryGroups: Record<number, ChefGroup>;
  group: Group;
  mixBasketSummaries: MixBasketSummary[];
  sx: SxProps<Theme>;
};

const useLayout = (
  materials: MaterialRead[],
  measure: Measure,
  planGroups: PlanGroup[],
  planMixes: PlanMixes,
  grouping: Record<number, ProductGroup> | Record<number, ChefGroup>,
  steelGrades: Set<string>,
  chemistryGroups: Record<number, ChefGroup>,
  group: Group,
  mixBasketSummaries: MixBasketSummary[]
) => {
  const { t } = useTenantTranslation();
  const { basket_tab_type: basketTabType } = useTenantData();
  const unit = useUnitsFormatter(true);

  const groupings: [ProductGroup, PlanGroup][] = planGroups
    .map((group): [ProductGroup, PlanGroup] => [
      grouping[group.groupId]!,
      group,
    ])
    .filter(
      ([grouping]) =>
        steelGrades.size === 0 ||
        grouping.steel_grades.some(({ name }) => steelGrades.has(name))
    );

  const scrapColumn: GridColDef<ScrapRow> = {
    field: "scrap",
    sortable: false,
    minWidth: 200,
    display: "flex",
    renderHeader: () => <TitleColumnHeader title={t("scrap")} />,
    headerClassName: "group--header",
    cellClassName: "group--header",
    valueGetter: (_, { scrap, id }) => {
      switch (id) {
        case "total_mass":
          return `${t("totalMass")}${unit("mass")}`;
        case "total_price":
          return `${t("totalPrice")}${unit("cost")}}`;
        case "materials_price":
          return `${t("totalPrice")}${unit("cost")}}`;
        case "electrical_energy_price":
          return `${t("totalPrice")}${unit("cost")}}`;
        default: {
          return scrap.name;
        }
      }
    },
    renderCell: ({
      row: {
        scrap: { name, id: material_id },
        id,
      },
    }) => {
      return <ScrapRowHeader rowId={id} id={material_id} name={name} />;
    },
  };

  const groupColumns: GridColDef<ScrapRow>[] = groupings.map(
    (
      [{ id, name }, { heats, mixId: mixId, multipleMixes, groupId: groupId }],
      index
    ) => {
      return {
        field: "group_" + index.toString(),
        sortable: false,
        minWidth: 132,
        headerClassName: "group--header",

        valueGetter: (_, { data }) => {
          if (id !== null && id !== undefined && data[id]?.[mixId]) {
            const { id: materialId, materials, total } = data[id]![mixId]!;
            const value = materials[materialId];
            if (value) {
              switch (measure) {
                case Measure.Percentage: {
                  return value / total;
                }
                case Measure.Mass: {
                  return value;
                }
              }
            } else {
              return null;
            }
          }
        },
        renderCell: ({ row: { data, id: rowId } }): JSX.Element | null => {
          if (id != null && data[id]?.[mixId]) {
            const {
              materials,
              total,
              id: materialId,
              maximum,
              total_price,
              materials_price,
              electrical_energy_price,
            } = data[id]![mixId]!;

            switch (rowId) {
              case "total_mass": {
                return <AggregationCell value={total} variant="mass" />;
              }
              case "total_price": {
                return <AggregationCell value={total_price} variant="price" />;
              }
              case "materials_price": {
                return (
                  <AggregationCell value={materials_price} variant="price" />
                );
              }
              case "electrical_energy_price": {
                return (
                  <AggregationCell
                    value={electrical_energy_price}
                    variant="price"
                  />
                );
              }
              default: {
                const value = materials[materialId];
                return value == null || value === 0.0 ? null : (
                  <VisualisedCell
                    value={value}
                    total={total}
                    maximum={maximum}
                  />
                );
              }
            }
          } else {
            return null;
          }
        },
        renderHeader: () => {
          const chemistryGroup = chemistryGroups[groupId];
          if (chemistryGroup) {
            switch (basketTabType) {
              case "layering_table":
                return (
                  <HeatHeader
                    heats={heats}
                    mixId={mixId}
                    multipleMixes={multipleMixes}
                    name={name}
                    groupId={groupId}
                    groupOption={group}
                    chemistryGroupId={chemistryGroup.id!}
                    headerType="column"
                  />
                );
              case "basket_plot":
                return (
                  <HeatHeader
                    heats={heats}
                    mixId={mixId}
                    multipleMixes={multipleMixes}
                    name={name}
                    groupId={groupId}
                    groupOption={group}
                    chemistryGroupName={chemistryGroup.name}
                    mixBasketSummary={mixBasketSummaries}
                    headerType="column"
                  />
                );
            }
          } else {
            return (
              <BaseHeatHeader
                heats={heats}
                mixId={mixId}
                multipleMixes={multipleMixes}
                name={name}
                groupId={groupId}
                groupOption={group}
                numBaskets={null}
                headerType="column"
              />
            );
          }
        },
      };
    }
  );

  const rows: ScrapRow[] = materials.map((material, index) => {
    const data: RowData = groupings.reduce<RowData>((row, [, group]) => {
      if (!row[group.groupId]) {
        row[group.groupId] = {};
      }

      if (!row[group.groupId]![group.mixId]) {
        row[group.groupId]![group.mixId] = {
          ...planMixes[group.groupId]![group.mixId]!,
          id: material.id,
        };
        return row;
      }

      row[group.groupId]![group.mixId] = {
        ...planMixes[group.groupId]![group.mixId]!,
        id: material.id,
      };

      return row;
    }, {});
    return {
      id: index,
      scrap: material,
      data,
    };
  });

  const columns: GridColDef<ScrapRow>[] = [scrapColumn, ...groupColumns];

  // Only show the price breakdown if the electrical energy price is nonzero for at least one mix
  const splitPriceIntoComponents =
    rows.filter(
      (row) =>
        Object.values(row.data).filter(
          (groupMixes) =>
            Object.values(groupMixes).filter(
              (mix) => mix.electrical_energy_price > 0
            ).length > 0
        ).length > 0
    ).length > 0;

  const pinnedRows: GridPinnedRowsProp<ScrapRow> = {
    bottom: rows[0]
      ? [
          {
            id: "total_mass",
            scrap: rows[0].scrap,
            data: rows[0].data,
          },
          ...(splitPriceIntoComponents
            ? [
                {
                  id: "materials_price" as AggregationRowId,
                  scrap: rows[0].scrap,
                  data: rows[0].data,
                },
                {
                  id: "electrical_energy_price" as AggregationRowId,
                  scrap: rows[0].scrap,
                  data: rows[0].data,
                },
              ]
            : []),
          {
            id: "total_price",
            scrap: rows[0].scrap,
            data: rows[0].data,
          },
        ]
      : [],
  };

  const pinnedColumns: GridPinnedColumnFields = {
    left: ["scrap"],
  };

  return {
    rows,
    columns,
    pinnedColumns,
    pinnedRows,
    rowHeight: 32,
    columnHeaderHeight: 70,
  };
};

export const HeatXScrapYTable = ({
  materials,
  measure,
  planGroups,
  planMixes,
  grouping,
  steelGrades,
  chemistryGroups,
  group,
  mixBasketSummaries,
  sx,
}: Props) => {
  const {
    pinnedColumns,
    pinnedRows,
    columns,
    rows,
    columnHeaderHeight,
    rowHeight,
  } = useLayout(
    materials,
    measure,
    planGroups,
    planMixes,
    grouping,
    steelGrades,
    chemistryGroups,
    group,
    mixBasketSummaries
  );

  return (
    <DataGridPremium
      sx={sx}
      pinnedColumns={pinnedColumns}
      columns={columns}
      pinnedRows={pinnedRows}
      rows={rows}
      columnHeaderHeight={columnHeaderHeight}
      rowHeight={rowHeight}
      cellSelection
      ignoreValueFormatterDuringExport
    />
  );
};
