import { useTenant } from "hooks/settings";
import {
  ChargeSummary,
  useGetActiveLoadDropWeightsAtQuery,
} from "src/store/api/generatedApi";
import { LoadedBucket } from "./bucket";
import { sorted } from "helpers";
import { Loaded, loadedEndpoint, mapLoaded } from "models/loaded";
import { LoadedContent } from "components/common/loading/loadedContent";
import {
  Button,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigateToReviewRecentHeats } from "hooks/navigation";

const LBS_PER_TON = 2000;

export type BucketLayer = {
  material: string;
  planned: number;
  actual: number;
  scoreAchieved: number;
  scoreAvailable: number;
};

export const computeLayers = (chargeSummary: ChargeSummary): BucketLayer[] => {
  const materials = Object.keys({
    ...chargeSummary.planned,
    ...chargeSummary.loaded,
  });

  return sorted(
    materials.map((material) => {
      const planned = chargeSummary.planned[material] ?? 0;
      const actual = chargeSummary.loaded[material] ?? 0;

      const scorePerLb =
        Math.max(
          (chargeSummary.prices["Shredded"] ?? 0) -
            (chargeSummary.prices[material] ?? 0),
          0
        ) / LBS_PER_TON;

      // Don't give extra credit for extra mass added
      const validActual = Math.min(actual, planned);
      const validRemaining = planned - validActual;

      return {
        material,
        planned,
        actual,
        scoreAchieved: scorePerLb * validActual,
        scoreAvailable: scorePerLb * validRemaining,
      };
    }),
    (layer) => -layer.scoreAvailable
  ); //.filter((layer) => layer.planned > 0);
};

export const LoadsView = () => {
  const tenantName = useTenant();

  const charges = loadedEndpoint(
    useGetActiveLoadDropWeightsAtQuery(
      { tenantName },
      { pollingInterval: 10000 }
    )
  );

  const navigate = useNavigateToReviewRecentHeats();

  return (
    <Stack>
      <Stack sx={{ padding: 1 }}>
        <Button
          variant="contained"
          onClick={navigate}
          sx={{ marginLeft: "auto" }}
        >
          Review recent heats
        </Button>
      </Stack>

      <Stack sx={{ margin: 4, height: 0.9 }} gap={4} direction="row">
        {[1, 2, 3].map((scrapCar) => (
          <Stack key={scrapCar} sx={{ width: 0.33 }}>
            <LoadingBucket
              title={`Scrap car ${scrapCar}`}
              missingText="Not loading"
              chargeSummary={mapLoaded(
                charges,
                (loadedCharges) =>
                  loadedCharges.find((charge) => charge.scrap_car === scrapCar)
                    ?.charge_summary ?? null
              )}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export const LoadingBucket = ({
  title,
  missingText,
  chargeSummary,
}: {
  title: string;
  missingText: string;
  chargeSummary: Loaded<ChargeSummary | null>;
}) => {
  const theme = useTheme();

  return (
    <Stack sx={{ width: 1 }} gap={4}>
      <Typography
        variant="h1"
        sx={{
          backgroundColor: theme.palette.grey[100],
          padding: 1,
          textAlign: "center",
          borderRadius: 1,
        }}
      >
        {title}
      </Typography>
      <LoadedContent
        data={chargeSummary}
        disableFetching
        waiting={<LinearProgress />}
      >
        {(loadedChargeSummary) =>
          loadedChargeSummary == null ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                height: 300,
                borderColor: theme.palette.grey[100],
                borderWidth: 4,
                borderRadius: 1,
                borderStyle: "solid",
              }}
            >
              <Typography variant="h2">{missingText}</Typography>
            </Stack>
          ) : (
            <LoadedBucket layers={computeLayers(loadedChargeSummary)} />
          )
        }
      </LoadedContent>
    </Stack>
  );
};
