import { sorted } from "helpers";
import { useTenant } from "hooks/settings";
import { useCallback } from "react";
import Plot from "react-plotly.js";
import { backendApi } from "src/store/api/enhancedApi";
import {
  Chunk,
  ChunkData,
  quantisedWindow,
  useLoadedWindow,
  useMergedChunks,
} from "../helpers";
import { defaultLayout, linePlot, loadingChunks, Point } from "../plotting";
import { OnRelayout } from "../viewportControls";

const useScaleWeights = (
  start: Date,
  end: Date
): ChunkData<{ [bucket: string]: Point[] }>[] => {
  const [getScaleWeights] = backendApi.endpoints.getScaleWeights.useLazyQuery();
  const tenant = useTenant();

  const get = useCallback(
    async (chunk: Chunk) =>
      getScaleWeights({ tenantName: tenant, ...chunk })
        .unwrap()
        .then((result) =>
          Object.fromEntries(
            result.data.map((item) => [
              item.bucket,
              item.weights.map((row) => ({
                ...row,
                timestamp: new Date(row.timestamp),
              })),
            ])
          )
        ),
    [getScaleWeights]
  );

  return useLoadedWindow(
    quantisedWindow(
      { start: start.getTime(), end: end.getTime() },
      300 * 1000,
      2
    ),
    get
  );
};

export const ScaleWeightsPlot = ({
  start,
  end,
  onRelayout,
}: {
  start: Date;
  end: Date;
  onRelayout?: OnRelayout;
}) => {
  const scaleWeights = useScaleWeights(start, end);
  const mergedScaleWeights = useMergedChunks(scaleWeights);

  return (
    <Plot
      data={sorted(Object.keys(mergedScaleWeights), (key) => key).map(
        (bucket) => ({ ...linePlot(mergedScaleWeights[bucket]!), name: bucket })
      )}
      layout={{
        ...defaultLayout(),
        xaxis: {
          range: [start, end],
          type: "date",
        },
        yaxis: { title: { text: "Scale weight [klbs]" } },
        shapes: loadingChunks(scaleWeights),
      }}
      onRelayout={onRelayout}
      style={{ width: "100%" }}
    />
  );
};
