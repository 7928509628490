import { Box, Button, Skeleton, Typography } from "@mui/material";

export const EditableTableSkeleton = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="200px repeat(25,1fr)"
      gridAutoRows="min-content"
      sx={{
        columnGap: 0.5,
        rowGap: 0.5,
        overflow: "hidden",
        height: "calc(100% - 24px)",
        margin: 1,
      }}
    >
      <Box sx={{ display: "flex", gridColumn: "1/-1", gap: 1 }}>
        <Skeleton variant="text">
          <Typography variant="h6">The first date in here</Typography>
        </Skeleton>
        <Skeleton variant="text">
          <Typography variant="h6">The last date in here</Typography>
        </Skeleton>
        <Skeleton sx={{ ml: "auto" }}>
          <Button>Discard changes</Button>
        </Skeleton>
        <Skeleton>
          <Button>Save changes</Button>
        </Skeleton>
      </Box>
      {new Array(26).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={60} key={`${index}.column`} variant="rectangular" />
      ))}
      {new Array(26 * 20).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={40} key={`${index}.cell`} variant="rectangular" />
      ))}
    </Box>
  );
};
