import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { RootState } from "store/store.tsx";

type View = "deploy" | "backup" | "supervisor";

type SteelGradeSearch = {
  planId: number;
  view: View;
  steelGradeIds: number[];
};

const makeId = (planId: number, view: View) => [planId, view].join(".");

const steelGradeSearchAdapter = createEntityAdapter({
  selectId: (steelGradeSearch: SteelGradeSearch) =>
    makeId(steelGradeSearch.planId, steelGradeSearch.view),
});

const adapterSelectors = steelGradeSearchAdapter.getSelectors();

export const steelGradeSearchSlice = createSlice({
  name: "steelGradeSearch",
  initialState: steelGradeSearchAdapter.getInitialState(undefined, []),
  reducers: {
    setSteelGrades: (
      state,
      {
        payload: [planId, steelGradeIds, view],
      }: PayloadAction<[number, number[], View]>
    ) => {
      steelGradeSearchAdapter.setOne(state, {
        planId,
        view,
        steelGradeIds,
      });
    },
    clear: (
      state,
      { payload: [planId, view] }: PayloadAction<[number, View]>
    ) => {
      steelGradeSearchAdapter.removeOne(state, makeId(planId, view));
    },
    copy(
      state,
      {
        payload: [fromPlanId, view, toPlanId],
      }: PayloadAction<[number, View, number]>
    ) {
      const fromPlanSearchedGrades = adapterSelectors.selectById(
        state,
        makeId(fromPlanId, view)
      );
      if (fromPlanSearchedGrades) {
        steelGradeSearchAdapter.setOne(state, {
          planId: toPlanId,
          view,
          steelGradeIds: fromPlanSearchedGrades.steelGradeIds,
        });
      }
    },
  },
});

const createAppSelector = createSelector.withTypes<RootState>();

export const selectSearchedSteelGradeIds = createAppSelector(
  [
    (state) => state.steelGradeSearch,
    (_, planId: number) => planId,
    (_, __: number, view: View) => view,
  ],
  (steelGradeSearch, planId, view) =>
    steelGradeSearchAdapter
      .getSelectors()
      .selectById(steelGradeSearch, makeId(planId, view))?.steelGradeIds ?? []
);
