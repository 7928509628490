import {
  Box,
  Button,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadedContent } from "components/common/loading/loadedContent";
import { useNavigateToLiveBuckets } from "hooks/navigation";
import { useTenant } from "hooks/settings";
import { Loaded, loadedEndpoint, mapLoaded } from "models/loaded";
import { useGetHeatSummaryQuery } from "src/store/api/generatedApi";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { computeHeatScore } from "./reviewRecentHeats";

export const RecentHeats = ({
  heats,
  heatId,
  setHeatId,
}: {
  heats: Loaded<{ heatId: string; shift: string }[]>;
  heatId: string | null;
  setHeatId: (heatId: string) => void;
}) => {
  const navigateToLiveBuckets = useNavigateToLiveBuckets();

  return (
    <Stack sx={{ height: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ margin: 2 }}
        gap={2}
      >
        <Typography variant="h2">Review recent heats</Typography>
        <Button variant="contained" onClick={navigateToLiveBuckets}>
          Back
        </Button>
      </Stack>

      <Stack sx={{ overflow: "auto" }}>
        <RecentHeatsList heats={heats} heatId={heatId} setHeatId={setHeatId} />
      </Stack>
    </Stack>
  );
};

const RecentHeatsList = ({
  heats,
  heatId,
  setHeatId,
}: {
  heats: Loaded<{ heatId: string; shift: string }[]>;
  heatId: string | null;
  setHeatId: (heatId: string) => void;
}) => {
  const theme = useTheme();

  return (
    <LoadedContent data={heats} waiting={<LinearProgress />}>
      {(loadedHeats) => (
        <Stack sx={{ margin: 2 }} gap={2}>
          {loadedHeats.map((heat) => (
            <Box
              key={heat.heatId}
              sx={{
                position: "relative",
                backgroundColor: theme.palette.background.default,
                p: 2,
                border: 1,
                borderRadius: 1,
                ...(heatId === heat.heatId
                  ? {
                      boxShadow: (theme) =>
                        `2px 3px 0px 0px #000,inset 5px 0px 0px 0px ${theme.palette.primary.main};`,
                      cursor: "pointer",
                    }
                  : {}),
                "&:hover": {
                  boxShadow: (theme) =>
                    `0px 1px 0px 0px #000,inset 2px 0px 0px 0px ${theme.palette.primary.main};`,
                  cursor: "pointer",
                },
                borderColor: theme.palette.common.black,
                transition: "box-shadow 0.05s ease-in-out",
              }}
              onClick={() => setHeatId(heat.heatId)}
            >
              <Stack
                key={heat.heatId}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button sx={{ fontSize: 20 }}>{heat.heatId}</Button>

                <HeatScore heatId={heat.heatId} />
              </Stack>
            </Box>
          ))}
        </Stack>
      )}
    </LoadedContent>
  );
};

const HeatScore = ({ heatId }: { heatId: string }) => {
  const tenant = useTenant();
  const summary = loadedEndpoint(
    useGetHeatSummaryQuery({
      tenantName: tenant,
      heatId,
    })
  );
  const { format } = useNumberSerialiser({ decimalPlaces: 2 });

  return (
    <Stack>
      <LoadedContent
        data={mapLoaded(summary, (loadedSummary) =>
          format(computeHeatScore(loadedSummary))
        )}
        waiting={<Skeleton sx={{ width: 50 }} />}
      >
        {(score) => (
          <Typography fontSize={16} fontFamily="monospace">
            ${score}
          </Typography>
        )}
      </LoadedContent>
    </Stack>
  );
};
