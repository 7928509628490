import { useCallback, useEffect, useRef, useState } from "react";
import { backendApi } from "src/store/api/enhancedApi";
import { groupBy, sorted } from "helpers";
import { useTenant } from "hooks/settings";
import { Loaded, loadedEndpoint, mapLoaded } from "models/loaded";
import {
  HeatSummary,
  useGetHeatSummariesQuery,
} from "src/store/api/generatedApi";
import { computeHeatScore } from "./reviewRecentHeats";

export const useHeats = (): Loaded<{ heatId: string; shift: string }[]> => {
  // Use a ref for this so it doesn't update on every render
  const end = useRef(new Date());
  const start = new Date(end.current.getTime() - 2 * 86400 * 1000);
  const tenant = useTenant();

  return mapLoaded(
    loadedEndpoint(
      useGetHeatSummariesQuery({
        tenantName: tenant,
        start: start.getTime(),
        end: end.current.getTime(),
      })
    ),
    (result) =>
      sorted(result.heat_summaries, (heat) => -heat.start).map((summary) => ({
        heatId: summary.heat_id,
        shift: summary.shift,
      }))
  );
};

export const useHeatSummaries = (
  shiftHeats: { heatId: string; shift: string }[]
) => {
  const [cachedSummaries, setCachedSummaries] = useState<{
    [heatId: string]: HeatSummary;
  }>({});
  const tenant = useTenant();
  const [getHeatSummary] = backendApi.endpoints.getHeatSummary.useLazyQuery();

  const refreshId = useCallback(
    (heatId: string, shift: string) => {
      void getHeatSummary({ tenantName: tenant, heatId })
        .unwrap()
        .then((summary) => {
          return setCachedSummaries((current) => ({
            ...current,
            [heatId]: { ...summary, shift },
          }));
        });
    },
    [setCachedSummaries]
  );

  useEffect(() => {
    shiftHeats.forEach(({ heatId, shift }) => {
      if (cachedSummaries[heatId] == null) {
        refreshId(heatId, shift);
      }
    });
  }, [shiftHeats]);

  return (heatId: string) => cachedSummaries[heatId] ?? null;
};

export const useShiftScores = (
  shiftHeats: { heatId: string; shift: string }[]
) => {
  const getHeatSummary = useHeatSummaries(shiftHeats);
  const summaries = shiftHeats.map(({ heatId }) => getHeatSummary(heatId));

  const loadedCount = summaries.filter((summary) => summary != null).length;
  const totalCount = summaries.length;

  const loadedSummaries = summaries.filter(
    (summary): summary is HeatSummary => summary != null
  );
  const shiftScores = groupBy(
    loadedSummaries,
    (summary) => parseInt(summary.shift),
    (shiftSummaries) =>
      shiftSummaries
        .map(computeHeatScore)
        .reduce((left, right) => left + right, 0)
  );

  return { loadedCount, totalCount, shiftScores };
};
