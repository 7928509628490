import React from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Chip, Tooltip } from "@mui/material";

import { SummaryTagEnum } from "store/api/generatedApi";
import { useTenantTranslation } from "hooks/formatters";
import { getSummaryTagEnumTranslationKey } from "src/utils/getSummaryTagEnumTranslationKey";
import { DiscriminatedSummaryTag } from "src/utils/groupSummaryTags";
import { getDescribedSummaryTags } from "src/utils/getDescribedSummaryTags";
import { getSummaryTagEnumDescriptionTranslationKey } from "src/utils/getSummaryTagEnumDescriptionTranslationKey";
import { Period } from "hooks/periodIndex";
import { usePeriodNameFormatter } from "hooks/usePeriodNameFormatter";

type GenericProps<Tag extends SummaryTagEnum> = {
  summaryTags: [
    DiscriminatedSummaryTag<Tag>,
    ...DiscriminatedSummaryTag<Tag>[],
  ];
};

type Props =
  | GenericProps<"challenging_for_scrapyard">
  | GenericProps<"not_enough_available_scrap">
  | GenericProps<"uses_non_default_baskets">;

export const PlanGroupedSummaryTagChip = ({ summaryTags }: Props) => {
  const { t } = useTenantTranslation();
  const describedSummaryTags = getDescribedSummaryTags(summaryTags);
  const periodNameFormatter = usePeriodNameFormatter();

  const tooltip =
    summaryTags[0].tag !== "uses_non_default_baskets" ? (
      <div>
        {t(getSummaryTagEnumDescriptionTranslationKey(summaryTags[0].tag))}
        <ul>
          {describedSummaryTags.map((summaryTag) => {
            return (
              <React.Fragment key={`${summaryTag.period}.${summaryTag.tag}`}>
                <li>{periodNameFormatter(summaryTag.period as Period)}</li>
                <ul>
                  {summaryTag.details.map((detail) => (
                    <li key={`${summaryTag.period}-${detail}`}>{t(detail)}</li>
                  ))}
                </ul>
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    ) : null;

  return (
    <Chip
      sx={{
        "& .MuiChip-deleteIcon": {
          color: "action.active",
          "&:hover": {
            color: "action.active",
          },
        },
      }}
      label={t(getSummaryTagEnumTranslationKey(summaryTags[0].tag))}
      size="small"
      onDelete={() => null}
      deleteIcon={
        tooltip ? (
          <Tooltip title={tooltip}>
            <InfoRoundedIcon />
          </Tooltip>
        ) : (
          <span />
        )
      }
    />
  );
};
