import { Typography } from "@mui/material";

export const MaterialHeader = ({ materialName }: { materialName: string }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        position: "absolute",
        bottom: 0,
        marginLeft: 1,
        whiteSpace: "normal",
        wordWrap: "break-word",
        rotate: "-70deg",
        transformOrigin: "bottom left",
        width: 110,
        maxLines: 2,
        height: 33,
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {materialName}
    </Typography>
  );
};
