import { ChevronRight } from "@mui/icons-material";
import { Box, ButtonBase, Typography } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { useTenantTranslation } from "hooks/formatters";
import { useNavigateToPurchasingPlan } from "hooks/navigation";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { PurchasingPlanSummary } from "src/store/api/generatedApi";

const PurchasingPlanNameButtonCell = ({
  row: purchasingPlan,
}: GridRenderCellParams<PurchasingPlanSummary>) => {
  const navigate = useNavigateToPurchasingPlan();
  return (
    <ButtonBase
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
      onClick={() => {
        navigate(purchasingPlan.purchasing_plan_id);
      }}
    >
      <Typography variant="button">{purchasingPlan.name}</Typography>
      <ChevronRight />
    </ButtonBase>
  );
};

export const PurchasingPlansTable = ({
  plans,
  loading,
}: {
  plans: PurchasingPlanSummary[];
  loading: boolean;
}) => {
  const { t } = useTenantTranslation();
  const formatLocalDateTime = useFormatLocalDateString("datetime");
  const formatLocalMonthYear = useFormatLocalDateString("monthYear");

  const columns: GridColDef<PurchasingPlanSummary>[] = [
    {
      field: "name",
      headerName: t("name"),
      flex: 2,
      display: "flex",
      renderCell: PurchasingPlanNameButtonCell,
    },
    {
      field: "plan_date",
      headerName: t("planDate"),
      display: "flex",
      flex: 1,
      valueFormatter: (value) => formatLocalMonthYear(value),
    },
    {
      field: "created_at",
      headerName: t("createdAt"),
      display: "flex",
      flex: 1,
      valueFormatter: (value) => formatLocalDateTime(value),
    },
    {
      field: "user_email",
      headerName: t("createdBy"),
      display: "flex",
      flex: 1,
    },
  ];

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGridPremium
        rows={plans}
        columns={columns}
        loading={loading}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
          },
        }}
        getRowId={(row) => row?.purchasing_plan_id}
        disableRowSelectionOnClick
        pageSizeOptions={[10, 25, 50]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          sorting: {
            sortModel: [{ field: "created_at", sort: "desc" }],
          },
        }}
        sx={{
          "& .MuiDataGrid-cell": {
            padding: "8px 16px",
          },
        }}
      />
    </Box>
  );
};
