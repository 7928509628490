import { Box } from "@mui/material";
import { Navigate } from "react-router";
import { useHasAvailableTenants } from "hooks/permissions";
import { NoTenantPage } from "../noTenant";
import { useNavigateToTenantHome } from "hooks/navigation";
import { useState } from "react";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { StringSerialiserOptions } from "hooks/serialisers/types";
import { useTenantPermissions } from "hooks/settings.tsx";

export const RedirectToTenant = () => {
  const { hasAvailableTenants, defaultTenant } = useHasAvailableTenants();
  const tenantPermissions = useTenantPermissions();

  if (defaultTenant != null) {
    const permissions = tenantPermissions[defaultTenant] ?? [];

    if (permissions.includes("crane_driver")) {
      return <Navigate to={`/${defaultTenant}/crane/driver`} replace />;
    } else if (permissions.includes("crane_supervisor")) {
      return (
        <Navigate
          to={`/${defaultTenant}/crane/supervisor/standard/latest`}
          replace
        />
      );
    } else {
      return <Navigate to={`/${defaultTenant}/`} replace />;
    }
  } else if (hasAvailableTenants) {
    return <SelectTenantInput />;
  } else {
    return <NoTenantPage />;
  }
};

const SelectTenantInput = () => {
  const [tenant, setTenant] = useState("");
  const navigate = useNavigateToTenantHome();
  const serialiser = useStringSerialiser();

  return (
    <Box sx={{ p: 4 }}>
      <ValidatedTextField<string, StringSerialiserOptions>
        serialiser={serialiser}
        value={tenant}
        setValue={setTenant}
        label="Organisation"
        onEditConfirmedWithKeyboard={(t) => navigate(t)}
        textFieldProps={{ autoFocus: true, autoComplete: "off" }}
      />
    </Box>
  );
};
