import { Skeleton, Stack, Theme, Typography, useTheme } from "@mui/material";
import { QueryStatus } from "@reduxjs/toolkit/query";

import { useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";

import {
  SteelGradePlanItem,
  useSummarisePlanMixesMutation,
} from "src/store/api/generatedApi";

import { useSummaryMutation } from "./useSummaryMutation";

type Props = {
  planId: number;
  mixId: number;
  steelGradeProduction: SteelGradePlanItem[];
  period: number;
  originalPrice: number;
  originalBasketsMaterialMasses: Record<number, Record<number, number>>;
};

const NoDiff = () => {
  return (
    <Typography
      component="span"
      variant="body2Mono"
      sx={{
        paddingX: 1,
      }}
    >
      -
    </Typography>
  );
};

const EditedPrice = ({
  planId,
  mixId,
  steelGradeProduction,
  period,
  originalPrice,
  originalBasketsMaterialMasses,
}: Props) => {
  const theme = useTheme();
  const { format: priceFormat } = useNumberSerialiser({
    decimalPlaces: 2,
    min: 0,
  });
  const unit = useUnitsFormatter(false);
  const [areOriginalBasketMaterialMasses, summarisePlanMixesMutationResult] =
    useSummaryMutation({
      mixId,
      planId,
      steelGradeProduction,
      period,
      originalBasketsMaterialMasses,
    });

  if (areOriginalBasketMaterialMasses) {
    return <NoDiff />;
  }

  switch (summarisePlanMixesMutationResult.status) {
    case QueryStatus.rejected: {
      throw new Error(
        `MixPriceCell: Price calculation for plan ${planId}, mix ${mixId} failed`
      );
    }
    case QueryStatus.uninitialized: {
      return null;
    }
    case QueryStatus.fulfilled: {
      const mix = summarisePlanMixesMutationResult.data.mixes.find(
        (mix) => mix.mix_id === mixId
      );
      if (mix?.summary) {
        const {
          summary: {
            cost: { display_cost_per_tonne },
          },
        } = mix;

        if (display_cost_per_tonne.toFixed(2) === originalPrice.toFixed(2)) {
          return <NoDiff />;
        }

        const backgroundColor: Theme["palette"]["success"]["main"] = (() => {
          switch (true) {
            case display_cost_per_tonne < originalPrice:
              return theme.palette.success.main;
            case display_cost_per_tonne > originalPrice:
              return theme.palette.error.main;
            default:
              return theme.palette.grey[600];
          }
        })();
        return (
          <Typography
            component="span"
            variant="body2Mono"
            sx={{
              backgroundColor,
              color: theme.palette.common.white,
              paddingX: 1,
            }}
          >
            {priceFormat(mix.summary.cost.display_cost_per_tonne)}
            <Typography component="span" variant="body2Mono">
              {unit("specific_cost")}
            </Typography>
          </Typography>
        );
      } else {
        return null;
      }
    }
    case QueryStatus.pending: {
      return (
        <Typography
          variant="body2Mono"
          sx={{ paddingX: 1, width: 80, height: 19.2 }}
        >
          <Skeleton variant="text" />
        </Typography>
      );
    }
  }
};

type CopperWeightPercentProps = {
  planId: number;
  mixId: number;
};

const CopperWeightPercent = ({ planId, mixId }: CopperWeightPercentProps) => {
  const theme = useTheme();
  const [, summarisePlanMixesMutationResult] = useSummarisePlanMixesMutation({
    fixedCacheKey: [planId, mixId].join("."),
  });
  const unitFormatter = useUnitsFormatter(false);

  switch (summarisePlanMixesMutationResult.status) {
    case QueryStatus.fulfilled: {
      const copperChemistry =
        summarisePlanMixesMutationResult.data.mixes[0]?.summary?.chemistry.find(
          (element) => element.element === "copper"
        );
      if (copperChemistry) {
        return (
          <Typography
            variant="body2Mono"
            sx={{ backgroundColor: theme.palette.warning.main, padding: 0.5 }}
          >
            Cu&nbsp;
            <Typography fontWeight="bold" component="span" variant="body2Mono">
              {copperChemistry.weight_percent_loc.toFixed(3)}
            </Typography>
            {unitFormatter("mass_fraction")}
          </Typography>
        );
      } else {
        return null;
      }
    }
    case QueryStatus.uninitialized:
    case QueryStatus.pending: {
      return (
        <Skeleton
          variant="text"
          sx={{
            fontSize: theme.typography.body2Mono.fontSize,
            padding: 0.5,
            width: 36,
          }}
        />
      );
    }
    case QueryStatus.rejected: {
      return null;
    }
  }
};

export const MixSummaryCell = ({
  originalPrice,
  planId,
  mixId,
  steelGradeProduction,
  period,
  originalBasketsMaterialMasses,
}: Props) => {
  const theme = useTheme();

  const { format: priceFormat } = useNumberSerialiser({
    decimalPlaces: 2,
    min: 0,
  });
  const unit = useUnitsFormatter(false);

  return (
    <Stack alignItems="center" justifyContent="center" height="100%" gap={1}>
      <Typography component="span" variant="body2Mono">
        {priceFormat(originalPrice)}
        <Typography
          component="span"
          variant="body2Mono"
          sx={{ color: theme.palette.text.disabled }}
        >
          {unit("specific_cost")}
        </Typography>
      </Typography>
      <EditedPrice
        planId={planId}
        mixId={mixId}
        period={period}
        originalPrice={originalPrice}
        steelGradeProduction={steelGradeProduction}
        originalBasketsMaterialMasses={originalBasketsMaterialMasses}
      />
      <CopperWeightPercent planId={planId} mixId={mixId} />
    </Stack>
  );
};
