import { Box, useTheme } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellParams,
  gridClasses,
  GridColDef,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { makePostDataGridProcessingCallback } from "components/common/plan/utils";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import React, { useMemo } from "react";
import { PurchasingPlanMaterial, Supplier } from "src/store/api/generatedApi";
import {
  calculateMaterialAverageCost,
  calculateUnfilledDemandForMaterial,
  makeBottomRows,
} from "../utils";
import { AddQuoteCell } from "./cells/AddQuoteCell";
import { MaterialHeader } from "./cells/MaterialHeader";
import { SupplierCell } from "./cells/SupplierCell";

type MaterialName = string;

export type MaterialDemandRow = {
  id: string;
  type: "material_demand";
  demands: Record<MaterialName, number>;
};

export type MaterialInventoryRow = {
  id: string;
  type: "material_inventory";
  inventories: Record<MaterialName, number>;
};

export type MaterialQuantityOutstandingRow = {
  id: string;
  type: "material_quantity_outstanding";
};

export type MaterialAverageCostRow = {
  id: string;
  type: "material_average_cost";
};

export type BaseQuoteRow = {
  id: string;
  quoteId: number;
  supplierName: string;
  type: "quote_price" | "quote_quantity";
};

export type QuotePriceRow = BaseQuoteRow & {
  type: "quote_price";
  prices: Record<MaterialName, number | null>;
};

export type QuoteQuantityRow = BaseQuoteRow & {
  type: "quote_quantity";
  quantities: Record<MaterialName, number | null>;
};

export type Row =
  | MaterialDemandRow
  | MaterialInventoryRow
  | MaterialQuantityOutstandingRow
  | MaterialAverageCostRow
  | QuotePriceRow
  | QuoteQuantityRow;

type Props = {
  planId: number;
  apiRef: ReturnType<typeof useGridApiRef>;
  materials: PurchasingPlanMaterial[];
  rows: Row[];
  suppliers: Supplier[];
  loading: boolean;
  onChange: (rows: Row[]) => void;
};

const useMakeColumns = (
  planId: number,
  materials: PurchasingPlanMaterial[],
  suppliers: Supplier[]
): GridColDef<Row>[] => {
  const { t } = useTenantTranslation();
  const unitsFormatter = useUnitsFormatter(false);
  const columns = React.useMemo((): GridColDef<Row>[] => {
    const columns: GridColDef<Row>[] = [
      {
        field: "labels",
        valueGetter: (_, row): string => {
          switch (row.type) {
            case "material_demand":
              return "Demand";
            case "material_inventory":
              return "Current Inventory";
            case "material_quantity_outstanding":
              return t("unfilledDemand");
            case "material_average_cost":
              return t("averageCost");
            case "quote_price":
            case "quote_quantity":
              return row.supplierName;
          }
        },
        rowSpanValueGetter: (value, row): string => {
          switch (row.type) {
            case "quote_price":
            case "quote_quantity":
              return `${row.quoteId}`;
            case "material_demand":
            case "material_inventory":
            case "material_quantity_outstanding":
            case "material_average_cost":
              return value;
          }
        },
        renderHeader: () => {
          return <AddQuoteCell planId={planId} suppliers={suppliers} />;
        },
        renderCell: (
          params: GridCellParams<Row, string>
        ): React.JSX.Element | string | undefined => {
          switch (params.row.type) {
            case "quote_price":
            case "quote_quantity":
              return <SupplierCell supplierRow={params.row} />;
            case "material_demand":
            case "material_inventory":
            case "material_quantity_outstanding":
            case "material_average_cost":
              return params.value;
          }
        },
        width: 200,
        align: "center",
        headerAlign: "center",
        cellClassName: (params): string => {
          const baseClass = "center";
          switch (params.row.type) {
            case "material_quantity_outstanding":
            case "material_average_cost":
              return baseClass + " top-border-dark" + " grey-50";
            case "material_demand":
            case "material_inventory":
              return baseClass + " grey-50";
            case "quote_price":
            case "quote_quantity":
              return baseClass;
          }
        },
        headerClassName: "border-bottom-dark",
      },
      {
        field: "units",
        maxWidth: 10,
        headerName: "",
        valueGetter: (_, row): string | null => {
          switch (row.type) {
            case "quote_price":
              return unitsFormatter("specific_cost");
            case "quote_quantity":
              return unitsFormatter("mass");
            case "material_demand":
            case "material_inventory":
            case "material_quantity_outstanding":
            case "material_average_cost":
              return null;
          }
        },
        cellClassName: (params): string => {
          const baseClass = "center edge-column";
          switch (params.row.type) {
            case "material_quantity_outstanding":
            case "material_average_cost":
              return baseClass + " top-border-dark" + " grey-50";
            case "material_demand":
            case "material_inventory":
              return baseClass + " grey-50";
            case "quote_price":
            case "quote_quantity":
              return baseClass;
          }
        },
        headerClassName: "border-bottom-dark",
      },
      ...materials.flatMap((material): GridColDef<Row>[] => [
        {
          field: `material_${material.material_name}`,
          headerName: material.material_name,
          flex: 1,
          minWidth: 45,
          type: "number",
          editable: true,
          valueGetter: (value, row, __, dataGridApi): number | null => {
            switch (row.type) {
              case "material_demand":
                return row.demands[material.material_name] ?? null;
              case "material_inventory":
                return row.inventories[material.material_name] ?? null;
              case "material_quantity_outstanding":
                return calculateUnfilledDemandForMaterial(
                  material.material_name,
                  dataGridApi.current
                    .getAllRowIds()
                    .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                    .filter((row): row is Row => row !== null),
                  materials
                );
              case "material_average_cost":
                return calculateMaterialAverageCost(
                  material.material_name,
                  dataGridApi.current
                    .getAllRowIds()
                    .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                    .filter((row): row is Row => row !== null)
                );
              case "quote_price":
                return value ?? row.prices[material.material_name] ?? null;
              case "quote_quantity":
                return value ?? row.quantities[material.material_name] ?? null;
            }
          },
          rowSpanValueGetter: (_, row): string => {
            return `${row.id}`;
          },
          valueSetter: (value: number | null, row): Row => {
            switch (row.type) {
              case "quote_price": {
                const newQuotes = {
                  ...row.prices,
                  [material.material_name]: value ?? null,
                };
                return { ...row, prices: newQuotes };
              }
              case "quote_quantity": {
                const newQuotes = {
                  ...row.quantities,
                  [material.material_name]: value ?? null,
                };
                return { ...row, quantities: newQuotes };
              }
              case "material_demand":
              case "material_inventory":
              case "material_quantity_outstanding":
              case "material_average_cost":
                return row;
            }
          },
          cellClassName: (params): string => {
            const baseClass = "center";
            const row = params.row;
            switch (row.type) {
              case "material_quantity_outstanding": {
                return (
                  baseClass +
                  " " +
                  (params.value === 0 ? " fulfilled" : " unfulfilled") +
                  " top-border-dark"
                );
              }
              case "quote_quantity":
              case "material_demand":
              case "material_inventory":
                return baseClass;
              case "quote_price":
                return baseClass + " border-bottom-dark";
              case "material_average_cost":
                return baseClass + " top-border-dark";
            }
          },
          renderHeader: () => {
            return <MaterialHeader materialName={material.material_name} />;
          },
          align: "center",
          headerClassName: "border-bottom-dark",
        },
      ]),
    ];
    return columns.map((column) => ({
      ...column,
      sortable: false,
      headerAlign: "center",
      resizable: false,
    }));
  }, [materials]);
  return columns;
};

export const PurchasingPlanTable = ({
  planId,
  apiRef,
  materials,
  rows,
  suppliers,
  loading,
  onChange,
}: Props) => {
  const columns = useMakeColumns(planId, materials, suppliers);

  const bottomRows = useMemo(() => makeBottomRows(materials), [materials]);
  const theme = useTheme();

  const handleOnChange = React.useCallback(() => {
    if (apiRef.current) {
      const rows = [...apiRef.current.getRowModels().values()].filter(
        (_: GridValidRowModel): _ is Row => true
      );
      onChange(rows);
    }
  }, [onChange]);

  const postDataGridProcessingHandleOnChange = React.useMemo(
    () => makePostDataGridProcessingCallback(handleOnChange),
    [handleOnChange]
  );

  return (
    <DataGridPremium<Row>
      apiRef={apiRef}
      columns={columns}
      loading={loading}
      unstable_rowSpanning
      columnHeaderHeight={120}
      disableVirtualization
      onCellEditStop={postDataGridProcessingHandleOnChange}
      pinnedColumns={{
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "labels"],
      }}
      pinnedRows={{
        bottom: bottomRows,
      }}
      slots={{
        noRowsOverlay: NoRowsOverlay,
      }}
      slotProps={{
        loadingOverlay: {
          variant: "linear-progress",
        },
      }}
      rows={rows}
      sx={{
        width: "calc(100%)",
        [".align-center"]: {
          alignContent: "center",
        },
        maxHeight: "calc(50%)",
        [".space-between"]: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        [".center"]: {
          display: "grid",
          justifyContent: "center",
          alignContent: "center",
        },
        [".grey-50"]: {
          backgroundColor: `${theme.palette.grey[50]}`,
        },
        [".border-bottom-dark"]: {
          borderBottom: `1px solid ${theme.palette.secondary.dark} !important`,
        },
        [".top-border-dark"]: {
          borderTop: `1px solid ${theme.palette.secondary.dark} !important`,
        },
        [".edge-column"]: {
          borderRight: `1px solid ${theme.palette.secondary.dark}`,
        },
        [`.${gridClasses.cell}.fulfilled`]: {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.contrastText,
          fontWeight: "bold",
        },
        [`.${gridClasses.cell}.unfulfilled`]: {
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.warning.contrastText,
          fontWeight: "bold",
        },
        ["input::-webkit-outer-spin-button, input::-webkit-inner-spin-button"]:
          {
            "-webkit-appearance": "none",
          },
        [".MuiDataGrid-editInputCell input"]: {
          padding: 0,
          textAlign: "center",
        },
        [".MuiDataGrid-editInputCell"]: {
          padding: 0,
          textAlign: "center",
        },
      }}
      getCellClassName={(
        params: GridCellParams<Row>
      ): "fulfilled" | "unfulfilled" | "" => {
        const row = params.row;
        switch (row.type) {
          case "material_quantity_outstanding":
            if (
              params.field !== "labels" &&
              params.field !== GRID_CHECKBOX_SELECTION_COL_DEF.field &&
              params.field !== "units"
            ) {
              return params.value === 0 ? "fulfilled" : "unfulfilled";
            }
            return "";
          case "material_demand":
          case "material_inventory":
          case "material_average_cost":
          case "quote_price":
          case "quote_quantity":
            return "";
        }
      }}
    />
  );
};

const NoRowsOverlay = () => {
  const { t } = useTenantTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {t("noQuotes")}
    </Box>
  );
};
