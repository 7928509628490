import React, { type JSX } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";

import { ApprovePlan } from "./ApprovePlan";
import { Download } from "./Download";
import { SendToScrapyard } from "./SendToScrapyard";
import {
  PlanId,
  usePlanId,
  useSetPlanId,
} from "components/common/boundary/PlanId";

export enum State {
  ApprovePlan,
  SendToScrapyard,
  Download,
}

const toNextState = (state: State): State.SendToScrapyard | State.Download => {
  switch (state) {
    case State.ApprovePlan:
      return State.SendToScrapyard;
    case State.SendToScrapyard:
    case State.Download:
      return State.Download;
  }
};

const toPreviousState = (
  state: State
): State.ApprovePlan | State.SendToScrapyard => {
  switch (state) {
    case State.ApprovePlan:
    case State.SendToScrapyard:
      return State.ApprovePlan;
    case State.Download:
      return State.SendToScrapyard;
  }
};

const stateToIndex = (state: State): 0 | 1 | 2 => {
  switch (state) {
    case State.ApprovePlan:
      return 0;
    case State.SendToScrapyard:
      return 1;
    case State.Download:
      return 2;
  }
};

type Props = {
  open: boolean;
  doClose: () => void;
};

export const Modal = ({ open, doClose }: Props) => {
  const [state, setState] = React.useState(State.ApprovePlan);
  const planId = usePlanId();
  const setPlanId = useSetPlanId();

  const { t } = useTenantTranslation();

  const steps = [t("approvePlan"), t("sendToScrapyard"), t("download")];

  const handleToNextState = React.useCallback(() => {
    setState(toNextState);
  }, [setState]);

  const handleToPreviousState = React.useCallback(() => {
    setState(toPreviousState);
  }, [setState]);

  const toInitialState = () => setState(State.ApprovePlan);

  const handleSetPlanId = React.useCallback(
    (planId: PlanId) => {
      setPlanId(planId, true);
    },
    [setPlanId]
  );

  const body = React.useMemo((): JSX.Element => {
    switch (state) {
      case State.ApprovePlan:
        return (
          <ApprovePlan
            toNextState={handleToNextState}
            planId={planId}
            setPlanId={handleSetPlanId}
          />
        );
      case State.Download:
        return <Download planId={planId} doClose={doClose} />;
      case State.SendToScrapyard:
        return (
          <SendToScrapyard
            planId={planId}
            toNextState={handleToNextState}
            toPreviousState={handleToPreviousState}
            toInitialState={toInitialState}
          />
        );
    }
  }, [state, handleToNextState, doClose, planId]);

  return (
    <Dialog
      onClose={doClose}
      open={open}
      fullWidth
      maxWidth="xl"
      sx={{
        "& .MuiDialog-paper": {
          // Fix to always be "full height" (i.e. 90vh)
          height: "90vh",
        },
      }}
    >
      <DialogTitle>{t("deployPlan")}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Stepper activeStep={stateToIndex(state)}>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>
                  <Typography>{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {body}
      </DialogContent>
    </Dialog>
  );
};
