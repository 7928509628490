import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, Menu, MenuItem, useTheme } from "@mui/material";
import React from "react";
import { Supplier, useAddQuotesMutation } from "src/store/api/generatedApi";
import {
  purchasingPlanTableEditsSlice,
  selectPurchasingPlanTableEdits,
} from "src/store/slices/purchasingPlanTableEditSlice";
import { useAppDispatch, useAppStore } from "src/store/store";

import { useGridApiContext } from "@mui/x-data-grid-premium";
import {
  createPlanUpdate,
  makeRows,
  QUOTE_CREATION_FIXED_CACHE_KEY,
} from "components/purchasing/utils";
import { useTenant } from "hooks/settings";

type Props = {
  planId: number;
  suppliers: Supplier[];
};

export const AddQuoteCell = ({ planId, suppliers }: Props) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const tenantName = useTenant();
  const [mutation] = useAddQuotesMutation({
    fixedCacheKey: QUOTE_CREATION_FIXED_CACHE_KEY,
  });
  const [addingQuote, setAddingQuote] = React.useState(false);
  const gridApiRef = useGridApiContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToLastRow = async (rowIndex: number) => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    gridApiRef.current.scrollToIndexes({
      rowIndex,
      colIndex: 0,
    });
  };

  const handleSelect = async (supplier: Supplier) => {
    handleClose();
    const planEdits = selectPurchasingPlanTableEdits(store.getState());
    if (planEdits !== undefined) {
      setAddingQuote(true);

      const purchasingPlanQuotesAdd = createPlanUpdate(planEdits.editedRows);
      const { data } = await mutation({
        tenantName: tenantName,
        purchasingPlanId: planId,
        purchasingPlanQuotesAdd: {
          quotes: [
            ...purchasingPlanQuotesAdd.quotes,
            {
              supplier_name: supplier.name,
              line_items: [],
            },
          ],
        },
      });

      if (data) {
        dispatch(
          purchasingPlanTableEditsSlice.actions.quoteAdded(
            makeRows(data.quotes)
          )
        );
        await scrollToLastRow(data.quotes.length * 2 - 1);
      }
      setAddingQuote(false);
    }
  };
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <Button
        variant="text"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        disabled={addingQuote}
        size="small"
        sx={{
          textTransform: "none",
          borderColor: theme.palette.grey[400],
          color: theme.palette.text.primary,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 12px",
        }}
      >
        Add Quote
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {suppliers.map((supplier) => (
          <MenuItem key={supplier.name} onClick={() => handleSelect(supplier)}>
            {supplier.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
