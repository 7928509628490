import { useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { UnitSuffix } from "components/plan/table/common/UnitSuffix";
import { Stack, Tooltip, Typography } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { ReactNode } from "react";

interface PriceDiffProps {
  priceDiff: number;
  tooltipTitle?: ReactNode | string;
}

export const FormattedPrice = ({
  value,
  variant,
}: {
  value: number;
  variant?: "body1Mono" | "body2Mono" | "unformatted";
}) => {
  const units = useUnitsFormatter(false);
  const { format } = useNumberSerialiser({ decimalPlaces: 2 });

  if (variant === "unformatted") {
    return (
      <span>
        {format(value)}
        {units("specific_cost")}
      </span>
    );
  }

  return (
    <Typography variant={variant ?? "body1Mono"} sx={{ textAlign: "right" }}>
      {format(value)}
      <UnitSuffix>{units("specific_cost")}</UnitSuffix>
    </Typography>
  );
};

export const PriceDiff = ({ priceDiff, tooltipTitle }: PriceDiffProps) => {
  const isIncrease = priceDiff > 0;
  const isSignificant = Math.abs(priceDiff) >= 0.005;

  const content = (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      sx={{
        color: isSignificant
          ? isIncrease
            ? "error.main"
            : "success.main"
          : "text.primary",
        typography: "body2",
        fontWeight: 500,
      }}
    >
      {isIncrease ? (
        <TrendingUpIcon sx={{ fontSize: 16 }} />
      ) : (
        <TrendingDownIcon sx={{ fontSize: 16 }} />
      )}
      <FormattedPrice value={Math.abs(priceDiff)} variant="body2Mono" />
    </Stack>
  );

  if (tooltipTitle) {
    return <Tooltip title={tooltipTitle}>{content}</Tooltip>;
  }

  return content;
};
