import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "hooks/formatters";
import { TargetBasketRow } from "./table";
import { useTargetBaskets } from "contexts/search/provider";
import { SearchChemistryGroups } from "contexts/search/context";
import { usePeriodIndex } from "hooks/periodIndex";

export const useHandleTableMutations = (
  maxBaskets: number,
  chemistryGroups: SearchChemistryGroups
) => {
  const periodTab = usePeriodIndex();
  const [, setTargetBaskets] = useTargetBaskets(periodTab);

  const { parse } = useNumberSerialiser({
    min: 0,
    max: maxBaskets,
    default: { value: null, text: "" },
    decimalPlaces: 0,
  });

  const notifyStatus = useNotifyStatus();
  const { t } = useTenantTranslation();

  const handleUpdateItem = (
    newRow: TargetBasketRow,
    oldRow: TargetBasketRow
  ): TargetBasketRow => {
    const group = chemistryGroups.byId[newRow.groupId]!;
    const targetResult = parse(newRow.target.toString());

    if (targetResult.valid) {
      const target = targetResult.value ?? group.default_target_num_baskets;

      setTargetBaskets((current) => [
        ...current.filter((item) => item.chef_group_id !== oldRow.groupId),
        {
          chef_group_id: oldRow.groupId,
          target_num_baskets: target,
        },
      ]);

      return { ...newRow, target };
    } else {
      notifyStatus({ text: t("invalidNumber"), type: "error" });
      return oldRow;
    }
  };

  return { handleUpdateItem };
};
