import { Stack, Theme, Typography, useTheme } from "@mui/material";
import { QueryStatus } from "@reduxjs/toolkit/query";

import { useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";

import { SteelGradePlanItem } from "src/store/api/generatedApi";

import { useSummaryMutation } from "./useSummaryMutation";

type Props = {
  planId: number;
  steelGradeProduction: SteelGradePlanItem[];
  mixId: number;
  period: number;
  originalPrice: number;
  originalBasketsMaterialMasses: Record<number, Record<number, number>>;
};

const NoDiff = () => {
  return (
    <Typography
      component="span"
      variant="body2Mono"
      sx={{
        paddingX: 1,
      }}
    >
      -
    </Typography>
  );
};

const PriceDiffDisplay = ({ priceDiff }: { priceDiff: number }) => {
  const theme = useTheme();
  const { format: priceFormat } = useNumberSerialiser({
    decimalPlaces: 2,
    min: 0,
  });
  const unit = useUnitsFormatter(false);

  const backgroundColor: Theme["palette"]["success"]["main"] | undefined =
    (() => {
      if (priceDiff === null) return undefined;
      switch (true) {
        case priceDiff < 0:
          return theme.palette.success.main;
        case priceDiff > 0:
          return theme.palette.error.main;
        default:
          return theme.palette.grey[600];
      }
    })();

  return (
    <Typography
      component="span"
      variant="body2Mono"
      sx={{
        backgroundColor,
        color: backgroundColor ? theme.palette.common.white : undefined,
        paddingX: 1,
        paddingY: 0.5,
        borderRadius: 0.5,
        lineHeight: 1,
      }}
    >
      {priceDiff > 0 ? "+" : ""}
      {priceFormat(priceDiff)}
      <Typography component="span" variant="body2Mono" sx={{ lineHeight: 1 }}>
        {unit("specific_cost")}
      </Typography>
    </Typography>
  );
};

const DynamicPriceDiff = ({
  planId,
  mixId,
  steelGradeProduction,
  period,
  originalPrice,
  originalBasketsMaterialMasses,
}: Props) => {
  const [areOriginalBasketMaterialMasses, summarisePlanMixesMutationResult] =
    useSummaryMutation({
      planId,
      mixId,
      steelGradeProduction,
      period,
      originalBasketsMaterialMasses,
    });

  if (areOriginalBasketMaterialMasses) {
    return <NoDiff />;
  }

  switch (summarisePlanMixesMutationResult.status) {
    case QueryStatus.rejected: {
      throw new Error(
        `MixPriceDiff: Price calculation for plan ${planId} failed`
      );
    }
    case QueryStatus.uninitialized: {
      return null;
    }
    case QueryStatus.fulfilled: {
      const mix = summarisePlanMixesMutationResult.data.mixes[0];
      if (mix?.summary) {
        const {
          summary: {
            cost: { display_cost_per_tonne },
          },
        } = mix;

        if (display_cost_per_tonne.toFixed(2) === originalPrice.toFixed(2)) {
          return <NoDiff />;
        }

        return (
          <PriceDiffDisplay
            priceDiff={display_cost_per_tonne - originalPrice}
          />
        );
      }
      return null;
    }
    case QueryStatus.pending: {
      return <NoDiff />;
    }
  }
};

export const MixPriceDiff = (props: Props) => {
  const {
    planId,
    mixId,
    steelGradeProduction,
    period,
    originalPrice,
    originalBasketsMaterialMasses,
  } = props;

  return (
    <Stack direction="column" alignItems="center" justifyContent="center">
      <DynamicPriceDiff
        planId={planId}
        mixId={mixId}
        steelGradeProduction={steelGradeProduction}
        period={period}
        originalPrice={originalPrice}
        originalBasketsMaterialMasses={originalBasketsMaterialMasses}
      />
    </Stack>
  );
};
