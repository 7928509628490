import { LoadRead } from "src/store/api/generatedApi";

export const refreshMS = 5000;
export const checkPlanPollMS = 60000;

export const isActive = (load: LoadRead) => {
  return (
    !isComplete(load) &&
    Object.values(load.baskets).some(
      (basket) => basket.assigned_to_uid !== null
    )
  );
};

export const isPending = (load: LoadRead) => {
  return (
    !isComplete(load) &&
    Object.values(load.baskets).every(
      (basket) => basket.assigned_to_uid === null
    )
  );
};

export const isComplete = (load: LoadRead) => {
  return Object.values(load.baskets).every(
    (basket) => basket.status == "filled"
  );
};

export const getLoadVariant = ({
  planId,
  currentPlanId,
  backupPlanId,
  nextPlanId,
}: {
  planId: number;
  currentPlanId: number;
  backupPlanId: number;
  nextPlanId: number | null;
}): "new" | "backup" | "out_of_date" | "next" => {
  if (planId === backupPlanId) {
    return "backup";
  } else if (planId === nextPlanId) {
    return "next";
  } else if (planId !== currentPlanId) {
    return "out_of_date";
  } else {
    return "new";
  }
};
