import { PlanIdBoundary } from "components/common/boundary/PlanId";
import { PeriodTabs } from "components/common/periodTabs/tabs";
import { NotFound } from "components/notFound";
import { StrategyDashboardPage } from "components/strategyDashboard/StrategyDashboardPage";
import { MaterialLimitsUpdatedSnackbar } from "components/tenantSnackbars/materialLimitsUpdatedSnackbar";
import { ProvideSearch } from "contexts/search/provider";
import { useIsAdmin, useTenant } from "hooks/settings";
import { Navigate, Route, Routes } from "react-router";
import { ConstraintsPage } from "src/components/constraints/constraintsPage";
import { DebugPage } from "src/components/debug/debugPage";
import { DeployedPlansPage } from "src/components/deployedPlans/deployedPlansPage";
import { PlanPage } from "src/components/plan/PlanPage";
import { ProfileSettingsPage } from "src/components/profileSettings/ProfileSettingsPage";
import { SearchPage } from "src/components/search/SearchPage";
import { SearchesPage } from "src/components/searches/SearchesPage";
import { SearchContextsRoutes } from "./search_context";
import { SessionRedirect } from "./sessionRedirect";

export const TenantRoutes = () => {
  const tenant = useTenant();
  const isAdmin = useIsAdmin();

  return (
    <Routes>
      <Route index element={<SearchesPage />} />
      <Route path="deployed-plans" element={<DeployedPlansPage />} />

      <Route path="profile-settings" element={<ProfileSettingsPage />} />
      <Route
        path="constraints"
        element={
          <ProvideSearch>
            <ConstraintsPage />
          </ProvideSearch>
        }
      />

      <Route
        path="/search"
        element={
          <ProvideSearch>
            <SearchPage />
            <MaterialLimitsUpdatedSnackbar />
          </ProvideSearch>
        }
      />
      {isAdmin ? (
        <Route
          path="/debug-search"
          element={
            <ProvideSearch>
              <DebugPage />
            </ProvideSearch>
          }
        />
      ) : null}

      <Route
        path="/plan"
        element={
          <ProvideSearch>
            <PlanIdBoundary fallback={<Navigate to={`/${tenant}/v3`} />}>
              <PeriodTabs>
                <PlanPage />
              </PeriodTabs>
            </PlanIdBoundary>
            <MaterialLimitsUpdatedSnackbar />
          </ProvideSearch>
        }
      />

      <Route
        path="/search_context_group/*"
        element={<SearchContextsRoutes />}
      />

      <Route path="/session/:session_id" element={<SessionRedirect />} />

      {isAdmin ? (
        <Route path="/dashboard" element={<StrategyDashboardPage />} />
      ) : null}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
