import { useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useEffect } from "react";
import {
  PurchasingPlanMaterial,
  PurchasingQuoteRead,
  Supplier,
  useAddQuotesMutation,
} from "src/store/api/generatedApi";
import {
  purchasingPlanTableEditsSlice,
  selectPurchasingPlanTableEdits,
} from "src/store/slices/purchasingPlanTableEditSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { makeRows, QUOTE_CREATION_FIXED_CACHE_KEY } from "../utils";
import { PurchasingPlanTable, Row } from "./PurchasingPlanTable";

type Props = {
  planId: number;
  materials: PurchasingPlanMaterial[];
  quotes: PurchasingQuoteRead[];
  suppliers: Supplier[];
};

export const EditablePurchasingPlanTable = ({
  planId,
  materials,
  quotes,
  suppliers,
}: Props) => {
  const gridApiRef = useGridApiRef();
  const dispatch = useAppDispatch();
  const [, createQuoteResult] = useAddQuotesMutation({
    fixedCacheKey: QUOTE_CREATION_FIXED_CACHE_KEY,
  });
  const planEdits = useAppSelector((state) =>
    selectPurchasingPlanTableEdits(state)
  );

  useEffect(() => {
    if (planEdits.planId !== planId) {
      dispatch(
        purchasingPlanTableEditsSlice.actions.set([
          planId,
          makeRows(quotes),
          materials,
        ])
      );
    }
  }, [planEdits, quotes, planId, dispatch]);

  const handleOnChange = React.useCallback(
    (rows: Row[]) => {
      dispatch(purchasingPlanTableEditsSlice.actions.setEditedRows(rows));
    },
    [planId]
  );

  if (!planEdits) {
    return null;
  }

  return (
    <PurchasingPlanTable
      planId={planId}
      apiRef={gridApiRef}
      materials={materials}
      rows={planEdits.editedRows}
      suppliers={suppliers}
      onChange={handleOnChange}
      loading={createQuoteResult.isLoading}
    />
  );
};
