import { generatedApi } from "./generatedApi";

const enhancedApi = generatedApi.enhanceEndpoints({
  endpoints: {
    getPlanMixesExcel: (endpoint) => {
      const query = endpoint.query!;

      endpoint.query = (queryArg) =>
        Object.assign(query(queryArg), {
          responseHandler: (response: Response) => response.blob(),
        });
    },

    sendSearchNotification: (endpoint) => {
      endpoint.invalidatesTags = [];
    },

    listSessions: (endpoint) => {
      endpoint.providesTags = ["session", "search"];
    },
    listSearches: (endpoint) => {
      endpoint.providesTags = [{ type: "search", id: "list" }];
    },

    runSearch: (endpoint) => {
      endpoint.invalidatesTags = ["search", "result"];
    },
    createSearch: (endpoint) => {
      endpoint.invalidatesTags = [{ type: "search", id: "list" }];
    },
    getSearch: (endpoint) => {
      endpoint.providesTags = ["search"];
    },
    getSearchContext: (endpoint) => {
      endpoint.providesTags = ["search"];
    },

    getChargeScheduleMessageTemplate: (endpoint) => {
      endpoint.providesTags = [];
    },

    getLayering: (endpoint) => {
      endpoint.invalidatesTags = [];
    },
  },
});

export { enhancedApi as backendApi };
