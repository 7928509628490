import React from "react";

import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";

import {
  AppDispatch,
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "src/store/store";
import {
  deployPlanFormSlice,
  selectDeployPlanFormChecked,
  selectDeployPlanFormCheckedCount,
  selectDeployPlanFormIsSubmitting,
} from "src/store/slices/deployPlanFormSlice";
import {
  ChargeScheduleMessage,
  generatedApi,
  PublishedChargeScheduleRead,
  usePublishPlanChargeScheduleMutation,
} from "src/store/api/generatedApi";
import { Button } from "@mui/material";

type Props = {
  formPlanId: number;
};

const waitUntilConfirmedChargeSchedulesConfirmed =
  (dispatch: AppDispatch, tenantName: string) =>
  async (
    publishedChargeSchedules: PublishedChargeScheduleRead[],
    planIdPeriodPairs: { planId: number; period: number }[]
  ): Promise<void> => {
    const confirmedChargeSchedules = await dispatch(
      generatedApi.endpoints.listConfirmedChargeScheduleMeta.initiate({
        tenantName,
        fdmChargeScheduleIds: publishedChargeSchedules.map(
          (publishedChargeSchedule) =>
            publishedChargeSchedule.fdm_charge_schedule_id
        ),
      })
    );
    if (confirmedChargeSchedules.data) {
      const confirmedFdmChargeScheduleIds = confirmedChargeSchedules.data.map(
        (confirmedChargeSchedule) =>
          confirmedChargeSchedule.fdm_charge_schedule_id
      );
      if (
        publishedChargeSchedules.every((publishedChargeSchedule) =>
          confirmedFdmChargeScheduleIds.includes(
            publishedChargeSchedule.fdm_charge_schedule_id
          )
        )
      ) {
        return;
      } else {
        await new Promise((r) => setTimeout(r, 2000));
        return waitUntilConfirmedChargeSchedulesConfirmed(dispatch, tenantName)(
          publishedChargeSchedules,
          planIdPeriodPairs
        );
      }
    }
  };

export const SyncWithLevel2Button = ({ formPlanId }: Props) => {
  const { t } = useTenantTranslation();
  const tenantName = useTenant();
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const deployCount = useAppSelector((state) =>
    selectDeployPlanFormCheckedCount(state, formPlanId)
  );
  const isSubmitting = useAppSelector((state) =>
    selectDeployPlanFormIsSubmitting(state, formPlanId)
  );

  const [publishPlan] = usePublishPlanChargeScheduleMutation();

  const handleWaitUntilChargeSchedulesConfirmed = React.useMemo(
    () => waitUntilConfirmedChargeSchedulesConfirmed(dispatch, tenantName),
    [dispatch, tenantName]
  );

  const handleOnClick = React.useCallback(async () => {
    dispatch(deployPlanFormSlice.actions.submittingDeployForm(formPlanId));
    const publishPlans = selectDeployPlanFormChecked(
      store.getState(),
      formPlanId
    );
    const publishedChargeSchedules = await publishPlan({
      tenantName,
      publishPlans: {
        plans: publishPlans.map(({ planId, period }) => ({
          plan_id: planId,
          period,
        })),
      },
    });
    if (publishedChargeSchedules.data) {
      await handleWaitUntilChargeSchedulesConfirmed(
        publishedChargeSchedules.data,
        publishPlans
      );
      const smsPlans = publishedChargeSchedules.data
        .map((publishedChargeSchedule) => ({
          planId: publishedChargeSchedule.plan_id,
          period: publishedChargeSchedule.period,
        }))
        .filter(
          (pair, index, allPairs) =>
            allPairs.findIndex(
              (p) => p.period === pair.period && p.planId === pair.planId
            ) === index
        );
      const messageTemplatePromises = smsPlans.map((smsPlan) => {
        const { planId, period } = smsPlan;
        return dispatch(
          generatedApi.endpoints.getChargeScheduleMessageTemplate.initiate(
            {
              tenantName,
              planId,
              period,
            },
            { forceRefetch: true }
          )
        );
      });

      const messageTemplates = await Promise.all(messageTemplatePromises);

      if (messageTemplates[0]) {
        dispatch(
          deployPlanFormSlice.actions.setSMSForm([
            formPlanId,
            messageTemplates
              .map((messageTemplate) => messageTemplate.data)
              .filter(
                (
                  messageTemplate: ChargeScheduleMessage | undefined
                ): messageTemplate is ChargeScheduleMessage =>
                  messageTemplate !== undefined
              )
              .filter(({ plan_id }) => plan_id === formPlanId)
              .map(({ period, text }) => ({
                period,
                message: text,
              })),
            messageTemplates
              .map((messageTemplate) => messageTemplate.data)
              .filter(
                (
                  messageTemplate: ChargeScheduleMessage | undefined
                ): messageTemplate is ChargeScheduleMessage =>
                  messageTemplate !== undefined
              )
              .filter(({ plan_id }) => plan_id !== formPlanId)
              .map(({ plan_id, text }) => ({
                planId: plan_id,
                message: text,
              })),
          ])
        );
      }
    }
  }, [publishPlan]);

  return (
    <Button
      disabled={deployCount === 0}
      loading={isSubmitting}
      variant="contained"
      onClick={handleOnClick}
    >
      {t("syncWithLevel2")}
    </Button>
  );
};
