import { NotFound } from "components/notFound";
import { PurchasingPlanPage } from "components/purchasing/plan/PurchasingPlanPage";
import { PurchasingPage } from "components/purchasing/PurchasingPage";

import { Route } from "react-router";

import { Routes } from "react-router";

export const PurchasingRoutes = () => {
  return (
    <Routes>
      <Route index element={<PurchasingPage />} />

      <Route
        path="/plan/:purchasing_plan_id"
        element={<PurchasingPlanPage />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
