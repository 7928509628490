import { Navigate, Route, Routes, useParams } from "react-router";

import { SupervisorView } from "components/crane/SupervisorView";
import { NotFound } from "components/notFound";
import { CraneBasket } from "components/crane/CraneBasket";

import {
  RequireIsCraneDriver,
  RequireIsCraneSupervisor,
} from "./authentication";
import { RequireVersionRefresh } from "./dependencies";

const ValidateSupervisorView = () => {
  const params = useParams<{
    deployment_type: string;
    plan_timeframe?: string;
  }>();

  if (
    params.plan_timeframe &&
    params.deployment_type === "standard" &&
    (params.plan_timeframe === "next" || params.plan_timeframe === "latest")
  ) {
    return (
      <SupervisorView
        deploymentType="standard"
        planTimeframe={params.plan_timeframe}
      />
    );
  } else if (params.deployment_type === "backup") {
    return <SupervisorView deploymentType="backup" />;
  } else {
    return <NotFound />;
  }
};

export const CraneRoutes = () => {
  return (
    <Routes>
      <Route
        path="/supervisor/*"
        element={
          <RequireIsCraneSupervisor>
            <Routes>
              <Route index element={<Navigate to="./standard/latest" />} />
              <Route
                path="/active"
                element={<Navigate to="../standard/latest" />}
              />
              <Route path="/standard" element={<Navigate to="./latest" />} />
              <Route
                path="/:deployment_type/:plan_timeframe?"
                element={<ValidateSupervisorView />}
              />
            </Routes>
          </RequireIsCraneSupervisor>
        }
      />

      <Route
        path="/driver/*"
        element={
          <RequireVersionRefresh>
            <RequireIsCraneDriver>
              <Routes>
                <Route index element={<CraneBasket />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </RequireIsCraneDriver>
          </RequireVersionRefresh>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
