import { FailureRateMetric } from "components/plan/table/inventory/types";
import { useTenantTranslation } from "hooks/formatters";
import { Layout, LayoutAxis, PlotData } from "plotly.js";
import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import {
  chemicalElementColours,
  defaultLayout,
  fractionTickFormatting,
  generalTickFormatting,
  getLineDash,
  getMarkerSymbol,
  setSelectedDateFromPlotClick,
} from "../plotting";
import { GroupedFailureRateSeries } from "../types";
import { TFunction } from "i18next";

type FailureRatePlotProps = {
  failureRateSeries: GroupedFailureRateSeries;
  selectedTimeAggregation: string | null;
  selectedMetric: FailureRateMetric;
  selectedSampleLocation: string | null;
  selectedSamplePositionType: string | null;
  setSelectedDate: (date: Date) => void;
};

export const FailureRatePlot = ({
  failureRateSeries,
  selectedTimeAggregation,
  selectedMetric,
  selectedSampleLocation,
  selectedSamplePositionType,
  setSelectedDate,
}: FailureRatePlotProps) => {
  const { t } = useTenantTranslation();

  const initialFigData = buildInitialFigData(failureRateSeries, t);

  const [figure, setFigure] = useState({
    data: initialFigData,
    layout: updateLayout(defaultLayout, selectedMetric, t),
  });

  // Update figure when selected options change
  useEffect(() => {
    setFigure({
      ...figure,
      data: updateFigData(
        figure.data,
        selectedTimeAggregation,
        selectedMetric,
        selectedSampleLocation,
        selectedSamplePositionType
      ),
      layout: updateLayout(figure.layout, selectedMetric, t),
    });
  }, [
    selectedTimeAggregation,
    selectedMetric,
    selectedSampleLocation,
    selectedSamplePositionType,
  ]);

  return (
    <div style={{ width: "100%" }}>
      <Plot
        data={figure.data}
        layout={figure.layout}
        // eslint-disable-next-line react/forbid-component-props
        style={{ width: "100%" }}
        useResizeHandler
        onClick={setSelectedDateFromPlotClick(setSelectedDate)}
      />
    </div>
  );
};

const buildInitialFigData = (
  groupedFailureRateSeries: GroupedFailureRateSeries,
  t: TFunction
) => {
  const figData: Partial<PlotData>[] = [];
  for (const [key, series] of groupedFailureRateSeries.values) {
    const colour = chemicalElementColours.get(key.chemicalElementSymbol);

    figData.push({
      x: series.date,
      y: series.failureRate,
      type: "scatter",
      mode: "lines+markers",
      name: t("samples"),
      legendgroup: key.chemicalElementSymbol,
      legendgrouptitle: {
        text: key.chemicalElementSymbol,
      },
      marker: { color: colour, symbol: getMarkerSymbol(0) },
      line: { color: colour, dash: getLineDash(0) },
      customdata: [
        key.timeAggregation,
        "failureRate",
        key.sampleLocation,
        key.samplePositionType,
      ],
    });
    figData.push({
      x: series.date,
      y: series.numFailures,
      type: "scatter",
      mode: "lines+markers",
      name: t("samples"),
      legendgroup: key.chemicalElementSymbol,
      legendgrouptitle: {
        text: key.chemicalElementSymbol,
      },
      marker: { color: colour, symbol: getMarkerSymbol(0) },
      line: { color: colour, dash: getLineDash(0) },
      customdata: [
        key.timeAggregation,
        "numFailures",
        key.sampleLocation,
        key.samplePositionType,
      ],
    });
    figData.push({
      x: series.date,
      y: series.expectedFailureRate,
      type: "scatter",
      mode: "lines+markers",
      name: t("expectedLevel2"),
      legendgroup: key.chemicalElementSymbol,
      legendgrouptitle: {
        text: key.chemicalElementSymbol,
      },
      marker: { color: colour, symbol: getMarkerSymbol(1) },
      line: { color: colour, dash: getLineDash(1) },
      customdata: [
        key.timeAggregation,
        "failureRate",
        key.sampleLocation,
        key.samplePositionType,
      ],
    });
    figData.push({
      x: series.date,
      y: series.expectedNumFailures,
      type: "scatter",
      mode: "lines+markers",
      name: t("expectedLevel2"),
      legendgroup: key.chemicalElementSymbol,
      legendgrouptitle: {
        text: key.chemicalElementSymbol,
      },
      marker: { color: colour, symbol: getMarkerSymbol(1) },
      line: { color: colour, dash: getLineDash(1) },
      customdata: [
        key.timeAggregation,
        "numFailures",
        key.sampleLocation,
        key.samplePositionType,
      ],
    });
  }

  return figData;
};

const updateFigData = (
  figData: Partial<PlotData>[],
  selectedTimeAggregation: string | null,
  selectedMetric: FailureRateMetric,
  selectedSampleLocation: string | null,
  selectedSamplePositionType: string | null
): Partial<PlotData>[] => {
  // Show all elements for the selected time aggregation, metric, location, and position type
  return figData.map((trace) => ({
    ...trace,
    visible:
      trace.customdata?.[0] === selectedTimeAggregation &&
      trace.customdata?.[1] === selectedMetric &&
      trace.customdata?.[2] === selectedSampleLocation &&
      trace.customdata?.[3] === selectedSamplePositionType,
  }));
};

const updateLayout = (
  currentLayout: Partial<Layout>,
  selectedMetric: FailureRateMetric,
  t: (key: string) => string
): Partial<Layout> => {
  const yaxisUpdate: Partial<LayoutAxis> = {
    ...currentLayout.yaxis,
    title:
      selectedMetric === "failureRate" ? t("failureRate") : t("numFailures"),
    tickformat:
      selectedMetric === "failureRate"
        ? fractionTickFormatting
        : generalTickFormatting,
  };

  return {
    ...currentLayout,
    yaxis: yaxisUpdate,
  };
};
