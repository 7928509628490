import { useTenant } from "hooks/settings";
import { Loaded, loadedEndpoint } from "models/loaded";
import {
  InventoryMassSeries,
  MaterialGroupingName,
  useGetStrategyDashboardInventoryQuery,
} from "src/store/api/generatedApi";
import {
  GroupedInventorySeries,
  InventoryTimeSeries,
  InventoryTimeSeriesKey,
} from "../types";

export const useStrategyDashboardInventory =
  (): Loaded<GroupedInventorySeries> => {
    const loadedRawData = loadedEndpoint(
      useGetStrategyDashboardInventoryQuery({ tenantName: useTenant() })
    );
    if (loadedRawData.status === "success") {
      return {
        status: "success",
        data: buildGroupedInventorySeries(loadedRawData.data.series),
        fetching: loadedRawData.fetching,
      };
    }
    return loadedRawData;
  };

const buildGroupedInventorySeries = (
  series: InventoryMassSeries[]
): GroupedInventorySeries => {
  const stringKeyKeyMap: Record<string, InventoryTimeSeriesKey> = {};
  const stringKeyValuesMap: Record<string, InventoryTimeSeries> = {};

  for (const item of series) {
    const key: InventoryTimeSeriesKey = {
      materialGroupingName: item.material_grouping_name,
      materialGroupName: item.material_group_name,
    };
    const stringKey = JSON.stringify(key);
    stringKeyKeyMap[stringKey] = key;

    const value = stringKeyValuesMap[stringKey] ?? {
      date: [],
      inventory_mass: [],
      inventory_fraction: [],
    };
    value.date.push(item.inventory_date);
    value.inventory_mass.push(item.inventory_mass);
    value.inventory_fraction.push(item.inventory_fraction);
    stringKeyValuesMap[stringKey] = value;
  }

  const values: [InventoryTimeSeriesKey, InventoryTimeSeries][] =
    Object.entries(stringKeyKeyMap).map(([stringKey, key]) => [
      key,
      stringKeyValuesMap[stringKey]!,
    ]);

  const availableMaterialGroupings = new Set<MaterialGroupingName>(
    series.map((item) => item.material_grouping_name)
  );

  return {
    values,
    availableMaterialGroupings,
  };
};
