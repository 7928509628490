import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";
import { LoadedContent } from "components/common/loading/loadedContent";
import { FullWidthPlot, PlotConfig } from "components/common/plots";
import { useNotifyStatus } from "contexts/status";
import { sorted } from "helpers";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { useTenant } from "hooks/settings";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { loadedEndpoint } from "models/loaded";
import { useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  PendingDelivery,
  useClearUploadedPendingDeliveriesMutation,
  useGetPendingDeliveriesMapMutation,
  useGetPendingDeliveriesQuery,
  useUploadPendingDeliveriesMutation,
} from "src/store/api/generatedApi";

export const DeliveryTrackingPage = () => {
  return (
    <>
      <ManualControls />
      <PendingDeliveriesTable />
    </>
  );
};

const ManualControls = () => {
  const [fileNames, setFileNames] = useState("");

  const tenant = useTenant();
  const [uploadPendingDeliveries] = useUploadPendingDeliveriesMutation();
  const [clearPendingDeliveries] = useClearUploadedPendingDeliveriesMutation();

  const [showManualControls, setShowManualControls] = useState(false);
  const notifyStatus = useNotifyStatus();

  useHotkeys("0", () => setShowManualControls((current) => !current));
  useHotkeys("1", () =>
    clearPendingDeliveries({ tenantName: tenant })
      .unwrap()
      .then(() =>
        uploadPendingDeliveries({
          tenantName: tenant,
          uploadedFileNames: {
            file_names: [
              "Guildford.pdf",
              "Yorkshire.pdf",
              "Portsmouth.pdf",
              "Bath.pdf",
              "Norwich.pdf",
            ],
          },
        })
      )
  );
  useHotkeys("2", () =>
    uploadPendingDeliveries({
      tenantName: tenant,
      uploadedFileNames: {
        file_names: ["Kent.pdf"],
      },
    }).then(() =>
      notifyStatus({
        type: "info",
        text: "New dispatch email received from supplier",
      })
    )
  );

  const serialiser = useStringSerialiser();

  return showManualControls ? (
    <>
      <ValidatedTextField
        value={fileNames}
        setValue={setFileNames}
        serialiser={serialiser}
        onEditConfirmedWithKeyboard={(text) => {
          void uploadPendingDeliveries({
            tenantName: tenant,
            uploadedFileNames: { file_names: text.split(",") },
          });
          setFileNames("");
        }}
      />
      <Button onClick={() => clearPendingDeliveries({ tenantName: tenant })}>
        Clear
      </Button>
    </>
  ) : null;
};

const PendingDeliveriesTable = () => {
  const tenant = useTenant();
  const pendingDeliveries = loadedEndpoint(
    useGetPendingDeliveriesQuery(
      {
        tenantName: tenant,
      },
      { pollingInterval: 5000 }
    )
  );

  return (
    <Stack>
      <LoadedContent
        data={pendingDeliveries}
        loading={<LinearProgress />}
        disableFetching
      >
        {(loadedPendingDeliveries) => (
          <PendingDeliveriesTableContent
            pendingDeliveries={loadedPendingDeliveries}
          />
        )}
      </LoadedContent>
    </Stack>
  );
};

const PendingDeliveriesTableContent = ({
  pendingDeliveries,
}: {
  pendingDeliveries: PendingDelivery[];
}) => {
  const formatLocalDate = useFormatLocalDateString("datetime");

  const massSerialiser = useNumberSerialiser({ decimalPlaces: 0 });
  const priceSerialiser = useNumberSerialiser({
    decimalPlaces: 2,
    default: { value: null, text: "N/A" },
  });

  const columns = useMemo(
    (): GridColDef[] => [
      { field: "order_id", headerName: "Order ID", display: "flex", flex: 1 },
      { field: "supplier", headerName: "Supplier", display: "flex", flex: 2 },
      { field: "material", headerName: "Material", display: "flex", flex: 1 },
      {
        field: "mass",
        headerName: "Mass [kg]",
        display: "flex",
        flex: 1,
        align: "right",
        valueFormatter: massSerialiser.format,
      },
      {
        field: "price",
        headerName: "Price [GBP]",
        display: "flex",
        flex: 1,
        align: "right",
        valueFormatter: priceSerialiser.format,
      },
      {
        field: "mode_of_transport",
        headerName: "Mode of transport",
        display: "flex",
        flex: 1,
        valueFormatter: (value) =>
          ({ truck: "Truck", train: "Train", ship: "Ship" })[value],
      },
      {
        field: "dispatch_address",
        headerName: "Dispatch address",
        display: "flex",
        flex: 3,
      },
      {
        field: "dispatch_time",
        headerName: "Dispatch time",
        display: "flex",
        flex: 1,
        valueFormatter: formatLocalDate,
      },
      {
        field: "arrival_time",
        headerName: "Arrival time",
        display: "flex",
        flex: 1,
        valueFormatter: formatLocalDate,
      },
    ],
    []
  );

  return (
    <>
      <PendingDeliveriesMap
        uploadedFileNames={pendingDeliveries.map(
          (delivery) => delivery.source_file_name
        )}
      />

      <Stack sx={{ px: 8 }} gap={4}>
        <Typography variant="h1">Pending Deliveries</Typography>

        <DataGridPremium
          rows={pendingDeliveries}
          columns={columns}
          getRowId={(row: PendingDelivery) => row.order_id ?? "null"}
          initialState={{
            sorting: {
              sortModel: [{ field: "order_id", sort: "desc" }],
            },
          }}
        />
      </Stack>
    </>
  );
};

const PendingDeliveriesMap = ({
  uploadedFileNames,
}: {
  uploadedFileNames: string[];
}) => {
  const tenant = useTenant();

  const [cachedFileNames, setCachedFileNames] = useState<string | null>(null);
  const fileNames = sorted(uploadedFileNames, (name) => name).join(":");

  const [getPendingDeliveriesMap] = useGetPendingDeliveriesMapMutation();
  const [cachedMap, setCachedMap] = useState<PlotConfig | null>(null);

  useEffect(() => {
    if (fileNames !== cachedFileNames) {
      void getPendingDeliveriesMap({
        tenantName: tenant,
        uploadedFileNames: { file_names: uploadedFileNames },
      })
        .unwrap()
        .then((mapString) => {
          setCachedMap(
            JSON.parse(JSON.parse(mapString) as string) as PlotConfig
          );
          setCachedFileNames(cachedFileNames);
        });
    }
  }, [fileNames, cachedFileNames, setCachedFileNames]);

  return cachedMap === null ? null : (
    <FullWidthPlot plotConfig={cachedMap} height={620} />
  );
};
