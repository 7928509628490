import { useTheme } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { useTenantTranslation } from "hooks/formatters";
import { useLanguage } from "hooks/settings";
import { useMemo } from "react";
import {
  ChemicalElementSymbol,
  ChemicalSampleLocation,
  ChemicalSamplePositionType,
  FailureMetadata,
  SupportedLanguage,
  TimeAggregation,
} from "src/store/api/generatedApi";
import { formatNumber } from "src/utils/formatNumber";
import { ColouredCell, ColourGradientCell } from "../ColouredCell";
import { chemicalElementColours } from "../plotting";
import { defaultTableSx } from "../tableFormatting";
import { GroupedFailureRateSeries } from "../types";

type FailureRateTableProps = {
  groupedFailureRateSeries: GroupedFailureRateSeries;
  selectedDate: Date | null;
  selectedTimeAggregation: TimeAggregation | null;
  selectedSampleLocation: ChemicalSampleLocation | null;
  selectedSamplePositionType: ChemicalSamplePositionType | null;
};

type FailureRow = FailureMetadata & {
  id: number;
  chemicalElementSymbol: ChemicalElementSymbol;
};

export const FailureRateTable = ({
  groupedFailureRateSeries,
  selectedDate,
  selectedTimeAggregation,
  selectedSampleLocation,
  selectedSamplePositionType,
}: FailureRateTableProps) => {
  const rows = useMemo(() => {
    return buildRows(
      groupedFailureRateSeries,
      selectedDate,
      selectedTimeAggregation,
      selectedSampleLocation,
      selectedSamplePositionType
    );
  }, [
    groupedFailureRateSeries,
    selectedDate,
    selectedTimeAggregation,
    selectedSampleLocation,
    selectedSamplePositionType,
  ]);

  const columns = buildColumns();

  return (
    <DataGridPremium
      sx={{ ...defaultTableSx, height: 550 }}
      rows={rows}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: "date", sort: "desc" }],
        },
      }}
    />
  );
};

const buildRows = (
  groupedFailureRateSeries: GroupedFailureRateSeries,
  selectedDate: Date | null,
  selectedTimeAggregation: TimeAggregation | null,
  selectedSampleLocation: ChemicalSampleLocation | null,
  selectedSamplePositionType: ChemicalSamplePositionType | null
): FailureRow[] => {
  const filteredSeries = groupedFailureRateSeries.values.filter(
    (item) =>
      item[0].timeAggregation == selectedTimeAggregation &&
      item[0].sampleLocation == selectedSampleLocation &&
      item[0].samplePositionType == selectedSamplePositionType
  );

  const relevantFailures: FailureRow[] = [];
  let rowId = 0;

  for (const [key, series] of filteredSeries) {
    for (const [index, dateString] of series.date.entries()) {
      const date = new Date(dateString);

      if (!selectedDate || date.toISOString() === selectedDate.toISOString()) {
        for (const failureMetdata of series.failuresMetadata[index] ?? []) {
          relevantFailures.push({
            id: rowId,
            chemicalElementSymbol: key.chemicalElementSymbol,
            ...failureMetdata,
          });
          rowId++;
        }
      }
    }
  }

  return relevantFailures;
};

const buildColumns = (): GridColDef[] => {
  const { t } = useTenantTranslation();
  const language = useLanguage();
  const theme = useTheme();

  const formatNumberLocal = formatNumber(language as SupportedLanguage);
  const formatWeightPercent = formatNumberLocal(3, true);
  const formatExcessFraction = (value?: number) =>
    !value ? "" : `${formatNumberLocal(1, true)(value * 100)} %`;

  return [
    { field: "heat_id", headerName: t("Heat ID"), flex: 1 },
    { field: "sample_timestamp", headerName: t("Sample Timestamp"), flex: 2 },
    { field: "steel_grade_name", headerName: t("steelGrade"), flex: 2 },
    { field: "product_group_name", headerName: t("productGroup"), flex: 2 },
    {
      field: "chemicalElementSymbol",
      headerName: t("Element"),
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<FailureRow, ChemicalElementSymbol>
      ) =>
        !params.value ? (
          ""
        ) : (
          <ColouredCell
            content={params.value}
            alpha={0.5}
            color={
              chemicalElementColours.get(params.value) ??
              theme.palette.common.white
            }
            justifyContent="center"
          />
        ),
      cellClassName: "coloured-cell",
    },
    {
      field: "weight_percent",
      headerName: t("%"),
      flex: 1,
      align: "right",
      valueFormatter: formatWeightPercent,
    },
    {
      field: "weight_percent_max",
      headerName: t("Max %"),
      flex: 1,
      align: "right",
      valueFormatter: formatWeightPercent,
    },
    {
      field: "violation_fraction",
      headerName: t("Violation"),
      flex: 1,
      renderCell: (params: GridRenderCellParams<FailureRow, number>) =>
        !params.value ? (
          ""
        ) : (
          <ColourGradientCell
            content={formatExcessFraction(params.value)}
            alphaVariableValue={params.value}
            alphaVariableLowerClippingValue={0}
            alphaVariableUpperClippingValue={0.3}
            color={theme.palette.data.red}
            justifyContent="flex-end"
          />
        ),
      align: "right",
      cellClassName: "coloured-cell",
    },
  ];
};
