import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { Layout, PlotData } from "plotly.js";
import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { MaterialGroupingName, UnitType } from "src/store/api/generatedApi";
import {
  buildMaterialColours,
  defaultLayout,
  fractionTickFormatting,
  generalTickFormatting,
  getMaterialColour,
  setSelectedDateFromPlotClick,
} from "../plotting";
import { GroupedInventorySeries, InventoryPlotType } from "../types";

type InventoryPlotProps = {
  inventorySeries: GroupedInventorySeries;
  selectedGrouping: MaterialGroupingName | null;
  selectedPlotType: InventoryPlotType;
  setSelectedDate: (date: Date) => void;
};

export const InventoryPlot = ({
  inventorySeries,
  selectedGrouping,
  selectedPlotType,
  setSelectedDate,
}: InventoryPlotProps) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);

  const initialFigData = buildInitialFigData(inventorySeries);

  const [figure, setFigure] = useState({
    data: initialFigData,
    layout: updateLayout(defaultLayout, selectedPlotType, t, units),
  });

  // Update figure when selected options or plot type change
  useEffect(() => {
    setFigure({
      data: updateFigData(figure.data, selectedGrouping, selectedPlotType),
      layout: updateLayout(figure.layout, selectedPlotType, t, units),
    });
  }, [selectedGrouping, selectedPlotType, t, units]);

  return (
    <div style={{ width: "100%" }}>
      <Plot
        data={figure.data}
        layout={figure.layout}
        // eslint-disable-next-line react/forbid-component-props
        style={{ width: "100%" }}
        useResizeHandler
        onClick={setSelectedDateFromPlotClick(setSelectedDate)}
      />
    </div>
  );
};

const buildInitialFigData = (
  groupedInventorySeries: GroupedInventorySeries
): Partial<PlotData>[] => {
  const figData: Partial<PlotData>[] = [];
  const materialColours = buildMaterialColours(
    groupedInventorySeries.values.map((item) => ({
      groupingName: item[0].materialGroupingName,
      groupName: item[0].materialGroupName,
    }))
  );

  for (const [key, series] of groupedInventorySeries.values) {
    const colour = getMaterialColour(
      materialColours,
      key.materialGroupingName,
      key.materialGroupName
    );

    // Add mass trace
    figData.push({
      x: series.date,
      y: series.inventory_mass,
      type: "scatter",
      mode: "lines+markers",
      visible: false,
      name: key.materialGroupName,
      line: {
        color: colour,
      },
      marker: {
        color: colour,
      },
      customdata: [key.materialGroupingName, "mass"],
      stackgroup: `${key.materialGroupingName}_mass`,
    });

    // Add fraction trace
    figData.push({
      x: series.date,
      y: series.inventory_fraction,
      type: "scatter",
      mode: "lines+markers",
      visible: false,
      name: key.materialGroupName,
      line: {
        color: colour,
      },
      marker: {
        color: colour,
      },
      customdata: [key.materialGroupingName, "fraction"],
      stackgroup: `${key.materialGroupingName}_fraction`,
    });
  }

  return figData;
};

const updateFigData = (
  figData: Partial<PlotData>[],
  selectedGrouping: MaterialGroupingName | null,
  selectedPlotType: InventoryPlotType
): Partial<PlotData>[] => {
  return figData.map((trace) => ({
    ...trace,
    visible:
      trace.customdata?.[0] === selectedGrouping &&
      trace.customdata?.[1] === selectedPlotType,
  }));
};

const updateLayout = (
  currentLayout: Partial<Layout>,
  selectedPlotType: InventoryPlotType,
  t: (key: string) => string,
  units: (key: UnitType) => string
): Partial<Layout> => {
  const yaxisTitle =
    selectedPlotType === "mass"
      ? `${t("inventory")}${units("mass")}`
      : `${t("inventory")} (%)`;

  return {
    ...currentLayout,
    yaxis: {
      ...currentLayout.yaxis,
      title: yaxisTitle,
      tickformat:
        selectedPlotType === "fraction"
          ? fractionTickFormatting
          : generalTickFormatting,
    },
  };
};
