import { Stack, SxProps } from "@mui/material";
import { HeatXScrapYTable } from "./HeatXScrapYTable";
import { ScrapXHeatYTable } from "./ScrapXHeatYTable";
import {
  ProductionContext,
  useGrouping,
  useGroupOption,
  useGroups,
  useIsTransposed,
  useMeasureOption,
  useMixes,
  usePeriodMaterialSummaries,
  usePeriodOption,
  useSteelGradesOption,
} from "./ProductionContext";
import {
  ChefGroup,
  MaterialRead,
  MixBasketSummary,
  MixMaterialSummary,
  MixSummary,
  PeriodMaterialSummary,
  ProductGroup,
  ProductGroupMixMaterialSummary,
  ProductGroupMixSummary,
} from "src/store/api/generatedApi";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";

type Props = {
  productGroups: ProductGroup[] | null;
  chemistryGroups: ChefGroup[];
  productGroupMixMaterialSummaries: ProductGroupMixMaterialSummary[] | null;
  productGroupMixSummaries: ProductGroupMixSummary[] | null;
  mixMaterialSummaries: MixMaterialSummary[];
  mixSummaries: MixSummary[];
  periodMaterialSummaries: PeriodMaterialSummary[];
  materials: MaterialRead[];
  mixBasketSummaries: MixBasketSummary[];
  period: number;
  sx?: SxProps;
};

const defaultSxProps = {};

export const ProductionTable = ({
  productGroups,
  chemistryGroups,
  productGroupMixMaterialSummaries,
  productGroupMixSummaries,
  mixMaterialSummaries,
  mixSummaries,
  periodMaterialSummaries,
  materials,
  mixBasketSummaries,
  period,
  sx = defaultSxProps,
}: Props) => {
  return (
    <Stack gap={2} sx={{ height: "100%", ...sx }}>
      <ProductionContext
        period={period}
        productGroups={productGroups}
        chemistryGroups={chemistryGroups}
        productGroupMixMaterialSummaries={productGroupMixMaterialSummaries}
        productGroupMixSummaries={productGroupMixSummaries}
        mixMaterialSummaries={mixMaterialSummaries}
        mixSummaries={mixSummaries}
        planPeriodMaterialSummaries={periodMaterialSummaries}
        materials={materials}
        hasProductGroups
      >
        <Table
          key={period}
          materials={materials}
          chemistryGroups={chemistryGroups}
          mixBasketSummaries={mixBasketSummaries}
        />
      </ProductionContext>
    </Stack>
  );
};

const sx = {
  "& .MuiDataGrid-cell": {
    paddingLeft: 0,
    paddingRight: 0,
  },
  "& .MuiDataGrid-columnHeader": {
    paddingLeft: 0,
    paddingRight: 0,
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },
  "& .group--header": {
    px: 1,
    py: 0.5,
  },
};

const Table = ({
  materials,
  chemistryGroups,
  mixBasketSummaries,
}: {
  materials: MaterialRead[];
  chemistryGroups: ChefGroup[];
  mixBasketSummaries: MixBasketSummary[];
}) => {
  const isTransposed = useIsTransposed();

  const group = useGroupOption();
  const measure = useMeasureOption();
  const period = usePeriodOption();
  const steelGrades = useSteelGradesOption();
  const planGroups = useGroups();
  const grouping = useGrouping();
  const periodMaterialSummaries = usePeriodMaterialSummaries();
  const planMixes = useMixes();

  if (isTransposed) {
    return (
      <ScrapXHeatYTable
        materials={materials}
        group={group}
        period={period}
        measure={measure}
        steelGrades={steelGrades}
        planGroups={planGroups}
        grouping={grouping}
        chemistryGroups={typeSafeObjectFromEntries(
          chemistryGroups.map((chemistryGroup) => [
            chemistryGroup.id!,
            chemistryGroup,
          ])
        )}
        periodMaterialSummaries={periodMaterialSummaries}
        mixBasketSummaries={mixBasketSummaries}
        planMixes={planMixes}
        sx={sx}
      />
    );
  } else {
    return (
      <HeatXScrapYTable
        materials={materials}
        measure={measure}
        planGroups={planGroups}
        planMixes={planMixes}
        grouping={grouping}
        steelGrades={steelGrades}
        chemistryGroups={typeSafeObjectFromEntries(
          chemistryGroups.map((chemistryGroup) => [
            chemistryGroup.id!,
            chemistryGroup,
          ])
        )}
        group={group}
        mixBasketSummaries={mixBasketSummaries}
        sx={sx}
      />
    );
  }
};
