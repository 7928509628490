import React, { ReactNode, useEffect } from "react";
import { useIdToken } from "react-firebase-hooks/auth";
import { Navigate, useLocation, useParams } from "react-router";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NotFound } from "../components/notFound";
import { authUpdate, TenantPermissions } from "../store/slices/settings";
import { getAuth, ParsedToken } from "firebase/auth";
import { LoadingWheel } from "src/components/common/loading/loadingWheel";
import {
  useHasTenant,
  useIsMeticulous,
  usePermission,
} from "hooks/permissions";
import { ProvideTenantData, useTenantData } from "contexts/tenant";
import { FeatureFlag, useGetMeQuery } from "store/api/generatedApi.tsx";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useIsAdmin, useUserUid } from "hooks/settings.tsx";
import { captureException } from "@sentry/react";
import { useAppDispatch } from "store/store.tsx";

interface ParsedClaims extends ParsedToken {
  permissions?: TenantPermissions;
  admin?: string;
}

/**
 * Only render the child if a user is signed in.
 *
 * Otherwise, redirect to the sign-in page.  Calls to `useGetMeQuery` from
 * inside this component will be valid.
 */
export const RequireUser = ({ children }: { children: ReactNode }) => {
  const auth = getAuth();
  const [user, loading] = useIdToken(auth);
  const location = useLocation();
  const dispatch = useAppDispatch();

  // If we're running in CI then don't show the login screen.
  // Backend requests are mocked out so there is no ID token necessary.
  const isTesting = useIsMeticulous();

  // Parse custom claims from firebase identity token

  useEffect(() => {
    const setPermissions = async () => {
      const idTokenResult = await user?.getIdTokenResult();
      // Return type of claims is ParsedToken but also includes custom claims
      // https://firebase.google.com/docs/reference/js/auth.idtokenresult
      const claims: ParsedClaims | undefined = idTokenResult?.claims;
      if (!claims) {
        return;
      }
      dispatch(
        authUpdate({
          userUid: user?.uid ?? "",
          isAdmin: claims?.admin === "admin",
          tenantPermissions: claims?.permissions ?? {},
          displayName: user?.displayName ?? "",
          email: user?.email ?? "",
        })
      );
    };
    setPermissions().catch(console.error);
  }, [user, dispatch]);

  if (!user && !loading && !isTesting) {
    // user is not authenticated
    return <Navigate to="/sign-in" replace state={{ from: location }} />;
  } else if (!user && loading) {
    return <LoadingWheel />;
  } else {
    return children;
  }
};

export const ValidateUser = ({ children }: { children: ReactNode }) => {
  /**
   * Only render the child if the user has access to the tenant in the URL.
   *
   * If the call to `useGetMeQuery` is successful, it is likely that the user has just been
   * invited to the tenant and the permissions have not been set yet.  In this case refresh
   * the page to fetch permissions.
   */
  const tenant = useParams().tenant;
  const auth = getAuth();
  const userUid = useUserUid();
  const hasAccess = useHasTenant(tenant ?? "");

  // Fetch user from backend if the token does not currently have permissions
  // This will set permissions if the user is a pending invite
  const queryResult = useGetMeQuery(
    hasAccess || !userUid || !tenant ? skipToken : { tenantName: tenant }
  );

  // Firebase account does not have permissions but the account is valid in db
  // Reload the token to get the permissions & refresh the page
  if (queryResult.isSuccess && !hasAccess) {
    auth.currentUser
      ?.getIdToken(true)
      .then(() => {
        window.location.reload();
      })
      .catch(captureException);
  }

  if (hasAccess) {
    return children;
  }
  return <NotFound />;
};

/**
 * Only render the child if a tenant is in the URL.
 *
 * Otherwise, an error will be shown prompting the user to access a different
 * URL.  Calls to `useTenant` from inside this component will be valid.
 */
export const RequireTenant = ({ children }: { children: ReactNode }) => {
  const tenant = useParams().tenant;
  const hasAccess = useHasTenant(tenant ?? "");

  // We cannot use tenant-specific translation until the tenant is verified
  const { t } = useTranslation();

  if (typeof tenant !== "string") {
    return <Alert severity="error">{t("errorLoadingTenantParams")}</Alert>;
  } else if (hasAccess) {
    return <ProvideTenantData>{children}</ProvideTenantData>;
  } else {
    return <NotFound />;
  }
};

export const RequireIsCraneDriver = ({ children }: React.PropsWithChildren) => {
  const isCraneDiver = usePermission(["crane_driver"]);
  if (isCraneDiver) {
    return children;
  } else {
    return <NotFound />;
  }
};

export const RequireIsScrapTrackingDashboardViewer = ({
  children,
}: React.PropsWithChildren) => {
  const isScrapTrackingDashboardViewer = usePermission([
    "scrap_tracking_dashboard_viewer",
  ]);
  if (isScrapTrackingDashboardViewer) {
    return children;
  } else {
    return <NotFound />;
  }
};

export const RequireIsCraneSupervisor = ({
  children,
}: React.PropsWithChildren) => {
  const isCraneSupervisor = usePermission([
    "crane_supervisor",
    "crane_supervisor_viewer",
  ]);
  if (isCraneSupervisor) {
    return children;
  } else {
    return <NotFound />;
  }
};

export const RequireRead = ({ children }: React.PropsWithChildren) => {
  const canRead = usePermission(["read"]);
  if (canRead) {
    return children;
  } else {
    return <NotFound />;
  }
};

export const RequireAdmin = ({ children }: React.PropsWithChildren) => {
  const isAdmin = useIsAdmin();
  if (isAdmin) {
    return children;
  } else {
    return <NotFound />;
  }
};

export const RequireFeatureFlags = ({
  children,
  featureFlags,
}: React.PropsWithChildren<{ featureFlags: FeatureFlag[] }>) => {
  const tenantData = useTenantData();

  if (
    featureFlags.every((featureFlag) =>
      tenantData.feature_flags.includes(featureFlag)
    )
  ) {
    return children;
  } else {
    return <NotFound />;
  }
};
