import { Grid } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { useEffect, useState } from "react";
import { MaterialGroupingName } from "src/store/api/generatedApi";
import { SelectBox } from "../Selectbox";
import { TableHeader } from "../TableHeader";
import { GroupedInventorySeries, InventoryPlotType } from "../types";
import { InventoryPlot } from "./InventoryPlot";
import { InventoryTable } from "./InventoryTable";

type InventoryTabProps = {
  groupedInventorySeries: GroupedInventorySeries;
};

export const InventoryTab = ({ groupedInventorySeries }: InventoryTabProps) => {
  const { t } = useTenantTranslation();

  const [selectedGrouping, setSelectedGrouping] =
    useState<MaterialGroupingName | null>(null);
  const [selectedPlotType, setSelectedPlotType] =
    useState<InventoryPlotType>("mass");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    // when the groupedInventorySeries changes, set the default values
    // for the select boxes, and set the date to the lates in the
    // timeseries
    setSelectedGrouping(
      getDefaultMaterialGrouping(
        groupedInventorySeries.availableMaterialGroupings
      )
    );
    setSelectedDate(getDefaultSelectedDate(groupedInventorySeries));
  }, [groupedInventorySeries]);

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Grid item>
          <SelectBox
            availableOptions={groupedInventorySeries.availableMaterialGroupings}
            selectedOption={selectedGrouping}
            onOptionChange={setSelectedGrouping}
            id="inventory-grouping-select"
            labelId="inventory-grouping-label"
            label={t("materialGrouping")}
          />
        </Grid>
        <Grid item>
          <SelectBox<InventoryPlotType>
            availableOptions={new Set<InventoryPlotType>(["mass", "fraction"])}
            selectedOption={selectedPlotType}
            onOptionChange={setSelectedPlotType}
            id="plot-type-select"
            labelId="plot-type-label"
            label={t("plotType")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={2} mx={0}>
        <Grid item xs={8} ml={0}>
          <InventoryPlot
            inventorySeries={groupedInventorySeries}
            selectedGrouping={selectedGrouping}
            selectedPlotType={selectedPlotType}
            setSelectedDate={setSelectedDate}
          />
        </Grid>
        <Grid item xs={4}>
          <TableHeader
            titlePrefix={t("inventoryOn")}
            subtitleText={t("inventoryTableSubtitleText")}
            selectedDate={selectedDate}
            formatDateMonthly={false}
          />
          <InventoryTable
            inventorySeries={groupedInventorySeries}
            selectedDate={selectedDate}
            selectedMaterialGrouping={selectedGrouping}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const getDefaultMaterialGrouping = (
  availableMaterialGroupings: Set<MaterialGroupingName>
) => {
  return Array.from(availableMaterialGroupings)[0] ?? null;
};

const getDefaultSelectedDate = (
  inventorySeries: GroupedInventorySeries
): Date | null => {
  const defaultSelectedDateString = inventorySeries.values
    .map((item) => item[1].date[item[1].date.length - 1] ?? null)
    .reduce((max, current) => {
      if (max === null) {
        return current;
      }
      if (current === null) {
        return max;
      }
      return current > max ? current : max;
    }, null);
  return defaultSelectedDateString ? new Date(defaultSelectedDateString) : null;
};
