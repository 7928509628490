import { Chunk, ChunkData, quantisedWindow, useLoadedWindow } from "../helpers";
import { useTenant } from "hooks/settings";
import { backendApi } from "src/store/api/enhancedApi";
import { useCallback, useMemo } from "react";
import { OnRelayout } from "../viewportControls";
import { EventPlot } from "./eventPlot";
import { mapLoadedUnpack } from "models/loaded";
import { MATERIAL_COLOURS } from "../constants";

export type DatedDropEvent = {
  start: Date;
  end: Date;
  material_id: number | null;
  labelled_material: string;
  load_id: number | null;
  new_load_id: number | null;
  scrap_car_id: number;
  drop_weight: number;
  charge_schedule_id: string;
  charge_schedule_bucket_number: number;
  status: string;
};

const useScaleWeightDropEvents = (
  start: Date,
  end: Date
): ChunkData<DatedDropEvent[]>[] => {
  const [getDropEvents] =
    backendApi.endpoints.getScaleWeightDropEvents.useLazyQuery();
  const tenant = useTenant();

  const get = useCallback(
    async (segment: Chunk) =>
      getDropEvents({ tenantName: tenant, ...segment })
        .unwrap()
        .then((result) =>
          result.drop_events.map(
            (event) =>
              ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end),
              }) as DatedDropEvent
          )
        ),
    [getDropEvents]
  );

  return useLoadedWindow(
    quantisedWindow(
      { start: start.getTime(), end: end.getTime() },
      300 * 1000,
      2
    ),
    get
  );
};

export const ScaleWeightDropEventsPlot = ({
  start,
  end,
  onRelayout,
}: {
  start: Date;
  end: Date;
  onRelayout?: OnRelayout;
}) => {
  const dropEvents = useScaleWeightDropEvents(start, end);

  const loadedDropEvents = useMemo(
    () =>
      dropEvents.flatMap(
        (chunk) =>
          mapLoadedUnpack(chunk.value, (events) =>
            events.filter((event) => event.start >= start && event.start < end)
          ) ?? []
      ),
    [dropEvents, start, end]
  );

  return (
    <EventPlot
      events={loadedDropEvents
        .filter((event) => event.status !== "invalidated")
        .map((event) => ({
          start: event.start,
          end: event.end,
          text: [
            `Mass: ${event.drop_weight}`,
            `Bucket: ${event.scrap_car_id}`,
            `Material: ${event.labelled_material}`,
          ].join("<br>"),
          colour: MATERIAL_COLOURS["lift_" + event.labelled_material],
          track: `Bucket ${event.scrap_car_id}`,
        }))}
      start={start}
      end={end}
      onRelayout={onRelayout}
      chunks={dropEvents}
      layout={{
        yaxis: {
          showticklabels: true,
          fixedrange: true,
          categoryarray: ["Bucket 3", "Bucket 2", "Bucket 1"],
        },
      }}
    />
  );
};

const useVisionModelDropEvents = (
  start: Date,
  end: Date
): ChunkData<DatedDropEvent[]>[] => {
  const [getDropEvents] =
    backendApi.endpoints.getVisionModelDropEvents.useLazyQuery();
  const tenant = useTenant();

  const get = useCallback(
    async (segment: Chunk) =>
      getDropEvents({ tenantName: tenant, ...segment })
        .unwrap()
        .then((result) =>
          result.drop_events.map(
            (event) =>
              ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end),
              }) as DatedDropEvent
          )
        ),
    [getDropEvents]
  );

  return useLoadedWindow(
    quantisedWindow(
      { start: start.getTime(), end: end.getTime() },
      300 * 1000,
      2
    ),
    get
  );
};

export const VisionModelDropEventsPlot = ({
  start,
  end,
  onRelayout,
}: {
  start: Date;
  end: Date;
  onRelayout?: OnRelayout;
}) => {
  const dropEvents = useVisionModelDropEvents(start, end);

  const loadedDropEvents = useMemo(
    () =>
      dropEvents.flatMap(
        (chunk) =>
          mapLoadedUnpack(chunk.value, (events) =>
            events.filter((event) => event.start >= start && event.start < end)
          ) ?? []
      ),
    [dropEvents, start, end]
  );

  return (
    <EventPlot
      events={loadedDropEvents
        .filter((event) => event.status !== "invalidated")
        .map((event) => ({
          start: event.start,
          end: event.end,
          text: [
            `Mass: ${event.drop_weight}`,
            `Bucket: ${event.scrap_car_id}`,
            `Material: ${event.labelled_material}`,
          ].join("<br>"),
          colour: MATERIAL_COLOURS["lift_" + event.labelled_material],
          track: `Bucket ${event.scrap_car_id}`,
        }))}
      start={start}
      end={end}
      onRelayout={onRelayout}
      chunks={dropEvents}
      layout={{
        yaxis: {
          showticklabels: true,
          fixedrange: true,
          categoryarray: ["Bucket 3", "Bucket 2", "Bucket 1"],
        },
      }}
    />
  );
};
