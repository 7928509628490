import { Box, LinearProgress } from "@mui/material";

import { ProductionTable } from "./table/production/ProductionTable";
import {
  PlanTableDependencies,
  usePlanTableDependencies,
} from "./table/dependencies";
import { LoadedContent } from "components/common/loading/loadedContent";
import { usePeriodIndex } from "hooks/periodIndex";

type Props = {
  planId: number;
};

export const ProductionTab = ({ planId }: Props) => {
  const dependencies = usePlanTableDependencies(planId);
  const period = usePeriodIndex();
  return (
    <Box
      sx={{
        py: 2,
        minHeight: 0,
      }}
    >
      <LoadedContent<PlanTableDependencies>
        data={dependencies}
        disableFetching
        loading={<LinearProgress />}
        waiting={<LinearProgress />}
      >
        {({
          plan: {
            summary: {
              mix_basket_summary,
              mix_material_summary,
              period_material_summary,
              mix_summary,
              product_group_mix_material_summary,
              product_group_mix_summary,
            },
          },
          productGroups,
          chemistryGroups: { byIndex: chemistryGroups },
          materials: { byIndex: materials },
        }) => (
          <ProductionTable
            productGroups={productGroups?.byIndex ?? null}
            chemistryGroups={chemistryGroups}
            materials={materials}
            productGroupMixMaterialSummaries={
              product_group_mix_material_summary
            }
            productGroupMixSummaries={product_group_mix_summary}
            mixMaterialSummaries={mix_material_summary}
            mixSummaries={mix_summary}
            periodMaterialSummaries={period_material_summary}
            mixBasketSummaries={mix_basket_summary}
            period={period}
          />
        )}
      </LoadedContent>
    </Box>
  );
};
