import { sorted } from "helpers";
import { Loaded } from "models/loaded";
import { useEffect, useMemo, useState } from "react";

export type Chunk = { start: number; end: number };
export type ChunkData<T> = Chunk & { value: Loaded<T> };

export const useLoadedWindow = <T,>(
  window: Chunk,
  get: (chunk: Chunk) => Promise<T>
) => {
  const [chunks, setChunks] = useState<ChunkData<T>[]>([]);

  const getChunk = (chunk: Chunk) => {
    setChunks((current) =>
      sorted(
        [...current, { ...chunk, value: { status: "loading" } }],
        (chunk) => chunk.start
      )
    );
    void get(chunk).then((data) =>
      setChunks((current) =>
        current.map((item) =>
          item.start === chunk.start && item.end === chunk.end
            ? { ...chunk, value: { status: "success", data, fetching: false } }
            : item
        )
      )
    );
  };

  useEffect(() => {
    // If/when performance becomes an issue, unused chunks (ie. those that have
    // no overlap with the start and end) can be removed
    missingChunks(window, chunks).forEach(getChunk);
  }, [window.start, window.end]);

  return chunks;
};

const missingChunks = (window: Chunk, chunks: Chunk[]): Chunk[] => {
  if (window.start >= window.end) {
    return [];
  } else if (chunks.length === 0) {
    return [window];
  } else {
    const chunk = chunks[0]!;

    return [
      { ...window, end: Math.min(window.end, chunk.start) },
      ...missingChunks(
        { ...window, start: Math.max(window.start, chunk.end) },
        chunks.slice(1)
      ),
    ].filter((item) => item.start < item.end);
  }
};

export const useMergedChunks = <T,>(
  chunks: ChunkData<{ [key: string]: T[] }>[]
) =>
  useMemo(
    () =>
      mergeObjects(
        chunks.flatMap((chunk) =>
          chunk.value.status === "success" ? [chunk.value.data] : []
        ),
        (left, right) => [...(left ?? []), ...(right ?? [])]
      ),
    [chunks]
  );

const mergeObjects = <T,>(
  objects: { [key: string]: T }[],
  merge: (left: T | undefined, right: T | undefined) => T
): { [key: string]: T } => {
  return objects.reduce(
    (left, right) =>
      Object.fromEntries(
        [...Object.keys(left), ...Object.keys(right)].map((key) => [
          key,
          merge(left[key], right[key]),
        ])
      ),
    {}
  );
};

/**
 * Force the bounds of a window to snap to discrete points in time.
 *
 * This is helpful when you want to avoid sending a whole query to load data
 * resulting from a small change in the window.  This will make sure you always
 * load a little bit more than needed, so small changes in the window will
 * usually not trigger a whole new query.
 *
 * A buffer can be added to either side; in this case, that number of chunks
 * will be loaded on either end in addition to what is strictly necessary.
 */
export const quantisedWindow = (
  window: Chunk,
  chunkSize: number,
  chunksBuffer: number
): Chunk => {
  const startChunkIndex = Math.floor(window.start / chunkSize);
  const endChunkIndex = Math.ceil(window.end / chunkSize);
  return {
    start: (startChunkIndex - chunksBuffer) * chunkSize,
    end: (endChunkIndex + chunksBuffer) * chunkSize,
  };
};
