import {
  Box,
  ButtonBase,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChevronRight, History } from "@mui/icons-material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { useNavigateToPlan } from "hooks/navigation";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import {
  DeployedPlanRead,
  useGetDeployedPlansQuery,
} from "src/store/api/generatedApi";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { UnitSuffix } from "../plan/table/common/UnitSuffix";
import { PlanAncestors } from "components/common/planHistory/PlanAncestors";
import { useCanEditAndSendDeploy } from "hooks/useCanEditAndSendDeploy";
import { useTenant } from "hooks/settings";
import theme from "src/theme";

export const DeployedPlansTable = ({
  plans,
}: {
  plans: DeployedPlanRead[];
}) => {
  const { t } = useTenantTranslation();
  const formatLocalDate = useFormatLocalDateString("datetime");

  const columns: GridColDef<DeployedPlanRead>[] = [
    {
      field: "name",
      headerName: t("searchName"),
      flex: 2,
      renderCell: PlanNameButtonCell,
    },
    {
      field: "numHeats",
      headerName: t("heats"),
      flex: 1,
      renderCell: NumHeatsCell,
      align: "right",
      display: "flex",
    },
    {
      field: "tappedPrice",
      headerName: t("tappedPrice"),
      flex: 1,
      renderCell: TappedPriceCell,
      align: "right",
      display: "flex",
    },
    {
      field: "deployed_at",
      headerName: t("deployedAt"),
      flex: 1,
      display: "flex",
      valueFormatter: (value) => formatLocalDate(value as string),
    },
    {
      field: "start_at",
      headerName: t("planStart"),
      flex: 1,
      display: "flex",
      valueFormatter: (value) => formatLocalDate(value as string),
    },
    {
      field: "end_at",
      headerName: t("planEnd"),
      flex: 1,
      display: "flex",
      valueFormatter: (value) => formatLocalDate(value as string),
    },
    {
      field: "deployed_by_email",
      headerName: t("deployedBy"),
      flex: 1,
      display: "flex",
    },
  ];

  return (
    <DataGridPremium
      rows={plans}
      columns={columns}
      getRowId={(row: DeployedPlanRead) => row.plan_id}
    />
  );
};

type BackupChipProps = {
  planId: number;
};

const BackupChip = ({ planId }: BackupChipProps) => {
  const { t } = useTenantTranslation();
  const tenantName = useTenant();
  const deployedBackupPlans = useGetDeployedPlansQuery({
    tenantName,
    deploymentTypes: ["backup"],
  });
  if (deployedBackupPlans.data && deployedBackupPlans.data.latest === planId) {
    return (
      <Chip
        label={t("backup")}
        variant="outlined"
        size="small"
        color="info"
        sx={{ justifySelf: "start" }}
      />
    );
  } else {
    return null;
  }
};

const PlanNameButtonCell = ({
  row: { plan_id, session_name, search_id },
}: GridRenderCellParams<DeployedPlanRead>) => {
  const navigateToPlan = useNavigateToPlan();
  const canEditAndSendDeploy = useCanEditAndSendDeploy();
  const tooltipContent = (
    <Box
      sx={{
        maxHeight: "60vh",
        overflowY: "auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        minWidth: 300,
      }}
    >
      <PlanAncestors planId={plan_id} />
    </Box>
  );

  return (
    <ButtonBase
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
        gap: 1,
      }}
      onClick={() => navigateToPlan(plan_id, search_id)}
    >
      <Typography
        variant="button"
        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {session_name}
      </Typography>
      {canEditAndSendDeploy ? <BackupChip planId={plan_id} /> : null}
      <ChevronRight sx={{ marginLeft: "auto" }} />
      <Tooltip
        title={tooltipContent}
        placement="right"
        arrow
        enterDelay={100}
        leaveDelay={500}
        slotProps={{
          popper: {
            sx: {
              "& .MuiTooltip-tooltip": {
                bgcolor: "background.paper",
                color: "text.primary",
                p: 0,
                maxWidth: "none",
              },
            },
          },
        }}
      >
        <IconButton size="small">
          <History fontSize="small" />
        </IconButton>
      </Tooltip>
    </ButtonBase>
  );
};

const NumHeatsCell = ({
  row: { first_period_summary },
}: GridRenderCellParams<DeployedPlanRead>) => {
  const { format } = useNumberSerialiser();

  return (
    <Typography variant="body1Mono" textAlign="right">
      {format(first_period_summary.num_heats)}
    </Typography>
  );
};

const TappedPriceCell = ({
  row: { first_period_summary },
}: GridRenderCellParams<DeployedPlanRead>) => {
  const units = useUnitsFormatter(false);
  const { format } = useNumberSerialiser({ decimalPlaces: 2 });

  return (
    <Typography variant="body1Mono" sx={{ textAlign: "right" }}>
      {format(first_period_summary.display_cost_per_tonne)}
      <UnitSuffix>{units("specific_cost")}</UnitSuffix>
    </Typography>
  );
};
