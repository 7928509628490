import { sorted } from "helpers";
import { useTenant } from "hooks/settings";
import { useCallback } from "react";
import Plot from "react-plotly.js";
import { backendApi } from "src/store/api/enhancedApi";
import { MATERIAL_COLOURS } from "../constants";
import {
  Chunk,
  ChunkData,
  quantisedWindow,
  useLoadedWindow,
  useMergedChunks,
} from "../helpers";
import { defaultLayout, linePlot, loadingChunks, Point } from "../plotting";
import { OnRelayout } from "../viewportControls";

const useLabelProbabilities = (
  start: Date,
  end: Date
): ChunkData<{ [label: string]: Point[] }>[] => {
  const [getLabelProbabilities] =
    backendApi.endpoints.getLabelProbabilities.useLazyQuery();
  const tenant = useTenant();

  const get = useCallback(
    async (segment: Chunk) =>
      getLabelProbabilities({ tenantName: tenant, ...segment })
        .unwrap()
        .then((result) =>
          Object.fromEntries(
            result.data.map((item) => [
              item.label,
              item.probabilities.map((row) => ({
                ...row,
                timestamp: new Date(row.timestamp),
              })),
            ])
          )
        ),
    [getLabelProbabilities]
  );

  return useLoadedWindow(
    quantisedWindow(
      { start: start.getTime(), end: end.getTime() },
      300 * 1000,
      2
    ),
    get
  );
};

export const LabelProbabilitiesPlot = ({
  start,
  end,
  onRelayout,
  settings,
}: {
  start: Date;
  end: Date;
  onRelayout?: OnRelayout;
  settings: { dropProbability?: boolean; labelProbability?: boolean };
}) => {
  const labelProbabilities = useLabelProbabilities(start, end);
  const mergedLabelProbabilities = useMergedChunks(labelProbabilities);

  return (
    <>
      {settings.dropProbability ? (
        <Plot
          data={[
            {
              ...linePlot(mergedLabelProbabilities["drop"] ?? []),
              line: { color: "red" },
              name: "Drop",
              fill: "tozeroy",
            },
          ]}
          layout={{
            ...defaultLayout(),
            xaxis: { range: [start, end], type: "date" },
            shapes: loadingChunks(labelProbabilities),
            yaxis: {
              range: [0, 1],
              fixedrange: true,
              title: { text: "Drop probability" },
            },
          }}
          onRelayout={onRelayout}
          style={{ width: "100%" }}
        />
      ) : null}

      {settings.labelProbability ? (
        <Plot
          data={sorted(
            Object.keys(mergedLabelProbabilities).filter(
              (key) => key !== "drop" && key !== "move"
            ),
            (key) => key
          ).map((material) => ({
            ...linePlot(mergedLabelProbabilities[material]!),
            name: material,
            line: { color: MATERIAL_COLOURS[material] },
            fill: "tozeroy",
          }))}
          layout={{
            ...defaultLayout(),
            xaxis: { range: [start, end], type: "date" },
            shapes: loadingChunks(labelProbabilities),
            yaxis: {
              range: [0, 1],
              fixedrange: true,
              title: { text: "Material probability" },
            },
          }}
          onRelayout={onRelayout}
          style={{ width: "100%" }}
        />
      ) : null}
    </>
  );
};
