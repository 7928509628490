import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { IconButton, Typography, useTheme } from "@mui/material";
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import React from "react";
import { z } from "zod";

import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import {
  DeploymentType,
  Latest,
  Next,
  PlanMixBasket,
  SteelGrade,
  SteelGradePlanItem,
} from "src/store/api/generatedApi";
import { sumRecords } from "src/utils/sumRecords";
import {
  makePostDataGridProcessingCallback,
  TableBasketNumberSchema,
  TableProfileBasketNumberSchema,
} from "./utils";

import { DRICell } from "./DriCell";
import { MixSummaryCell } from "./MixSummaryCell";
import { SteelGradesCell } from "./SteelGradesCell";

import { SupervisorActionCell } from "./SupervisorActionCell";
import { useColumnGroupingModel } from "./useColumnGroupingModel";

type TableBasketNumber = z.infer<typeof TableBasketNumberSchema>;
type TableProfileBasketNumber = z.infer<typeof TableProfileBasketNumberSchema>;

export type TotalRow = {
  id: number;
  type: "total";
  steelGrades: SteelGrade[];
  mixId: number;
  copperGroup: number;
  period: number;
  numberOfHeats: number;
  totals: PlanMixBasket;
};

export type BasketRow = {
  id: number;
  type: "basket";
  copperGroup: number;
  steelGrades: SteelGrade[];
  materialMasses: Record<number, number>;
  mixId: number;
  basketNumber: TableBasketNumber;
  period: number;
  numberOfHeats: number;
  totals: PlanMixBasket;
};

export type EmptyBasketRow = {
  id: number;
  type: "empty_basket";
  mixId: number;
  copperGroup: number;
  steelGrades: SteelGrade[];
  basketNumber: TableBasketNumber;
  period: number;
  numberOfHeats: number;
  totals: PlanMixBasket;
};

export type ProfileRow = {
  id: number;
  planId: number;
  type: "profile";
  copperGroup: number;
  steelGrades: SteelGrade[];
  steelGradeProduction: SteelGradePlanItem[];
  materialMasses: Record<number, number>;
  originalBasketsMaterialMasses: Record<number, Record<number, number>>;
  mixId: number;
  period: number;
  numberOfHeats: number;
  totals: PlanMixBasket;
  originalPrice: number | null;
};

export type EmptyProfileRow = {
  id: number;
  planId: number;
  type: "empty_profile";
  mixId: number;
  copperGroup: number;
  steelGrades: SteelGrade[];
  steelGradeProduction: SteelGradePlanItem[];
  basketNumber: TableProfileBasketNumber;
  period: number;
  numberOfHeats: number;
  totals: PlanMixBasket;
  originalPrice: number | null;
  originalBasketsMaterialMasses: Record<number, Record<number, number>>;
};

export type Row =
  | ProfileRow
  | BasketRow
  | TotalRow
  | EmptyBasketRow
  | EmptyProfileRow;

type Material = {
  id: number;
  name: string;
  inventory: number | null;
};

type VisualPrice = "diff" | "full";

type Props = {
  disableEdit: boolean;
  visualPrice: VisualPrice;
  rows: Row[];
  onChange: (rows: Row[]) => void;
  materials: Material[];
  steelGradeColumnWidth: number;
  selectedSteelGrades: number[];
  canClickSteelGrade: boolean;
  apiRef: ReturnType<typeof useGridApiRef>;
  showNumberOfHeats: boolean;
  deploymentDetails:
    | [Exclude<DeploymentType, "backup" | "edited_backup">, Latest | Next]
    | null;
  startAt: Date;
  endAt: Date | null;
  view: "backup" | "supervisor" | "deploy";
  showInventoryAndConsumption: boolean;
};

type BaseEmptyBasketWeightCellProps = {
  id: number;
  copperGroup: number;
  steelGrades: SteelGrade[];
  mixId: number;
  period: number;
  numberOfHeats: number;
  totals: PlanMixBasket;
};

type EmptyBasketWeightCellProps = BaseEmptyBasketWeightCellProps & {
  type: "basket";
  basketNumber: TableBasketNumber;
};
type EmptyProfileWeightCellProps = BaseEmptyBasketWeightCellProps & {
  type: "profile";
  planId: number;
  basketNumber: TableProfileBasketNumber;
  originalPrice: number | null;
  steelGradeProduction: SteelGradePlanItem[];
  originalBasketsMaterialMasses: Record<number, Record<number, number>>;
};

type BaseBasketWeightCellProps = {
  id: number;
  copperGroup: number;
  steelGrades: SteelGrade[];
  mixId: number;
  value: number | null;
  period: number;
  numberOfHeats: number;
  totals: PlanMixBasket;
};

type BasketWeightCellProps = BaseBasketWeightCellProps & {
  type: "basket";
  basketNumber: TableBasketNumber;
};
type ProfileWeightCellProps = BaseBasketWeightCellProps & {
  type: "profile";
  planId: number;
  basketNumber: TableProfileBasketNumber;
  originalPrice: number | null;
  steelGradeProduction: SteelGradePlanItem[];
  originalBasketsMaterialMasses: Record<number, Record<number, number>>;
};

export const updateRowEventParam = "__updateRow__";
export const resetEventParam = "__reset__";
export const disableEditEventParam = "__disableEdit__";
export const enableEditEventParam = "__enableEdit__";

const EmptyBasketWeightCell = ({
  id,
  copperGroup,
  steelGrades,
  mixId,
  period,
  numberOfHeats,
  totals,
  ...props
}: EmptyBasketWeightCellProps | EmptyProfileWeightCellProps) => {
  const apiRef = useGridApiContext();
  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const filledRow = ((): BasketRow | ProfileRow => {
      const { type } = props;
      switch (type) {
        case "basket": {
          const { basketNumber } = props;
          const basketRow: BasketRow = {
            id,
            type: "basket",
            copperGroup,
            steelGrades,
            mixId,
            basketNumber,
            materialMasses: {},
            period,
            numberOfHeats,
            totals,
          };
          return basketRow;
        }
        case "profile": {
          const {
            originalPrice,
            steelGradeProduction,
            originalBasketsMaterialMasses,
            planId,
          } = props;
          const basketRow: ProfileRow = {
            id,
            planId,
            type: "profile",
            copperGroup,
            steelGrades,
            steelGradeProduction,
            mixId,
            materialMasses: {},
            originalBasketsMaterialMasses,
            period,
            numberOfHeats,
            totals,
            originalPrice,
          };
          return basketRow;
        }
      }
    })();
    apiRef.current.updateRows([filledRow]);
    apiRef.current.publishEvent("stateChange", updateRowEventParam);
  };
  return (
    <IconButton sx={{ padding: 0 }} onClick={handleOnClick}>
      <AddCircleOutline fontSize="small" />
    </IconButton>
  );
};

const BasketWeightCell = ({
  id,
  copperGroup,
  steelGrades,
  mixId,
  value,
  period,
  numberOfHeats,
  totals,
  ...props
}: BasketWeightCellProps | ProfileWeightCellProps) => {
  const apiRef = useGridApiContext();
  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const emptyRow = (() => {
      const { type } = props;
      switch (type) {
        case "basket": {
          const { basketNumber } = props;
          const emptyBasketRow: EmptyBasketRow = {
            id,
            type: "empty_basket",
            copperGroup,
            steelGrades,
            mixId,
            basketNumber,
            period,
            numberOfHeats,
            totals,
          };
          return emptyBasketRow;
        }
        case "profile": {
          const {
            basketNumber,
            originalPrice,
            steelGradeProduction,
            originalBasketsMaterialMasses,
            planId,
          } = props;
          const emptyProfileRow: EmptyProfileRow = {
            type: "empty_profile",
            id,
            planId,
            copperGroup,
            steelGrades,
            mixId,
            originalPrice,
            basketNumber,
            steelGradeProduction,
            period,
            numberOfHeats,
            totals,
            originalBasketsMaterialMasses,
          };
          return emptyProfileRow;
        }
      }
    })();
    apiRef.current.updateRows([emptyRow]);
    apiRef.current.publishEvent("stateChange", updateRowEventParam);
  };
  return (
    <>
      <Typography variant="body1Mono">{value?.toFixed(0)}</Typography>
      <IconButton sx={{ padding: 0 }} onClick={handleOnClick}>
        <RemoveCircleOutline fontSize="small" />
      </IconButton>
    </>
  );
};

const useMakeColumns = (
  materials: Material[],
  steelGradeColumnWidth: number,
  disableEdit: boolean,
  visualPrice: VisualPrice,
  canClickSteelGrade: boolean,
  deploymentDetails:
    | [Exclude<DeploymentType, "backup" | "edited_backup">, Latest | Next]
    | null,
  startAt: Date,
  endAt: Date | null,
  view: "backup" | "deploy" | "supervisor"
): GridColDef<Row>[] => {
  const { t } = useTenantTranslation();
  const bracketUnits = useUnitsFormatter(true);
  const units = useUnitsFormatter(false);
  const columns = React.useMemo((): GridColDef<Row>[] => {
    const columns: GridColDef<Row>[] = [
      {
        field: "mix_name",
        width: 120,
        headerName:
          visualPrice === "diff"
            ? "#"
            : `${units("specific_cost")} | Cu ${units("mass_fraction")}`,
        valueGetter: (_, row): number => {
          return row.mixId;
        },
        renderCell: (row: GridRenderCellParams<Row, number>) => {
          if (row.row.type === "profile" || row.row.type === "empty_profile") {
            if (visualPrice === "full" && row.row.originalPrice !== null) {
              return (
                <MixSummaryCell
                  key={[row.row.mixId, row.row.planId].join(".")}
                  originalPrice={row.row.originalPrice}
                  planId={row.row.planId}
                  mixId={row.row.mixId}
                  steelGradeProduction={row.row.steelGradeProduction}
                  originalBasketsMaterialMasses={
                    row.row.originalBasketsMaterialMasses
                  }
                  period={row.row.period}
                />
              );
            } else {
              if (deploymentDetails !== null && endAt !== null) {
                const [deploymentType, planTimeframe] = deploymentDetails;
                return (
                  <SupervisorActionCell
                    planId={row.row.planId}
                    mixId={row.row.mixId}
                    steelGradeIds={row.row.steelGrades.map(
                      (steelGrade) => steelGrade.id
                    )}
                    deploymentType={deploymentType}
                    startAt={startAt}
                    endAt={endAt}
                    planTimeframe={planTimeframe}
                    view={view}
                    steelGradeProduction={row.row.steelGradeProduction}
                    period={row.row.period}
                    originalPrice={row.row.originalPrice ?? 0}
                    originalBasketsMaterialMasses={
                      row.row.originalBasketsMaterialMasses
                    }
                  />
                );
              } else {
                return null;
              }
            }
          } else {
            return null;
          }
        },
        cellClassName: "grey-50 bottom-row",
        headerClassName: "grey-50 border-bottom-dark",
      },
      {
        field: "num_heats",
        headerName: "#",
        width: 50,
        type: "number" as const,
        valueGetter: (_, row: Row) => row.numberOfHeats,
        rowSpanValueGetter: (_, row: Row) => row.mixId,
        cellClassName: "grey-50 bottom-row center",
        headerClassName: "grey-50 border-bottom-dark",
      },
      {
        field: "steel_grades",
        headerName: t("steelGrades"),
        width: steelGradeColumnWidth,
        filterOperators: [
          {
            label: "Contains",
            value: "contains",
            getApplyFilterFn: (item: { value?: number[] }) => {
              return (steelGradeIds: number[]) => {
                return (
                  item.value === undefined ||
                  (item.value !== undefined && item.value.length === 0) ||
                  steelGradeIds.some(
                    (steelGradeId) =>
                      item.value !== undefined &&
                      item.value.includes(steelGradeId)
                  )
                );
              };
            },
          },
        ],
        valueGetter: (_, row): number[] => {
          switch (row.type) {
            case "basket":
            case "total":
            case "empty_basket":
            case "empty_profile":
            case "profile": {
              return row.steelGrades.map((steelGrade) => steelGrade.id);
            }
          }
        },
        rowSpanValueGetter: (_, row) => row.mixId,
        renderCell: ({ row }) => {
          switch (row.type) {
            case "empty_profile":
            case "profile": {
              return (
                <SteelGradesCell
                  planId={row.planId}
                  mixId={row.mixId}
                  steelGrades={row.steelGrades}
                  canClick={canClickSteelGrade}
                  view={view}
                />
              );
            }
            case "basket":
            case "empty_basket":
            case "total":
              return null;
          }
        },
        cellClassName: "grey-50 bottom-row",
        headerClassName: "grey-50 border-bottom-dark",
      },
      {
        field: "copper_group",
        headerName: "Cu",
        width: 50,
        headerClassName: "warning-light border-bottom-dark",
        valueGetter: (_, row): number => {
          switch (row.type) {
            case "basket":
            case "total":
            case "empty_profile":
            case "empty_basket":
            case "profile": {
              return row.mixId;
            }
          }
        },
        valueFormatter: (_, row) => {
          return (row.copperGroup * 100).toFixed(0);
        },
        cellClassName: "warning-light bottom-row center",
      },
      {
        field: "dri_weight",
        headerName: `${t("dri")} ${bracketUnits("mass")}`,
        width: 50,
        headerClassName: "grey-200 border-bottom-dark",
        valueGetter: (_, row): number => {
          switch (row.type) {
            case "basket":
            case "profile":
            case "empty_basket":
            case "empty_profile":
            case "total": {
              return row.mixId;
            }
          }
        },
        renderCell: ({ row }: GridRenderCellParams<Row>) => {
          switch (row.type) {
            case "empty_profile":
            case "profile":
              return <DRICell mixId={row.mixId} planId={row.planId} />;
            case "basket":
            case "empty_basket":
            case "total":
              return null;
          }
        },
        cellClassName: "grey-200 bottom-row center",
      },
      {
        field: "basket_weight",
        resizable: false,
        headerName: `${t("basket")} ${bracketUnits("mass")}`,
        width: 100,
        headerClassName: "grey-200 edge-column border-bottom-dark",
        renderCell: (
          rowParams: GridRenderCellParams<Row, number | null>
        ): React.JSX.Element | string | null => {
          const { row, value } = rowParams;
          switch (row.type) {
            case "empty_basket": {
              if (!disableEdit) {
                const {
                  copperGroup,
                  steelGrades,
                  mixId,
                  basketNumber,
                  id,
                  period,
                  numberOfHeats,
                  totals,
                } = row;
                return (
                  <EmptyBasketWeightCell
                    type="basket"
                    id={id}
                    copperGroup={copperGroup}
                    steelGrades={steelGrades}
                    mixId={mixId}
                    basketNumber={basketNumber}
                    period={period}
                    numberOfHeats={numberOfHeats}
                    totals={totals}
                  />
                );
              } else {
                return null;
              }
            }
            case "empty_profile": {
              if (!disableEdit) {
                const {
                  copperGroup,
                  steelGrades,
                  mixId,
                  basketNumber,
                  id,
                  period,
                  numberOfHeats,
                  totals,
                  originalPrice,
                  steelGradeProduction,
                  originalBasketsMaterialMasses,
                  planId,
                } = row;
                return (
                  <EmptyBasketWeightCell
                    type="profile"
                    planId={planId}
                    originalPrice={originalPrice}
                    id={id}
                    copperGroup={copperGroup}
                    steelGrades={steelGrades}
                    mixId={mixId}
                    basketNumber={basketNumber}
                    period={period}
                    numberOfHeats={numberOfHeats}
                    totals={totals}
                    steelGradeProduction={steelGradeProduction}
                    originalBasketsMaterialMasses={
                      originalBasketsMaterialMasses
                    }
                  />
                );
              } else {
                return null;
              }
            }
            case "total": {
              if (value) {
                return value.toFixed(0);
              }
              return null;
            }
            case "profile": {
              if (!disableEdit) {
                const {
                  id,
                  mixId,
                  copperGroup,
                  steelGrades,
                  type,
                  period,
                  numberOfHeats,
                  totals,
                  originalPrice,
                  steelGradeProduction,
                  originalBasketsMaterialMasses,
                  planId,
                } = row;
                return (
                  <BasketWeightCell
                    type={type}
                    id={id}
                    planId={planId}
                    mixId={mixId}
                    originalPrice={originalPrice}
                    copperGroup={copperGroup}
                    steelGrades={steelGrades}
                    steelGradeProduction={steelGradeProduction}
                    basketNumber={1}
                    value={value ?? null}
                    period={period}
                    numberOfHeats={numberOfHeats}
                    totals={totals}
                    originalBasketsMaterialMasses={
                      originalBasketsMaterialMasses
                    }
                  />
                );
              } else {
                return value?.toFixed(0) ?? null;
              }
            }
            case "basket": {
              if (!disableEdit) {
                const {
                  id,
                  mixId,
                  copperGroup,
                  steelGrades,
                  type,
                  basketNumber,
                  period,
                  numberOfHeats,
                  totals,
                } = row;
                return (
                  <BasketWeightCell
                    type={type}
                    id={id}
                    mixId={mixId}
                    copperGroup={copperGroup}
                    steelGrades={steelGrades}
                    basketNumber={TableBasketNumberSchema.parse(basketNumber)}
                    value={value ?? null}
                    period={period}
                    numberOfHeats={numberOfHeats}
                    totals={totals}
                  />
                );
              } else {
                return value?.toFixed(0) ?? null;
              }
            }
          }
        },
        valueGetter: (_, row, __, dataGridApi) => {
          switch (row.type) {
            case "basket":
            case "profile":
              return Object.values(row.materialMasses).reduce(
                (total, mass) => total + mass,
                0
              );
            case "total":
              return Object.values(
                dataGridApi.current
                  .getAllRowIds()
                  .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                  .filter(
                    (row: Row | null): row is ProfileRow | BasketRow =>
                      row !== null &&
                      (row.type === "profile" || row.type === "basket")
                  )
                  .filter(({ mixId }) => mixId === row.mixId)
                  .reduce(
                    (totals, row) => {
                      const { materialMasses } = row;
                      return sumRecords(totals, materialMasses);
                    },
                    {} as Record<number, number>
                  )
              ).reduce((sum, mass) => sum + mass, 0);
          }
        },
        valueFormatter: (value: number) => {
          if (value !== undefined) {
            return value.toFixed(0);
          }
        },
        rowSpanValueGetter: () => null,
        cellClassName: (row) => {
          const baseClass = "grey-200 edge-column";
          const {
            row: { type },
          } = row;
          switch (type) {
            case "empty_basket": {
              return baseClass + " flex-start";
            }
            case "empty_profile": {
              return baseClass + " flex-start";
            }
            case "total": {
              return baseClass + " bottom-row align-center";
            }
            case "basket":
            case "profile":
              return baseClass + " space-between";
          }
        },
      },
      ...materials.map(
        (material): GridColDef<Row> => ({
          field: material.id.toString(),
          headerName: material.name,
          headerClassName: "border-bottom-dark",
          flex: 1,
          minWidth: 50,
          type: "number",
          valueGetter: (_, row, __, dataGridApi): number | null => {
            switch (row.type) {
              case "empty_basket":
              case "empty_profile":
                return null;
              case "basket":
              case "profile":
                return row.materialMasses[material.id] &&
                  row.materialMasses[material.id] !== 0
                  ? row.materialMasses[material.id] ?? null
                  : null;
              case "total": {
                const value = dataGridApi.current
                  .getAllRowIds()
                  .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                  .filter((row: Row | null): row is Row => row !== null)
                  .filter(
                    (
                      dataGridRow: Row
                    ): dataGridRow is ProfileRow | BasketRow => {
                      const { type } = dataGridRow;
                      switch (type) {
                        case "basket":
                        case "profile": {
                          return row.mixId === dataGridRow.mixId;
                        }
                        case "total": {
                          return false;
                        }
                      }
                      // This needs to be here to make eslint happy. Not sure why.
                      return false;
                    }
                  )
                  .reduce(
                    (totals, row) => {
                      const { materialMasses } = row;
                      return sumRecords(totals, materialMasses);
                    },
                    {} as Record<number, number>
                  )[material.id];

                return value ? value : null;
              }
            }
          },
          rowSpanValueGetter: () => null,
          editable: !disableEdit,
          valueSetter: (value, row) => {
            switch (row.type) {
              case "empty_basket":
              case "empty_profile":
              case "total": {
                return row;
              }
              case "profile":
              case "basket":
                if (value !== undefined && value !== null && value >= 0) {
                  return {
                    ...row,
                    materialMasses: {
                      ...row.materialMasses,
                      [material.id]: Number(value),
                    },
                  };
                } else {
                  const { id } = material;
                  const {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    materialMasses: { [id]: _, ...materialMasses },
                  } = row;
                  return {
                    ...row,
                    materialMasses: {
                      ...materialMasses,
                    },
                  };
                }
            }
          },
          cellClassName: ({ row }) => {
            const { type } = row;
            switch (type) {
              case "total": {
                return "grey-50 bottom-row center";
              }
              case "empty_profile":
              case "empty_basket":
                return "";
              case "basket":
              case "profile": {
                return "center";
              }
            }
          },
        })
      ),
    ];

    return columns.map((column) => ({
      ...column,
      sortable: false,
      headerAlign: "center",
      resizable: false,
    }));
  }, [
    t,
    view,
    deploymentDetails,
    useUnitsFormatter,
    materials,
    steelGradeColumnWidth,
    disableEdit,
    canClickSteelGrade,
    visualPrice,
    startAt,
    endAt,
  ]);
  return columns;
};

export const Table = ({
  disableEdit,
  visualPrice,
  materials,
  steelGradeColumnWidth,
  rows,
  canClickSteelGrade,
  selectedSteelGrades,
  onChange,
  apiRef,
  showNumberOfHeats,
  deploymentDetails,
  startAt,
  endAt,
  view,
  showInventoryAndConsumption,
}: Props) => {
  const [internalDisableEdit, setInternalDisableEdit] = React.useState(false);
  const theme = useTheme();

  const columns = useMakeColumns(
    materials,
    steelGradeColumnWidth,
    internalDisableEdit || disableEdit,
    visualPrice,
    canClickSteelGrade,
    deploymentDetails,
    startAt,
    endAt,
    view
  );

  const handleOnChange = React.useCallback(() => {
    if (apiRef.current) {
      const rows = [...apiRef.current.getRowModels().values()].filter(
        (_: GridValidRowModel): _ is Row => true
      );
      onChange(rows);
    }
  }, [onChange]);

  const postDataGridProcessingHandleOnChange = React.useMemo(
    () => makePostDataGridProcessingCallback(handleOnChange),
    [handleOnChange]
  );

  const handleIsCellEditable = React.useCallback(
    (params: GridCellParams<Row>) =>
      !["total", "empty_basket", "empty_profile"].includes(params.row.type) ||
      !internalDisableEdit ||
      !disableEdit,
    [disableEdit, internalDisableEdit]
  );

  const handleStateChange = React.useCallback(
    (params: unknown) => {
      if (z.literal(updateRowEventParam).safeParse(params).success) {
        void postDataGridProcessingHandleOnChange(params);
      }
      if (z.literal(disableEditEventParam).safeParse(params).success) {
        setInternalDisableEdit(true);
      }
      if (z.literal(enableEditEventParam).safeParse(params).success) {
        setInternalDisableEdit(false);
      }
    },
    [postDataGridProcessingHandleOnChange, setInternalDisableEdit]
  );

  React.useEffect(() => {
    if (apiRef.current) {
      apiRef.current.subscribeEvent(
        "cellEditStop",
        postDataGridProcessingHandleOnChange
      );
      apiRef.current.subscribeEvent("stateChange", handleStateChange);
    }
  }, [postDataGridProcessingHandleOnChange]);

  const cellHeight = 35;

  const columnGroupingModel = useColumnGroupingModel(materials);

  return (
    <DataGridPremium<Row>
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      rowHeight={cellHeight}
      columnHeaderHeight={40}
      columnVisibilityModel={{
        num_heats: showNumberOfHeats,
      }}
      columnGroupHeaderHeight={cellHeight}
      columnGroupingModel={
        showInventoryAndConsumption ? columnGroupingModel : undefined
      }
      filterModel={{
        items: [
          {
            field: "steel_grades",
            operator: "contains",
            value: selectedSteelGrades,
          },
        ],
      }}
      getRowClassName={(params) =>
        params.row.type === "total" ? "total-row" : ""
      }
      unstable_rowSpanning
      isCellEditable={handleIsCellEditable}
      sx={{
        width: "calc(100%)",
        [".align-center"]: {
          alignContent: "center",
        },
        [".space-between"]: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        [".flex-start"]: {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        [".center"]: {
          display: "grid",
          justifyContent: "center",
          alignContent: "center",
        },
        [".warning-light"]: {
          backgroundColor: theme.palette.warning.light,
        },
        [".grey-200"]: {
          backgroundColor: theme.palette.grey[200],
        },
        [".grey-50"]: {
          backgroundColor: theme.palette.grey[50],
        },
        [".border-bottom-dark"]: {
          borderBottom: `1px solid ${theme.palette.secondary.dark} !important`,
        },
        [".bottom-border-light"]: {
          borderBottom: `1px solid ${theme.palette.secondary.light}`,
        },
        [".edge-column"]: {
          borderRight: `1px solid ${theme.palette.secondary.dark}`,
        },
        [".group-column-row-header"]: {
          borderRight: `1px solid ${theme.palette.secondary.dark}`,
          [".MuiDataGrid-columnHeaderTitleContainer"]: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
          },
        },
        [".group-column-cell"]: {
          backgroundColor: theme.palette.common.white,
          fontFamily: theme.typography.body1Mono,
          width: "100%",
          [".MuiDataGrid-columnHeaderTitleContainer"]: {
            width: "100%",
            [".MuiDataGrid-columnHeaderTitleContainerContent"]: {
              width: "100%",
              height: "100%",
            },
          },
        },
        ".empty-column-group-header": {
          visibility: "hidden",
        },
        ".bottom-empty-column-group-header": {
          background: "none",
        },
        ".bold": {
          fontWeight: "bold !important",
        },
        [".MuiDataGrid-columnHeader"]: {
          textAlign: "center",
          padding: 0,
        },
        [".MuiDataGrid-columnHeaderTitle"]: {
          fontSize: theme.typography.body1.fontSize,
        },
        [".MuiDataGrid-cell"]: {
          fontFamily: theme.typography.body1Mono,
        },
        [".MuiDataGrid-row.total-row"]: {
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
          boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.1)",
        },
        ["input::-webkit-outer-spin-button, input::-webkit-inner-spin-button"]:
          {
            "-webkit-appearance": "none",
          },
        [".MuiDataGrid-columnHeader--filtered .MuiDataGrid-filterIcon"]: {
          display: "none",
        },
      }}
    />
  );
};
