import {
  PurchasingLineItem,
  PurchasingPlanMaterial,
  PurchasingPlanQuotesAdd,
  PurchasingQuoteBase,
  PurchasingQuoteRead,
} from "src/store/api/generatedApi";
import {
  QuotePriceRow,
  QuoteQuantityRow,
  Row,
} from "./plan/PurchasingPlanTable";

export const QUOTE_CREATION_FIXED_CACHE_KEY = "create-quote";

type MaterialId = number;

export type QuantitiesByMaterial = Record<MaterialId, number>;

export const createPlanUpdate = (rows: Row[]): PurchasingPlanQuotesAdd => {
  const quotePriceRows = rows.filter(
    (row): row is QuotePriceRow => row.type === "quote_price"
  );
  const quoteQuantityRows = rows.filter(
    (row): row is QuoteQuantityRow => row.type === "quote_quantity"
  );

  const updatedQuotes = quotePriceRows
    .map((priceRow): PurchasingQuoteBase | null => {
      const quoteQuantityRow = quoteQuantityRows.find(
        (row) => row.quoteId === priceRow.quoteId
      );
      if (quoteQuantityRow === undefined) {
        return null;
      }
      const lineItemsWithQuantityEntry = Object.entries(
        quoteQuantityRow.quantities
      )
        .map(([materialName, quantity]): PurchasingLineItem | null => {
          if (quantity !== null) {
            return {
              material_name: materialName,
              quantity: quantity,
              unit_price: priceRow?.prices[materialName] ?? 0,
            };
          }
          return null;
        })
        .filter(
          (lineItem): lineItem is PurchasingLineItem => lineItem !== null
        );
      const lineItemsWithoutQuantityEntry = Object.entries(priceRow.prices)
        .map(([materialName, price]): PurchasingLineItem | null => {
          if (
            quoteQuantityRow.quantities[materialName] === undefined &&
            price !== null
          ) {
            return {
              material_name: materialName,
              quantity: 0,
              unit_price: price,
            };
          }
          return null;
        })
        .filter(
          (lineItem): lineItem is PurchasingLineItem => lineItem !== null
        );
      const lineItems = [
        ...lineItemsWithQuantityEntry,
        ...lineItemsWithoutQuantityEntry,
      ];
      return {
        supplier_name: priceRow.supplierName,
        line_items: lineItems,
      };
    })
    .filter((quote): quote is PurchasingQuoteBase => quote !== null);

  return {
    quotes: updatedQuotes,
  };
};

export const makeBottomRows = (materials: PurchasingPlanMaterial[]): Row[] => {
  return [
    {
      id: "material_quantity_outstanding",
      type: "material_quantity_outstanding",
    },
    {
      id: "material_demand",
      type: "material_demand",
      demands: Object.fromEntries(
        materials.map((material) => [material.material_name, material.demand])
      ),
    },
    {
      id: "material_inventory",
      type: "material_inventory",
      inventories: Object.fromEntries(
        materials.map((material) => [
          material.material_name,
          material.current_inventory,
        ])
      ),
    },
    {
      id: "material_average_cost",
      type: "material_average_cost",
    },
  ];
};

export const makeRows = (quotes: PurchasingQuoteRead[]): Row[] => {
  return quotes.flatMap((quote) => {
    const quantityRow: QuoteQuantityRow = {
      id: `${quote.id}_quantity`,
      type: "quote_quantity",
      quoteId: quote.id,
      supplierName: quote.supplier_name,
      quantities: Object.fromEntries(
        quote.line_items.map((lineItem) => [
          lineItem.material_name,
          lineItem.quantity,
        ])
      ),
    };
    const priceRow: QuotePriceRow = {
      id: `${quote.id}_price`,
      type: "quote_price",
      quoteId: quote.id,
      supplierName: quote.supplier_name,
      prices: Object.fromEntries(
        quote.line_items.map((lineItem) => [
          lineItem.material_name,
          lineItem.unit_price,
        ])
      ),
    };
    return [quantityRow, priceRow];
  });
};

export const calculateUnfilledDemandForMaterial = (
  materialName: string,
  rows: Row[],
  materials: PurchasingPlanMaterial[]
): number => {
  const material = materials.find(
    (material) => material.material_name === materialName
  );
  const totalDemand =
    (material?.demand ?? 0) - (material?.current_inventory ?? 0);

  const totalQuoteVolume = rows
    .filter(
      (dataGridRow): dataGridRow is QuoteQuantityRow =>
        dataGridRow.type === "quote_quantity"
    )
    .reduce((acc, row) => {
      return acc + (row.quantities[materialName] ?? 0);
    }, 0);

  return Math.max(0, totalDemand - totalQuoteVolume);
};

export const calculateMaterialAverageCost = (
  materialName: string,
  rows: Row[]
): number => {
  // Get all quantity rows containing the specific material
  const quantityRows = rows.filter(
    (row): row is QuoteQuantityRow =>
      row.type === "quote_quantity" && materialName in row.quantities
  );

  // Get all price rows
  const priceRows = rows.filter(
    (
      row
    ): row is Row & {
      type: "quote_price";
      prices: Record<string, number>;
    } => row.type === "quote_price"
  );

  const totalMaterialQuantity = quantityRows.reduce((acc, quantityRow) => {
    const quantity = quantityRow.quantities[materialName] ?? 0;

    if (quantity > 0) {
      const matchingPriceRow = priceRows.find(
        (priceRow) => priceRow.quoteId === quantityRow.quoteId
      );

      if (matchingPriceRow && materialName in matchingPriceRow.prices) {
        return acc + quantity;
      }
    }
    return acc;
  }, 0);

  const totalMaterialCost = quantityRows.reduce((acc, quantityRow) => {
    const quantity = quantityRow.quantities[materialName] ?? 0;

    if (quantity > 0) {
      const matchingPriceRow = priceRows.find(
        (priceRow) => priceRow.quoteId === quantityRow.quoteId
      );

      if (matchingPriceRow && materialName in matchingPriceRow.prices) {
        const price = matchingPriceRow.prices[materialName] ?? 0;
        return acc + price * quantity;
      }
    }
    return acc;
  }, 0);

  return totalMaterialQuantity > 0
    ? Number((totalMaterialCost / totalMaterialQuantity).toFixed(2))
    : 0;
};

export const debounce = <T extends unknown[], R>(
  callback: (...args: T) => R,
  debounceMs: number
) => {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: T) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => callback(...args), debounceMs);
  };
};
