import type { MixDifference, PlanDifferences } from "store/api/generatedApi";
import { useGetPlanAncestorsQuery } from "store/api/generatedApi";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useTenant } from "hooks/settings";
import {
  alpha,
  Box,
  Chip,
  Grid2,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  FormattedPrice,
  PriceDiff,
} from "components/common/planHistory/PriceDiff";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  AccessTimeRounded,
  ChangeCircleRounded,
  CheckCircleRounded,
  Person2Rounded,
} from "@mui/icons-material";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

type Props = {
  planId: number;
};

export const PlanAncestors = ({ planId }: Props) => {
  const { t } = useTenantTranslation();
  const tenantName = useTenant();

  const { data, isLoading } = useGetPlanAncestorsQuery({
    tenantName,
    planId,
  });

  if (isLoading) {
    return <PlanAncestorsSkeleton />;
  }

  const planAncestors = data?.ancestors.slice().reverse() ?? [];

  if (!planAncestors.length) {
    return (
      <Typography sx={{ color: "text.secondary", px: 2, py: 1 }}>
        {t("noDifferencesFound")}
      </Typography>
    );
  }

  return (
    <Stack spacing={0}>
      {planAncestors.map((item, index) => (
        <PlanAncestorItem
          key={`plan-${item.target_plan_id}`}
          item={item}
          index={index}
          totalAncestors={planAncestors.length}
          t={t}
        />
      ))}
    </Stack>
  );
};

const PlanAncestorsSkeleton = () => {
  return (
    <Stack spacing={2} p={1} sx={{ width: "90%" }}>
      <Skeleton variant="text" width="60%" height={30} />
      <Skeleton variant="text" width="30%" height={20} sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={60} sx={{ mt: 1 }} />
    </Stack>
  );
};

type PlanAncestorItemProps = {
  item: PlanDifferences;
  index: number;
  totalAncestors: number;
  t: (key: string) => string;
};

const PlanAncestorItem = ({
  item,
  index,
  totalAncestors,
  t,
}: PlanAncestorItemProps) => {
  return (
    <Box
      sx={{
        borderRadius: "4px",
        "&:hover": {
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.03),
        },
      }}
    >
      <AncestorHeader
        item={item}
        index={index}
        totalAncestors={totalAncestors}
        t={t}
      />
      <MixDifferencesTable item={item} t={t} />
    </Box>
  );
};

type AncestorHeaderProps = {
  item: PlanDifferences;
  index: number;
  totalAncestors: number;
  t: (key: string) => string;
};

const AncestorHeader = ({
  item,
  index,
  totalAncestors,
  t,
}: AncestorHeaderProps) => {
  const deployment = item.target_deployments?.[0];
  const isEditedDeployment = ["edited_standard", "edited_backup"].includes(
    deployment?.deployment_type ?? ""
  );
  const displayName =
    deployment && !isEditedDeployment
      ? deployment.deployed_by_name
      : item.target_created_by_name;
  const timestamp =
    deployment && !isEditedDeployment
      ? new Date(deployment.deployed_at)
      : new Date(item.target_created_at);
  const formattedDate = dayjs(timestamp).format("ll"); // localised abbreviated date
  const formattedTime = dayjs(timestamp).format("LT"); // localised time
  const relativeTime = dayjs(timestamp).fromNow();
  return (
    <Box sx={{ pt: 1.5, px: 2, borderTop: "1px solid #e0e0e0" }}>
      <Grid2 container spacing={1}>
        {/* Left Column */}
        <Grid2 size={{ xs: 6 }}>
          <Tooltip
            title={`${
              deployment
                ? isEditedDeployment
                  ? t("editedBy")
                  : t("deployedBy")
                : t("createdBy")
            } ${displayName}`}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              sx={{ color: "text.secondary" }}
            >
              <Person2Rounded sx={{ fontSize: 22 }} />
              <Typography variant="h6">{displayName}</Typography>
            </Stack>
          </Tooltip>
          <Tooltip
            title={`${
              deployment
                ? isEditedDeployment
                  ? t("editedAt")
                  : t("deployedAt")
                : t("createdAt")
            }: ${formattedDate} ${formattedTime}`}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              sx={{
                color: "text.secondary",
                mt: 0.5,
              }}
            >
              <AccessTimeRounded sx={{ fontSize: 14 }} />
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                {relativeTime}
              </Typography>
            </Stack>
          </Tooltip>
        </Grid2>

        {/* Right Column */}
        <Grid2 size={{ xs: 6 }}>
          <Stack spacing={0.5} alignItems="flex-end">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Tooltip
                title={`${t("plan")}: ${item.source_plan_id} → ${
                  item.target_plan_id
                }`}
              >
                <Chip
                  icon={<ChangeCircleRounded />}
                  label={`${t("change")} ${totalAncestors - index}`}
                  size="small"
                  sx={{
                    fontWeight: 600,
                    bgcolor: deployment
                      ? (theme) => alpha(theme.palette.success.main, 0.1)
                      : (theme) => alpha(theme.palette.primary.main, 0.1),
                    color: deployment ? "success.main" : "primary.main",
                    "& .MuiChip-icon": {
                      color: "inherit",
                    },
                  }}
                />
              </Tooltip>
              {deployment ? (
                <Tooltip
                  title={
                    <>
                      {`${t("createdAt")}: ${dayjs(timestamp).format("ll")}`}
                      <br />
                      {`${t("deployedAt")}: ${dayjs(timestamp).format("ll")}`}
                    </>
                  }
                >
                  <CheckCircleRounded
                    sx={{ color: "success.main", fontSize: 20 }}
                  />
                </Tooltip>
              ) : null}
            </Stack>

            <PriceDiff
              priceDiff={
                item.summary.target_display_cost_per_tonne -
                item.summary.source_display_cost_per_tonne
              }
              tooltipTitle={
                <Stack direction="row" spacing={1}>
                  <FormattedPrice
                    value={item.summary.source_display_cost_per_tonne}
                    variant="unformatted"
                  />
                  <span>→</span>
                  <FormattedPrice
                    value={item.summary.target_display_cost_per_tonne}
                    variant="unformatted"
                  />
                </Stack>
              }
            />
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
};

type MixDifferencesTableProps = {
  item: PlanDifferences;
  t: (key: string) => string;
};

const MixDifferencesTable = ({ item, t }: MixDifferencesTableProps) => {
  const groupedDiffs = Object.entries(
    (item.differences || [])
      .filter((diff) => Math.abs(diff.mass_difference) >= 0.1)
      .reduce(
        (acc, diff) => {
          const key = diff.target_mix_id;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key]!.push(diff);
          return acc;
        },
        {} as Record<string, MixDifference[]>
      )
  );

  return (
    <Table size="small" sx={{ "& td, & th": { py: 0.5 } }}>
      <TableBody>
        {groupedDiffs.map(([tgt_mix_id, diffs]) => (
          <MixDifferenceGroup
            key={`mix-${tgt_mix_id}`}
            targetMixId={tgt_mix_id}
            diffs={diffs}
            t={t}
          />
        ))}
      </TableBody>
    </Table>
  );
};

type MixDifferenceGroupProps = {
  targetMixId: string;
  diffs: MixDifference[];
  t: (key: string) => string;
};

const MixDifferenceGroup = ({
  targetMixId,
  diffs,
  t,
}: MixDifferenceGroupProps) => {
  return (
    <TableRow>
      <TableCell sx={{ border: "none" }}>
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <Box
            sx={{
              position: "relative",
              width: 20,
              alignSelf: "stretch",
            }}
          >
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: "primary.main",
                position: "relative",
                top: 8,
                left: 12,
                zIndex: 1,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                left: 15,
                top: 8,
                bottom: 0,
                width: 2,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.3),
              }}
            />
          </Box>
          <Stack spacing={1} sx={{ flex: 1, pt: 0.1 }}>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "0.875rem", fontWeight: 600 }}
            >
              {t("mix")} {targetMixId}
            </Typography>
            {diffs.map((diff) => (
              <MaterialDifferenceRow
                key={`${diff.material_name}-${diff.basket_number}`}
                diff={diff}
                t={t}
              />
            ))}
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

type MaterialDifferenceRowProps = {
  diff: MixDifference;
  t: (key: string) => string;
};

const MaterialDifferenceRow = ({ diff, t }: MaterialDifferenceRowProps) => {
  return (
    <Stack
      key={`${diff.material_name}-${diff.basket_number}`}
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        pl: 1,
        py: 0.1,
        marginTop: "0px !important",
        "&:hover": {
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.03),
        },
      }}
    >
      <Box sx={{ width: 40, textAlign: "right" }}>
        <FormattedMass value={diff.mass_difference} showSign />
      </Box>
      <Typography
        variant="body2"
        sx={{
          fontSize: "0.75rem",
          flex: 1,
          minWidth: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {diff.material_name}
      </Typography>
      {diff.addition_location === "basket" && (
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.75rem",
            color: "text.secondary",
            width: 80,
            textAlign: "right",
          }}
        >
          {t("basket")} {diff.basket_number}
        </Typography>
      )}
    </Stack>
  );
};

const FormattedMass = ({
  value,
  showSign = false,
}: {
  value: number;
  showSign?: boolean;
}) => {
  const { format } = useNumberSerialiser({ decimalPlaces: 1 });
  const unitsFormatter = useUnitsFormatter(false);
  const theme = useTheme();

  return (
    <Typography
      variant="body2"
      sx={{
        fontWeight: 500,
        color:
          value > 0 ? theme.palette.primary.main : theme.palette.error.main,
      }}
    >
      {showSign ? (value > 0 ? "+" : "-") : null}
      {format(Math.abs(value))}
      {unitsFormatter("mass")}
    </Typography>
  );
};
