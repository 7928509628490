import { ExactStringUnionArray } from "helpers";
import { Permission } from "store/api/generatedApi.tsx";

export const PERMISSION_VALUES = [
  "review",
  "publish",
  "edit",
  "read",
  "review_mix",
  "video_labelling",
  "crane_driver",
  "crane_supervisor",
  "crane_supervisor_viewer",
  "plan_recipient",
  "scrap_tracking_dashboard_viewer",
] as const;

type CheckPermissions = ExactStringUnionArray<
  typeof PERMISSION_VALUES,
  Permission
>;

// Force a type instantiation so that TypeScript will complain if there's a mismatch.
// If everything matches, `check` can be assigned `true` with no error.
// If not, you'll see the error message from `ExactStringUnionArray`.
const check: CheckPermissions = true;

if (!check) {
  // Unreachable code
  throw new Error("Permissions do not match.");
}
