import { useParams } from "react-router";

export const usePurchasingPlanId = (): number | null => {
  const urlPurchasingPlanId = parseInt(useParams().purchasing_plan_id ?? "");
  if (!isNaN(urlPurchasingPlanId)) {
    return urlPurchasingPlanId;
  } else {
    return null;
  }
};
