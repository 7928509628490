import { SelectChangeEvent } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export type SelectBoxProps<T> = {
  availableOptions: Set<T>;
  selectedOption: T | null;
  onOptionChange: (option: T) => void;
  id: string;
  labelId: string;
  label: string;
};

export const SelectBox = <T,>({
  availableOptions,
  selectedOption,
  onOptionChange,
  id,
  labelId,
  label,
}: SelectBoxProps<T>) => {
  const { t } = useTenantTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    onOptionChange(event.target.value as T);
  };

  return (
    <FormControl sx={{ mt: 3, minWidth: 240 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        value={String(selectedOption ?? "")}
        label={label}
        onChange={handleChange}
      >
        {Array.from(availableOptions).map((option) => (
          <MenuItem key={String(option)} value={String(option)}>
            {t(String(option))}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
