import { Serialiser } from "hooks/serialisers/types";
import { Setter } from "hooks/state/syncing";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router";

export const useLocalStorageState = <T,>(key: string, value: T) => {
  const storedValue = localStorage.getItem(key);
  const [state, setState] = useState<T>(
    storedValue === null ? value : (JSON.parse(storedValue) as T)
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state]);

  return [state, setState] as const;
};

export const useQueryParamState = <T extends object | null, O>(
  key: string,
  serialiser: Serialiser<T, O>,
  defaultValue: T
): [T, Setter<T>] => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const pathName = useLocation().pathname;

  const updateQueryParam = (newValue: T) => {
    const currentParams = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );
    void navigate(
      {
        pathname: pathName,
        search: new URLSearchParams({
          ...currentParams,
          [key]: serialiser.format(newValue),
        }).toString(),
      },
      { replace: true }
    );
  };

  const result = serialiser.parse(searchParams.get(key) ?? "");
  const value = result.valid ? result.value : defaultValue;

  return [
    value,
    (update) =>
      updateQueryParam(typeof update === "object" ? update : update(value)),
  ];
};
