import equal from "fast-deep-equal";
import { z } from "zod";
import {
  NamedPlanMix,
  PlanMixBasket,
  SteelGrade,
} from "src/store/api/generatedApi";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import { typeSafeObjectKeys } from "src/utils/typeSafeObjectKeys";
import {
  BasketRow,
  EmptyBasketRow,
  EmptyProfileRow,
  ProfileRow,
  Row,
  TotalRow,
} from "./Table";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";

export const TableBasketNumberSchema = z.union([z.literal(2), z.literal(3)]);
export const TableProfileBasketNumberSchema = z.literal(1);

type TableBasketNumber = z.infer<typeof TableBasketNumberSchema>;

const cleanMaterialMasses = (materialMasses: Record<string, number>) => {
  return Object.fromEntries(
    Object.entries(materialMasses).filter(([, value]) => value !== 0)
  );
};

const cleanSingleBasket = (planMixBasket: PlanMixBasket) => {
  return {
    material_masses: cleanMaterialMasses(planMixBasket.material_masses),
    total_mass: planMixBasket.total_mass,
  };
};

export const cleanBaskets = (baskets: Record<string, PlanMixBasket>) => {
  const basketsWithMass = Object.fromEntries(
    Object.entries(baskets).filter(
      ([, basket]) =>
        Object.entries(cleanMaterialMasses(basket.material_masses)).length > 0
    )
  );
  return Object.fromEntries(
    Object.entries(basketsWithMass).map(([key, basket]) => [
      key,
      cleanSingleBasket(basket),
    ])
  );
};

const orderSteelGradeIds = (ids: number[]) => ids.slice(0).sort();

export const isMixEqual = (mixA: NamedPlanMix, mixB: NamedPlanMix) => {
  return equal(
    {
      ...mixA,
      baskets: cleanBaskets(mixA.baskets),
      totals: cleanSingleBasket(mixA.totals),
      steel_grade_ids: orderSteelGradeIds(mixA.steel_grade_ids),
    },
    {
      ...mixB,
      baskets: cleanBaskets(mixB.baskets),
      totals: cleanSingleBasket(mixB.totals),
      steel_grade_ids: orderSteelGradeIds(mixB.steel_grade_ids),
    }
  );
};

export const areMixesEqual = (
  mixesA: NamedPlanMix[],
  mixesB: NamedPlanMix[]
) => {
  const byMixIdA = typeSafeObjectFromEntries(
    mixesA.map((mix) => [mix.mix_id, mix])
  );
  const byMixIdB = typeSafeObjectFromEntries(
    mixesB.map((mix) => [mix.mix_id, mix])
  );
  if (!equal(Object.keys(byMixIdA).sort(), Object.keys(byMixIdB).sort())) {
    // Short circuit if the mix IDs are not the same
    return false;
  }
  return typeSafeObjectKeys(byMixIdA).every((mixId) => {
    const mixA = byMixIdA[mixId];
    const mixB = byMixIdB[mixId];
    if (mixA === undefined || mixB === undefined) {
      return false;
    } else {
      return isMixEqual(mixA, mixB);
    }
  });
};

export const matchMixesBySteelGrades = (
  mixA: NamedPlanMix,
  mixB: NamedPlanMix
) => {
  return (
    mixA.steel_grade_ids.every((id) => mixB.steel_grade_ids.includes(id)) &&
    mixB.steel_grade_ids.every((id) => mixA.steel_grade_ids.includes(id))
  );
};

const makeBasketRow =
  (basketNumber: TableBasketNumber) =>
  (
    mix: NamedPlanMix,
    steelGrades: SteelGrade[]
  ): Omit<EmptyBasketRow, "id"> | Omit<BasketRow, "id"> => {
    const { baskets, steel_grade_ids, copper_percent_max_tolerance, mix_id } =
      mix;
    if (baskets[basketNumber]) {
      const { material_masses } = baskets[basketNumber]!;
      return {
        type: "basket",
        steelGrades: steelGrades.filter((steelGrade) =>
          steel_grade_ids.includes(steelGrade.id)
        ),
        materialMasses: material_masses,
        copperGroup: copper_percent_max_tolerance,
        mixId: mix_id,
        basketNumber,
        numberOfHeats: mix.number_of_heats,
        period: mix.period,
        totals: mix.totals,
      };
    } else {
      return {
        type: "empty_basket",
        mixId: mix_id,
        copperGroup: copper_percent_max_tolerance,
        steelGrades: steelGrades.filter((steelGrade) =>
          steel_grade_ids.includes(steelGrade.id)
        ),
        basketNumber,
        numberOfHeats: mix.number_of_heats,
        period: mix.period,
        totals: mix.totals,
      };
    }
  };

const makeBasketRow2 = makeBasketRow(2);
const makeBasketRow3 = makeBasketRow(3);

const makeProfileRow = (
  planId: number,
  mix: NamedPlanMix,
  steelGrades: SteelGrade[]
): Omit<ProfileRow, "id"> | Omit<EmptyProfileRow, "id"> => {
  if (mix.baskets[1]) {
    const materialMasses = mix.baskets[1] ? mix.baskets[1].material_masses : {};
    return {
      type: "profile",
      planId,
      originalPrice: mix.summary?.cost.display_cost_per_tonne ?? null,
      copperGroup: mix.copper_percent_max_tolerance,
      steelGrades: steelGrades.filter((steelGrade) =>
        mix.steel_grade_ids.includes(steelGrade.id)
      ),
      materialMasses,
      mixId: mix.mix_id,
      numberOfHeats: mix.number_of_heats,
      period: mix.period,
      totals: mix.totals,
      steelGradeProduction: mix.steel_grade_production,
      originalBasketsMaterialMasses: typeSafeObjectFromEntries(
        typeSafeObjectEntries(mix.baskets).map(([id, planMixBasket]) => [
          id,
          planMixBasket.material_masses,
        ])
      ),
    };
  } else {
    return {
      type: "empty_profile",
      planId,
      copperGroup: mix.copper_percent_max_tolerance,
      steelGrades: steelGrades.filter((steelGrade) =>
        mix.steel_grade_ids.includes(steelGrade.id)
      ),
      mixId: mix.mix_id,
      basketNumber: 1,
      originalPrice: mix.summary?.cost.display_cost_per_tonne ?? null,
      numberOfHeats: mix.number_of_heats,
      period: mix.period,
      totals: mix.totals,
      steelGradeProduction: mix.steel_grade_production,
      originalBasketsMaterialMasses: typeSafeObjectFromEntries(
        typeSafeObjectEntries(mix.baskets).map(([id, planMixBasket]) => [
          id,
          planMixBasket.material_masses,
        ])
      ),
    };
  }
};

const makeTotalRow = (
  mix: NamedPlanMix,
  steelGrades: SteelGrade[]
): Omit<TotalRow, "id"> => {
  return {
    type: "total",
    steelGrades: steelGrades.filter((steelGrade) =>
      mix.steel_grade_ids.includes(steelGrade.id)
    ),
    copperGroup: mix.copper_percent_max_tolerance,
    mixId: mix.mix_id,
    numberOfHeats: mix.number_of_heats,
    period: mix.period,
    totals: mix.totals,
  };
};

export const makeRows = (
  planId: number,
  mixes: NamedPlanMix[],
  steelGrades: SteelGrade[]
): Row[] => {
  return Object.values(mixes)
    .flatMap((mix) => {
      const row = [
        makeProfileRow(planId, mix, steelGrades),
        makeBasketRow2(mix, steelGrades),
        makeBasketRow3(mix, steelGrades),
        makeTotalRow(mix, steelGrades),
      ];
      return row;
    })
    .map((row, index) => ({
      ...row,
      id: index,
    }));
};

export const mergeRowsAndMix = (
  rows: Row[],
  mix: NamedPlanMix
): NamedPlanMix => {
  const basketRows = rows.filter(
    (row: Row): row is BasketRow =>
      row.mixId === mix.mix_id && row.type === "basket"
  );

  const profileRow = (() => {
    const profileRow = rows.find(
      (row: Row): row is ProfileRow =>
        row.mixId === mix.mix_id && row.type === "profile"
    );
    const emptyProfileRow = rows.find(
      (row: Row): row is EmptyProfileRow =>
        row.mixId === mix.mix_id && row.type === "empty_profile"
    );
    if (profileRow) {
      return profileRow;
    } else if (emptyProfileRow) {
      return emptyProfileRow;
    } else {
      throw new Error("Profile row missing on edit in Plan Table");
    }
  })();

  return {
    ...mix,
    baskets: {
      1: {
        material_masses:
          "materialMasses" in profileRow ? profileRow.materialMasses : {},
        total_mass: Object.values(
          "materialMasses" in profileRow ? profileRow.materialMasses : {}
        ).reduce((acc, mass) => acc + mass, 0),
      },

      ...basketRows
        .filter((basketRow) => basketRow.mixId === mix.mix_id)
        .reduce((baskets, basketRow) => {
          return {
            ...baskets,
            [basketRow.basketNumber]: {
              material_masses: basketRow.materialMasses,
              total_mass: Object.values(basketRow.materialMasses).reduce(
                (acc, mass) => acc + mass,
                0
              ),
            },
          };
        }, {}),
    },
  };
};

export const makePostDataGridProcessingCallback = <T, R>(
  callback: (arg: T) => R
): ((arg: T) => Promise<R>) => {
  return async (arg: T) => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    return callback(arg);
  };
};
