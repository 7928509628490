import { CheckCircle } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";

export const Finished = () => {
  const { t } = useTenantTranslation();
  return (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <CheckCircle sx={{ color: "success.main", height: 28, width: 28 }} />
      <Typography variant="h5" sx={{ mt: 1 }}>
        {t("smsSent")}
      </Typography>
    </Stack>
  );
};
