import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";

import { RootState } from "store/store";

type FinishedForm = {
  type: "finished_form";
  planId: number;
};

type SMSForm = {
  type: "sms_form";
  planId: number;
  periodSMS: Record<number, string>;
  backupPlanSMS: Record<number, string>;
};

type State = "static" | "submitting";

type DeployForm = {
  type: "deploy_form";
  planId: number;
  preview: {
    planId: number;
    period: number;
    title: string;
  } | null;
  selectedPeriods: number[];
  selectedBackups: number[];
  state: State;
};

const deployPlanFormAdapter = createEntityAdapter({
  selectId: (form: DeployForm | SMSForm | FinishedForm) => form.planId,
});

const deployPlanFormSelectors = deployPlanFormAdapter.getSelectors();

export const deployPlanFormSlice = createSlice({
  name: "deployPlanForm",
  initialState: deployPlanFormAdapter.getInitialState(undefined, []),
  reducers: {
    cancelFlow(state, { payload: planId }: PayloadAction<number>) {
      deployPlanFormAdapter.removeOne(state, planId);
    },
    setDeployForm(state, { payload: planId }: PayloadAction<number>) {
      deployPlanFormAdapter.addOne(state, {
        planId,
        preview: null,
        selectedBackups: [],
        selectedPeriods: [],
        type: "deploy_form",
        state: "static",
      });
    },
    setSMSForm(
      state,
      {
        payload: [planId, periodSMSMessages, backupSMSMessages],
      }: PayloadAction<
        [
          number,
          {
            period: number;
            message: string;
          }[],
          {
            planId: number;
            message: string;
          }[],
        ]
      >
    ) {
      deployPlanFormAdapter.setOne(state, {
        planId,
        type: "sms_form",
        periodSMS: typeSafeObjectFromEntries(
          periodSMSMessages.map(({ period, message }) => [period, message])
        ),
        backupPlanSMS: typeSafeObjectFromEntries(
          backupSMSMessages.map(({ planId, message }) => [planId, message])
        ),
      });
    },
    setFinishedForm(state, { payload }: PayloadAction<number>) {
      deployPlanFormAdapter.setOne(state, {
        type: "finished_form",
        planId: payload,
      });
    },
    setSMSPeriodMessage(
      state,
      {
        payload: [planId, message, period],
      }: PayloadAction<[number, string, number]>
    ) {
      const form = deployPlanFormSelectors.selectById(state, planId);
      if (form && form.type === "sms_form") {
        deployPlanFormAdapter.setOne(state, {
          ...form,
          periodSMS: {
            ...form.periodSMS,
            [period]: message,
          },
        });
      }
    },
    setSMSBackupMessage(
      state,
      {
        payload: [formPlanId, message, backupPlanId],
      }: PayloadAction<[number, string, number]>
    ) {
      const form = deployPlanFormSelectors.selectById(state, formPlanId);
      if (form && form.type === "sms_form") {
        deployPlanFormAdapter.setOne(state, {
          ...form,
          backupPlanSMS: {
            ...form.backupPlanSMS,
            [backupPlanId]: message,
          },
        });
      }
    },
    clear(state, { payload: planId }: PayloadAction<number>) {
      deployPlanFormAdapter.removeOne(state, planId);
    },
    setPreview(
      state,
      {
        payload: [planId, preview],
      }: PayloadAction<
        [number, { planId: number; period: number; title: string }]
      >
    ) {
      const deployPlanForm = deployPlanFormSelectors.selectById(state, planId);
      if (deployPlanForm && deployPlanForm.type === "deploy_form") {
        deployPlanFormAdapter.updateOne(state, {
          id: planId,
          changes: { preview },
        });
      }
    },
    addAll(
      state,
      {
        payload: [planId, backupPlanIds, periods],
      }: PayloadAction<[number, number[], number[]]>
    ) {
      const deployPlanForm = deployPlanFormSelectors.selectById(state, planId);
      if (deployPlanForm?.type === "deploy_form") {
        deployPlanFormAdapter.updateOne(state, {
          id: planId,
          changes: {
            selectedPeriods: [
              ...deployPlanForm.selectedPeriods.filter(
                (period) => !periods.includes(period)
              ),
              ...periods,
            ],
            selectedBackups: [
              ...deployPlanForm.selectedBackups.filter(
                (planId) => !backupPlanIds.includes(planId)
              ),
              ...backupPlanIds,
            ],
          },
        });
      }
    },
    addPeriod(
      state,
      {
        payload: { planId, period },
      }: PayloadAction<{ planId: number; period: number }>
    ) {
      const deployPlanForm = deployPlanFormSelectors.selectById(state, planId);
      if (deployPlanForm && deployPlanForm.type === "deploy_form") {
        deployPlanFormAdapter.updateOne(state, {
          id: planId,
          changes: {
            ...deployPlanForm,
            selectedPeriods: [
              ...deployPlanForm.selectedPeriods.filter(
                (selectedPeriod) => selectedPeriod !== period
              ),
              period,
            ],
          },
        });
      }
    },
    removePeriod(
      state,
      {
        payload: { planId, period },
      }: PayloadAction<{ planId: number; period: number }>
    ) {
      const deployPlanForm = deployPlanFormSelectors.selectById(state, planId);
      if (deployPlanForm && deployPlanForm.type === "deploy_form") {
        deployPlanFormAdapter.updateOne(state, {
          id: planId,
          changes: {
            ...deployPlanForm,
            selectedPeriods: deployPlanForm.selectedPeriods.filter(
              (deployedPeriod) => deployedPeriod !== period
            ),
          },
        });
      }
    },
    addBackup(
      state,
      {
        payload: [planId, backup],
      }: PayloadAction<[number, { planId: number; period: number }]>
    ) {
      const deployPlanForm = deployPlanFormSelectors.selectById(state, planId);
      if (deployPlanForm && deployPlanForm.type === "deploy_form") {
        deployPlanFormAdapter.updateOne(state, {
          id: planId,
          changes: {
            ...deployPlanForm,
            selectedBackups: [
              ...deployPlanForm.selectedBackups.filter(
                (planId) => backup.planId !== planId
              ),
              backup.planId,
            ],
          },
        });
      }
    },
    removeBackup(
      state,
      {
        payload: [planId, backup],
      }: PayloadAction<[number, { planId: number; period: number }]>
    ) {
      const deployPlanForm = deployPlanFormSelectors.selectById(state, planId);
      if (deployPlanForm && deployPlanForm.type === "deploy_form") {
        deployPlanFormAdapter.updateOne(state, {
          id: planId,
          changes: {
            ...deployPlanForm,
            selectedBackups: deployPlanForm.selectedBackups.filter(
              (planId) => planId !== backup.planId
            ),
          },
        });
      }
    },
    submittingDeployForm(state, { payload }: PayloadAction<number>) {
      const deployPlanForm = deployPlanFormSelectors.selectById(state, payload);
      if (deployPlanForm) {
        switch (deployPlanForm.type) {
          case "deploy_form": {
            deployPlanFormAdapter.updateOne(state, {
              id: payload,
              changes: { state: "submitting" },
            });
          }
        }
      }
    },
    backToDeployForm(state, { payload }: PayloadAction<number>) {
      const deployPlanForm = deployPlanFormSelectors.selectById(state, payload);
      if (deployPlanForm) {
        switch (deployPlanForm.type) {
          case "sms_form": {
            deployPlanFormAdapter.setOne(state, {
              state: "static",
              type: "deploy_form",
              planId: payload,
              preview: null,
              selectedBackups: Object.keys(deployPlanForm.backupPlanSMS).map(
                Number
              ),
              selectedPeriods: Object.keys(deployPlanForm.periodSMS).map(
                Number
              ),
            });
          }
        }
      }
    },
  },
});

const createAppSelector = createSelector.withTypes<RootState>();

export const selectDeployPlanForm = createAppSelector(
  [(state) => state.deployPlanForm, (_, planId: number) => planId],
  (deployPlanForm, planId) =>
    deployPlanFormSelectors.selectById(deployPlanForm, planId)
);

export const selectDeployPlanFormType = createAppSelector(
  [selectDeployPlanForm],
  (form) => form?.type
);

export const selectDeployForm = createAppSelector(
  [selectDeployPlanForm],
  (form) => {
    if (form && form.type === "deploy_form") {
      return form;
    }
  }
);

export const selectSMSPlanForm = createAppSelector(
  [selectDeployPlanForm],
  (form) => {
    if (form && form.type === "sms_form") {
      return form;
    }
  }
);

export const selectSMSPlanFormPeriodSMSMessage = createAppSelector(
  [
    selectSMSPlanForm,
    (_, __: number, smsPreviewPeriod: number) => smsPreviewPeriod,
  ],
  (smsDeployForm, period) => {
    if (smsDeployForm) {
      return smsDeployForm.periodSMS[period];
    }
  }
);

export const selectSMSPlanFormBackupSMSMessage = createAppSelector(
  [selectSMSPlanForm, (_, __: number, backupPlanId: number) => backupPlanId],
  (smsDeployForm, backupPlanId) => {
    if (smsDeployForm) {
      return smsDeployForm.backupPlanSMS[backupPlanId];
    }
  }
);

export const selectDeployPlanPeriodSelected = createAppSelector(
  [selectDeployForm, (_, __: number, period: number) => period],
  (deployPlanForm, period) => {
    if (deployPlanForm) {
      return deployPlanForm.selectedPeriods.includes(period);
    } else {
      return false;
    }
  }
);

export const selectDeployBackupPlanSelected = createAppSelector(
  [selectDeployForm, (_, __: number, backupPlanId: number) => backupPlanId],
  (deployPlanForm, backupPlanId) => {
    return Boolean(deployPlanForm?.selectedBackups.includes(backupPlanId));
  }
);

export const selectIsDeployPlanPreviewed = createAppSelector(
  [
    selectDeployForm,
    (_, __: number, preview: { planId: number; period: number }) => preview,
  ],
  (deployPlanForm, preview) => {
    return (
      deployPlanForm?.preview?.planId === preview.planId &&
      deployPlanForm?.preview?.period === preview.period
    );
  }
);

export const selectIsBackupPlanPreviewed = createAppSelector(
  [
    selectDeployForm,
    (_, __: number, planIds: number[]) => planIds,
    (_, __: number, ___: number[], period: number) => period,
  ],
  (deployPlanForm, planIds, period) => {
    return (
      deployPlanForm?.preview?.planId &&
      planIds.includes(deployPlanForm.preview.planId) &&
      deployPlanForm?.preview?.period === period
    );
  }
);

export const selectDeployPlanPreview = createAppSelector(
  [selectDeployPlanForm],
  (deployPlanForm) =>
    deployPlanForm?.type === "deploy_form" ? deployPlanForm?.preview : undefined
);

export const selectDeployPlanFormChecked = createAppSelector(
  [selectDeployForm],
  (deployPlanForm): { planId: number; period: number }[] => {
    if (deployPlanForm) {
      return [
        ...deployPlanForm.selectedPeriods.map((period) => ({
          planId: deployPlanForm.planId,
          period,
        })),
        ...deployPlanForm.selectedBackups.map((planId) => ({
          planId,
          period: 1,
        })),
      ];
    } else {
      return [];
    }
  }
);

export const selectDeployPlanFormCheckedCount = createAppSelector(
  [selectDeployPlanFormChecked],
  (checked) => {
    return checked.length;
  }
);

export const selectSMSPeriodMessages = createAppSelector(
  [selectSMSPlanForm],
  (SMSForm) => {
    return SMSForm?.periodSMS;
  }
);

export const selectSMSPeriodMessagedIds = createAppSelector(
  [selectSMSPeriodMessages],
  (periodMessages) => {
    return periodMessages ? Object.keys(periodMessages) : [];
  }
);

export const selectSMSBackupPlanMessages = createAppSelector(
  [selectSMSPlanForm],
  (SMSForm) => {
    return SMSForm?.backupPlanSMS;
  }
);

export const selectSMSBackupPlanMessagedIds = createAppSelector(
  [selectSMSBackupPlanMessages],
  (backupMessages) => {
    return backupMessages ? Object.keys(backupMessages) : [];
  }
);

export const selectDeployFormSelectedPeriods = createAppSelector(
  [selectDeployForm],
  (deployForm) => deployForm?.selectedPeriods ?? []
);

export const selectDeployFormSelectedBackupPlanIds = createAppSelector(
  [selectDeployForm],
  (deployForm) => deployForm?.selectedBackups ?? []
);

export const selectDeployPlanFormIsSubmitting = createAppSelector(
  [selectDeployForm],
  (deployForm) => deployForm?.state === "submitting"
);
