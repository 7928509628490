import { LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { LoadedContent } from "components/common/loading/loadedContent";
import { Loaded } from "models/loaded";
import { ChargeSummary } from "src/store/api/generatedApi";
import {
  BaseTableProps,
  commonDataGridSx,
  LoadMap,
  useTableLayout,
} from "./material_table";

type Props = {
  loads: ChargeSummary[];
} & BaseTableProps;

export const HeatSummaryTable = ({ loads, sx }: Props) => {
  // Combine all loads into a single plan and actual map
  const plan: LoadMap = {};
  const actual_load: LoadMap = {};
  loads.forEach((load) => {
    Object.entries(load.planned).forEach(([material, value]) => {
      plan[material] = (plan[material] ?? 0) + value;
    });
    Object.entries(load.loaded).forEach(([material, value]) => {
      actual_load[material] = (actual_load[material] ?? 0) + value;
    });
  });

  const {
    columns,
    rows,
    pinnedRows,
    pinnedColumns,
    rowHeight,
    columnHeaderHeight,
  } = useTableLayout({
    plannedLoad: plan,
    actualLoad: actual_load,
    columnWidths: {
      material: { flex: 1, minWidth: 110 },
      planned: 85,
      actual: 85,
      diff: 85,
    },
  });

  return (
    <DataGridPremium
      sx={{ ...commonDataGridSx, ...sx }}
      columns={columns}
      rows={rows}
      pinnedRows={pinnedRows}
      pinnedColumns={pinnedColumns}
      rowHeight={rowHeight}
      columnHeaderHeight={columnHeaderHeight}
      cellSelection
      ignoreValueFormatterDuringExport
    />
  );
};

export const LoadedHeatSummaryTable = ({
  chargeSummaries,
}: {
  chargeSummaries: Loaded<ChargeSummary[] | null>;
}) => {
  const theme = useTheme();

  return (
    <Stack sx={{ width: 1 }} gap={4}>
      <Typography
        variant="h1"
        sx={{
          backgroundColor: theme.palette.grey[100],
          padding: 1,
          textAlign: "center",
          borderRadius: 1,
        }}
      >
        Summary
      </Typography>
      <LoadedContent
        data={chargeSummaries}
        disableFetching
        waiting={<LinearProgress />}
      >
        {(loadedChargeSummary) => (
          <HeatSummaryTable loads={loadedChargeSummary ?? []} />
        )}
      </LoadedContent>
    </Stack>
  );
};
