import { Chunk, ChunkData, quantisedWindow, useLoadedWindow } from "../helpers";
import { backendApi } from "src/store/api/enhancedApi";
import { useTenant } from "hooks/settings";
import { useCallback } from "react";
import { OnRelayout } from "../viewportControls";
import { mapLoadedUnpack } from "models/loaded";
import { EventPlot } from "./eventPlot";
import { generateColour } from "../plotting";

export type DatedScrapCarCycle = {
  start: Date;
  end: Date;
  scrapCar: number;
  chargeSchedule: string;
  chargeNumber: number;
};

export const useScrapCarCycles = (
  start: Date,
  end: Date
): ChunkData<DatedScrapCarCycle[]>[] => {
  const [getScrapCarCycles] =
    backendApi.endpoints.getScrapCarCycles.useLazyQuery();
  const tenant = useTenant();

  const get = useCallback(
    async (segment: Chunk) =>
      getScrapCarCycles({ tenantName: tenant, ...segment })
        .unwrap()
        .then((result) =>
          result.scrap_car_cycles.map(
            (cycle) =>
              ({
                start: new Date(cycle.start),
                end: new Date(cycle.end),
                scrapCar: cycle.scrap_car,
                chargeSchedule: cycle.charge_schedule,
                chargeNumber: cycle.charge_number,
              }) as DatedScrapCarCycle
          )
        ),
    [getScrapCarCycles]
  );

  return useLoadedWindow(
    quantisedWindow(
      { start: start.getTime(), end: end.getTime() },
      300 * 1000,
      2
    ),
    get
  );
};

export const ScrapCarCyclesPlot = ({
  start,
  end,
  onRelayout,
}: {
  start: Date;
  end: Date;
  onRelayout?: OnRelayout;
}) => {
  const scrapCarCycles = useScrapCarCycles(start, end);

  const loadedScrapCarCycles = scrapCarCycles.flatMap(
    (chunk) => mapLoadedUnpack(chunk.value, (cycles) => cycles) ?? []
  );

  return (
    <EventPlot
      events={loadedScrapCarCycles.map((cycle) => ({
        start: cycle.start,
        end: cycle.end,
        colour: generateColour(cycle.scrapCar.toString()),
        track: `Scrap<br>car ${cycle.scrapCar}`,
        text: `${cycle.chargeSchedule} (#${cycle.chargeNumber})`,
      }))}
      start={start}
      end={end}
      onRelayout={onRelayout}
      chunks={scrapCarCycles}
    />
  );
};
