import React from "react";
import { LoadingButton } from "@mui/lab";
import { Alert, Snackbar } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";

import {
  DeploymentType,
  generatedApi,
  GetDeployedPlansApiResponse,
  Latest,
  Next,
  useGetDeployedPlansQuery,
} from "src/store/api/generatedApi";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { checkPlanPollMS } from "./utils";

type Props = {
  deploymentGroup: "backup" | "standard";
  deploymentTypes: DeploymentType[];
  planTimeframe: Latest | Next;
  currentPlanId: number | null;
};

const useUncachedGetDeployedPlansQuery = (
  currentPlanId: number | null,
  deploymentTypes: DeploymentType[],
  tenantName: string
): [number | null, GetDeployedPlansApiResponse | undefined] | null => {
  const [handledCurrentPlanId, setHandledCurrentPlanId] = React.useState<
    number | null
  >(currentPlanId);

  const deployedPlans = useGetDeployedPlansQuery(
    {
      tenantName,
      deploymentTypes,
    },
    {
      pollingInterval: checkPlanPollMS,
    }
  );

  React.useEffect(() => {
    void deployedPlans.refetch().then(() => {
      setHandledCurrentPlanId(currentPlanId);
    });
  }, [deployedPlans.refetch, currentPlanId, setHandledCurrentPlanId]);

  if (currentPlanId !== handledCurrentPlanId) {
    return null;
  } else {
    return [handledCurrentPlanId, deployedPlans.data];
  }
};

export const RefreshPlanSnackbar = ({
  deploymentGroup,
  deploymentTypes,
  planTimeframe,
  currentPlanId,
}: Props) => {
  const tenantName = useTenant();
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const upToDatePlanIds = useUncachedGetDeployedPlansQuery(
    currentPlanId,
    deploymentTypes,
    tenantName
  );

  const doRefresh = React.useCallback(() => {
    void dispatch(
      generatedApi.endpoints.getDeployedPlanMixes.initiate(
        {
          tenantName,
          period: 1,
          planId: planTimeframe,
          deploymentTypes,
        },
        { forceRefetch: true }
      )
    );
  }, [dispatch, deploymentTypes, planTimeframe, tenantName]);

  const hasNewPlan =
    upToDatePlanIds !== null &&
    upToDatePlanIds[0] === currentPlanId &&
    upToDatePlanIds[1] !== undefined &&
    upToDatePlanIds[1][planTimeframe] !== currentPlanId;

  const alertTranslationKey = React.useMemo(():
    | "newBackupPlanAvailableNotification"
    | "newPlanAvailableNotification" => {
    switch (deploymentGroup) {
      case "backup":
        return "newBackupPlanAvailableNotification";
      case "standard":
        return "newPlanAvailableNotification";
    }
  }, [deploymentGroup, planTimeframe]);

  const isLoading = useAppSelector((state) => {
    return generatedApi.endpoints.getDeployedPlanMixes.select({
      tenantName,
      period: 1,
      planId: planTimeframe,
      deploymentTypes,
    })(state).isLoading;
  });

  return (
    <Snackbar
      open={hasNewPlan}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        severity="warning"
        action={
          <LoadingButton
            color="primary"
            size="medium"
            onClick={doRefresh}
            loading={isLoading}
          >
            {t("refresh")}
          </LoadingButton>
        }
      >
        {t(alertTranslationKey)}
      </Alert>
    </Snackbar>
  );
};
