import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AccountPageLayout } from "components/common/AccountPageLayout";
import dayjs, { Dayjs } from "dayjs";
import { useTenantTranslation } from "hooks/formatters";
import { useNavigateToNewPurchasingPlan } from "hooks/navigation";
import { useTenant } from "hooks/settings";
import { useState } from "react";
import { useListPurchasingPlansQuery } from "src/store/api/generatedApi";
import { PurchasingPlansTable } from "./PurchasingPlansTable";

export const PurchasingPage = () => {
  const { t } = useTenantTranslation();
  const tenantName = useTenant();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs().add(1, "month")
  );

  const { data: purchasingPlans, error } = useListPurchasingPlansQuery({
    tenantName,
  });

  const [navigateToPurchasingPlan, { isLoading: isCreatingPlan }] =
    useNavigateToNewPurchasingPlan();

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setSelectedDate(dayjs().add(1, "month"));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCreatePlan = () => {
    if (selectedDate) {
      void navigateToPurchasingPlan(selectedDate);
    }
    handleCloseModal();
  };

  if (error) {
    return <Alert severity="error">{t("unknownError")}</Alert>;
  }

  return (
    <>
      <AccountPageLayout
        title={t("purchasing")}
        actionButton={
          <LoadingButton
            variant="contained"
            onClick={handleOpenModal}
            loading={isCreatingPlan}
          >
            {t("newPurchasingPlan")}
          </LoadingButton>
        }
      >
        <Box sx={{ mt: 5, mb: 2, height: "100%" }}>
          <PurchasingPlansTable
            plans={purchasingPlans ?? []}
            loading={purchasingPlans === undefined}
          />
        </Box>
      </AccountPageLayout>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("newPurchasingPlan")}</DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Typography sx={{ mb: 3 }}>{t("selectMonthYearForPlan")}</Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <DatePicker
              label={t("monthAndYear")}
              value={selectedDate}
              onChange={(newDate) => setSelectedDate(newDate)}
              views={["month", "year"]}
              openTo="month"
              format="MMMM YYYY"
              sx={{ width: "100%" }}
              minDate={dayjs()}
              maxDate={dayjs().add(3, "month")}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {t("cancel")}
          </Button>
          <Button
            onClick={handleCreatePlan}
            variant="contained"
            color="primary"
            disabled={!selectedDate}
          >
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
