import { ContentCopy, ContentPaste } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "hooks/formatters";
import {
  NameBasedSearch,
  NameBasedSearch2,
  useGetNameBasedSearchQuery,
  useOverwriteFromNameBasedSearchMutation,
} from "src/store/api/generatedApi";
import { useTenant } from "src/hooks/settings.tsx";
import { useSearchId } from "hooks/search.ts";
import { useSearch } from "contexts/search/provider.tsx";
import { useState } from "react";

export const CopySearchButtonWrapper = () => {
  const tenantName = useTenant();
  const searchId = useSearchId();
  if (searchId === null) {
    return null;
  }
  const {
    data: nameBasedSearch,
    isLoading,
    isError,
  } = useGetNameBasedSearchQuery({ tenantName, searchId });
  if (isError) {
    return null;
  }
  return (
    <CopySearchButton
      nameBasedSearch={nameBasedSearch}
      loading={isLoading || nameBasedSearch === undefined}
    />
  );
};

const CopySearchButton = ({
  nameBasedSearch,
  loading,
}: {
  nameBasedSearch: NameBasedSearch | undefined;
  loading: boolean;
}) => {
  const { t } = useTenantTranslation();
  const notifyStatus = useNotifyStatus();

  return (
    <Box>
      <LoadingButton
        variant="outlined"
        color="secondary"
        startIcon={<ContentCopy />}
        loading={loading}
        onClick={() => {
          if (nameBasedSearch === undefined) {
            return;
          }
          return navigator.clipboard
            .writeText(JSON.stringify(nameBasedSearch))
            .then(() =>
              notifyStatus({
                type: "success",
                text: t("searchCopied"),
              })
            );
        }}
      >
        {t("copyVerb")}
      </LoadingButton>
    </Box>
  );
};

export const PasteSearchButtonWrapper = () => {
  const tenantName = useTenant();
  const searchId = useSearchId();
  if (!searchId) {
    return null;
  }
  return <PasteSearchButton tenantName={tenantName} searchId={searchId} />;
};

const PasteSearchButton = ({
  tenantName,
  searchId,
}: {
  tenantName: string;
  searchId: number;
}) => {
  const { t } = useTenantTranslation();
  const [overwriteNameBasedSearch] = useOverwriteFromNameBasedSearchMutation();
  const { setSearchId } = useSearch();
  const [loading, setLoading] = useState(false);

  const pasteSearch = async () => {
    setLoading(true);
    const nameBasedSearchInput = await navigator.clipboard
      .readText()
      .then((text) => JSON.parse(text) as NameBasedSearch2);

    const SearchRead = await overwriteNameBasedSearch({
      tenantName,
      searchId,
      nameBasedSearchInput,
    }).unwrap();

    const newSearchId = SearchRead.id;
    if (newSearchId) {
      setSearchId(newSearchId);
    }
    setLoading(false);
  };

  return (
    <Box>
      <LoadingButton
        variant="outlined"
        color="secondary"
        startIcon={<ContentPaste />}
        loading={loading}
        onClick={pasteSearch}
      >
        {t("pasteVerb")}
      </LoadingButton>
    </Box>
  );
};
