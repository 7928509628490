import { Button, Stack } from "@mui/material";
import { useQueryParamState } from "components/videoLabelling/helpers";
import { useNavigateTo } from "hooks/navigation";
import { Serialiser } from "hooks/serialisers/types";
import { Setter } from "hooks/state/syncing";
import { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export type OnRelayout = (event: Readonly<Plotly.PlotRelayoutEvent>) => void;

export const useViewportControls = () => {
  const currentTimestamp = new Date();

  const [start, setStart] = useQueryParamState(
    "start",
    dateSerialiser,
    new Date(currentTimestamp.getTime() - 5 * 60 * 1000)
  );
  const [end, setEnd] = useQueryParamState(
    "end",
    dateSerialiser,
    currentTimestamp
  );

  const jump = (seconds: number) => {
    setStart((current) => new Date(current.getTime() + seconds * 1000));
    setEnd((current) => new Date(current.getTime() + seconds * 1000));
  };

  const [play, setPlay] = useState(false);
  const speed = useRef(1);

  useHotkeys("k", () => setPlay((current) => !current));
  useHotkeys("j", () => jump(-10 * speed.current));
  useHotkeys("l", () => jump(10 * speed.current));
  useHotkeys("shift+period", () => (speed.current = 2 * speed.current));
  useHotkeys("shift+comma", () => (speed.current = speed.current / 2));

  useEffect(() => {
    if (play) {
      const frequency = 4;

      // The polling interval is set quite high as new requests will overwrite
      // old ones.  So if you set it much higher than the typical time taken to
      // complete a request, the graph will never update.  Obviously there are
      // nicer ways to do this but it's good enough for now
      const interval = window.setInterval(() => {
        jump(speed.current / frequency);
      }, 1000 / frequency);
      return () => clearInterval(interval);
    }
  }, [play, setStart, setEnd]);

  const handleJump = () => {
    const current_size = end.getTime() - start.getTime();
    const middle = new Date(start.getTime() + current_size / 2);

    const jumpTimestamp = prompt(
      "Jump to date/video_key:",
      middle.toISOString().replace("T", " ").split(".")[0]
    );

    if (jumpTimestamp == null) {
      return;
    }

    const parsedTimestamp = jumpTimestamp.includes("_")
      ? new Date(
          parseInt(jumpTimestamp.slice(0, 4)),
          parseInt(jumpTimestamp.slice(4, 6)) - 1,
          parseInt(jumpTimestamp.slice(6, 8)),
          parseInt(jumpTimestamp.slice(9, 11)),
          parseInt(jumpTimestamp.slice(11, 13)),
          parseInt(jumpTimestamp.slice(13, 15))
        )
      : new Date(jumpTimestamp);

    setStart(new Date(parsedTimestamp.getTime() - current_size / 2));
    setEnd(new Date(parsedTimestamp.getTime() + current_size / 2));
  };

  useHotkeys("=,F", handleJump);

  const onRelayout: OnRelayout = (event) => {
    const start = event["xaxis.range[0]"];
    const end = event["xaxis.range[1]"];

    if (start != null) {
      setStart(new Date(start));
    }
    if (end != null) {
      setEnd(new Date(end));
    }
  };

  return { start, setStart, end, setEnd, play, setPlay, onRelayout };
};

const dateSerialiser: Serialiser<Date, never> = {
  format: (value) => value.getTime().toString(),
  parse: (text) => {
    const milliseconds = parseFloat(text);
    return isNaN(milliseconds)
      ? { valid: false }
      : { valid: true, value: new Date(parseFloat(text)) };
  },
};

export const ViewportControlButtons = ({
  end,
  setStart,
}: {
  end: Date;
  setStart: Setter<Date>;
}) => {
  const navigateTo = useNavigateTo();

  const reset = () => navigateTo({ params: { start: null, end: null } });
  useHotkeys("r", reset);

  const hour = () => setStart(new Date(end.getTime() - 60 * 60 * 1000));
  useHotkeys("h", hour);

  const day = () => setStart(new Date(end.getTime() - 24 * 60 * 60 * 1000));
  useHotkeys("d", day);

  const week = () => setStart(new Date(end.getTime() - 168 * 60 * 60 * 1000));
  useHotkeys("w", week);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      gap={2}
    >
      <Button onClick={week}>Week [w]</Button>
      <Button onClick={day}>Day [d]</Button>
      <Button onClick={hour}>Hour [h]</Button>
      <Button onClick={reset}>Reset [r]</Button>
    </Stack>
  );
};
