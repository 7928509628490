import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { Layout, PlotData } from "plotly.js";
import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { UnitType } from "src/store/api/generatedApi";
import {
  buildMaterialColours,
  defaultLayout,
  generalTickFormatting,
  getLineDash,
  getMarkerSymbol,
  getMaterialColour,
  setSelectedDateFromPlotClick,
} from "../plotting";
import { GroupedPriceSeries } from "../types";

type PricesPlotProps = {
  priceSeries: GroupedPriceSeries;
  setSelectedDate: (date: Date) => void;
};

export const PricesPlot = ({
  priceSeries,
  setSelectedDate,
}: PricesPlotProps) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);

  const [figure, setFigure] = useState<{
    data: Partial<PlotData>[];
    layout: Partial<Layout>;
  }>({
    data: [],
    layout: updateLayout(defaultLayout, t, units),
  });

  // Update figure when price series changes
  useEffect(() => {
    setFigure({
      data: buildFigData(priceSeries),
      layout: updateLayout(figure.layout, t, units),
    });
  }, [priceSeries, t, units]);

  return (
    <div style={{ width: "100%" }}>
      <Plot
        data={figure.data}
        layout={figure.layout}
        // eslint-disable-next-line react/forbid-component-props
        style={{ width: "100%" }}
        useResizeHandler
        onClick={setSelectedDateFromPlotClick(setSelectedDate)}
      />
    </div>
  );
};

const buildFigData = (
  groupedPriceSeries: GroupedPriceSeries
): Partial<PlotData>[] => {
  const materialColours = buildMaterialColours(
    groupedPriceSeries.items.map((item) => ({
      groupingName: "Price Groups",
      groupName: item[0].materialName,
    }))
  );
  const figData: Partial<PlotData>[] = [];

  for (const [key, series] of groupedPriceSeries.items) {
    const colour = getMaterialColour(
      materialColours,
      "Price Groups",
      key.materialName
    );
    const lineDash = getLineDash(
      Array.from(groupedPriceSeries.availablePriceSources).indexOf(
        key.priceSource
      )
    );
    const markerSymbol = getMarkerSymbol(
      Array.from(groupedPriceSeries.availablePriceSources).indexOf(
        key.priceSource
      )
    );

    // Add price data
    figData.push({
      x: series.validFrom.map((validFrom) => validFrom.toISOString()),
      y: series.specificPrice,
      type: "scatter",
      mode: "lines+markers",
      visible: true,
      name: key.priceSource,
      legendgroup: key.materialName,
      legendgrouptitle: {
        text: key.materialName,
      },
      line: {
        color: colour,
        dash: lineDash,
        shape: "hv",
      },
      marker: {
        color: colour,
        symbol: markerSymbol,
      },
    });
  }

  return figData;
};

const updateLayout = (
  currentLayout: Partial<Layout>,
  t: (key: string) => string,
  units: (key: UnitType) => string
): Partial<Layout> => {
  const yaxisTitle = `${t("price")} ${units("specific_cost")}`;

  return {
    ...currentLayout,
    yaxis: {
      ...currentLayout.yaxis,
      title: yaxisTitle,
      tickformat: generalTickFormatting,
    },
  };
};
