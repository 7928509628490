import React from "react";
import { NavLink, useNavigate } from "react-router";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";

import {
  DeploymentType,
  NamedPlanMix,
  useGetDeployedPlanMixesQuery,
  useUpdateAndDeployPlanMixesMutation,
  PlanMixUpdate,
  generatedApi,
  Latest,
  Next,
  OptimisationResultSetSummary,
  SearchContextData,
} from "src/store/api/generatedApi";

import { useTenant } from "hooks/settings";
import { useTenantTranslation } from "hooks/formatters";

import { selectIsVersionOutOfDate } from "src/store/slices/versionSlice";
import { steelGradeSearchSlice } from "src/store/slices/steelGradeSearchSlice";
import { AppDispatch, useAppDispatch, useAppSelector } from "src/store/store";

import { TitledTabs } from "components/common/titledTabs";
import { EditableTable } from "components/common/plan/EditableTable";
import { EditableTableSkeleton } from "components/common/plan/EditableTableSkeleton";

import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";

import { LoadQueue } from "./LoadQueue";
import { RefreshPlanSnackbar } from "./RefreshPlanSnackbar";
import { PlanNotFound } from "./PlanNotFound";

type PlanTimeframeToggleProps = {
  value: Latest | Next;
};

type InitialDeploymentType = "standard" | "backup";

export const initialDeploymentTypeToDeploymentTypes: Record<
  InitialDeploymentType,
  DeploymentType[]
> = {
  standard: ["standard", "edited_standard"],
  backup: ["backup", "edited_backup"],
};

type ActivePlanWrapperProps = {
  planTimeframe: Latest | Next;
};

type PlanProps = {
  deploymentType: DeploymentType;
  planTimeframe: Latest | Next;
  id: number;
  mixes: NamedPlanMix[];
  startAt: Date;
  endAt: Date;
  summary: OptimisationResultSetSummary;
  context: SearchContextData;
};

const PlanTimeframeToggle = ({ value }: PlanTimeframeToggleProps) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {value === "next" ? (
        <Alert variant="outlined" severity="warning" sx={{ paddingY: 0 }}>
          {t("youAreCurrentlyViewingTheNextPlan")}
        </Alert>
      ) : null}
      <ToggleButtonGroup
        sx={{ borderColor: theme.palette.grey[500] }}
        color="primary"
        size="small"
        value={value}
        exclusive
      >
        <NavLink to="../standard/latest">
          <ToggleButton
            sx={{
              borderColor:
                value === "latest"
                  ? theme.palette.primary.main
                  : theme.palette.grey[500],
            }}
            value="latest"
          >
            {t("latest")}
          </ToggleButton>
        </NavLink>
        <NavLink to="../standard/next">
          <ToggleButton
            sx={{
              borderColor:
                value === "next"
                  ? theme.palette.primary.main
                  : theme.palette.grey[500],
            }}
            value="next"
          >
            {t("next")}
          </ToggleButton>
        </NavLink>
      </ToggleButtonGroup>
    </Box>
  );
};

type Props =
  | {
      deploymentType: "standard";
      planTimeframe: Latest | Next;
    }
  | {
      deploymentType: "backup";
    };

export const SupervisorView = ({ ...props }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const cachedPlantimeFrame = React.useRef(
    props.deploymentType === "standard" ? props.planTimeframe : undefined
  );

  if (props.deploymentType === "standard") {
    cachedPlantimeFrame.current = props.planTimeframe;
  }

  const isOutOfDate = useAppSelector(selectIsVersionOutOfDate);

  const { t } = useTenantTranslation();

  const tabIndex = React.useMemo(() => {
    switch (props.deploymentType) {
      case "standard":
        return 0;
      case "backup":
        return 1;
    }
  }, [props.deploymentType]);

  const handleOnChange = React.useCallback(
    (index: number) => {
      switch (index) {
        case 0: {
          void navigate(
            `../standard/${cachedPlantimeFrame.current ?? "latest"}`
          );
          break;
        }
        case 1: {
          void navigate("../backup");
          break;
        }
      }
    },
    [navigate]
  );

  return (
    <>
      <Box
        sx={{
          display: "grid",
          width: "100vw",
          gridTemplateColumns: "1fr 300px",
          gridTemplateRows: "min-content 1fr",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 32px)",
            overflow: "hidden",
            gridRow: "1 / span 2",
            borderRight: "1px solid black",
            display: "flex",
            flexDirection: "column",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <TitledTabs
            sx={{ alignItems: "center" }}
            tabIndex={tabIndex}
            onChange={handleOnChange}
            rightComponent={
              props.deploymentType === "standard" &&
              props.planTimeframe !== undefined ? (
                <PlanTimeframeToggle value={props.planTimeframe} />
              ) : undefined
            }
            tabSpecs={[
              {
                title: t("active"),
                key: "active",
                content:
                  props.deploymentType === "standard" ? (
                    <ActivePlanWrapper planTimeframe={props.planTimeframe} />
                  ) : null,
              },
              {
                title: t("backup"),
                key: "backup",
                content: <BackupPlanWrapper />,
              },
            ]}
          />
        </Box>
        <Box
          sx={{
            gridRow: "1 / span 2",
            minHeight: 1,
            height: "100%",
            overflow: "hidden",
            backgroundColor: theme.palette.grey[50],
            borderLeftWidth: 1,
            borderLeftColor: theme.palette.divider,
            borderLeftStyle: "solid",
          }}
        >
          <LoadQueue />
        </Box>
      </Box>
      <Snackbar
        open={isOutOfDate}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <Button
              color="primary"
              size="medium"
              onClick={() => window.location.reload()}
            >
              {t("refresh")}
            </Button>
          }
        >
          {t("newVersionNotification")}
        </Alert>
      </Snackbar>
    </>
  );
};

const ActivePlanWrapper = ({ planTimeframe }: ActivePlanWrapperProps) => {
  const tenantName = useTenant();

  const { data: planMixes, error } = useGetDeployedPlanMixesQuery({
    tenantName,
    planId: planTimeframe,
    period: 1,
    deploymentTypes: initialDeploymentTypeToDeploymentTypes.standard,
  });

  if (error && "status" in error && error.status === 404) {
    return (
      <PlanNotFound
        planTimeframe={planTimeframe}
        deploymentGroup="standard"
        deploymentTypes={initialDeploymentTypeToDeploymentTypes.standard}
      />
    );
  }

  if (planMixes) {
    const {
      plan_id,
      mixes,
      start_at,
      end_at,
      deployment_type,
      summary,
      context,
    } = planMixes;
    return (
      <Plan
        deploymentType={deployment_type}
        key={deployment_type}
        id={plan_id}
        mixes={mixes}
        startAt={new Date(start_at)}
        endAt={new Date(end_at)}
        planTimeframe={planTimeframe}
        summary={summary}
        context={context}
      />
    );
  }
  return <EditableTableSkeleton />;
};

const BackupPlanWrapper = () => {
  const tenantName = useTenant();

  const { data: planMixes, error } = useGetDeployedPlanMixesQuery({
    tenantName,
    planId: "latest",
    period: 1,
    deploymentTypes: initialDeploymentTypeToDeploymentTypes.backup,
  });

  if (error && "status" in error && error.status === 404) {
    return (
      <PlanNotFound
        planTimeframe="latest"
        deploymentGroup="backup"
        deploymentTypes={initialDeploymentTypeToDeploymentTypes.backup}
      />
    );
  }

  if (planMixes) {
    const {
      plan_id,
      mixes,
      start_at,
      end_at,
      deployment_type,
      summary,
      context,
    } = planMixes;
    return (
      <Plan
        planTimeframe="latest"
        deploymentType={deployment_type}
        key={deployment_type}
        id={plan_id}
        mixes={mixes}
        startAt={new Date(start_at)}
        endAt={new Date(end_at)}
        summary={summary}
        context={context}
      />
    );
  }
  return <EditableTableSkeleton />;
};

const handleOnSubmit =
  (
    dispatch: AppDispatch,
    tenantName: string,
    startAt: Date,
    endAt: Date,
    planId: number,
    mutation: ReturnType<typeof useUpdateAndDeployPlanMixesMutation>[0]
  ) =>
  async (mixes: Record<string, PlanMixUpdate>): Promise<void> => {
    const { data } = await mutation({
      tenantName,
      planId,
      deployedPlanMixUpdates: {
        period: 1,
        mixes,
        replacements: {},
        start_at: startAt.toISOString(),
        end_at: endAt.toISOString(),
        deployment_type: "edited_standard",
      },
    });

    if (data) {
      dispatch(
        generatedApi.util.updateQueryData(
          "getDeployedPlanMixes",
          {
            tenantName,
            planId: "latest",
            deploymentTypes: initialDeploymentTypeToDeploymentTypes.standard,
            period: 1,
          },
          () => data
        )
      );
      dispatch(
        steelGradeSearchSlice.actions.copy([planId, "supervisor", data.plan_id])
      );
    }
  };

const useHandleOnSubmit = (startAt: Date, endAt: Date, planId: number) => {
  const dispatch = useAppDispatch();
  const tenantName = useTenant();
  const [mutation] = useUpdateAndDeployPlanMixesMutation();
  const callback = React.useMemo(() => {
    return handleOnSubmit(
      dispatch,
      tenantName,
      startAt,
      endAt,
      planId,
      mutation
    );
  }, [dispatch, tenantName, startAt, endAt, planId, mutation]);

  return callback;
};

export const Plan = ({
  id,
  mixes,
  startAt,
  endAt,
  deploymentType,
  planTimeframe,
  summary,
  context,
}: PlanProps) => {
  const materialInventory = React.useMemo(
    () =>
      typeSafeObjectFromEntries(
        summary.period_material_summary
          .filter((material) => material.period === 1)
          .map((material) => [material.material_id, material.initial_inventory])
      ),
    [summary]
  );

  const steelGrades = React.useMemo(() => {
    const steelGradeIds = new Set(mixes.flatMap((mix) => mix.steel_grade_ids));
    return context.steel_grades.filter((steelGrade) =>
      steelGradeIds.has(steelGrade.id)
    );
  }, [context, mixes]);

  const deploymentDetails = React.useMemo(() => {
    switch (deploymentType) {
      case "backup":
      case "edited_backup":
        return null;
      case "edited_standard":
      case "standard":
        return [deploymentType, planTimeframe] as [
          Exclude<DeploymentType, "backup" | "edited_backup">,
          Latest | Next,
        ];
    }
  }, [deploymentType, planTimeframe]);

  const deploymentGroup = React.useMemo(() => {
    switch (deploymentType) {
      case "backup":
      case "edited_backup":
        return "backup";
      case "standard":
      case "edited_standard":
        return "standard";
    }
  }, [deploymentType]);

  const deploymentTypes: DeploymentType[] = React.useMemo(() => {
    switch (deploymentType) {
      case "standard":
      case "edited_standard":
        return ["standard", "edited_standard"];
      case "backup":
      case "edited_backup":
        return ["backup", "edited_backup"];
    }
  }, [deploymentType]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const doSubmit = useHandleOnSubmit(startAt, endAt, id);

  const handleOnSubmit = async (mixes: Record<string, PlanMixUpdate>) => {
    setIsSubmitting(true);
    await doSubmit(mixes);
    setIsSubmitting(false);
  };

  return (
    <>
      <EditableTable
        key={id}
        planId={id}
        deploymentDetails={deploymentDetails}
        steelGrades={steelGrades}
        startAt={startAt}
        endAt={endAt}
        mixes={mixes}
        materials={context.materials.filter(
          (material) => material.visibility === "visible"
        )}
        materialInventory={materialInventory}
        steelGradeColumnWidth={350}
        canClickSteelGrade
        onSubmit={handleOnSubmit}
        disableEdit={initialDeploymentTypeToDeploymentTypes.backup.includes(
          deploymentType
        )}
        visualPrice="diff"
        showNumberOfHeats={false}
        view="supervisor"
        showInventoryAndConsumption={false}
      />
      {!isSubmitting ? (
        <RefreshPlanSnackbar
          planTimeframe={planTimeframe}
          deploymentTypes={deploymentTypes}
          deploymentGroup={deploymentGroup}
          currentPlanId={id}
        />
      ) : null}
    </>
  );
};
