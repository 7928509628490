import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { usePurchasingPlanId } from "hooks/purchasing";
import { useTenant } from "hooks/settings";
import {
  useGetPurchasingPlanQuery,
  useListSuppliersQuery,
} from "src/store/api/generatedApi";
import { EditablePurchasingPlanTable } from "./EditablePurchasingPlanTable";
import { PurchasingPlanSaveStatus } from "./PurchasingPlanSaveStatus";
import { PurchasingPlanStats } from "./PurchasingPlanStats";

export const PurchasingPlanPage = () => {
  const { t } = useTenantTranslation();
  const purchasingPlanId = usePurchasingPlanId();

  if (purchasingPlanId) {
    return <PurchasingPlanBody planId={purchasingPlanId} />;
  } else {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {t("pageNotFound")}
      </Box>
    );
  }
};

const PurchasingPlanBody = ({ planId }: { planId: number }) => {
  const tenantName = useTenant();
  const { t } = useTenantTranslation();
  const purchasingPlan = useGetPurchasingPlanQuery({
    tenantName: tenantName,
    purchasingPlanId: planId,
    revisionId: "latest",
  });
  const suppliers = useListSuppliersQuery({
    tenantName: tenantName,
  });

  if (purchasingPlan.isError || suppliers.isError) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {t("errorLoadingPlanData")}
      </Box>
    );
  }

  if (purchasingPlan.isSuccess && suppliers.isSuccess) {
    return (
      <Stack direction="column" spacing={6} sx={{ padding: 8 }}>
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ pl: 4 }}
        >
          <PurchasingPlanStats />
          <PurchasingPlanSaveStatus planId={planId} />
        </Stack>
        <Stack
          display="flex"
          direction="column"
          sx={{ minHeight: "10vh", maxHeight: "60vh" }}
        >
          <EditablePurchasingPlanTable
            planId={planId}
            materials={purchasingPlan.data.materials}
            quotes={purchasingPlan.data.quotes}
            suppliers={suppliers.data.suppliers}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={4}>
          <Button
            variant="contained"
            color="primary"
            disabled
            loading={false}
            loadingPosition="end"
          >
            {t("syncWithSAP")}
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
