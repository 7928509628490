import React from "react";
import dayjs from "dayjs";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";

import { EditableTable } from "components/common/plan/EditableTable";
import { EditableTableSkeleton } from "components/common/plan/EditableTableSkeleton";

import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";

import {
  DeployedNamedPlanMixes,
  generatedApi,
  MaterialRead,
  NamedPlanMix,
  OptimisationResultSetSummary,
  PlanMixUpdate,
  SearchContextData,
  useGetDeployedPlanMixesQuery,
  useUpdateAndDeployPlanMixesMutation,
} from "src/store/api/generatedApi";
import { useAppDispatch } from "src/store/store";
import { steelGradeSearchSlice } from "src/store/slices/steelGradeSearchSlice";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";

type Props = {
  open: boolean;
  doClose: () => void;
  period: number;
};

type PlanContextWrapperProps = {
  period: number;
  deployedPlanMixes: DeployedNamedPlanMixes;
};

type BodyProps = {
  planId: number;
  mixes: NamedPlanMix[];
  materials: MaterialRead[];
  summary: OptimisationResultSetSummary;
  context: SearchContextData;
  startAt: Date;
  period: number;
};

const useHandleOnSubmit = (planId: number, period: number) => {
  const dispatch = useAppDispatch();
  const tenantName = useTenant();
  const [doUpdateAndDeployPlanMixes] = useUpdateAndDeployPlanMixesMutation();

  const doSubmit = React.useCallback(
    async (mixes: Record<string, PlanMixUpdate>) => {
      const startAt = new Date();
      const updatedDeployedPlanMixes = await doUpdateAndDeployPlanMixes({
        tenantName,
        planId,
        deployedPlanMixUpdates: {
          mixes,
          replacements: {},
          period,
          start_at: startAt.toISOString(),
          end_at: dayjs(startAt).add(1, "year").toISOString(),
          deployment_type: "backup",
        },
      });
      if (updatedDeployedPlanMixes.data) {
        dispatch(
          generatedApi.util.updateQueryData(
            "getDeployedPlanMixes",
            {
              tenantName,
              deploymentTypes: ["backup", "edited_backup"],
              planId: "latest",
              period,
            },
            () => updatedDeployedPlanMixes.data
          )
        );
        dispatch(
          generatedApi.util.updateQueryData(
            "getDeployedPlans",
            {
              tenantName,
              deploymentTypes: ["backup"],
            },
            (draft) => {
              return {
                ...draft,
                latest: updatedDeployedPlanMixes.data.plan_id,
              };
            }
          )
        );
        dispatch(
          steelGradeSearchSlice.actions.copy([
            planId,
            "backup",
            updatedDeployedPlanMixes.data.plan_id,
          ])
        );
        void dispatch(
          generatedApi.endpoints.listDeployedPlans.initiate(
            {
              tenantName,
            },
            { forceRefetch: true }
          )
        );
      }
    },
    [doUpdateAndDeployPlanMixes, dispatch, tenantName, planId, period]
  );
  return doSubmit;
};

export const Body = ({
  planId,
  mixes,
  materials,
  startAt,
  period,
  summary,
  context,
}: BodyProps) => {
  const handleOnSubmit = useHandleOnSubmit(planId, period);
  const materialInventory = typeSafeObjectFromEntries(
    summary.period_material_summary
      .filter((material) => material.period === 1)
      .map((material) => [material.material_id, material.initial_inventory])
  );
  const steelGrades = React.useMemo(() => {
    const steelGradeIds = new Set(mixes.flatMap((mix) => mix.steel_grade_ids));
    return context.steel_grades.filter((steelGrade) =>
      steelGradeIds.has(steelGrade.id)
    );
  }, [mixes, context]);

  return (
    <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
      <Stack sx={{ minHeight: 0, display: "flex" }}>
        <EditableTable
          visualPrice="full"
          canClickSteelGrade={false}
          planId={planId}
          steelGrades={steelGrades}
          disableEdit={false}
          mixes={mixes}
          materials={materials}
          materialInventory={materialInventory}
          steelGradeColumnWidth={210}
          deployedAt={startAt}
          deploymentDetails={null}
          showNumberOfHeats
          onSubmit={handleOnSubmit}
          view="backup"
          showInventoryAndConsumption={false}
        />
      </Stack>
    </DialogContent>
  );
};

const PlanContextWrapper = ({
  deployedPlanMixes,
  period,
}: PlanContextWrapperProps) => {
  const {
    plan_id: planId,
    mixes,
    start_at,
    summary,
    context,
  } = deployedPlanMixes;

  const startAt = React.useMemo(() => {
    return new Date(start_at);
  }, [start_at]);

  return (
    <Body
      planId={planId}
      mixes={mixes}
      materials={context.materials}
      summary={summary}
      startAt={startAt}
      period={period}
      context={context}
    />
  );
};

export const EditBackupPlanModal = ({ open, doClose, period }: Props) => {
  const tenantName = useTenant();
  const { t } = useTenantTranslation();
  const deployedPlanMixes = useGetDeployedPlanMixesQuery(
    {
      tenantName,
      deploymentTypes: ["backup", "edited_backup"],
      planId: "latest",
      period,
    },
    { skip: !open }
  );
  return (
    <Dialog
      open={open}
      onClose={doClose}
      fullWidth
      maxWidth="xl"
      sx={{
        "& .MuiDialog-paper": {
          height: "90vh",
        },
      }}
    >
      <DialogTitle>{t("editBackupPlan")}</DialogTitle>

      {deployedPlanMixes.isSuccess ? (
        <PlanContextWrapper
          deployedPlanMixes={deployedPlanMixes.data}
          period={period}
        />
      ) : (
        <EditableTableSkeleton />
      )}
    </Dialog>
  );
};
