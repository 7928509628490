import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { Layout, PlotData } from "plotly.js";
import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import {
  MaterialGroupingName,
  TimeAggregation,
  UnitType,
} from "src/store/api/generatedApi";
import {
  buildMaterialColours,
  defaultLayout,
  fractionTickFormatting,
  generalTickFormatting,
  getLineDash,
  getMarkerSymbol,
  getMaterialColour,
  setSelectedDateFromPlotClick,
} from "../plotting";
import { ConsumptionPlotType, GroupedConsumptionSeries } from "../types";

type ConsumptionPlotProps = {
  consumptionSeries: GroupedConsumptionSeries;
  selectedFrequency: TimeAggregation | null;
  selectedGrouping: MaterialGroupingName | null;
  selectedPlotType: ConsumptionPlotType;
  setSelectedDate: (date: Date) => void;
};

export const ConsumptionPlot = ({
  consumptionSeries,
  selectedFrequency,
  selectedGrouping,
  selectedPlotType,
  setSelectedDate,
}: ConsumptionPlotProps) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);

  const initialFigData = buildInitialFigData(consumptionSeries);

  const [figure, setFigure] = useState({
    data: initialFigData,
    layout: updateLayout(defaultLayout, selectedPlotType, t, units),
  });

  // Update figure when selected options or plot type change
  useEffect(() => {
    setFigure({
      data: updateFigData(
        figure.data,
        selectedFrequency,
        selectedGrouping,
        selectedPlotType
      ),
      layout: updateLayout(figure.layout, selectedPlotType, t, units),
    });
  }, [selectedFrequency, selectedGrouping, selectedPlotType, t, units]);

  return (
    <div style={{ width: "100%" }}>
      <Plot
        data={figure.data}
        layout={figure.layout}
        // eslint-disable-next-line react/forbid-component-props
        style={{ width: "100%" }}
        useResizeHandler
        onClick={setSelectedDateFromPlotClick(setSelectedDate)}
      />
    </div>
  );
};

const buildInitialFigData = (
  groupedConsumptionSeries: GroupedConsumptionSeries
) => {
  const materialColours = buildMaterialColours(
    groupedConsumptionSeries.items.map((item) => ({
      groupingName: item[0].materialGrouping,
      groupName: item[0].materialGroup,
    }))
  );
  const figData: Partial<PlotData>[] = [];

  for (const [key, series] of groupedConsumptionSeries.items) {
    const colour = getMaterialColour(
      materialColours,
      key.materialGrouping,
      key.materialGroup
    );
    const lineDash = getLineDash(
      Array.from(groupedConsumptionSeries.availableConsumptionSources).indexOf(
        key.consumptionSource
      )
    );
    const markerSymbol = getMarkerSymbol(
      Array.from(groupedConsumptionSeries.availableConsumptionSources).indexOf(
        key.consumptionSource
      )
    );

    // Add mass data
    figData.push({
      x: series.date,
      y: series.consumed_mass,
      type: "scatter",
      mode: "lines+markers",
      visible: false,
      name: key.consumptionSource,
      legendgroup: key.materialGroup,
      legendgrouptitle: {
        text: key.materialGroup,
      },
      line: {
        color: colour,
        dash: lineDash,
      },
      marker: {
        color: colour,
        symbol: markerSymbol,
      },
      customdata: [key.timeAggregation, key.materialGrouping, "mass"],
    });

    // Add fraction data
    figData.push({
      x: series.date,
      y: series.consumed_fraction,
      type: "scatter",
      mode: "lines+markers",
      visible: false,
      name: key.consumptionSource,
      legendgroup: key.materialGroup,
      legendgrouptitle: {
        text: key.materialGroup,
      },
      line: {
        color: colour,
        dash: lineDash,
      },
      marker: {
        color: colour,
        symbol: markerSymbol,
      },
      customdata: [key.timeAggregation, key.materialGrouping, "fraction"],
    });
  }

  return figData;
};

const updateFigData = (
  figData: Partial<PlotData>[],
  selectedFrequency: TimeAggregation | null,
  selectedGrouping: MaterialGroupingName | null,
  selectedPlotType: ConsumptionPlotType
): Partial<PlotData>[] => {
  return figData.map((trace) => ({
    ...trace,
    visible:
      trace.customdata?.[0] === selectedFrequency &&
      trace.customdata?.[1] === selectedGrouping &&
      trace.customdata?.[2] === selectedPlotType,
  }));
};

const updateLayout = (
  currentLayout: Partial<Layout>,
  selectedPlotType: ConsumptionPlotType,
  t: (key: string) => string,
  units: (key: UnitType) => string
): Partial<Layout> => {
  const yaxisTitle =
    selectedPlotType === "mass"
      ? `${t("consumption")}${units("mass")}`
      : `${t("consumption")} (%)`;

  return {
    ...currentLayout,
    yaxis: {
      ...currentLayout.yaxis,
      title: yaxisTitle,
      tickformat:
        selectedPlotType === "fraction"
          ? fractionTickFormatting
          : generalTickFormatting,
    },
  };
};
