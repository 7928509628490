import Plot from "react-plotly.js";
import { Data, Layout } from "plotly.js";
import { defaultLayout, loadingChunks } from "../plotting";
import { OnRelayout } from "../viewportControls";
import { ChunkData } from "../helpers";

export type TrackingEvent = {
  start: Date;
  end: Date;
  text?: string;
  colour?: string;
  track?: string;
};

export const EventPlot = ({
  events,
  start,
  end,
  onRelayout,
  chunks,
  layout,
  onClick,
}: {
  events: TrackingEvent[];
  start: Date;
  end: Date;
  onRelayout?: OnRelayout;
  chunks?: ChunkData<unknown>[];
  layout?: Partial<Layout>;
  onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
}) => {
  const data = events.map(
    (event) =>
      ({
        x: [event.end.getTime() - event.start.getTime()],
        y: [event.track ?? ""],
        base: event.start.getTime(),
        type: "bar",
        orientation: "h",
        text: event.text,
        textposition: "inside",
        marker: { color: event.colour ?? "black" },
      }) as Data
  );

  return (
    <Plot
      data={data}
      layout={{
        ...defaultLayout(),
        title: "Event Timeline",
        xaxis: { range: [start, end], type: "date" },
        height: 200,
        barmode: "stack",
        yaxis: { showticklabels: true, fixedrange: true },
        shapes: loadingChunks(chunks ?? []),
        ...(layout ?? {}),
      }}
      onRelayout={onRelayout}
      style={{ width: "100%" }}
      onClick={onClick}
    />
  );
};
