import { Link, Tooltip, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { usePlanId } from "components/common/boundary/PlanId";
import { ChefGroupDetailView } from "components/common/panels/chefgroup";
import { LoadedProductGroupPanel } from "components/common/panels/productGroup";
import { useTenantTranslation } from "hooks/formatters";
import { useHeatTextFormatter } from "hooks/heats";
import { Period } from "hooks/periodIndex";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { mapLoadedUnpack } from "models/loaded";
import React, { useMemo } from "react";
import { MixBasketSummary } from "src/store/api/generatedApi";
import { Group, usePeriodOption } from "./ProductionContext";
import { useLayering } from "hooks/useLayering";

type BaseProps = {
  heats: number;
  name: string;
  multipleMixes: boolean;
  mixId: number;
  groupId: number;
  groupOption: Group;
  headerType: "row" | "column";
};

type LayeringTableTypeProps = {
  chemistryGroupId: number;
} & BaseProps;

type BasketPlotTypeProps = {
  chemistryGroupName: string;
  mixBasketSummary: MixBasketSummary[];
} & BaseProps;

type BaseHeatHeaderProps = {
  heats: number;
  name: string;
  multipleMixes: boolean;
  mixId: number;
  groupId: number;
  groupOption: Group;
  numBaskets: number | null;
  headerType: "row" | "column";
};

type Props = LayeringTableTypeProps | BasketPlotTypeProps;

export const HeatHeader = ({
  heats,
  name,
  multipleMixes,
  mixId,
  groupId,
  groupOption,
  headerType,
  ...props
}: Props) => {
  if ("chemistryGroupId" in props) {
    return (
      <LayeringTableTypeHeatHeader
        heats={heats}
        name={name}
        multipleMixes={multipleMixes}
        mixId={mixId}
        groupId={groupId}
        groupOption={groupOption}
        headerType={headerType}
        chemistryGroupId={props.chemistryGroupId}
      />
    );
  } else {
    return (
      <BasketPlotTypeHeatHeader
        heats={heats}
        name={name}
        multipleMixes={multipleMixes}
        mixId={mixId}
        groupId={groupId}
        groupOption={groupOption}
        headerType={headerType}
        mixBasketSummary={props.mixBasketSummary}
        chemistryGroupName={props.chemistryGroupName}
      />
    );
  }
};

export const BaseHeatHeader = ({
  heats,
  name,
  multipleMixes,
  mixId,
  groupId,
  groupOption,
  numBaskets,
  headerType,
}: BaseHeatHeaderProps) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();
  const { format } = useNumberSerialiser();
  const planId = usePlanId();
  const period = usePeriodOption();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const heatTextFormatter = useHeatTextFormatter();
  const heatText = `${format(heats)} ${heats === 1 ? t("heat") : t("heats")}`;
  const text = heatTextFormatter(multipleMixes, name, mixId);
  const basketText = React.useMemo(() => {
    if (numBaskets !== null) {
      return `${numBaskets} ${numBaskets === 1 ? t("basket") : t("baskets")}`;
    } else {
      return null;
    }
  }, [numBaskets]);

  return (
    <>
      <Tooltip
        title={`${heatText}${
          basketText !== null ? `- ${basketText}` : ""
        }- ${text}`}
      >
        <Box
          sx={{
            flex: 1,
            minWidth: 0,
            ...(() => {
              switch (headerType) {
                case "column":
                  return {
                    height: "100%",
                  };
                case "row":
                default:
                  return {};
              }
            })(),
          }}
          flexDirection="column"
          justifyContent="flex-start"
          display="flex"
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 400,
              whiteSpace: "nowrap",
            }}
          >
            {heatText} {basketText !== null ? `• ${basketText}` : ""}
          </Typography>{" "}
          <Link
            variant="body2"
            color={theme.palette.text.primary}
            fontWeight="bold"
            sx={{ cursor: "pointer", textWrap: "wrap", width: "100%" }}
            onClick={() => setIsModalOpen(true)}
          >
            {text}
          </Link>
        </Box>
      </Tooltip>
      {groupOption === Group.Chef ? (
        <ChefGroupDetailView
          open={isModalOpen}
          setOpen={setIsModalOpen}
          group={groupId}
          mix={mixId}
          multipleMixes={multipleMixes}
          planId={planId}
          period={period}
        />
      ) : (
        <LoadedProductGroupPanel
          id={groupId}
          open={isModalOpen}
          doClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

const LayeringTableTypeHeatHeader = ({
  heats,
  name,
  multipleMixes,
  mixId,
  groupId,
  groupOption,
  chemistryGroupId,
  headerType,
}: LayeringTableTypeProps) => {
  const planId = usePlanId();

  const key = useMemo(
    () => ({
      planId,
      groupId: chemistryGroupId,
      mixNumber: mixId,
    }),
    [planId, groupId, mixId]
  );

  const layeringSummary = mapLoadedUnpack(
    useLayering(key),
    (layering) => layering.summary
  );

  const numBaskets = useMemo(() => {
    return layeringSummary?.charges
      ? Object.values(layeringSummary.charges).reduce(
          (total, layerSumary) => total + (layerSumary.length > 0 ? 1 : 0),
          0
        )
      : 0;
  }, [layeringSummary]);

  return (
    <BaseHeatHeader
      heats={heats}
      name={name}
      multipleMixes={multipleMixes}
      mixId={mixId}
      groupId={groupId}
      groupOption={groupOption}
      numBaskets={numBaskets}
      headerType={headerType}
    />
  );
};

const BasketPlotTypeHeatHeader = ({
  heats,
  name,
  multipleMixes,
  mixId,
  groupId,
  groupOption,
  chemistryGroupName,
  mixBasketSummary,
  headerType,
}: BasketPlotTypeProps) => {
  const period = usePeriodOption();

  const filterForChefGroup =
    (chefGroupName: string, period: Period, mix?: number) =>
    (item: MixBasketSummary) => {
      return (
        item.chef_group_name === chefGroupName &&
        period === item.period &&
        (mix === undefined || item.mix_number === mix)
      );
    };

  const filteredMixBasketSummary = mixBasketSummary.filter(
    filterForChefGroup(chemistryGroupName, period, mixId)
  );
  const numBaskets = filteredMixBasketSummary.length;

  return (
    <BaseHeatHeader
      heats={heats}
      name={name}
      multipleMixes={multipleMixes}
      mixId={mixId}
      groupId={groupId}
      groupOption={groupOption}
      numBaskets={numBaskets}
      headerType={headerType}
    />
  );
};
