import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

import { AccountPageLayout } from "components/common/AccountPageLayout";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { useDeployedPlans } from "contexts/plan";

import { DeployedPlansTable } from "./deployedPlansTable";
import { useTenantTranslation } from "hooks/formatters";
import { EditBackupPlanModal } from "./EditBackupPlanModal";
import { useCanEditAndSendDeploy } from "hooks/useCanEditAndSendDeploy";

export const DeployedPlansPage = () => {
  const [openEditBackupPlan, setOpenEditBackupPlan] = React.useState(false);
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const canEditAndSendDeploy = useCanEditAndSendDeploy();

  const handleClose = () => {
    setOpenEditBackupPlan(false);
  };

  const handleOpen = () => {
    if (canEditAndSendDeploy) {
      setOpenEditBackupPlan(true);
    }
  };

  return (
    <>
      <AccountPageLayout
        title={t("deployedPlans")}
        actionButton={
          canEditAndSendDeploy ? (
            <Button variant="contained" onClick={handleOpen}>
              {t("editBackupPlan")}
            </Button>
          ) : undefined
        }
      >
        <Box sx={{ mt: 5, mb: 2, minHeight: 0 }}>
          <LoadedContent data={useDeployedPlans()} disableFetching>
            {(plans) =>
              plans.length > 0 ? (
                <DeployedPlansTable plans={plans} />
              ) : (
                <Box
                  sx={{
                    borderRadius: 0.5,
                    px: 2,
                    py: 1,
                    backgroundColor: "grey.100",
                    display: "inline-block",
                    inlineSize: "fit-content",
                  }}
                >
                  <Typography sx={{ color: theme.palette.text.secondary }}>
                    {t("noDeployedPlans")}
                  </Typography>
                </Box>
              )
            }
          </LoadedContent>
        </Box>
      </AccountPageLayout>
      {canEditAndSendDeploy ? (
        <EditBackupPlanModal
          open={openEditBackupPlan}
          doClose={handleClose}
          period={1}
        />
      ) : null}
    </>
  );
};
