import { useTenant } from "hooks/settings";
import { Loaded, loadedEndpoint } from "models/loaded";
import {
  ChemicalElementSymbol,
  ChemicalSampleLocation,
  ChemicalSamplePositionType,
  FailureRateSeries,
  TimeAggregation,
  useGetStrategyDashboardFailureRateQuery,
} from "src/store/api/generatedApi";
import {
  FailureRateTimeSeries,
  FailureRateTimeSeriesKey,
  GroupedFailureRateSeries,
} from "../types";

export const useStrategyDashboardFailureRate =
  (): Loaded<GroupedFailureRateSeries> => {
    const loadedRawData = loadedEndpoint(
      useGetStrategyDashboardFailureRateQuery({ tenantName: useTenant() })
    );
    if (loadedRawData.status === "success") {
      return {
        status: "success",
        data: buildGroupedFailureSeries(loadedRawData.data.series),
        fetching: loadedRawData.fetching,
      };
    }
    return loadedRawData;
  };

const buildGroupedFailureSeries = (
  series: FailureRateSeries[]
): GroupedFailureRateSeries => {
  const stringKeyKeyMap: Record<string, FailureRateTimeSeriesKey> = {};
  const stringKeyValuesMap: Record<string, FailureRateTimeSeries> = {};

  for (const item of series) {
    const key: FailureRateTimeSeriesKey = {
      timeAggregation: item.time_aggregation,
      sampleLocation: item.sample_location,
      samplePositionType: item.sample_position_type,
      chemicalElementSymbol: item.chemical_element_symbol,
    };
    const stringKey = JSON.stringify(key);
    stringKeyKeyMap[stringKey] = key;

    const value = stringKeyValuesMap[stringKey] ?? {
      date: [],
      numFailures: [],
      failureRate: [],
      expectedNumFailures: [],
      expectedFailureRate: [],
      failuresMetadata: [],
    };
    value.date.push(item.sample_date);
    value.numFailures.push(item.num_failures);
    value.failureRate.push(item.failure_rate);
    value.expectedNumFailures.push(item.expected_num_failures);
    value.expectedFailureRate.push(item.expected_failure_rate);
    value.failuresMetadata.push(item.failures_metadata);
    stringKeyValuesMap[stringKey] = value;
  }

  const values: [FailureRateTimeSeriesKey, FailureRateTimeSeries][] =
    Object.entries(stringKeyKeyMap).map(([stringKey, key]) => [
      key,
      stringKeyValuesMap[stringKey]!,
    ]);

  const availableTimeAggregations = new Set<TimeAggregation>(
    series.map((item) => item.time_aggregation)
  );
  const availableSampleLocations = new Set<ChemicalSampleLocation>(
    series.map((item) => item.sample_location)
  );
  const availableSamplePositionTypes = new Set<ChemicalSamplePositionType>(
    series.map((item) => item.sample_position_type)
  );
  const availableChemicalElementSymbols = new Set<ChemicalElementSymbol>(
    series.map((item) => item.chemical_element_symbol)
  );

  return {
    values,
    availableTimeAggregations,
    availableSampleLocations,
    availableSamplePositionTypes,
    availableChemicalElementSymbols,
  };
};
