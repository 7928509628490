import { useTenantData } from "contexts/tenant";
import { SearchPeriodProductionPlan } from "src/store/api/generatedApi";

export const useTenantProductionPlan = (): SearchPeriodProductionPlan => {
  const { default_production_plan_source: defaultProductionPlanSource } =
    useTenantData();
  switch (defaultProductionPlanSource) {
    case "steel_grades":
      return {
        steel_grade_items: [],
        product_group_items: null,
      };
    case "product_groups":
      return {
        steel_grade_items: null,
        product_group_items: [],
      };
  }
};
