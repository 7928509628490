import React from "react";
import { Button } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";

import { useSendChargeScheduleMessageListMutation } from "src/store/api/generatedApi";
import { useAppDispatch, useAppStore } from "src/store/store";
import {
  deployPlanFormSlice,
  selectSMSBackupPlanMessages,
  selectSMSPeriodMessages,
} from "src/store/slices/deployPlanFormSlice";

import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";

type Props = { formPlanId: number };

export const SendSMSButton = ({ formPlanId }: Props) => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const tenantName = useTenant();
  const store = useAppStore();

  const [send, { isLoading }] = useSendChargeScheduleMessageListMutation();

  const handleOnClick = React.useCallback(async () => {
    const periodSMSMessages = selectSMSPeriodMessages(
      store.getState(),
      formPlanId
    );
    const backupPlanSMSMessages = selectSMSBackupPlanMessages(
      store.getState(),
      formPlanId
    );
    if (periodSMSMessages && backupPlanSMSMessages) {
      await send({
        tenantName,
        chargeScheduleMessages: {
          charge_schedule_messages: [
            ...typeSafeObjectEntries(periodSMSMessages).map(
              ([period, message]) => ({
                plan_id: formPlanId,
                period: period,
                text: message,
              })
            ),
            ...typeSafeObjectEntries(backupPlanSMSMessages).map(
              ([planId, message]) => ({
                plan_id: planId,
                period: 1,
                text: message,
              })
            ),
          ],
        },
      });
      dispatch(deployPlanFormSlice.actions.setFinishedForm(formPlanId));
    }
  }, [send, store]);

  return (
    <Button variant="contained" loading={isLoading} onClick={handleOnClick}>
      {t("sendSms")}
    </Button>
  );
};
