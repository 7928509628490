import { Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { LoadedContent } from "components/common/loading/loadedContent";
import { useTenant } from "hooks/settings";
import { loadedEndpoint } from "models/loaded";
import { useGetHeatSummaryQuery } from "src/store/api/generatedApi";
import { ChargeSummaryTable } from "./chargeSummaryTable";
import { HeatSummaryTable } from "./heatSummaryTable";

export const HeatSummaryFocusContent = ({ heatId }: { heatId: string }) => {
  const heatSummary = loadedEndpoint(
    useGetHeatSummaryQuery({ tenantName: useTenant(), heatId })
  );

  return (
    <Stack gap={2}>
      <Typography variant="h2">Heat {heatId}</Typography>

      <LoadedContent
        data={heatSummary}
        waiting={<LinearProgress />}
        loading={<LinearProgress />}
      >
        {(loadedHeatSummary) => {
          const loads = loadedHeatSummary.charges ?? [];
          return (
            <Stack gap={2}>
              <Typography variant="h4" sx={{ color: "#666666" }}>
                Summary
              </Typography>
              <HeatSummaryTable loads={loads} sx={{ maxWidth: 500 }} />
              <Divider sx={{ opacity: 0.2 }} />
              {loads.map((planVsActualLoad, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Stack key={index} gap={2}>
                  <Typography variant="h4" sx={{ color: "#666666" }}>
                    Charge {index + 1}
                  </Typography>
                  <ChargeSummaryTable
                    plan={planVsActualLoad.planned}
                    actual={planVsActualLoad.loaded}
                    sx={{ maxWidth: 500 }}
                  />
                  <Divider sx={{ opacity: 0.2 }} />
                </Stack>
              ))}
            </Stack>
          );
        }}
      </LoadedContent>
    </Stack>
  );
};
