import { Stack } from "@mui/material";
import { sorted } from "helpers";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { TFunction } from "i18next";
import { Annotations, Data } from "plotly.js";
import { FullWidthPlot, PlotConfig } from "src/components/common/plots";
import { materialGroupColours, recipeDecimalPlaces } from "src/constants";
import {
  MixBasketSummary,
  MixMaterialBasketSummary,
} from "src/store/api/generatedApi";
import { BasketsTable } from "./basketsTable";

export const BasketsTab = ({
  mixBasketsSummary,
  mixMaterialBasketsSummary,
}: {
  mixBasketsSummary: MixBasketSummary[];
  mixMaterialBasketsSummary: MixMaterialBasketSummary[];
}) => {
  const { t } = useTenantTranslation();

  const basketsBarChart = useBasketsBarChart(
    mixMaterialBasketsSummary,
    t,
    mixBasketsSummary
  );

  return (
    <Stack spacing={2} sx={{ my: 2 }}>
      <BasketsTable
        materialBasketsSummary={mixMaterialBasketsSummary}
        basketsSummary={mixBasketsSummary}
      />
      {basketsBarChart}
    </Stack>
  );
};

const useBasketsBarChart = (
  materialBasketsSummary: MixMaterialBasketSummary[],
  t: TFunction,
  basketsSummary: MixBasketSummary[]
) => {
  const materialGroupsShown: string[] = [];
  const data: Data[] = [];
  let showLegend;

  const units = useUnitsFormatter(false);
  const brackettedUnits = useUnitsFormatter(true);
  const { format } = useNumberSerialiser({
    decimalPlaces: recipeDecimalPlaces,
  });

  for (const item of sorted(
    materialBasketsSummary,
    (summary) => summary.basket_layer
  )) {
    showLegend = false;
    if (!materialGroupsShown.includes(item.basket_layer)) {
      showLegend = true;
      materialGroupsShown.push(item.basket_layer);
    }
    data.push({
      x: [item.basket_number],
      y: [item.recipe_mass],
      type: "bar",
      marker: {
        color: materialGroupColours[item.basket_layer],
        line: {
          color: "black",
          width: 2,
        },
      },
      showlegend: showLegend,
      legendgroup: t(item.basket_layer),
      text: [item.material],
      hovertemplate: `%{text}<br>%{y:.${recipeDecimalPlaces}f}${units("mass")}`,
      name: t(item.basket_layer),
    });
  }
  const maxBasketMass = Math.max(
    ...basketsSummary.map((item) => item.recipe_mass)
  );

  const basketAnnotationLabel = (item: MixBasketSummary) => {
    const fullTranslation: string = t("full");
    const basketNumberTranslation: string = t("basketNumber");
    return `${basketNumberTranslation} ${item.basket_number}<br>${format(
      item.recipe_mass
    )}${units("mass")}<br>${(100 * item.fullness).toFixed(
      0
    )}% ${fullTranslation}`;
  };

  const annotations: Partial<Annotations>[] = [];
  for (const item of basketsSummary) {
    annotations.push({
      x: item.basket_number,
      y: maxBasketMass + 25,
      text: basketAnnotationLabel(item),
      font: {
        size: 18,
      },
      showarrow: false,
    });
  }

  const recipeMassTranslation: string = t("recipe_mass");
  const layout = {
    barmode: "stack",
    yaxis: {
      range: [0, maxBasketMass + 40],
      title: {
        text: `${recipeMassTranslation}${brackettedUnits("mass")}`,
      },
    },
    xaxis: {
      // hide xaxis ticks and labels
      showticklabels: false,
      showgrid: false,
    },
    legend: {
      orientation: "h",
      yanchor: "bottom",
      x: 0.5,
      y: -0.2,
      xanchor: "center",
    },
    height: 400,
    annotations: annotations,
    margin: {
      t: 0,
      b: 0,
      l: 100,
    },
  };
  const plotConfig: PlotConfig = {
    data: data,
    layout: layout,
  };
  return <FullWidthPlot plotConfig={plotConfig} />;
};
