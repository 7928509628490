import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { RootState } from "store/store";

import { Row } from "components/common/plan/Table";

type PlanMixTableEdits = {
  planId: number;
  editedRows: Row[];
  originalRows: Row[];
};

const planMixTableEditsAdapter = createEntityAdapter({
  selectId: (planMixTableEdits: PlanMixTableEdits) => planMixTableEdits.planId,
});

export const planMixTableEditsSlice = createSlice({
  name: "planMixTableEdits",
  initialState: planMixTableEditsAdapter.getInitialState(undefined, []),
  reducers: {
    set(state, { payload: [planId, rows] }: PayloadAction<[number, Row[]]>) {
      planMixTableEditsAdapter.setOne(state, {
        planId,
        originalRows: rows,
        editedRows: rows,
      });
    },
    update(
      state,
      { payload: [planId, editedRows] }: PayloadAction<[number, Row[]]>
    ) {
      planMixTableEditsAdapter.updateOne(state, {
        id: planId,
        changes: { editedRows },
      });
    },
    clear(state, { payload }: PayloadAction<number>) {
      planMixTableEditsAdapter.removeOne(state, payload);
    },
  },
});

const createAppSelector = createSelector.withTypes<RootState>();

export const selectPlanMixTableEdits = createAppSelector(
  [(state) => state.planMixTableEdits, (_, planId: number) => planId],
  (planMixTableEdits, planId) => {
    return planMixTableEditsAdapter
      .getSelectors()
      .selectById(planMixTableEdits, planId);
  }
);
