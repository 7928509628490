import {
  Alert,
  Box,
  Grid,
  LinearProgress,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { PlanId } from "components/common/boundary/PlanId";
import { LoadedContent } from "components/common/loading/loadedContent";
import { useMaterials } from "contexts/search/provider";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { t } from "i18next";
import { mapLoaded } from "models/loaded";
import React, { useMemo } from "react";
import { MaterialDetailDrawer } from "../material";
import { LayeringSummary, useLayering } from "hooks/useLayering";

export const LayeringTab = ({
  planId,
  groupId,
  mixNumber,
}: {
  planId: PlanId;
  groupId: number;
  mixNumber: number;
}) => {
  const key = useMemo(
    () => ({
      planId,
      groupId,
      mixNumber,
    }),
    [planId, groupId, mixNumber]
  );

  const layering = useLayering(key);

  return (
    <Box sx={{ pt: 2 }}>
      <LoadedContent
        data={mapLoaded(layering, ({ summary }) => summary)}
        error={<Alert severity="error">{t("errorLoadingLayering")}</Alert>}
        loading={<LinearProgress />}
      >
        {(loadedLayering) => (
          <Grid container spacing={3}>
            <PreviewCharge
              layering={loadedLayering}
              chargeNumber={1}
              title={t("firstBasket")}
              displayPercentage
            />
            <PreviewCharge
              layering={loadedLayering}
              chargeNumber={2}
              title={t("secondBasket")}
            />
            <PreviewCharge
              layering={loadedLayering}
              chargeNumber={3}
              title={t("thirdBasket")}
            />
          </Grid>
        )}
      </LoadedContent>
    </Box>
  );
};

type Row = {
  id: number;
  percentage: string;
  description: string;
  weight: null | string;
  total: null | string;
};

const PreviewCharge = ({
  layering,
  chargeNumber,
  title,
  displayPercentage,
}: {
  layering: LayeringSummary;
  chargeNumber: 1 | 2 | 3;
  title: string;
  displayPercentage?: boolean;
}) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(false);
  const { format } = useNumberSerialiser({
    default: { value: null, text: "" },
  });
  const materials = useMaterials();
  const theme = useTheme();

  const rows = layering.charges[chargeNumber].map((layer) => ({
    id: layer.step,
    percentage: layer.firstInCharge
      ? format(layering.materialFractions[layer.description]! * 100 || null)
      : "",
    description: layer.description,
    weight: format(layer.weight || null),
    total: format(layer.total || null),
  }));

  const columns: GridColDef<Row>[] = [
    {
      field: "id",
      headerName: t("step"),
      flex: 1,
      sortable: false,
    },
    {
      field: "percentage",
      headerName: units("mass_fraction"),
      flex: 1,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "description",
      headerName: t("description"),
      flex: 20,
      sortable: false,
      renderCell: (params) => {
        const {
          row: { description },
        } = params;
        if (
          materials.status === "success" &&
          materials.data.byName[description]
        ) {
          return (
            <MaterialCell
              id={materials.data.byName[description]!.id}
              name={description}
            />
          );
        } else {
          return <Typography variant="body2">{description}</Typography>;
        }
      },
    },
    {
      field: "weight",
      headerName: `${t("weight")} (lbs)`,
      flex: 10,
      sortable: false,
      renderCell: ({ value }) => (
        <Typography variant="body1Mono">{value}</Typography>
      ),
      align: "right",
      headerAlign: "right",
    },
    {
      field: "total",
      headerName: `${t("total")} (lbs)`,
      flex: 10,
      sortable: false,
      renderCell: ({ value }) => (
        <Typography variant="body1Mono">{value}</Typography>
      ),
      align: "right",
      headerAlign: "right",
    },
  ];

  return (
    <Grid item sm={12} lg={4}>
      <Typography sx={{ mb: 0.5 }}>{title}</Typography>

      {rows.length === 0 ? (
        <Box
          sx={{
            height: 150,
            border: 1,
            borderRadius: 1,
            borderColor: "grey.100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.disabled }}
          >
            {t("noContents")}
          </Typography>
        </Box>
      ) : (
        <DataGridPremium
          autoHeight
          getRowHeight={() => "auto"}
          rows={rows}
          columns={columns}
          hideFooter
          initialState={{
            columns: {
              columnVisibilityModel: {
                percentage: Boolean(displayPercentage),
              },
            },
          }}
        />
      )}
    </Grid>
  );
};

type MaterialCellProps = {
  id: number;
  name: string;
};

const MaterialCell = ({ id, name }: MaterialCellProps) => {
  const theme = useTheme();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Link
        variant="body2"
        color={theme.palette.text.primary}
        fontWeight="bold"
        sx={{ cursor: "pointer" }}
        onClick={() => setShowModal(true)}
      >
        {name}
      </Link>
      <MaterialDetailDrawer
        title={name}
        id={id}
        open={showModal}
        doClose={() => setShowModal(false)}
      />
    </>
  );
};
