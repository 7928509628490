import { Alert, CircularProgress, Stack, Typography } from "@mui/material";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useShortLanguage, useTenant } from "hooks/settings";
import {
  SupportedLanguage,
  useGetRecipesImpuritiesPlotRiskQuery,
} from "src/store/api/generatedApi";
import { Fetching } from "src/components/common/loading/fetching";
import { FullWidthPlot, PlotConfig } from "src/components/common/plots";

export const ImpuritiesTab = ({
  planId,
  mixId,
  period,
}: {
  planId: number;
  mixId: number;
  period: number;
}) => {
  const language = useShortLanguage();
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);

  const parameters = {
    tenantName: useTenant(),
    planId: planId,
    mixId: mixId,
    period: period,
    language: language as SupportedLanguage,
  };

  const impurities = useGetRecipesImpuritiesPlotRiskQuery(parameters);
  return (
    <Stack alignItems="center">
      {impurities.isLoading ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : impurities.isError ? (
        <Alert severity="error">{t("errorLoadingPlot")}</Alert>
      ) : (
        <Fetching fetching={impurities.isFetching}>
          <Typography variant="h5" marginTop={2}>
            {t("massFraction")} {units("mass_fraction")}
          </Typography>
          <FullWidthPlot
            plotConfig={JSON.parse(impurities.data!) as PlotConfig}
          />
        </Fetching>
      )}
    </Stack>
  );
};
