import { Loaded, loadedEndpoint, mapLoaded } from "models/loaded";
import {
  BasketMaterialLimitBounds,
  BasketRead,
  ChefGroup,
  ChefGroupBasketMaterialLimitRead,
  ChemicalElement,
  MaterialChemistry,
  MaterialLimitCoefficientCreate,
  MaterialPhysics,
  MaterialRead,
  MixMaterialLimitRead,
  ProductGroup,
  SteelGrade as SteelGradeType,
  useGetSearchContextQuery,
} from "src/store/api/generatedApi";
import { useTenant } from "hooks/settings";
import {
  EntityMapping,
  createMapping,
  createNestedMapping,
  sorted,
} from "helpers";
import React from "react";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import { useMixMaterialLimitsSlice } from "src/store/hooks/mixMaterialLimits";
import { useChefGroupBasketMaterialLimitsSlice } from "src/store/hooks/chefGroupBasketMaterialLimits";
import { useAppSelector } from "src/store/store";
import { skipToken } from "@reduxjs/toolkit/query/react";

export type SyncedContextData = {
  productGroupsId: number | null;
  steelGradesId: number;
  materialsId: number;
  materialPhysicsId: number;
  fixedMixSetId: number;

  productGroups: SearchProductGroups;
  chemistryGroups: SearchChemistryGroups;
  steelGrades: SearchSteelGrades;

  materials: SearchMaterials;
  materialPhysics: SearchMaterialPhysics;
  materialChemistry: SearchMaterialChemistry;

  mixMaterialLimits: MixMaterialLimit[];

  baskets: SearchBaskets;
  chemicalElements: SearchChemicalElements;

  hasDefaultSets: boolean;
};

export type SyncedContextState = Loaded<SyncedContextData>;

export type ProductGroupId = number & { __productGroupId__: true };
export type SearchProductGroups = EntityMapping<
  ProductGroup & { id: ProductGroupId }
> & { id: number };

export type ChemistryGroupId = number & { __chemistryGroupId__: true };
export type SearchChemistryGroups = EntityMapping<
  ChefGroup & { id: ChemistryGroupId }
>;

export type SteelGradesId = number & { __steelGradesId__: true };
export type SteelGrade = SteelGradeType & { id: SteelGradesId };
export type SearchSteelGrades = EntityMapping<SteelGrade> & { id: number };

export type MaterialsId = number & { __materialsId__: true };
export type SearchMaterials = EntityMapping<
  MaterialRead & {
    id: MaterialsId;
  }
>;

export type MixMaterialLimitId = number & { __mixMaterialLimitId__: true };
export type MixMaterialLimit = MixMaterialLimitRead & {
  id: MixMaterialLimitId;
};

export type BasketMaterialLimitId = number & {
  __basketMaterialLimitId__: true;
};
export type ChefGroupBasketMaterialLimitId = number & {
  __chefGroupBasketMaterialLimitId__: true;
};
export type FlattenedChefGroupBasketMaterialLimitId = string & {
  __flattenedChefGroupBasketMaterialLimitId__: true;
};

export type ChefGroupBasketMaterialLimit = ChefGroupBasketMaterialLimitRead & {
  id: ChefGroupBasketMaterialLimitId;
};
export type FlattenedChefGroupBasketMaterialLimit = Omit<
  BasketMaterialLimitBounds,
  "basket"
> & {
  basket_number: number;
  basket_volume: number;
  id: FlattenedChefGroupBasketMaterialLimitId;
  chef_group_id: number;
  chef_group_basket_material_limit_id: ChefGroupBasketMaterialLimitId;
  basket_material_limit_id: BasketMaterialLimitId;
  coefficients: MaterialLimitCoefficientCreate[];
  hardness: number;
  name: string;
  coefficients_signature: string;
};

export type SearchMaterialPhysics = Omit<
  EntityMapping<MaterialPhysics>,
  "byName"
>;
export type SearchMaterialChemistry = {
  [materialId: number]: { [elementId: number]: MaterialChemistry };
};

export type SearchBaskets = BasketRead[];
export type SearchChemicalElements = EntityMapping<ChemicalElement>;

const flattenChefGroupBasketMaterialLimits = (
  chefGroupBasketMaterialLimit: ChefGroupBasketMaterialLimit
): FlattenedChefGroupBasketMaterialLimit[] => {
  return chefGroupBasketMaterialLimit.basket_material_limit.bounds.map(
    (bound) => ({
      id: createFlattenedChefGroupBasketMaterialLimitId(
        chefGroupBasketMaterialLimit.id,
        chefGroupBasketMaterialLimit.basket_material_limit
          .id as BasketMaterialLimitId,
        bound.basket_id
      ),
      basket_id: bound.basket_id,
      basket_number: bound.basket.basket_number,
      basket_volume: bound.basket.basket_volume,
      max_mass: bound.max_mass,
      min_mass: bound.min_mass,
      soft_max_mass: bound.soft_max_mass,
      soft_min_mass: bound.soft_min_mass,
      chef_group_id: chefGroupBasketMaterialLimit.chef_group_id,
      chef_group_basket_material_limit_id: chefGroupBasketMaterialLimit.id,
      basket_material_limit_id: chefGroupBasketMaterialLimit
        .basket_material_limit.id as BasketMaterialLimitId,
      coefficients:
        chefGroupBasketMaterialLimit.basket_material_limit.coefficients,
      hardness: chefGroupBasketMaterialLimit.basket_material_limit.hardness,
      name: chefGroupBasketMaterialLimit.basket_material_limit.name,
      coefficients_signature:
        chefGroupBasketMaterialLimit.basket_material_limit
          .coefficients_signature,
    })
  );
};

export const createFlattenedChefGroupBasketMaterialLimitId = (
  chefGroupBasketMaterialLimitId: ChefGroupBasketMaterialLimitId,
  basketMaterialLimitId: BasketMaterialLimitId,
  basketId: number
) =>
  `${chefGroupBasketMaterialLimitId}.${basketMaterialLimitId}.${basketId}` as FlattenedChefGroupBasketMaterialLimitId;

export const useSyncedContextState = (
  searchContextId: number | null
): SyncedContextState => {
  const tenant = useTenant();
  const { dump: dumpMixMaterialLimits } = useMixMaterialLimitsSlice();
  const { dump: dumpChefGroupBasketMaterialLimits } =
    useChefGroupBasketMaterialLimitsSlice();
  const mixMaterialLimitSetId = useAppSelector(
    (state) => state.mixMaterialLimits.mixMaterialLimitSetId
  );
  const chefGroupBasketMaterialLimitSetId = useAppSelector(
    (state) =>
      state.chefGroupBasketMaterialLimits.chefGroupBasketMaterialLimitSetId
  );

  const contextState = mapLoaded(
    loadedEndpoint(
      useGetSearchContextQuery(
        searchContextId == null
          ? skipToken
          : {
              tenantName: tenant,
              searchContextId,
            }
      )
    ),
    ({
      has_default_sets,
      product_groups_id,
      steel_grades_id,
      materials_id,
      material_physics_id,
      product_groups,
      chef_groups,
      steel_grades,
      materials,
      material_physics,
      material_chemistry,
      mix_material_limits,
      baskets,
      chemical_elements,
      mix_material_limit_set_id,
      chef_group_basket_material_limit_set,
      chef_group_basket_material_limit_set_id,
      fixed_mix_set_id,
    }) => {
      const taggedProductGroups = product_groups.map(
        ({ id, ...productGroup }) => ({
          id: id as ProductGroupId,
          ...productGroup,
        })
      );

      const taggedChemistryGroups = chef_groups.map(({ id, ...chefGroup }) => ({
        id: id as ChemistryGroupId,
        ...chefGroup,
      }));

      const taggedSteelGrades = steel_grades.map(({ id, ...steel_grade }) => ({
        id: id as SteelGradesId,
        ...steel_grade,
      }));

      const taggedMaterials = materials.map(({ id, ...materials }) => ({
        id: id as MaterialsId,
        ...materials,
      }));

      const taggedMixMaterialLimits = mix_material_limits.map(
        ({ id, ...mixMaterialLimit }) => ({
          ...mixMaterialLimit,
          id: id as MixMaterialLimitId,
        })
      );
      const taggedChefGroupBasketMaterialLimits =
        chef_group_basket_material_limit_set.chef_group_basket_material_limits
          .map(({ id, ...chefGroupBasketMaterialLimit }) => ({
            ...chefGroupBasketMaterialLimit,
            id: id as ChefGroupBasketMaterialLimitId,
          }))
          .flatMap(flattenChefGroupBasketMaterialLimits);

      return {
        hasDefaultSets: has_default_sets,
        productGroupsId: product_groups_id,
        steelGradesId: steel_grades_id,
        materialsId: materials_id,
        materialPhysicsId: material_physics_id,
        mixMaterialLimitSetId: mix_material_limit_set_id,
        chefGroupBasketMaterialLimitSetId:
          chef_group_basket_material_limit_set_id,
        fixedMixSetId: fixed_mix_set_id,
        productGroups: {
          byIndex: sorted(taggedProductGroups, (item) => item.name),
          byId: createMapping(taggedProductGroups, (item) => item.id),
          byName: createMapping(taggedProductGroups, (item) => item.name),
          id: product_groups_id!,
          __productGroups__: true,
        },
        chemistryGroups: {
          byIndex: sorted(taggedChemistryGroups, (item) => item.name),
          byId: createMapping(taggedChemistryGroups, (item) => item.id),
          byName: createMapping(taggedChemistryGroups, (item) => item.name),
        },
        steelGrades: {
          byIndex: sorted(taggedSteelGrades, (item) => item.name),
          byId: createMapping(taggedSteelGrades, (item) => item.id),
          byName: createMapping(taggedSteelGrades, (item) => item.name),
          id: steel_grades_id,
        },
        materials: {
          byIndex: sorted(taggedMaterials, (item) => item.ordering),
          byId: createMapping(taggedMaterials, (material) => material.id),
          byName: createMapping(taggedMaterials, (material) => material.name),
        },
        materialPhysics: {
          byIndex: material_physics,
          byId: createMapping(
            material_physics,
            (material) => material.material_id
          ),
        },
        materialChemistry: createNestedMapping(
          material_chemistry,
          (item) => item.material_id,
          (item) => item.chemical_element_id
        ),
        mixMaterialLimits: taggedMixMaterialLimits,
        chefGroupBasketMaterialLimits: taggedChefGroupBasketMaterialLimits,
        baskets,
        chemicalElements: {
          byIndex: chemical_elements,
          byId: createMapping(chemical_elements, (item) => item.id),
          byName: createMapping(chemical_elements, (item) => item.name),
        },
      };
    }
  );

  React.useEffect(() => {
    if (contextState.status === "success") {
      if (contextState.data.mixMaterialLimitSetId !== mixMaterialLimitSetId) {
        const {
          data: { mixMaterialLimitSetId },
        } = contextState;
        dumpMixMaterialLimits(
          mixMaterialLimitSetId,
          typeSafeObjectFromEntries(
            contextState.data.mixMaterialLimits.map((mixMaterialLimit) => [
              mixMaterialLimit.id,
              mixMaterialLimit,
            ])
          )
        );
      }
      if (
        contextState.data.chefGroupBasketMaterialLimitSetId !==
        chefGroupBasketMaterialLimitSetId
      ) {
        dumpChefGroupBasketMaterialLimits(
          contextState.data.chefGroupBasketMaterialLimitSetId,
          typeSafeObjectFromEntries(
            contextState.data.chefGroupBasketMaterialLimits.map(
              (chefGroupBasketMaterialLimit) => [
                chefGroupBasketMaterialLimit.id,
                chefGroupBasketMaterialLimit,
              ]
            )
          )
        );
      }
    }
  }, [contextState]);

  return contextState;
};
