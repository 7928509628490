import { sorted } from "helpers";
import { usePeriodIndex } from "hooks/periodIndex";
import { useTenant } from "hooks/settings";
import { useMutationAsQuery } from "hooks/state/mutation";
import { Loaded, mapLoaded } from "models/loaded";
import { useMemo } from "react";
import {
  GetLayeringApiArg,
  PublishedChargeScheduleLayeringItem,
  useGetLayeringMutation,
} from "store/api/generatedApi";

export type LayeringKey = {
  planId: number;
  groupId: number;
  mixNumber: number;
};

export type LayeringSummary = {
  layering: PublishedChargeScheduleLayeringItem[];

  totalWeight: number;
  charges: {
    1: LayerSummary[];
    2: LayerSummary[];
    3: LayerSummary[];
  };
  materialFractions: { [material: string]: number };
};

export type LayerSummary = {
  /* The layer number (1 for the bottom of the basket) */
  step: number;
  /* Customer's name for the scrap grade */
  description: string;
  /* Amount of mass to be added in this layer, in lbs */
  weight: number;
  /* Total weight of all layers up to and including this one, in lbs */
  total: number;
  /* Whether or not this layer is the first time its material has appeared in
    the charge */
  firstInCharge: boolean;
};

const formatLayering = (
  layering: PublishedChargeScheduleLayeringItem[]
): LayeringSummary => {
  const totalWeight = layering
    .map((layer) => layer.Weight)
    .reduce((left, right) => left + right, 0);

  return {
    layering,
    totalWeight,
    charges: {
      1: formatLayeringCharge(layering, 1),
      2: formatLayeringCharge(layering, 2),
      3: formatLayeringCharge(layering, 3),
    },
    materialFractions: formatMaterialFractions(layering, totalWeight),
  };
};

const formatLayeringCharge = (
  layering: PublishedChargeScheduleLayeringItem[],
  chargeNumber: 1 | 2 | 3
): LayerSummary[] => {
  // Take only the layers for this charge
  const layers = sorted(
    layering.filter((layer) => layer.ChargeNum === chargeNumber),
    (layer) => layer.LayerNum
  );

  return layers.map((layer) => ({
    step: layer.LayerNum,
    description: layer.Description,
    weight: layer.Weight,
    scrapId: layer.ScrapID,
    total: layers
      .filter(({ LayerNum }) => LayerNum <= layer.LayerNum)
      .map(({ Weight }) => Weight)
      .reduce((left, right) => left + right, 0),
    firstInCharge: !layers.some(
      ({ LayerNum, ScrapID }) =>
        LayerNum < layer.LayerNum && ScrapID === layer.ScrapID
    ),
  }));
};

const formatMaterialFractions = (
  layering: PublishedChargeScheduleLayeringItem[],
  totalWeight: number
) => {
  const materials = Array.from(
    new Set(layering.map(({ Description }) => Description))
  );

  return Object.fromEntries(
    materials.map((material) => [
      material,
      layering
        .filter(({ Description }) => Description === material)
        .map(({ Weight }) => Weight)
        .reduce((left, right) => left + right, 0) / totalWeight,
    ])
  );
};

export const useLayering = (
  key: LayeringKey
): Loaded<{ key: LayeringKey; summary: LayeringSummary }> => {
  const tenant = useTenant();
  const period = usePeriodIndex();

  const [getLayering] = useGetLayeringMutation();

  const inputs = useMemo(
    () => ({
      mutation: (input: GetLayeringApiArg) => getLayering(input).unwrap(),
      arguments: {
        tenantName: tenant,
        period: period,
        ...key,
      },
    }),
    [getLayering, key, tenant, period]
  );

  const layering = useMutationAsQuery(inputs);

  return useMemo(
    () =>
      mapLoaded(layering, (result) => ({
        key,
        summary: formatLayering(result.layering),
      })),
    [key, layering]
  );
};
