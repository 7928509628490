import { Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import {
  DataGridPremium,
  GridColDef,
  GridPinnedRowsProp,
} from "@mui/x-data-grid-premium";
import { useTenantData } from "contexts/tenant";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useHeatTextFormatter } from "hooks/heats";
import {
  ChefGroup,
  MaterialRead,
  MixBasketSummary,
  PeriodMaterialSummary,
  ProductGroup,
} from "src/store/api/generatedApi";
import { TitleColumnHeader } from "../common/TitleColumnHeader";
import { BaseHeatHeader, HeatHeader } from "./HeatHeader";
import { AggregationCell, VisualisedCell } from "./ProductionCell";
import { PlanGroup, PlanMix, PlanMixes } from "./productionContent";
import { Group, Measure } from "./ProductionContext";
import { ScrapColumnHeader } from "./ScrapColumnHeader";
import { Period } from "hooks/periodIndex";

type RowData = Record<number, PlanMix & { id: number }>;

type AggregationRowId = "total_mass" | "total_cost";

type Heat = {
  heats: number;
  name: string;
  mixId: number;
  groupId: number;
  multipleMixes: boolean;
  hasDetails: boolean;
};

type HeatRow = {
  id: number;
  heat: Heat;
  data: RowData;
};

type AggregationRow = { id: AggregationRowId; data: Record<number, number> };

type Row = HeatRow | AggregationRow;

type Props = {
  periodMaterialSummaries: PeriodMaterialSummary[];
  materials: MaterialRead[];
  group: Group;
  period: Period;
  measure: Measure;
  steelGrades: Set<string>;
  planGroups: PlanGroup[];
  grouping: Record<number, ProductGroup> | Record<number, ChefGroup>;
  chemistryGroups: Record<number, ChefGroup>;
  mixBasketSummaries: MixBasketSummary[];
  planMixes: PlanMixes;
  sx: SxProps<Theme>;
};

const useLayout = (
  periodMaterialSummaries: PeriodMaterialSummary[],
  materials: MaterialRead[],
  group: Group,
  period: Period,
  measure: Measure,
  steelGrades: Set<string>,
  planGroups: PlanGroup[],
  grouping: Record<number, ProductGroup> | Record<number, ChefGroup>,
  chemistryGroups: Record<number, ChefGroup>,
  mixBasketSummaries: MixBasketSummary[],
  planMixes: PlanMixes
) => {
  const { basket_tab_type: basketTabType } = useTenantData();
  const { t } = useTenantTranslation();
  const unit = useUnitsFormatter(true);

  const haveDetails = group !== Group.Product && period === 1;

  const groupings: [ProductGroup, PlanGroup][] = planGroups
    .map((planGroup): [ProductGroup, PlanGroup] => [
      grouping[planGroup.groupId]!,
      planGroup,
    ])
    .filter(
      ([grouping]) =>
        steelGrades.size === 0 ||
        grouping.steel_grades.some(({ name }) => steelGrades.has(name))
    );
  const heatTextFormatter = useHeatTextFormatter();

  const heatColumn: GridColDef<Row> = {
    field: "heat",
    minWidth: 200,
    headerClassName: "group--header",
    cellClassName: "group--header",
    renderHeader: () => <TitleColumnHeader title={t("heat")} />,
    valueGetter: (_, row: Row) => {
      switch (row.id) {
        case "total_mass":
          return `${t("totalMass")}${unit("mass")}`;
        case "total_cost":
          return `${t("totalPrice")}${unit("cost")}}`;
        default: {
          return heatTextFormatter(
            row.heat.multipleMixes,
            row.heat.name,
            row.heat.mixId
          );
        }
      }
    },
    renderCell: ({ row }) => {
      switch (row.id) {
        case "total_mass": {
          return (
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              {`${t("totalMass")}${unit("mass")}`}
            </Typography>
          );
        }
        case "total_cost": {
          return (
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              {`${t("totalPrice")}${unit("cost")}`}
            </Typography>
          );
        }
        default: {
          const chemistryGroup = chemistryGroups[row.heat.groupId];
          if (chemistryGroup) {
            switch (basketTabType) {
              case "layering_table":
                return (
                  <HeatHeader
                    heats={row.heat.heats}
                    mixId={row.heat.mixId}
                    multipleMixes={row.heat.multipleMixes}
                    name={row.heat.name}
                    groupId={row.heat.groupId}
                    groupOption={group}
                    chemistryGroupId={chemistryGroup.id!}
                    headerType="column"
                  />
                );
              case "basket_plot":
                return (
                  <HeatHeader
                    heats={row.heat.heats}
                    mixId={row.heat.mixId}
                    multipleMixes={row.heat.multipleMixes}
                    name={row.heat.name}
                    groupId={row.heat.groupId}
                    groupOption={group}
                    chemistryGroupName={chemistryGroup.name}
                    mixBasketSummary={mixBasketSummaries}
                    headerType="column"
                  />
                );
            }
          } else {
            return (
              <BaseHeatHeader
                heats={row.heat.heats}
                mixId={row.heat.mixId}
                multipleMixes={row.heat.multipleMixes}
                name={row.heat.name}
                groupId={row.heat.groupId}
                groupOption={group}
                numBaskets={null}
                headerType="column"
              />
            );
          }
        }
      }
    },
  };

  const materialColumns: GridColDef<Row>[] = materials.map(
    (material, index) => ({
      field: "group_" + index.toString(),
      sortable: false,
      minWidth: 132,
      headerClassName: "group--header",
      renderHeader: () => {
        return <ScrapColumnHeader name={material.name} id={material.id} />;
      },
      valueGetter: (_, { id: rowId, data }) => {
        switch (rowId) {
          case "total_mass":
          case "total_cost": {
            return null;
          }
          default: {
            const datum = data[material.id];
            if (datum) {
              const { materials, total, id } = datum;
              const value = materials[id];
              if (value !== undefined) {
                switch (measure) {
                  case Measure.Mass: {
                    return value;
                  }
                  case Measure.Percentage: {
                    return value / total;
                  }
                }
              } else {
                return null;
              }
            } else {
              return null;
            }
          }
        }
      },
      renderCell: ({ row }) => {
        switch (row.id) {
          case "total_mass": {
            const total = row.data[material.id]!;
            return <AggregationCell value={total} variant="mass" />;
          }
          case "total_cost": {
            const cost = row.data[material.id]!;
            return <AggregationCell value={cost} variant="cost" />;
          }
          default: {
            const {
              materials,
              total,
              id: materialId,
              maximum,
            } = row.data[material.id]!;
            const value = materials[materialId];
            if (value) {
              return (
                <VisualisedCell
                  value={materials[materialId]!}
                  total={total}
                  maximum={maximum}
                />
              );
            } else {
              return null;
            }
          }
        }
      },
    })
  );

  const rows: HeatRow[] = groupings.map(([{ name }, group], index) => {
    const data = materials.reduce((row, material) => {
      const summary = planMixes[group.groupId]![group.mixId];
      return {
        ...row,
        [material.id]: { ...summary, id: material.id },
      };
    }, {});
    return {
      id: index,
      heat: {
        ...group,
        hasDetails: haveDetails,
        name,
      },
      data,
    };
  });

  const columns = [heatColumn, ...materialColumns];

  const pinnedRows: GridPinnedRowsProp<AggregationRow> = {
    bottom: rows[0]
      ? [
          {
            id: "total_mass",
            data: materials.reduce((mapping, material) => {
              const materialSummary = periodMaterialSummaries.find(
                (summary) => summary.material === material.name
              );
              return {
                ...mapping,
                [material.id]: materialSummary
                  ? materialSummary.mass_consumed
                  : 0,
              };
            }, {}),
          },
          {
            id: "total_cost",
            data: materials.reduce((mapping, material) => {
              const materialSummary = periodMaterialSummaries.find(
                (summary) => summary.material === material.name
              );
              return {
                ...mapping,
                [material.id]: materialSummary
                  ? materialSummary.cost_consumed
                  : 0,
              };
            }, {}),
          },
        ]
      : [],
  };
  return {
    columns,
    rows,
    pinnedRows,
    pinnedColumns: {
      left: ["heat"],
    },
    rowHeight: 52,
    columnHeaderHeight: 32,
  };
};

export const ScrapXHeatYTable = ({
  materials,
  group,
  period,
  measure,
  steelGrades,
  planGroups,
  grouping,
  periodMaterialSummaries,
  mixBasketSummaries,
  chemistryGroups,
  planMixes,
  sx,
}: Props) => {
  const {
    pinnedColumns,
    columns,
    pinnedRows,
    rows,
    columnHeaderHeight,
    rowHeight,
  } = useLayout(
    periodMaterialSummaries,
    materials,
    group,
    period,
    measure,
    steelGrades,
    planGroups,
    grouping,
    chemistryGroups,
    mixBasketSummaries,
    planMixes
  );

  return (
    <DataGridPremium
      sx={sx}
      pinnedColumns={pinnedColumns}
      columns={columns}
      pinnedRows={pinnedRows}
      rows={rows}
      columnHeaderHeight={columnHeaderHeight}
      rowHeight={rowHeight}
      cellSelection
      ignoreValueFormatterDuringExport
    />
  );
};
