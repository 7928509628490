import { useTenant } from "hooks/settings";
import { Loaded, loadedEndpoint } from "models/loaded";
import {
  ConsumptionSeries,
  MaterialGroupingName,
  TimeAggregation,
  useGetStrategyDashboardConsumptionQuery,
} from "src/store/api/generatedApi";
import {
  ConsumptionSource,
  ConsumptionTimeSeries,
  ConsumptionTimeSeriesKey,
  GroupedConsumptionSeries,
} from "../types";

export const useStrategyDashboardConsumption =
  (): Loaded<GroupedConsumptionSeries> => {
    const loadedRawData = loadedEndpoint(
      useGetStrategyDashboardConsumptionQuery({ tenantName: useTenant() })
    );
    if (loadedRawData.status === "success") {
      return {
        status: "success",
        data: buildGroupedConsumptionSeries(loadedRawData.data.series),
        fetching: loadedRawData.fetching,
      };
    }
    return loadedRawData;
  };

const buildGroupedConsumptionSeries = (
  series: ConsumptionSeries[]
): GroupedConsumptionSeries => {
  const stringKeyKeyMap: Record<string, ConsumptionTimeSeriesKey> = {};
  const stringKeyValuesMap: Record<string, ConsumptionTimeSeries> = {};

  for (const item of series) {
    const key: ConsumptionTimeSeriesKey = {
      timeAggregation: item.time_aggregation,
      consumptionSource: item.consumption_source,
      materialGrouping: item.material_grouping_name,
      materialGroup: item.material_group_name,
    };
    const stringKey = JSON.stringify(key);
    stringKeyKeyMap[stringKey] = key;

    const value = stringKeyValuesMap[stringKey] ?? {
      date: [],
      consumed_mass: [],
      consumed_fraction: [],
    };
    value.date.push(item.consumption_date);
    value.consumed_mass.push(item.consumed_mass);
    value.consumed_fraction.push(item.consumed_fraction);
    stringKeyValuesMap[stringKey] = value;
  }

  const values: [ConsumptionTimeSeriesKey, ConsumptionTimeSeries][] =
    Object.entries(stringKeyKeyMap).map(([stringKey, key]) => [
      key,
      stringKeyValuesMap[stringKey]!,
    ]);

  const availableTimeAggregations = new Set<TimeAggregation>(
    series.map((item) => item.time_aggregation)
  );
  const availableMaterialGroupings = new Set<MaterialGroupingName>(
    series.map((item) => item.material_grouping_name)
  );
  const availableConsumptionSources = new Set<ConsumptionSource>(
    series.map((item) => item.consumption_source)
  );

  return {
    items: values,
    availableTimeAggregations,
    availableMaterialGroupings,
    availableConsumptionSources,
  };
};
