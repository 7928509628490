import { Box, useTheme } from "@mui/material";
import { mapLoadedUnpack } from "models/loaded";
import { useState } from "react";
import { HeatSummary } from "src/store/api/generatedApi";
import { computeLayers } from "../loadsView";
import { ShiftLeaderboard } from "./leaderboard";
import { HeatBreakdown } from "./heatBreakdown";
import { RecentHeats } from "./recentHeats";
import { useHeats } from "./hooks";

export const ReviewRecentHeatsPage = () => {
  const [heatId, setHeatId] = useState<string | null>(null);
  const theme = useTheme();

  const heats = useHeats();

  return (
    <Box sx={{ display: "flex", height: 1, overflowY: "hidden" }}>
      <Box sx={{ flex: 2, overflowY: "auto" }}>
        <RecentHeats heats={heats} heatId={heatId} setHeatId={setHeatId} />
      </Box>
      <Box
        sx={{
          flex: 3,
          overflowY: "auto",
          borderLeft: `1px solid ${theme.palette.common.black}`,
          backgroundColor: theme.palette.grey[50],
          padding: 2,
        }}
      >
        {heatId != null ? (
          <HeatBreakdown key={heatId} heatId={heatId} setHeatId={setHeatId} />
        ) : (
          <ShiftLeaderboard
            shiftHeats={mapLoadedUnpack(heats, (summaries) => summaries) ?? []}
          />
        )}
      </Box>
    </Box>
  );
};

export const computeHeatScore = (heatSummary: HeatSummary) =>
  (heatSummary.charges ?? [])
    .flatMap((charge) => computeLayers(charge))
    .map((layer) => layer.scoreAchieved)
    .reduce((left, right) => left + right, 0);
