import { Chunk, ChunkData, quantisedWindow, useLoadedWindow } from "../helpers";
import { backendApi } from "src/store/api/enhancedApi";
import { useTenant } from "hooks/settings";
import { useCallback } from "react";
import { OnRelayout } from "../viewportControls";
import { mapLoadedUnpack } from "models/loaded";
import { EventPlot } from "./eventPlot";
import { generateColour } from "../plotting";

export type DatedHeatSummary = {
  heatId: string;
  start: Date;
  end: Date;
  shift: string;
};

export const useHeatSummaries = (
  start: Date,
  end: Date
): ChunkData<DatedHeatSummary[]>[] => {
  const [getHeatSummaries] =
    backendApi.endpoints.getHeatSummaries.useLazyQuery();
  const tenant = useTenant();

  const get = useCallback(
    async (segment: Chunk) =>
      getHeatSummaries({ tenantName: tenant, ...segment })
        .unwrap()
        .then((result) =>
          result.heat_summaries.map(
            (summary) =>
              ({
                heatId: summary.heat_id,
                start: new Date(summary.start),
                end: new Date(summary.end),
                shift: summary.shift,
              }) as DatedHeatSummary
          )
        ),
    [getHeatSummaries]
  );

  return useLoadedWindow(
    quantisedWindow(
      { start: start.getTime(), end: end.getTime() },
      300 * 1000,
      2
    ),
    get
  );
};

export const HeatSummariesPlot = ({
  start,
  end,
  onRelayout,
  setFocusHeatId,
}: {
  start: Date;
  end: Date;
  onRelayout?: OnRelayout;
  setFocusHeatId?: (focusHeatId: string) => void;
}) => {
  const heatSummaries = useHeatSummaries(start, end);

  const loadedHeatSummaries = heatSummaries.flatMap(
    (chunk) => mapLoadedUnpack(chunk.value, (summaries) => summaries) ?? []
  );

  return (
    <EventPlot
      events={loadedHeatSummaries.map((summary) => ({
        start: summary.start,
        end: summary.end,
        text: summary.heatId,
        colour: generateColour(summary.heatId),
      }))}
      start={start}
      end={end}
      onRelayout={onRelayout}
      chunks={heatSummaries}
      onClick={(event) => {
        const point = event.points[0];
        if (point != null && setFocusHeatId) {
          const text = point.data.text;
          setFocusHeatId(typeof text === "string" ? text : text[0]!);
        }
      }}
    />
  );
};
