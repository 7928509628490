import { alpha } from "@mui/material";

type ColouredCellProps = {
  content: string;
  alpha: number;
  color: string;
  justifyContent: "flex-end" | "center";
};

export const ColouredCell = ({
  content,
  alpha: alpha_,
  color,
  justifyContent,
}: ColouredCellProps) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: alpha(color, alpha_),
        display: "flex",
        justifyContent: justifyContent,
      }}
    >
      {content}
    </div>
  );
};

type ColourGradientCellProps = {
  content: string;
  alphaVariableValue: number;
  alphaVariableLowerClippingValue: number;
  alphaVariableUpperClippingValue: number;
  color: string;
  justifyContent: "flex-end" | "center";
};

export const ColourGradientCell = ({
  content,
  alphaVariableValue,
  alphaVariableLowerClippingValue,
  alphaVariableUpperClippingValue,
  color,
  justifyContent,
}: ColourGradientCellProps) => {
  const alpha =
    alphaVariableValue > alphaVariableUpperClippingValue
      ? 1
      : alphaVariableValue < alphaVariableLowerClippingValue
      ? 0
      : (alphaVariableValue - alphaVariableLowerClippingValue) /
        (alphaVariableUpperClippingValue - alphaVariableLowerClippingValue);

  return (
    <ColouredCell
      content={content}
      alpha={alpha}
      color={color}
      justifyContent={justifyContent}
    />
  );
};
