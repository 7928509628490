import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { purchasingPlanTableEditsSlice } from "src/store/slices/purchasingPlanTableEditSlice";
import { useAppDispatch } from "src/store/store";
import { BaseQuoteRow, Row } from "../PurchasingPlanTable";

type Props = {
  supplierRow: BaseQuoteRow;
};

export const SupplierCell = ({ supplierRow }: Props) => {
  const apiRef = useGridApiContext();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    const updatedRows = apiRef.current
      .getAllRowIds()
      .map((rowId) => apiRef.current.getRow<Row>(rowId))
      .filter((row): row is Row => row !== null)
      .filter((row: Row): boolean => {
        switch (row.type) {
          case "quote_quantity":
          case "quote_price":
            return row.quoteId !== supplierRow.quoteId;
          case "material_demand":
          case "material_inventory":
          case "material_quantity_outstanding":
          case "material_average_cost":
            return true;
          default:
            return true;
        }
      });
    dispatch(purchasingPlanTableEditsSlice.actions.setEditedRows(updatedRows));
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        "&:hover .delete-button": {
          display: "initial",
        },
      }}
    >
      {supplierRow.supplierName}
      <IconButton
        size="small"
        onClick={handleClick}
        aria-label="delete"
        className="delete-button"
        sx={{ display: "none" }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
