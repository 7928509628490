import { GridColumnGroupingModel } from "@mui/x-data-grid-premium";
import { useTenantTranslation } from "hooks/formatters";
import { InventoryCell } from "./InventoryCell";
import { TotalConsumptionCell } from "./TotalConsumptionCell";
import React from "react";

type Material = {
  id: number;
  name: string;
  inventory: number | null;
};

export const useColumnGroupingModel = (materials: Material[]) => {
  const { t } = useTenantTranslation();
  const columnGroupingModel = React.useMemo(() => {
    return [
      {
        groupId: "0",
        headerClassName: "empty-column-group-header",
        children: [
          {
            groupId: "1",
            renderHeaderGroup: () => null,
            headerClassName:
              "bottom-empty-column-group-header border-bottom-dark",
            children: [
              {
                field: "mix_name",
              },
              {
                field: "num_heats",
              },
              {
                field: "steel_grades",
              },
              {
                field: "copper_group",
              },
              {
                field: "dri_weight",
              },
            ],
          },
        ],
      },
      {
        groupId: "inventory",
        headerName: t("initialInventory"),
        headerClassName: "group-column-row-header",
        children: [
          {
            groupId: t("consumption"),
            headerClassName: "group-column-row-header border-bottom-dark",
            children: [{ field: "basket_weight" }],
          },
        ],
      },
      ...materials.map((material): GridColumnGroupingModel[0] => ({
        groupId: material.id.toString() + ".inventory",
        headerName: material.inventory?.toString() ?? "N/A",
        renderHeaderGroup: () => (
          <InventoryCell amount={material.inventory ?? 0} />
        ),
        headerClassName: "group-column-cell bold",
        children: [
          {
            groupId: material.id.toString() + " consumption",
            headerClassName: "group-column-cell border-bottom-dark",
            renderHeaderGroup: () => (
              <TotalConsumptionCell
                materialId={material.id}
                inventoryAmount={material.inventory ?? 0}
              />
            ),
            children: [
              {
                field: material.id.toString(),
              },
            ],
          },
        ],
      })),
    ];
  }, [materials]);
  return columnGroupingModel;
};
