import { Button } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { deployPlanFormSlice } from "src/store/slices/deployPlanFormSlice";
import { useAppDispatch } from "src/store/store";

type Props = {
  planId: number;
};

export const BackButton = ({ planId }: Props) => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const handleOnClick = () => {
    dispatch(deployPlanFormSlice.actions.backToDeployForm(planId));
  };
  return (
    <Button variant="outlined" onClick={handleOnClick}>
      {t("back")}
    </Button>
  );
};
