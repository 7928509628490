import { useLanguage } from "hooks/settings";
import { validateDate } from "../utils/validateDate";

type DateType = "date" | "datetime" | "monthYear";

export const useFormatLocalDate = (
  period: DateType
): ((date: Date) => string) => {
  const language = useLanguage();

  return (date: Date) => {
    switch (period) {
      case "date":
        return date.toLocaleDateString(language);
      case "datetime":
        return date.toLocaleString(language);
      case "monthYear":
        return date.toLocaleDateString(language, {
          month: "long",
          year: "numeric",
          day: undefined,
        });
    }
  };
};

export const useFormatLocalDateString = (period: DateType) => {
  const format = useFormatLocalDate(period);
  return (dateString: string) => {
    return format(validateDate(dateString));
  };
};
