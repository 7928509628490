import { Grid } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { useEffect, useState } from "react";
import {
  MaterialGroupingName,
  TimeAggregation,
} from "src/store/api/generatedApi";
import { SelectBox } from "../Selectbox";
import { TableHeader } from "../TableHeader";
import { ConsumptionPlotType, GroupedConsumptionSeries } from "../types";
import { ConsumptionPlot } from "./ConsumptionPlot";
import { ConsumptionTable } from "./ConsumptionTable";

type ConsumptionTabProps = {
  groupedConsumptionSeries: GroupedConsumptionSeries;
};

export const ConsumptionTab = ({
  groupedConsumptionSeries,
}: ConsumptionTabProps) => {
  const { t } = useTenantTranslation();

  const [selectedTimeAggregation, setSelectedTimeAggregation] =
    useState<TimeAggregation | null>(null);
  const [selectedMaterialGrouping, setSelectedMaterialGrouping] =
    useState<MaterialGroupingName | null>(null);
  const [selectedPlotType, setSelectedPlotType] =
    useState<ConsumptionPlotType>("mass");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    // when the groupedConsumptionSeries changes, set the default values
    // for the select boxes
    setSelectedTimeAggregation(
      getDefaultTimeAggregation(
        groupedConsumptionSeries.availableTimeAggregations
      )
    );
    setSelectedMaterialGrouping(
      getDefaultMaterialGrouping(
        groupedConsumptionSeries.availableMaterialGroupings
      )
    );
  }, [groupedConsumptionSeries]);

  useEffect(() => {
    // when one of the select boxes changes, set the default date
    // to the latest date in the series
    setSelectedDate(
      getDefaultSelectedDate(
        groupedConsumptionSeries,
        selectedTimeAggregation,
        selectedMaterialGrouping
      )
    );
  }, [selectedTimeAggregation, selectedMaterialGrouping]);

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Grid item>
          <SelectBox
            availableOptions={
              groupedConsumptionSeries.availableTimeAggregations
            }
            selectedOption={selectedTimeAggregation}
            onOptionChange={setSelectedTimeAggregation}
            id="time-aggregation-select"
            labelId="time-aggregation-label"
            label={t("frequency")}
          />
        </Grid>
        <Grid item>
          <SelectBox
            availableOptions={
              groupedConsumptionSeries.availableMaterialGroupings
            }
            selectedOption={selectedMaterialGrouping}
            onOptionChange={setSelectedMaterialGrouping}
            id="material-grouping-select"
            labelId="material-grouping-label"
            label={t("materialGrouping")}
          />
        </Grid>
        <Grid item>
          <SelectBox<ConsumptionPlotType>
            availableOptions={
              new Set<ConsumptionPlotType>(["mass", "fraction"])
            }
            selectedOption={selectedPlotType}
            onOptionChange={setSelectedPlotType}
            id="plot-type-select"
            labelId="plot-type-label"
            label={t("plotType")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={2} mx={0}>
        <Grid item xs={7} ml={0}>
          <ConsumptionPlot
            consumptionSeries={groupedConsumptionSeries}
            selectedFrequency={selectedTimeAggregation}
            selectedGrouping={selectedMaterialGrouping}
            selectedPlotType={selectedPlotType}
            setSelectedDate={setSelectedDate}
          />
        </Grid>
        <Grid item xs={5}>
          <TableHeader
            titlePrefix={t("consumptionDuring")}
            subtitleText={t("consumptionTableSubtitleText")}
            selectedDate={selectedDate}
            formatDateMonthly={selectedTimeAggregation === "monthly"}
          />
          <ConsumptionTable
            consumptionSeries={groupedConsumptionSeries}
            selectedDate={selectedDate}
            selectedTimeAggregation={selectedTimeAggregation}
            selectedMaterialGrouping={selectedMaterialGrouping}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const getDefaultTimeAggregation = (
  availableTimeAggregations: Set<TimeAggregation>
) => {
  return availableTimeAggregations.has("monthly")
    ? "monthly"
    : Array.from(availableTimeAggregations)[0] ?? null;
};

const getDefaultMaterialGrouping = (
  availableMaterialGroupings: Set<MaterialGroupingName>
) => {
  return Array.from(availableMaterialGroupings)[0] ?? null;
};

const getDefaultSelectedDate = (
  groupedConsumptionSeries: GroupedConsumptionSeries,
  selectedTimeAggregation: TimeAggregation | null,
  selectedMaterialGrouping: MaterialGroupingName | null
): Date | null => {
  const defaultSelectedDateString = groupedConsumptionSeries.items
    .filter(
      (item) =>
        item[0].timeAggregation == selectedTimeAggregation &&
        item[0].materialGrouping == selectedMaterialGrouping
    )
    .map((item) => item[1].date[item[1].date.length - 1] ?? null)
    .reduce((max, current) => {
      if (max === null) {
        return current;
      }
      if (current === null) {
        return max;
      }
      return current > max ? current : max;
    }, null);
  return defaultSelectedDateString ? new Date(defaultSelectedDateString) : null;
};
