import { templateApi as api } from "./templateApi";
export const addTagTypes = [
  "tenants",
  "recipes",
  "chef_groups",
  "steel_grades",
  "steel_grade_plans",
  "baskets",
  "baskets_material_limits",
  "chemistry",
  "crane",
  "materials",
  "loads",
  "deployments",
  "drop_weight",
  "multiple_price_availability",
  "product_groups",
  "product_group_plans",
  "search",
  "search_context",
  "plan",
  "session",
  "result",
  "uploaded_files",
  "obtainable_estimates",
  "publish",
  "messages",
  "inventory",
  "target_baskets",
  "invite",
  "auth",
  "video_labelling",
  "delivery_tracking",
  "mix_material_limits",
  "scrap_tracking",
  "simulator",
  "summaries",
  "strategy_dashboard",
  "scrap_tracking_dashboard",
  "purchasing",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getTenantMetadata: build.query<
        GetTenantMetadataApiResponse,
        GetTenantMetadataApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/tenants/${queryArg.tenantName}`,
        }),
        providesTags: ["tenants"],
      }),
      getSimilarChargedRecipes: build.query<
        GetSimilarChargedRecipesApiResponse,
        GetSimilarChargedRecipesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/recipes/regular/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.similarChargedRecipesParams,
        }),
        providesTags: ["recipes"],
      }),
      createAutoRecipe: build.mutation<
        CreateAutoRecipeApiResponse,
        CreateAutoRecipeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/recipes/auto/${queryArg.tenantName}/${queryArg.autoRecipeType}`,
          method: "POST",
          params: {
            on_timestamp: queryArg.onTimestamp,
            deleted: queryArg.deleted,
          },
        }),
        invalidatesTags: ["recipes"],
      }),
      listChefGroups: build.query<
        ListChefGroupsApiResponse,
        ListChefGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chef_groups/${queryArg.tenantName}`,
        }),
        providesTags: ["chef_groups"],
      }),
      createChefGrouping: build.mutation<
        CreateChefGroupingApiResponse,
        CreateChefGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chef_groups/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.chefGroupingCreate,
        }),
        invalidatesTags: ["chef_groups"],
      }),
      getChefGrouping: build.query<
        GetChefGroupingApiResponse,
        GetChefGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chef_groups/${queryArg.tenantName}/${queryArg.chefGroupingId}`,
        }),
        providesTags: ["chef_groups"],
      }),
      setDefaultChefGrouping: build.mutation<
        SetDefaultChefGroupingApiResponse,
        SetDefaultChefGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chef_groups/${queryArg.tenantName}/${queryArg.chefGroupingId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["chef_groups"],
      }),
      listSteelGrades: build.query<
        ListSteelGradesApiResponse,
        ListSteelGradesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grades/${queryArg.tenantName}`,
        }),
        providesTags: ["steel_grades"],
      }),
      createSteelGradeSet: build.mutation<
        CreateSteelGradeSetApiResponse,
        CreateSteelGradeSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grades/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.steelGradeSetCreate,
        }),
        invalidatesTags: ["steel_grades"],
      }),
      getSteelGradeSet: build.query<
        GetSteelGradeSetApiResponse,
        GetSteelGradeSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grades/${queryArg.tenantName}/${queryArg.steelGradeSetId}`,
        }),
        providesTags: ["steel_grades"],
      }),
      setDefaultSteelGradeSet: build.mutation<
        SetDefaultSteelGradeSetApiResponse,
        SetDefaultSteelGradeSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grades/${queryArg.tenantName}/${queryArg.steelGradeSetId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["steel_grades"],
      }),
      listSteelGradePlans: build.query<
        ListSteelGradePlansApiResponse,
        ListSteelGradePlansApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/${queryArg.tenantName}`,
        }),
        providesTags: ["steel_grade_plans"],
      }),
      createSteelGradePlan: build.mutation<
        CreateSteelGradePlanApiResponse,
        CreateSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.steelGradePlanCreate,
        }),
        invalidatesTags: ["steel_grade_plans"],
      }),
      getSteelGradePlan: build.query<
        GetSteelGradePlanApiResponse,
        GetSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/${queryArg.tenantName}/${queryArg.steelGradePlanId}`,
        }),
        providesTags: ["steel_grade_plans"],
      }),
      setDefaultSteelGradePlan: build.mutation<
        SetDefaultSteelGradePlanApiResponse,
        SetDefaultSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/${queryArg.tenantName}/${queryArg.steelGradePlanId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["steel_grade_plans"],
      }),
      getTypicalSteelGradePlan: build.query<
        GetTypicalSteelGradePlanApiResponse,
        GetTypicalSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/typical/production/${queryArg.tenantName}/${queryArg.steelGradeSetId}`,
          params: {
            num_heats: queryArg.numHeats,
          },
        }),
        providesTags: ["steel_grade_plans"],
      }),
      getSteelGradePlanItemsFromProductGroupPlanItems: build.mutation<
        GetSteelGradePlanItemsFromProductGroupPlanItemsApiResponse,
        GetSteelGradePlanItemsFromProductGroupPlanItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/from_product_group_plan_items/${queryArg.tenantName}/${queryArg.steelGradeSetId}`,
          method: "POST",
          body: queryArg.productGroupPlanItems,
        }),
        invalidatesTags: ["steel_grade_plans"],
      }),
      getFormattedBasketConstraints: build.query<
        GetFormattedBasketConstraintsApiResponse,
        GetFormattedBasketConstraintsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/formatted_basket_constraints/${queryArg.tenantName}`,
        }),
        providesTags: ["baskets"],
      }),
      updateSearchChefGroupBasketMaterialLimits: build.mutation<
        UpdateSearchChefGroupBasketMaterialLimitsApiResponse,
        UpdateSearchChefGroupBasketMaterialLimitsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_material_limit/${queryArg.tenantName}/search/${queryArg.searchId}/chef_group_basket_material_limit_set`,
          method: "POST",
          body: queryArg.chefGroupBasketMaterialLimitSetCreate,
        }),
        invalidatesTags: ["baskets_material_limits"],
      }),
      getChefGroupBasketMaterialLimitSet: build.query<
        GetChefGroupBasketMaterialLimitSetApiResponse,
        GetChefGroupBasketMaterialLimitSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_material_limit/${queryArg.tenantName}/chef_group_basket_material_limit_set/${queryArg.chefGroupBasketMaterialLimitSetId}`,
        }),
        providesTags: ["baskets_material_limits"],
      }),
      listChemicalElements: build.query<
        ListChemicalElementsApiResponse,
        ListChemicalElementsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chemical_elements/${queryArg.tenantName}`,
        }),
        providesTags: ["chemistry"],
      }),
      getCraneAssignedBasket: build.query<
        GetCraneAssignedBasketApiResponse,
        GetCraneAssignedBasketApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/crane/${queryArg.tenantName}/${queryArg.craneUserUid}`,
        }),
        providesTags: ["crane"],
      }),
      getMaterialMetadataSet: build.query<
        GetMaterialMetadataSetApiResponse,
        GetMaterialMetadataSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/materials/${queryArg.tenantName}/${queryArg.materialMetadataSetId}`,
        }),
        providesTags: ["materials"],
      }),
      listMaterialConstraintClasses: build.query<
        ListMaterialConstraintClassesApiResponse,
        ListMaterialConstraintClassesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_constraint_classes/${queryArg.tenantName}/${queryArg.materialMetadataSetId}/${queryArg.materialPhysicsSetId}`,
        }),
        providesTags: ["materials"],
      }),
      createMaterialChemistrySet: build.mutation<
        CreateMaterialChemistrySetApiResponse,
        CreateMaterialChemistrySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_chemistry/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.materialChemistrySetCreate,
        }),
        invalidatesTags: ["materials"],
      }),
      listMaterialChemistrySets: build.query<
        ListMaterialChemistrySetsApiResponse,
        ListMaterialChemistrySetsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_chemistry/${queryArg.tenantName}`,
        }),
        providesTags: ["materials"],
      }),
      getMaterialChemistrySet: build.query<
        GetMaterialChemistrySetApiResponse,
        GetMaterialChemistrySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_chemistry/${queryArg.tenantName}/${queryArg.materialChemistrySetId}`,
        }),
        providesTags: ["materials"],
      }),
      setDefaultMaterialChemistrySet: build.mutation<
        SetDefaultMaterialChemistrySetApiResponse,
        SetDefaultMaterialChemistrySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_chemistry/${queryArg.tenantName}/${queryArg.materialChemistrySetId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["materials"],
      }),
      createMaterialPhysicsSet: build.mutation<
        CreateMaterialPhysicsSetApiResponse,
        CreateMaterialPhysicsSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_physics/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.materialPhysicsSetCreate,
        }),
        invalidatesTags: ["materials"],
      }),
      listMaterialPhysicsSets: build.query<
        ListMaterialPhysicsSetsApiResponse,
        ListMaterialPhysicsSetsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_physics/${queryArg.tenantName}`,
        }),
        providesTags: ["materials"],
      }),
      getMaterialPhysicsSet: build.query<
        GetMaterialPhysicsSetApiResponse,
        GetMaterialPhysicsSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_physics/${queryArg.tenantName}/${queryArg.materialPhysicsSetId}`,
        }),
        providesTags: ["materials"],
      }),
      setDefaultMaterialPhysicsSet: build.mutation<
        SetDefaultMaterialPhysicsSetApiResponse,
        SetDefaultMaterialPhysicsSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_physics/${queryArg.tenantName}/${queryArg.materialPhysicsSetId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["materials"],
      }),
      getMaterialPrices: build.query<
        GetMaterialPricesApiResponse,
        GetMaterialPricesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/prices/${queryArg.tenantName}/${queryArg.timestamp}`,
        }),
        providesTags: ["materials"],
      }),
      listLoads: build.query<ListLoadsApiResponse, ListLoadsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/load/${queryArg.tenantName}`,
          params: {
            created_since: queryArg.createdSince,
            created_before: queryArg.createdBefore,
          },
        }),
        providesTags: ["loads"],
      }),
      createLoad: build.mutation<CreateLoadApiResponse, CreateLoadApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/load/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.loadCreate,
        }),
        invalidatesTags: ["loads"],
      }),
      updateLoad: build.mutation<UpdateLoadApiResponse, UpdateLoadApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/load/${queryArg.tenantName}`,
          method: "PATCH",
          body: queryArg.loadUpdate,
        }),
        invalidatesTags: ["loads"],
      }),
      listCranes: build.query<ListCranesApiResponse, ListCranesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/load/${queryArg.tenantName}/cranes`,
        }),
        providesTags: ["loads"],
      }),
      getLoad: build.query<GetLoadApiResponse, GetLoadApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/load/${queryArg.tenantName}/${queryArg.loadId}`,
        }),
        providesTags: ["loads"],
      }),
      deleteLoad: build.mutation<DeleteLoadApiResponse, DeleteLoadApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/load/${queryArg.tenantName}/${queryArg.loadId}/delete`,
          method: "PATCH",
        }),
        invalidatesTags: ["loads"],
      }),
      getLoadBasket: build.query<GetLoadBasketApiResponse, GetLoadBasketApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/load_basket/${queryArg.tenantName}/${queryArg.loadBasketId}`,
          }),
          providesTags: ["loads"],
        },
      ),
      updateLoadBasketStatus: build.mutation<
        UpdateLoadBasketStatusApiResponse,
        UpdateLoadBasketStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/load_basket/${queryArg.tenantName}/${queryArg.loadBasketId}/status`,
          method: "POST",
          body: queryArg.loadBasketStatusUpdate,
        }),
        invalidatesTags: ["loads"],
      }),
      updateLoadBasketAssignment: build.mutation<
        UpdateLoadBasketAssignmentApiResponse,
        UpdateLoadBasketAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/load_basket/${queryArg.tenantName}/${queryArg.loadBasketId}/assignment`,
          method: "POST",
          body: queryArg.loadBasketAssignmentUpdate,
        }),
        invalidatesTags: ["loads"],
      }),
      updateLoadBasketAdditions: build.mutation<
        UpdateLoadBasketAdditionsApiResponse,
        UpdateLoadBasketAdditionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/load_basket/${queryArg.tenantName}/${queryArg.loadBasketId}/additions`,
          method: "POST",
          body: queryArg.loadBasketAdditionUpdate,
        }),
        invalidatesTags: ["loads"],
      }),
      getPlanMixes: build.query<GetPlanMixesApiResponse, GetPlanMixesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/deployments/${queryArg.tenantName}/plan_mixes/${queryArg.planId}`,
          params: {
            period: queryArg.period,
          },
        }),
        providesTags: ["deployments"],
      }),
      getDeployedPlans: build.query<
        GetDeployedPlansApiResponse,
        GetDeployedPlansApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/deployments/${queryArg.tenantName}/deployed`,
          params: {
            deployment_types: queryArg.deploymentTypes,
          },
        }),
        providesTags: ["deployments"],
      }),
      getDeployedPlanMixes: build.query<
        GetDeployedPlanMixesApiResponse,
        GetDeployedPlanMixesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/deployments/${queryArg.tenantName}/plan_mixes/${queryArg.planId}/deployed`,
          params: {
            period: queryArg.period,
            deployment_types: queryArg.deploymentTypes,
          },
        }),
        providesTags: ["deployments"],
      }),
      getPlanMixesExcel: build.query<
        GetPlanMixesExcelApiResponse,
        GetPlanMixesExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/deployments/${queryArg.tenantName}/plan_mixes/${queryArg.planId}/excel/${queryArg.language}`,
          params: {
            period: queryArg.period,
          },
        }),
        providesTags: ["deployments"],
      }),
      deployPlan: build.mutation<DeployPlanApiResponse, DeployPlanApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/deployments/${queryArg.tenantName}/deploy/${queryArg.planId}`,
          method: "POST",
          body: queryArg.deployParameters,
        }),
        invalidatesTags: ["deployments"],
      }),
      getActiveLoadDropWeightsAt: build.query<
        GetActiveLoadDropWeightsAtApiResponse,
        GetActiveLoadDropWeightsAtApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/drop_weight/${queryArg.tenantName}/active`,
          params: {
            at_timestamp: queryArg.atTimestamp,
          },
        }),
        providesTags: ["drop_weight"],
      }),
      getObtainableBundleItemSet: build.query<
        GetObtainableBundleItemSetApiResponse,
        GetObtainableBundleItemSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/multiple_price_availability/${queryArg.tenantName}/${queryArg.obtainableBundleSetId}`,
          params: {
            material_metadata_set_id: queryArg.materialMetadataSetId,
          },
        }),
        providesTags: ["multiple_price_availability"],
      }),
      createObtainableBundleItemSet: build.mutation<
        CreateObtainableBundleItemSetApiResponse,
        CreateObtainableBundleItemSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/multiple_price_availability/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.obtainableBundleItemSetCreate,
        }),
        invalidatesTags: ["multiple_price_availability"],
      }),
      listProductGroupings: build.query<
        ListProductGroupingsApiResponse,
        ListProductGroupingsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_groups/${queryArg.tenantName}`,
        }),
        providesTags: ["product_groups"],
      }),
      createProductGrouping: build.mutation<
        CreateProductGroupingApiResponse,
        CreateProductGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_groups/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.productGroupingCreate,
        }),
        invalidatesTags: ["product_groups"],
      }),
      getProductGrouping: build.query<
        GetProductGroupingApiResponse,
        GetProductGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_groups/${queryArg.tenantName}/${queryArg.productGroupingId}`,
        }),
        providesTags: ["product_groups"],
      }),
      setDefaultProductGrouping: build.mutation<
        SetDefaultProductGroupingApiResponse,
        SetDefaultProductGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_groups/${queryArg.tenantName}/${queryArg.productGroupingId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["product_groups"],
      }),
      listProductGroupPlans: build.query<
        ListProductGroupPlansApiResponse,
        ListProductGroupPlansApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/${queryArg.tenantName}`,
        }),
        providesTags: ["product_group_plans"],
      }),
      createProductGroupPlan: build.mutation<
        CreateProductGroupPlanApiResponse,
        CreateProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.productGroupPlanCreate,
        }),
        invalidatesTags: ["product_group_plans"],
      }),
      getProductGroupPlan: build.query<
        GetProductGroupPlanApiResponse,
        GetProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/${queryArg.tenantName}/${queryArg.productGroupPlanId}`,
        }),
        providesTags: ["product_group_plans"],
      }),
      setDefaultProductGroupPlan: build.mutation<
        SetDefaultProductGroupPlanApiResponse,
        SetDefaultProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/${queryArg.tenantName}/${queryArg.productGroupPlanId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["product_group_plans"],
      }),
      getTypicalProductGroupPlan: build.query<
        GetTypicalProductGroupPlanApiResponse,
        GetTypicalProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/typical_production/${queryArg.tenantName}/${queryArg.productGroupingId}`,
          params: {
            num_heats: queryArg.numHeats,
          },
        }),
        providesTags: ["product_group_plans"],
      }),
      getProductGroupPlanItemsFromSteelGradePlanItems: build.mutation<
        GetProductGroupPlanItemsFromSteelGradePlanItemsApiResponse,
        GetProductGroupPlanItemsFromSteelGradePlanItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/from_steel_grade_plan_items/${queryArg.tenantName}/${queryArg.productGroupingId}`,
          method: "POST",
          body: queryArg.steelGradePlanItems,
        }),
        invalidatesTags: ["product_group_plans"],
      }),
      listSearches: build.query<ListSearchesApiResponse, ListSearchesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}`,
          params: {
            ordering: queryArg.ordering,
            session_id: queryArg.sessionId,
            limit: queryArg.limit,
            status: queryArg.status,
          },
        }),
        providesTags: ["search"],
      }),
      sendSearchNotification: build.mutation<
        SendSearchNotificationApiResponse,
        SendSearchNotificationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/${queryArg.searchId}/notify`,
          method: "POST",
        }),
        invalidatesTags: ["search"],
      }),
      createSearchFromContext: build.mutation<
        CreateSearchFromContextApiResponse,
        CreateSearchFromContextApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/${queryArg.searchId}/context`,
          method: "POST",
        }),
        invalidatesTags: ["search"],
      }),
      runSearch: build.mutation<RunSearchApiResponse, RunSearchApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/${queryArg.searchId}/run`,
          method: "POST",
          body: queryArg.runSearchOptions,
        }),
        invalidatesTags: ["search"],
      }),
      getSearch: build.query<GetSearchApiResponse, GetSearchApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/search_parameters/${queryArg.searchId}`,
        }),
        providesTags: ["search"],
      }),
      createSearch: build.mutation<CreateSearchApiResponse, CreateSearchApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/search/${queryArg.tenantName}/search_parameters`,
            method: "POST",
            body: queryArg.searchCreate,
          }),
          invalidatesTags: ["search"],
        },
      ),
      getSearchContext: build.query<
        GetSearchContextApiResponse,
        GetSearchContextApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/search_context/${queryArg.searchContextId}`,
        }),
        providesTags: ["search"],
      }),
      getNameBasedSearch: build.query<
        GetNameBasedSearchApiResponse,
        GetNameBasedSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/name_based_search/${queryArg.searchId}`,
        }),
        providesTags: ["search"],
      }),
      overwriteFromNameBasedSearch: build.mutation<
        OverwriteFromNameBasedSearchApiResponse,
        OverwriteFromNameBasedSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/name_based_search/${queryArg.searchId}`,
          method: "POST",
          body: queryArg.nameBasedSearchInput,
        }),
        invalidatesTags: ["search"],
      }),
      createSearchContextGroup: build.mutation<
        CreateSearchContextGroupApiResponse,
        CreateSearchContextGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search_context_group/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.searchContextGroupCreate,
        }),
        invalidatesTags: ["search_context"],
      }),
      listSearchContextGroups: build.query<
        ListSearchContextGroupsApiResponse,
        ListSearchContextGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search_context_group/${queryArg.tenantName}`,
        }),
        providesTags: ["search_context"],
      }),
      getSearchContextGroup: build.query<
        GetSearchContextGroupApiResponse,
        GetSearchContextGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search_context_group/${queryArg.tenantName}/${queryArg.searchContextGroupId}`,
        }),
        providesTags: ["search_context"],
      }),
      updateSearchContextGroupMixMaterialLimits: build.mutation<
        UpdateSearchContextGroupMixMaterialLimitsApiResponse,
        UpdateSearchContextGroupMixMaterialLimitsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search_context_group/${queryArg.tenantName}/${queryArg.searchContextGroupId}/search_context/${queryArg.searchContextId}/mix_material_limits`,
          method: "POST",
          body: queryArg.mixMaterialLimits,
        }),
        invalidatesTags: ["search_context"],
      }),
      listDeployedPlans: build.query<
        ListDeployedPlansApiResponse,
        ListDeployedPlansApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/deployed`,
        }),
        providesTags: ["plan"],
      }),
      sendPlanNotification: build.mutation<
        SendPlanNotificationApiResponse,
        SendPlanNotificationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/${queryArg.planId}/send_notification`,
          method: "POST",
          params: {
            plan_name: queryArg.planName,
          },
        }),
        invalidatesTags: ["plan"],
      }),
      updatePlanMixes: build.mutation<
        UpdatePlanMixesApiResponse,
        UpdatePlanMixesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/${queryArg.planId}/mixes`,
          method: "POST",
          body: queryArg.planMixUpdates,
        }),
        invalidatesTags: ["plan"],
      }),
      updateAndDeployPlanMixes: build.mutation<
        UpdateAndDeployPlanMixesApiResponse,
        UpdateAndDeployPlanMixesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/${queryArg.planId}/mixes/deploy`,
          method: "POST",
          body: queryArg.deployedPlanMixUpdates,
        }),
        invalidatesTags: ["plan"],
      }),
      compareMixes: build.query<CompareMixesApiResponse, CompareMixesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/mix/compare/${queryArg.materialMetadataSetId}/${queryArg.srcMixId}/${queryArg.tgtMixId}`,
        }),
        providesTags: ["plan"],
      }),
      comparePlans: build.query<ComparePlansApiResponse, ComparePlansApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/compare/${queryArg.sourcePlanId}/${queryArg.targetPlanId}`,
        }),
        providesTags: ["plan"],
      }),
      getPlanAncestors: build.query<
        GetPlanAncestorsApiResponse,
        GetPlanAncestorsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/${queryArg.planId}/ancestors`,
        }),
        providesTags: ["plan"],
      }),
      getBackups: build.query<GetBackupsApiResponse, GetBackupsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/${queryArg.planId}/backups`,
        }),
        providesTags: ["plan"],
      }),
      getPlan: build.query<GetPlanApiResponse, GetPlanApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/${queryArg.planId}`,
        }),
        providesTags: ["plan"],
      }),
      listSessions: build.query<ListSessionsApiResponse, ListSessionsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/session/${queryArg.tenantName}`,
        }),
        providesTags: ["session"],
      }),
      createSession: build.mutation<
        CreateSessionApiResponse,
        CreateSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/session/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.sessionCreate,
        }),
        invalidatesTags: ["session"],
      }),
      renameSession: build.mutation<
        RenameSessionApiResponse,
        RenameSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/session/${queryArg.tenantName}/${queryArg.sessionId}`,
          method: "PATCH",
          body: queryArg.renameSession,
        }),
        invalidatesTags: ["session"],
      }),
      deleteSession: build.mutation<
        DeleteSessionApiResponse,
        DeleteSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/session/${queryArg.tenantName}/${queryArg.sessionId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["session"],
      }),
      copySearch: build.mutation<CopySearchApiResponse, CopySearchApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/session/${queryArg.tenantName}/${queryArg.sessionId}`,
          method: "POST",
          body: queryArg.copySearch,
        }),
        invalidatesTags: ["session"],
      }),
      getResult: build.query<GetResultApiResponse, GetResultApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/result/${queryArg.tenantName}/${queryArg.searchId}`,
        }),
        providesTags: ["result"],
      }),
      getNameBasedResults: build.query<
        GetNameBasedResultsApiResponse,
        GetNameBasedResultsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/result/name_based/${queryArg.tenantName}/${queryArg.searchId}`,
        }),
        providesTags: ["result"],
      }),
      saveUploadedFile: build.mutation<
        SaveUploadedFileApiResponse,
        SaveUploadedFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/files`,
          method: "POST",
          body: queryArg.uploadedFileCreate,
        }),
        invalidatesTags: ["uploaded_files"],
      }),
      listUploadedFiles: build.query<
        ListUploadedFilesApiResponse,
        ListUploadedFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/files`,
          params: {
            types: queryArg.types,
            deleted: queryArg.deleted,
            since: queryArg.since,
            limit: queryArg.limit,
            order: queryArg.order,
          },
        }),
        providesTags: ["uploaded_files"],
      }),
      getUploadedFile: build.query<
        GetUploadedFileApiResponse,
        GetUploadedFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/files/${queryArg.uploadedFileId}`,
        }),
        providesTags: ["uploaded_files"],
      }),
      reassignUploadedFile: build.mutation<
        ReassignUploadedFileApiResponse,
        ReassignUploadedFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/files/${queryArg.uploadedFileId}`,
          method: "PATCH",
          body: queryArg.uploadedFileType,
        }),
        invalidatesTags: ["uploaded_files"],
      }),
      listUploadedFileTypes: build.query<
        ListUploadedFileTypesApiResponse,
        ListUploadedFileTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/types`,
          method: "POST",
          body: queryArg.outputTypes,
        }),
        providesTags: ["uploaded_files"],
      }),
      getParsedSearchParameters: build.query<
        GetParsedSearchParametersApiResponse,
        GetParsedSearchParametersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/parsed/${queryArg.uploadedFileId}/${queryArg.materialMetadataSetId}/${queryArg.steelGradeSetId}`,
        }),
        providesTags: ["uploaded_files"],
      }),
      getPerHeatObtainableEstimateFromTypicalConsumption: build.query<
        GetPerHeatObtainableEstimateFromTypicalConsumptionApiResponse,
        GetPerHeatObtainableEstimateFromTypicalConsumptionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/materials/obtainable_estimate/${queryArg.tenantName}/typical_consumption`,
          params: {
            material_metadata_set_id: queryArg.materialMetadataSetId,
          },
        }),
        providesTags: ["obtainable_estimates"],
      }),
      getObtainableEstimateFromPurchasingPlan: build.query<
        GetObtainableEstimateFromPurchasingPlanApiResponse,
        GetObtainableEstimateFromPurchasingPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/materials/obtainable_estimate/${queryArg.tenantName}/purchasing_plan`,
          params: {
            material_metadata_set_id: queryArg.materialMetadataSetId,
            purchasing_plan_file_id: queryArg.purchasingPlanFileId,
          },
        }),
        providesTags: ["obtainable_estimates"],
      }),
      getLayering: build.mutation<GetLayeringApiResponse, GetLayeringApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/${queryArg.planId}/${queryArg.period}/${queryArg.groupId}/${queryArg.mixNumber}`,
          method: "POST",
        }),
        invalidatesTags: ["publish"],
      }),
      publishPlanChargeSchedule: build.mutation<
        PublishPlanChargeScheduleApiResponse,
        PublishPlanChargeScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/new`,
          method: "POST",
          body: queryArg.publishPlans,
        }),
        invalidatesTags: ["publish"],
      }),
      listNewPublishedChargeSchedules: build.query<
        ListNewPublishedChargeSchedulesApiResponse,
        ListNewPublishedChargeSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/new`,
        }),
        providesTags: ["publish"],
      }),
      listAllPublishedChargeSchedules: build.query<
        ListAllPublishedChargeSchedulesApiResponse,
        ListAllPublishedChargeSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/all`,
          params: {
            plan_id: queryArg.planId,
          },
        }),
        providesTags: ["publish"],
      }),
      listCustomerPublishedChargeScheduleLayers: build.query<
        ListCustomerPublishedChargeScheduleLayersApiResponse,
        ListCustomerPublishedChargeScheduleLayersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/${queryArg.publishedChargeScheduleId}/layers`,
        }),
        providesTags: ["publish"],
      }),
      listCustomerPublishedChargeScheduleLayersRecent: build.query<
        ListCustomerPublishedChargeScheduleLayersRecentApiResponse,
        ListCustomerPublishedChargeScheduleLayersRecentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/${queryArg.customerChargeScheduleId}/layers/recent`,
        }),
        providesTags: ["publish"],
      }),
      confirmPublishedChargeSchedules: build.mutation<
        ConfirmPublishedChargeSchedulesApiResponse,
        ConfirmPublishedChargeSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/confirm`,
          method: "POST",
          body: queryArg.confirmChargeSchedules,
        }),
        invalidatesTags: ["publish"],
      }),
      listConfirmedChargeScheduleMeta: build.mutation<
        ListConfirmedChargeScheduleMetaApiResponse,
        ListConfirmedChargeScheduleMetaApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/confirmed`,
          method: "POST",
          body: queryArg.fdmChargeScheduleIds,
        }),
        invalidatesTags: ["publish"],
      }),
      getChargeScheduleMessageTemplate: build.query<
        GetChargeScheduleMessageTemplateApiResponse,
        GetChargeScheduleMessageTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/message-template`,
          params: {
            plan_id: queryArg.planId,
            period: queryArg.period,
          },
        }),
        providesTags: ["publish"],
      }),
      sendChargeScheduleMessageList: build.mutation<
        SendChargeScheduleMessageListApiResponse,
        SendChargeScheduleMessageListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/messages`,
          method: "POST",
          body: queryArg.chargeScheduleMessages,
        }),
        invalidatesTags: ["publish"],
      }),
      sendPlanDeployedMessage: build.mutation<
        SendPlanDeployedMessageApiResponse,
        SendPlanDeployedMessageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/message/${queryArg.planId}`,
          method: "POST",
        }),
        invalidatesTags: ["publish"],
      }),
      listQueuedMessages: build.query<
        ListQueuedMessagesApiResponse,
        ListQueuedMessagesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/messages/${queryArg.tenantName}`,
        }),
        providesTags: ["messages"],
      }),
      confirmMessageSent: build.mutation<
        ConfirmMessageSentApiResponse,
        ConfirmMessageSentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/messages/${queryArg.tenantName}/${queryArg.messageId}`,
          method: "POST",
        }),
        invalidatesTags: ["messages"],
      }),
      getInventoryEstimateAtTimestamp: build.query<
        GetInventoryEstimateAtTimestampApiResponse,
        GetInventoryEstimateAtTimestampApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/inventory_estimate/${queryArg.tenantName}`,
          params: {
            material_metadata_set_id: queryArg.materialMetadataSetId,
            material_physics_set_id: queryArg.materialPhysicsSetId,
            timestamp: queryArg.timestamp,
          },
        }),
        providesTags: ["inventory"],
      }),
      getRecipesImpuritiesPlotRisk: build.query<
        GetRecipesImpuritiesPlotRiskApiResponse,
        GetRecipesImpuritiesPlotRiskApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/recipes/impurities/${queryArg.tenantName}/${queryArg.planId}`,
          params: {
            mix_id: queryArg.mixId,
            period: queryArg.period,
            language: queryArg.language,
            steel_grade_ids: queryArg.steelGradeIds,
          },
        }),
        providesTags: ["inventory"],
      }),
      createTargetBasketSet: build.mutation<
        CreateTargetBasketSetApiResponse,
        CreateTargetBasketSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/target_baskets/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.targetBasketSetCreate,
        }),
        invalidatesTags: ["target_baskets"],
      }),
      getTargetBasketSet: build.query<
        GetTargetBasketSetApiResponse,
        GetTargetBasketSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/target_baskets/${queryArg.tenantName}/${queryArg.targetBasketSetId}`,
        }),
        providesTags: ["target_baskets"],
      }),
      inviteUsers: build.mutation<InviteUsersApiResponse, InviteUsersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/invite`,
          method: "POST",
          body: queryArg.userInvite,
        }),
        invalidatesTags: ["invite", "auth"],
      }),
      getPendingInvites: build.query<
        GetPendingInvitesApiResponse,
        GetPendingInvitesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/invite`,
        }),
        providesTags: ["invite", "auth"],
      }),
      deleteUserInvite: build.mutation<
        DeleteUserInviteApiResponse,
        DeleteUserInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/invite`,
          method: "DELETE",
          params: {
            email: queryArg.email,
          },
        }),
        invalidatesTags: ["invite", "auth"],
      }),
      getVideoUrl: build.query<GetVideoUrlApiResponse, GetVideoUrlApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/get_video_url/${queryArg.videoKey}/${queryArg.camera}`,
          params: {
            downsampled: queryArg.downsampled,
          },
        }),
        providesTags: ["video_labelling"],
      }),
      suggestVideo: build.query<SuggestVideoApiResponse, SuggestVideoApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/suggest_video`,
          params: {
            required_materials: queryArg.requiredMaterials,
            prioritise_days_with_fewest_labelled_videos:
              queryArg.prioritiseDaysWithFewestLabelledVideos,
          },
        }),
        providesTags: ["video_labelling"],
      }),
      readVideoLabels: build.query<
        ReadVideoLabelsApiResponse,
        ReadVideoLabelsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/read_video_labels`,
          params: {
            video_key: queryArg.videoKey,
          },
        }),
        providesTags: ["video_labelling"],
      }),
      writeVideoLabels: build.mutation<
        WriteVideoLabelsApiResponse,
        WriteVideoLabelsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/write_video_labels`,
          method: "POST",
          body: queryArg.videoLabels,
        }),
        invalidatesTags: ["video_labelling"],
      }),
      getRailcars: build.query<GetRailcarsApiResponse, GetRailcarsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/railcars/${queryArg.key}`,
        }),
        providesTags: ["video_labelling"],
      }),
      getPendingDeliveries: build.query<
        GetPendingDeliveriesApiResponse,
        GetPendingDeliveriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/delivery_tracking/${queryArg.tenantName}/pending_deliveries`,
        }),
        providesTags: ["delivery_tracking"],
      }),
      uploadPendingDeliveries: build.mutation<
        UploadPendingDeliveriesApiResponse,
        UploadPendingDeliveriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/delivery_tracking/${queryArg.tenantName}/upload_pending_deliveries`,
          method: "POST",
          body: queryArg.uploadedFileNames,
        }),
        invalidatesTags: ["delivery_tracking"],
      }),
      clearUploadedPendingDeliveries: build.mutation<
        ClearUploadedPendingDeliveriesApiResponse,
        ClearUploadedPendingDeliveriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/delivery_tracking/${queryArg.tenantName}/clear_uploaded_pending_deliveries`,
          method: "POST",
        }),
        invalidatesTags: ["delivery_tracking"],
      }),
      getPendingDeliveriesMap: build.mutation<
        GetPendingDeliveriesMapApiResponse,
        GetPendingDeliveriesMapApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/delivery_tracking/${queryArg.tenantName}/pending_deliveries_map`,
          method: "POST",
          body: queryArg.uploadedFileNames,
        }),
        invalidatesTags: ["delivery_tracking"],
      }),
      getMixMaterialLimitSet: build.query<
        GetMixMaterialLimitSetApiResponse,
        GetMixMaterialLimitSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/mix_material_limit_set/${queryArg.mixMaterialLimitSetId}`,
          params: {
            chef_group_id: queryArg.chefGroupId,
          },
        }),
        providesTags: ["mix_material_limits"],
      }),
      updateSearchMixMaterialLimitSet: build.mutation<
        UpdateSearchMixMaterialLimitSetApiResponse,
        UpdateSearchMixMaterialLimitSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/${queryArg.searchId}/mix_material_limit_set`,
          method: "POST",
          body: queryArg.mixMaterialLimits,
        }),
        invalidatesTags: ["mix_material_limits"],
      }),
      getPicks: build.query<GetPicksApiResponse, GetPicksApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking/${queryArg.tenantName}/picks/${queryArg.year}/${queryArg.month}/${queryArg.day}`,
        }),
        providesTags: ["scrap_tracking"],
      }),
      getHeats: build.query<GetHeatsApiResponse, GetHeatsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking/${queryArg.tenantName}/heats/${queryArg.year}/${queryArg.month}/${queryArg.day}`,
        }),
        providesTags: ["scrap_tracking"],
      }),
      listAutoRecipeStrategies: build.query<
        ListAutoRecipeStrategiesApiResponse,
        ListAutoRecipeStrategiesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/auto_recipe_strategies`,
        }),
        providesTags: ["simulator"],
      }),
      listAutoRecipeEstimators: build.query<
        ListAutoRecipeEstimatorsApiResponse,
        ListAutoRecipeEstimatorsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/auto_recipe_estimators`,
        }),
        providesTags: ["simulator"],
      }),
      listSimulatorDataLoaders: build.query<
        ListSimulatorDataLoadersApiResponse,
        ListSimulatorDataLoadersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/simulator_data_loaders`,
        }),
        providesTags: ["simulator"],
      }),
      listSimulatorRuns: build.query<
        ListSimulatorRunsApiResponse,
        ListSimulatorRunsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/simulation`,
        }),
        providesTags: ["simulator"],
      }),
      simulate: build.mutation<SimulateApiResponse, SimulateApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/simulation`,
          method: "POST",
          body: queryArg.simulatorRunCreate,
        }),
        invalidatesTags: ["simulator"],
      }),
      getSimulatorRun: build.query<
        GetSimulatorRunApiResponse,
        GetSimulatorRunApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/simulation/${queryArg.simulatorRunId}`,
        }),
        providesTags: ["simulator"],
      }),
      summarisePlanMixes: build.mutation<
        SummarisePlanMixesApiResponse,
        SummarisePlanMixesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/summaries/${queryArg.tenantName}/plan_mixes`,
          method: "POST",
          body: queryArg.unsummarisedPlanMixes,
        }),
        invalidatesTags: ["summaries"],
      }),
      getStrategyDashboardInventory: build.query<
        GetStrategyDashboardInventoryApiResponse,
        GetStrategyDashboardInventoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/strategy_dashboard/${queryArg.tenantName}/inventory`,
        }),
        providesTags: ["strategy_dashboard", "strategy_dashboard"],
      }),
      getStrategyDashboardConsumption: build.query<
        GetStrategyDashboardConsumptionApiResponse,
        GetStrategyDashboardConsumptionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/strategy_dashboard/${queryArg.tenantName}/consumption`,
        }),
        providesTags: ["strategy_dashboard", "strategy_dashboard"],
      }),
      getStrategyDashboardFailureRate: build.query<
        GetStrategyDashboardFailureRateApiResponse,
        GetStrategyDashboardFailureRateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/strategy_dashboard/${queryArg.tenantName}/failure_rate`,
        }),
        providesTags: ["strategy_dashboard", "strategy_dashboard"],
      }),
      getStrategyDashboardPrices: build.query<
        GetStrategyDashboardPricesApiResponse,
        GetStrategyDashboardPricesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/strategy_dashboard/${queryArg.tenantName}/prices`,
        }),
        providesTags: ["strategy_dashboard", "strategy_dashboard"],
      }),
      getLabelProbabilities: build.query<
        GetLabelProbabilitiesApiResponse,
        GetLabelProbabilitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.start}/${queryArg.end}/label_probabilities`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getScaleWeights: build.query<
        GetScaleWeightsApiResponse,
        GetScaleWeightsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.start}/${queryArg.end}/scale_weights`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getScrapCarLoadingStatus: build.query<
        GetScrapCarLoadingStatusApiResponse,
        GetScrapCarLoadingStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.start}/${queryArg.end}/scrap_car_loading_status`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getCameraUptimePlot: build.query<
        GetCameraUptimePlotApiResponse,
        GetCameraUptimePlotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.startTimestamp}/${queryArg.endTimestamp}/camera_uptime_plot`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getMockTimeline: build.query<
        GetMockTimelineApiResponse,
        GetMockTimelineApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.startTimestamp}/${queryArg.endTimestamp}/mock_timeline`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getScaleWeightDropEvents: build.query<
        GetScaleWeightDropEventsApiResponse,
        GetScaleWeightDropEventsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.start}/${queryArg.end}/get_scale_weight_drop_events`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getVisionModelDropEvents: build.query<
        GetVisionModelDropEventsApiResponse,
        GetVisionModelDropEventsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.start}/${queryArg.end}/get_vision_model_drop_events`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getHeatSummaries: build.query<
        GetHeatSummariesApiResponse,
        GetHeatSummariesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.start}/${queryArg.end}/heat_summaries`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getHeatSummary: build.query<
        GetHeatSummaryApiResponse,
        GetHeatSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/heat_summary/${queryArg.heatId}`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getLabelSources: build.query<
        GetLabelSourcesApiResponse,
        GetLabelSourcesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.start}/${queryArg.end}/label_sources`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      getScrapCarCycles: build.query<
        GetScrapCarCyclesApiResponse,
        GetScrapCarCyclesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking_dashboard/${queryArg.tenantName}/${queryArg.start}/${queryArg.end}/scrap_car_cycles`,
        }),
        providesTags: ["scrap_tracking_dashboard", "scrap_tracking_dashboard"],
      }),
      listPurchasingPlans: build.query<
        ListPurchasingPlansApiResponse,
        ListPurchasingPlansApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/purchasing/${queryArg.tenantName}/plans`,
        }),
        providesTags: ["purchasing"],
      }),
      createPurchasingPlan: build.mutation<
        CreatePurchasingPlanApiResponse,
        CreatePurchasingPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/purchasing/${queryArg.tenantName}/plans`,
          method: "POST",
          body: queryArg.purchasingPlanCreate,
        }),
        invalidatesTags: ["purchasing"],
      }),
      getPurchasingPlan: build.query<
        GetPurchasingPlanApiResponse,
        GetPurchasingPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/purchasing/${queryArg.tenantName}/plans/${queryArg.purchasingPlanId}`,
          params: {
            revision_id: queryArg.revisionId,
          },
        }),
        providesTags: ["purchasing"],
      }),
      addQuotes: build.mutation<AddQuotesApiResponse, AddQuotesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/purchasing/${queryArg.tenantName}/plans/${queryArg.purchasingPlanId}/quotes`,
          method: "POST",
          body: queryArg.purchasingPlanQuotesAdd,
        }),
        invalidatesTags: ["purchasing"],
      }),
      listSuppliers: build.query<ListSuppliersApiResponse, ListSuppliersApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/purchasing/${queryArg.tenantName}/suppliers`,
          }),
          providesTags: ["purchasing"],
        },
      ),
      getMe: build.query<GetMeApiResponse, GetMeApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/me`,
        }),
        providesTags: ["auth"],
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/users`,
        }),
        providesTags: ["auth"],
      }),
      getUserByEmail: build.query<
        GetUserByEmailApiResponse,
        GetUserByEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/user`,
          params: {
            email: queryArg.email,
          },
        }),
        providesTags: ["auth"],
      }),
      updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/user`,
          method: "PATCH",
          body: queryArg.userUpdate,
        }),
        invalidatesTags: ["auth"],
      }),
      syncWithFirebase: build.mutation<
        SyncWithFirebaseApiResponse,
        SyncWithFirebaseApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/auth/users/${queryArg.tenantName}/sync`,
          method: "POST",
        }),
        invalidatesTags: ["auth"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as generatedApi };
export type GetTenantMetadataApiResponse =
  /** status 200 Successful Response */ Tenant;
export type GetTenantMetadataApiArg = {
  tenantName: string;
};
export type GetSimilarChargedRecipesApiResponse =
  /** status 200 Successful Response */ SimilarChargedRecipe[];
export type GetSimilarChargedRecipesApiArg = {
  tenantName: string;
  similarChargedRecipesParams: SimilarChargedRecipesParams;
};
export type CreateAutoRecipeApiResponse =
  /** status 200 Successful Response */ SearchRead;
export type CreateAutoRecipeApiArg = {
  tenantName: string;
  autoRecipeType: SearchType;
  onTimestamp?: string;
  /** Immediately mark the search session as deleted */
  deleted?: boolean;
};
export type ListChefGroupsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListChefGroupsApiArg = {
  tenantName: string;
};
export type CreateChefGroupingApiResponse =
  /** status 200 Successful Response */ ChefGroupingRead;
export type CreateChefGroupingApiArg = {
  tenantName: string;
  chefGroupingCreate: ChefGroupingCreate;
};
export type GetChefGroupingApiResponse =
  /** status 200 Successful Response */ ChefGroupingRead;
export type GetChefGroupingApiArg = {
  tenantName: string;
  chefGroupingId: number | Default;
};
export type SetDefaultChefGroupingApiResponse = unknown;
export type SetDefaultChefGroupingApiArg = {
  tenantName: string;
  chefGroupingId: number;
};
export type ListSteelGradesApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListSteelGradesApiArg = {
  tenantName: string;
};
export type CreateSteelGradeSetApiResponse =
  /** status 200 Successful Response */ SteelGradeSetRead;
export type CreateSteelGradeSetApiArg = {
  tenantName: string;
  steelGradeSetCreate: SteelGradeSetCreate;
};
export type GetSteelGradeSetApiResponse =
  /** status 200 Successful Response */ SteelGradeSetRead;
export type GetSteelGradeSetApiArg = {
  tenantName: string;
  steelGradeSetId: number | Default;
};
export type SetDefaultSteelGradeSetApiResponse = unknown;
export type SetDefaultSteelGradeSetApiArg = {
  tenantName: string;
  steelGradeSetId: number;
};
export type ListSteelGradePlansApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListSteelGradePlansApiArg = {
  tenantName: string;
};
export type CreateSteelGradePlanApiResponse =
  /** status 200 Successful Response */ SteelGradePlanRead;
export type CreateSteelGradePlanApiArg = {
  tenantName: string;
  steelGradePlanCreate: SteelGradePlanCreate;
};
export type GetSteelGradePlanApiResponse =
  /** status 200 Successful Response */ SteelGradePlanRead;
export type GetSteelGradePlanApiArg = {
  tenantName: string;
  steelGradePlanId: number | Default;
};
export type SetDefaultSteelGradePlanApiResponse = unknown;
export type SetDefaultSteelGradePlanApiArg = {
  tenantName: string;
  steelGradePlanId: number;
};
export type GetTypicalSteelGradePlanApiResponse =
  /** status 200 Successful Response */ SteelGradePlanRead;
export type GetTypicalSteelGradePlanApiArg = {
  tenantName: string;
  steelGradeSetId: number;
  numHeats: number;
};
export type GetSteelGradePlanItemsFromProductGroupPlanItemsApiResponse =
  /** status 200 Successful Response */ SteelGradePlanItem[];
export type GetSteelGradePlanItemsFromProductGroupPlanItemsApiArg = {
  tenantName: string;
  steelGradeSetId: number;
  productGroupPlanItems: ProductGroupPlanItem[];
};
export type GetFormattedBasketConstraintsApiResponse =
  /** status 200 Successful Response */ FormattedBasketConstraint[];
export type GetFormattedBasketConstraintsApiArg = {
  tenantName: string;
};
export type UpdateSearchChefGroupBasketMaterialLimitsApiResponse =
  /** status 200 Successful Response */ number;
export type UpdateSearchChefGroupBasketMaterialLimitsApiArg = {
  tenantName: string;
  searchId: number;
  chefGroupBasketMaterialLimitSetCreate: ChefGroupBasketMaterialLimitSetCreate;
};
export type GetChefGroupBasketMaterialLimitSetApiResponse =
  /** status 200 Successful Response */ ChefGroupBasketMaterialLimitSetRead;
export type GetChefGroupBasketMaterialLimitSetApiArg = {
  tenantName: string;
  chefGroupBasketMaterialLimitSetId: number;
};
export type ListChemicalElementsApiResponse =
  /** status 200 Successful Response */ ChemicalElement[];
export type ListChemicalElementsApiArg = {
  tenantName: string;
};
export type GetCraneAssignedBasketApiResponse =
  /** status 200 Successful Response */ LoadBasketRead;
export type GetCraneAssignedBasketApiArg = {
  tenantName: string;
  craneUserUid: string;
};
export type GetMaterialMetadataSetApiResponse =
  /** status 200 Successful Response */ MaterialMetadataSetRead;
export type GetMaterialMetadataSetApiArg = {
  tenantName: string;
  materialMetadataSetId: number | Default;
};
export type ListMaterialConstraintClassesApiResponse =
  /** status 200 Successful Response */ MaterialConstraintClass[];
export type ListMaterialConstraintClassesApiArg = {
  tenantName: string;
  materialMetadataSetId: number;
  materialPhysicsSetId: number;
};
export type CreateMaterialChemistrySetApiResponse =
  /** status 200 Successful Response */ MaterialChemistrySetRead;
export type CreateMaterialChemistrySetApiArg = {
  tenantName: string;
  materialChemistrySetCreate: MaterialChemistrySetCreate;
};
export type ListMaterialChemistrySetsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListMaterialChemistrySetsApiArg = {
  tenantName: string;
};
export type GetMaterialChemistrySetApiResponse =
  /** status 200 Successful Response */ MaterialChemistrySetRead;
export type GetMaterialChemistrySetApiArg = {
  tenantName: string;
  materialChemistrySetId: number | Default;
};
export type SetDefaultMaterialChemistrySetApiResponse = unknown;
export type SetDefaultMaterialChemistrySetApiArg = {
  tenantName: string;
  materialChemistrySetId: number;
};
export type CreateMaterialPhysicsSetApiResponse =
  /** status 200 Successful Response */ MaterialPhysicsSetRead;
export type CreateMaterialPhysicsSetApiArg = {
  tenantName: string;
  materialPhysicsSetCreate: MaterialPhysicsSetCreate;
};
export type ListMaterialPhysicsSetsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListMaterialPhysicsSetsApiArg = {
  tenantName: string;
};
export type GetMaterialPhysicsSetApiResponse =
  /** status 200 Successful Response */ MaterialPhysicsSetRead;
export type GetMaterialPhysicsSetApiArg = {
  tenantName: string;
  materialPhysicsSetId: number | Default;
};
export type SetDefaultMaterialPhysicsSetApiResponse = unknown;
export type SetDefaultMaterialPhysicsSetApiArg = {
  tenantName: string;
  materialPhysicsSetId: number;
};
export type GetMaterialPricesApiResponse =
  /** status 200 Successful Response */ {
    [key: string]: number;
  };
export type GetMaterialPricesApiArg = {
  tenantName: string;
  timestamp: string;
};
export type ListLoadsApiResponse =
  /** status 200 Successful Response */ LoadRead[];
export type ListLoadsApiArg = {
  tenantName: string;
  createdSince?: string | null;
  createdBefore?: string | null;
};
export type CreateLoadApiResponse =
  /** status 200 Successful Response */ LoadRead;
export type CreateLoadApiArg = {
  tenantName: string;
  loadCreate: LoadCreate;
};
export type UpdateLoadApiResponse =
  /** status 200 Successful Response */ LoadRead;
export type UpdateLoadApiArg = {
  tenantName: string;
  loadUpdate: LoadUpdate;
};
export type ListCranesApiResponse =
  /** status 200 Successful Response */ CraneList;
export type ListCranesApiArg = {
  tenantName: string;
};
export type GetLoadApiResponse = /** status 200 Successful Response */ LoadRead;
export type GetLoadApiArg = {
  tenantName: string;
  loadId: number;
};
export type DeleteLoadApiResponse = unknown;
export type DeleteLoadApiArg = {
  tenantName: string;
  loadId: number;
};
export type GetLoadBasketApiResponse =
  /** status 200 Successful Response */ LoadBasketRead;
export type GetLoadBasketApiArg = {
  tenantName: string;
  loadBasketId: number;
};
export type UpdateLoadBasketStatusApiResponse =
  /** status 200 Successful Response */ LoadBasketRead;
export type UpdateLoadBasketStatusApiArg = {
  tenantName: string;
  loadBasketId: number;
  loadBasketStatusUpdate: LoadBasketStatusUpdate;
};
export type UpdateLoadBasketAssignmentApiResponse =
  /** status 200 Successful Response */ LoadBasketRead;
export type UpdateLoadBasketAssignmentApiArg = {
  tenantName: string;
  loadBasketId: number;
  loadBasketAssignmentUpdate: LoadBasketAssignmentUpdate;
};
export type UpdateLoadBasketAdditionsApiResponse =
  /** status 200 Successful Response */ LoadBasketRead;
export type UpdateLoadBasketAdditionsApiArg = {
  tenantName: string;
  loadBasketId: number;
  loadBasketAdditionUpdate: LoadBasketAdditionUpdate;
};
export type GetPlanMixesApiResponse =
  /** status 200 Successful Response */ NamedPlanMixes;
export type GetPlanMixesApiArg = {
  tenantName: string;
  planId: number;
  period: number;
};
export type GetDeployedPlansApiResponse =
  /** status 200 Successful Response */ DeployedPlans;
export type GetDeployedPlansApiArg = {
  tenantName: string;
  deploymentTypes?: DeploymentType[] | null;
};
export type GetDeployedPlanMixesApiResponse =
  /** status 200 Successful Response */ DeployedNamedPlanMixes;
export type GetDeployedPlanMixesApiArg = {
  tenantName: string;
  planId: number | Latest | Next;
  period: number;
  deploymentTypes?: DeploymentType[] | null;
};
export type GetPlanMixesExcelApiResponse =
  /** status 200 Successful Response */ Blob;
export type GetPlanMixesExcelApiArg = {
  tenantName: string;
  planId: number;
  language: SupportedLanguage;
  period?: number | null;
};
export type DeployPlanApiResponse = unknown;
export type DeployPlanApiArg = {
  tenantName: string;
  planId: number;
  deployParameters: DeployParameters;
};
export type GetActiveLoadDropWeightsAtApiResponse =
  /** status 200 Successful Response */ LiveChargeSummary[];
export type GetActiveLoadDropWeightsAtApiArg = {
  tenantName: string;
  atTimestamp?: string | null;
};
export type GetObtainableBundleItemSetApiResponse =
  /** status 200 Successful Response */ ObtainableBundleItemSet;
export type GetObtainableBundleItemSetApiArg = {
  tenantName: string;
  obtainableBundleSetId: number;
  materialMetadataSetId?: number | null;
};
export type CreateObtainableBundleItemSetApiResponse =
  /** status 200 Successful Response */ ObtainableBundleItemSet;
export type CreateObtainableBundleItemSetApiArg = {
  tenantName: string;
  obtainableBundleItemSetCreate: ObtainableBundleItemSetCreate;
};
export type ListProductGroupingsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListProductGroupingsApiArg = {
  tenantName: string;
};
export type CreateProductGroupingApiResponse =
  /** status 200 Successful Response */ ProductGroupingRead;
export type CreateProductGroupingApiArg = {
  tenantName: string;
  productGroupingCreate: ProductGroupingCreate;
};
export type GetProductGroupingApiResponse =
  /** status 200 Successful Response */ ProductGroupingRead;
export type GetProductGroupingApiArg = {
  tenantName: string;
  productGroupingId: number | Default;
};
export type SetDefaultProductGroupingApiResponse = unknown;
export type SetDefaultProductGroupingApiArg = {
  tenantName: string;
  productGroupingId: number;
};
export type ListProductGroupPlansApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListProductGroupPlansApiArg = {
  tenantName: string;
};
export type CreateProductGroupPlanApiResponse =
  /** status 200 Successful Response */ ProductGroupPlanRead;
export type CreateProductGroupPlanApiArg = {
  tenantName: string;
  productGroupPlanCreate: ProductGroupPlanCreate;
};
export type GetProductGroupPlanApiResponse =
  /** status 200 Successful Response */ ProductGroupPlanRead;
export type GetProductGroupPlanApiArg = {
  tenantName: string;
  productGroupPlanId: number | Default;
};
export type SetDefaultProductGroupPlanApiResponse = unknown;
export type SetDefaultProductGroupPlanApiArg = {
  tenantName: string;
  productGroupPlanId: number;
};
export type GetTypicalProductGroupPlanApiResponse =
  /** status 200 Successful Response */ ProductGroupPlanRead;
export type GetTypicalProductGroupPlanApiArg = {
  tenantName: string;
  productGroupingId: number;
  numHeats: number;
};
export type GetProductGroupPlanItemsFromSteelGradePlanItemsApiResponse =
  /** status 200 Successful Response */ ProductGroupPlanItem[];
export type GetProductGroupPlanItemsFromSteelGradePlanItemsApiArg = {
  tenantName: string;
  productGroupingId: number;
  steelGradePlanItems: SteelGradePlanItem[];
};
export type ListSearchesApiResponse =
  /** status 200 Successful Response */ SearchSummary[];
export type ListSearchesApiArg = {
  tenantName: string;
  ordering: Ordering;
  sessionId?: number | null;
  limit?: number | null;
  status?: SearchStatus | null;
};
export type SendSearchNotificationApiResponse = unknown;
export type SendSearchNotificationApiArg = {
  tenantName: string;
  searchId: number;
};
export type CreateSearchFromContextApiResponse =
  /** status 200 Successful Response */ SearchRead;
export type CreateSearchFromContextApiArg = {
  tenantName: string;
  searchId: number;
};
export type RunSearchApiResponse =
  /** status 200 Successful Response */ SearchResult;
export type RunSearchApiArg = {
  tenantName: string;
  searchId: number;
  runSearchOptions: RunSearchOptions;
};
export type GetSearchApiResponse =
  /** status 200 Successful Response */ SearchRead;
export type GetSearchApiArg = {
  tenantName: string;
  searchId: number;
};
export type CreateSearchApiResponse =
  /** status 200 Successful Response */ SearchRead;
export type CreateSearchApiArg = {
  tenantName: string;
  searchCreate: SearchCreate;
};
export type GetSearchContextApiResponse =
  /** status 200 Successful Response */ SearchContextData;
export type GetSearchContextApiArg = {
  tenantName: string;
  searchContextId: number;
};
export type GetNameBasedSearchApiResponse =
  /** status 200 Successful Response */ NameBasedSearch;
export type GetNameBasedSearchApiArg = {
  tenantName: string;
  searchId: number;
};
export type OverwriteFromNameBasedSearchApiResponse =
  /** status 200 Successful Response */ SearchRead;
export type OverwriteFromNameBasedSearchApiArg = {
  tenantName: string;
  searchId: number;
  nameBasedSearchInput: NameBasedSearch2;
};
export type CreateSearchContextGroupApiResponse =
  /** status 200 Successful Response */ SearchContextGroup;
export type CreateSearchContextGroupApiArg = {
  tenantName: string;
  searchContextGroupCreate: SearchContextGroupCreate;
};
export type ListSearchContextGroupsApiResponse =
  /** status 200 Successful Response */ SearchContextGroupSummary[];
export type ListSearchContextGroupsApiArg = {
  tenantName: string;
};
export type GetSearchContextGroupApiResponse =
  /** status 200 Successful Response */ SearchContextGroup;
export type GetSearchContextGroupApiArg = {
  tenantName: string;
  searchContextGroupId: number;
};
export type UpdateSearchContextGroupMixMaterialLimitsApiResponse =
  /** status 200 Successful Response */ SearchContextGroup;
export type UpdateSearchContextGroupMixMaterialLimitsApiArg = {
  tenantName: string;
  searchContextGroupId: number;
  searchContextId: number;
  mixMaterialLimits: MixMaterialLimitCreate[];
};
export type ListDeployedPlansApiResponse =
  /** status 200 Successful Response */ DeployedPlanRead[];
export type ListDeployedPlansApiArg = {
  tenantName: string;
};
export type SendPlanNotificationApiResponse = unknown;
export type SendPlanNotificationApiArg = {
  tenantName: string;
  planId: number;
  planName: string;
};
export type UpdatePlanMixesApiResponse =
  /** status 200 Successful Response */ NamedPlanMixes;
export type UpdatePlanMixesApiArg = {
  tenantName: string;
  planId: number;
  planMixUpdates: PlanMixUpdates;
};
export type UpdateAndDeployPlanMixesApiResponse =
  /** status 200 Successful Response */ DeployedNamedPlanMixes;
export type UpdateAndDeployPlanMixesApiArg = {
  tenantName: string;
  planId: number;
  deployedPlanMixUpdates: DeployedPlanMixUpdates;
};
export type CompareMixesApiResponse =
  /** status 200 Differences between two mixes including material mass changes. */ MixDifferences;
export type CompareMixesApiArg = {
  tenantName: string;
  materialMetadataSetId: number;
  /** ID of first mix to compare */
  srcMixId: number;
  /** ID of second mix to compare */
  tgtMixId: number;
};
export type ComparePlansApiResponse =
  /** status 200 Differences between two plans including optimisation task info and deployment history. */ PlanDifferences;
export type ComparePlansApiArg = {
  tenantName: string;
  sourcePlanId: number;
  targetPlanId: number;
};
export type GetPlanAncestorsApiResponse =
  /** status 200 A list of plan differences between the ancestor plans. */ PlanAncestorDiffs;
export type GetPlanAncestorsApiArg = {
  tenantName: string;
  planId: number;
};
export type GetBackupsApiResponse =
  /** status 200 Successful Response */ PlanBackupSummaries;
export type GetBackupsApiArg = {
  tenantName: string;
  planId: number;
};
export type GetPlanApiResponse = /** status 200 Successful Response */ Plan;
export type GetPlanApiArg = {
  tenantName: string;
  planId: number;
};
export type ListSessionsApiResponse =
  /** status 200 Successful Response */ SessionSummary[];
export type ListSessionsApiArg = {
  tenantName: string;
};
export type CreateSessionApiResponse =
  /** status 200 Successful Response */ SearchRead;
export type CreateSessionApiArg = {
  tenantName: string;
  sessionCreate: SessionCreate;
};
export type RenameSessionApiResponse = unknown;
export type RenameSessionApiArg = {
  tenantName: string;
  sessionId: number;
  renameSession: RenameSession;
};
export type DeleteSessionApiResponse = unknown;
export type DeleteSessionApiArg = {
  tenantName: string;
  sessionId: number;
};
export type CopySearchApiResponse =
  /** status 200 Successful Response */ SearchRead;
export type CopySearchApiArg = {
  tenantName: string;
  sessionId: number;
  copySearch: CopySearch;
};
export type GetResultApiResponse =
  /** status 200 Successful Response */ SearchResult;
export type GetResultApiArg = {
  tenantName: string;
  searchId: number;
};
export type GetNameBasedResultsApiResponse =
  /** status 200 Successful Response */ ResultsRead[];
export type GetNameBasedResultsApiArg = {
  tenantName: string;
  searchId: number;
};
export type SaveUploadedFileApiResponse =
  /** status 200 Successful Response */ UploadedFileMeta;
export type SaveUploadedFileApiArg = {
  tenantName: string;
  uploadedFileCreate: UploadedFileCreate;
};
export type ListUploadedFilesApiResponse =
  /** status 200 Successful Response */ UploadedFileMeta[];
export type ListUploadedFilesApiArg = {
  tenantName: string;
  types: UploadedFileType[];
  deleted?: boolean | null;
  since?: string | null;
  limit?: number | null;
  order?: Ordering;
};
export type GetUploadedFileApiResponse =
  /** status 200 Successful Response */ UploadedFileRead;
export type GetUploadedFileApiArg = {
  tenantName: string;
  uploadedFileId: number;
};
export type ReassignUploadedFileApiResponse =
  /** status 200 Successful Response */ UploadedFileMeta;
export type ReassignUploadedFileApiArg = {
  tenantName: string;
  uploadedFileId: number;
  uploadedFileType: UploadedFileType;
};
export type ListUploadedFileTypesApiResponse =
  /** status 200 Successful Response */ UploadedFileType[];
export type ListUploadedFileTypesApiArg = {
  tenantName: string;
  outputTypes: UploadedFileOutputTypeList | null;
};
export type GetParsedSearchParametersApiResponse =
  /** status 200 Successful Response */ UploadedSearchParameters;
export type GetParsedSearchParametersApiArg = {
  tenantName: string;
  uploadedFileId: number;
  materialMetadataSetId: number;
  steelGradeSetId: number;
};
export type GetPerHeatObtainableEstimateFromTypicalConsumptionApiResponse =
  /** status 200 Successful Response */ ObtainableEstimate[];
export type GetPerHeatObtainableEstimateFromTypicalConsumptionApiArg = {
  tenantName: string;
  materialMetadataSetId: number;
};
export type GetObtainableEstimateFromPurchasingPlanApiResponse =
  /** status 200 Successful Response */ ObtainableEstimate[];
export type GetObtainableEstimateFromPurchasingPlanApiArg = {
  tenantName: string;
  materialMetadataSetId: number;
  purchasingPlanFileId: number;
};
export type GetLayeringApiResponse =
  /** status 200 Successful Response */ PublishedChargeScheduleLayering;
export type GetLayeringApiArg = {
  tenantName: string;
  planId: number;
  period: number;
  groupId: number;
  mixNumber: number;
};
export type PublishPlanChargeScheduleApiResponse =
  /** status 200 Successful Response */ PlanPublishedChargeScheduleRead[];
export type PublishPlanChargeScheduleApiArg = {
  tenantName: string;
  publishPlans: PublishPlans;
};
export type ListNewPublishedChargeSchedulesApiResponse =
  /** status 200 Successful Response */ PublishedChargeScheduleRead[];
export type ListNewPublishedChargeSchedulesApiArg = {
  tenantName: string;
};
export type ListAllPublishedChargeSchedulesApiResponse =
  /** status 200 Successful Response */ PublishedChargeScheduleRead[];
export type ListAllPublishedChargeSchedulesApiArg = {
  tenantName: string;
  planId?: number | null;
};
export type ListCustomerPublishedChargeScheduleLayersApiResponse =
  /** status 200 Successful Response */ CustomerPublishedChargeScheduleLayer[];
export type ListCustomerPublishedChargeScheduleLayersApiArg = {
  tenantName: string;
  publishedChargeScheduleId: number;
};
export type ListCustomerPublishedChargeScheduleLayersRecentApiResponse =
  /** status 200 Successful Response */ CustomerPublishedChargeScheduleLayer[];
export type ListCustomerPublishedChargeScheduleLayersRecentApiArg = {
  tenantName: string;
  customerChargeScheduleId: string;
};
export type ConfirmPublishedChargeSchedulesApiResponse =
  /** status 200 Successful Response */ string;
export type ConfirmPublishedChargeSchedulesApiArg = {
  tenantName: string;
  confirmChargeSchedules: ChargeScheduleConfirm[];
};
export type ListConfirmedChargeScheduleMetaApiResponse =
  /** status 200 Successful Response */ ConfirmedChargeScheduleMeta[];
export type ListConfirmedChargeScheduleMetaApiArg = {
  tenantName: string;
  fdmChargeScheduleIds: number[];
};
export type GetChargeScheduleMessageTemplateApiResponse =
  /** status 200 Successful Response */ ChargeScheduleMessage;
export type GetChargeScheduleMessageTemplateApiArg = {
  tenantName: string;
  planId: number;
  period: number;
};
export type SendChargeScheduleMessageListApiResponse = unknown;
export type SendChargeScheduleMessageListApiArg = {
  tenantName: string;
  chargeScheduleMessages: ChargeScheduleMessages;
};
export type SendPlanDeployedMessageApiResponse = unknown;
export type SendPlanDeployedMessageApiArg = {
  tenantName: string;
  planId: number;
};
export type ListQueuedMessagesApiResponse =
  /** status 200 Successful Response */ Message[];
export type ListQueuedMessagesApiArg = {
  tenantName: string;
};
export type ConfirmMessageSentApiResponse =
  /** status 200 Successful Response */ string;
export type ConfirmMessageSentApiArg = {
  tenantName: string;
  messageId: number;
};
export type GetInventoryEstimateAtTimestampApiResponse =
  /** status 200 Successful Response */ InventoryItem[];
export type GetInventoryEstimateAtTimestampApiArg = {
  tenantName: string;
  materialMetadataSetId: number;
  materialPhysicsSetId: number;
  timestamp: string;
};
export type GetRecipesImpuritiesPlotRiskApiResponse =
  /** status 200 Successful Response */ string;
export type GetRecipesImpuritiesPlotRiskApiArg = {
  tenantName: string;
  planId: number;
  mixId: number;
  period: number;
  language: SupportedLanguage;
  steelGradeIds?: number[] | null;
};
export type CreateTargetBasketSetApiResponse =
  /** status 200 Successful Response */ TargetBasketSetRead;
export type CreateTargetBasketSetApiArg = {
  tenantName: string;
  targetBasketSetCreate: TargetBasketSetCreate;
};
export type GetTargetBasketSetApiResponse =
  /** status 200 Successful Response */ TargetBasketSetRead;
export type GetTargetBasketSetApiArg = {
  tenantName: string;
  targetBasketSetId: number | Default;
};
export type InviteUsersApiResponse = unknown;
export type InviteUsersApiArg = {
  tenantName: string;
  userInvite: UserInvite;
};
export type GetPendingInvitesApiResponse =
  /** status 200 Successful Response */ InvitedUser[];
export type GetPendingInvitesApiArg = {
  tenantName: string;
};
export type DeleteUserInviteApiResponse = unknown;
export type DeleteUserInviteApiArg = {
  tenantName: string;
  email: string;
};
export type GetVideoUrlApiResponse =
  /** status 200 Successful Response */ string;
export type GetVideoUrlApiArg = {
  tenantName: string;
  videoKey: string;
  camera: number;
  downsampled: boolean;
};
export type SuggestVideoApiResponse =
  /** status 200 Successful Response */ string;
export type SuggestVideoApiArg = {
  tenantName: string;
  requiredMaterials: string[];
  prioritiseDaysWithFewestLabelledVideos: boolean;
};
export type ReadVideoLabelsApiResponse =
  /** status 200 Successful Response */ VideoLabels;
export type ReadVideoLabelsApiArg = {
  tenantName: string;
  videoKey: string;
};
export type WriteVideoLabelsApiResponse =
  /** status 200 Successful Response */ string;
export type WriteVideoLabelsApiArg = {
  tenantName: string;
  videoLabels: VideoLabels;
};
export type GetRailcarsApiResponse =
  /** status 200 Successful Response */ string;
export type GetRailcarsApiArg = {
  tenantName: string;
  key: string;
};
export type GetPendingDeliveriesApiResponse =
  /** status 200 Successful Response */ PendingDelivery[];
export type GetPendingDeliveriesApiArg = {
  tenantName: string;
};
export type UploadPendingDeliveriesApiResponse =
  /** status 200 Successful Response */ string;
export type UploadPendingDeliveriesApiArg = {
  tenantName: string;
  uploadedFileNames: UploadedFileNames;
};
export type ClearUploadedPendingDeliveriesApiResponse =
  /** status 200 Successful Response */ string;
export type ClearUploadedPendingDeliveriesApiArg = {
  tenantName: string;
};
export type GetPendingDeliveriesMapApiResponse =
  /** status 200 Successful Response */ string;
export type GetPendingDeliveriesMapApiArg = {
  tenantName: string;
  uploadedFileNames: UploadedFileNames;
};
export type GetMixMaterialLimitSetApiResponse =
  /** status 200 Successful Response */ MixMaterialLimitSetRead;
export type GetMixMaterialLimitSetApiArg = {
  tenantName: string;
  mixMaterialLimitSetId: number;
  chefGroupId?: number | null;
};
export type UpdateSearchMixMaterialLimitSetApiResponse =
  /** status 200 Successful Response */ number;
export type UpdateSearchMixMaterialLimitSetApiArg = {
  tenantName: string;
  searchId: number;
  mixMaterialLimits: MixMaterialLimitCreate[];
};
export type GetPicksApiResponse =
  /** status 200 Successful Response */ RealisedPick[];
export type GetPicksApiArg = {
  tenantName: string;
  year: number;
  month: number;
  day: number;
};
export type GetHeatsApiResponse =
  /** status 200 Successful Response */ PlannedHeat[];
export type GetHeatsApiArg = {
  tenantName: string;
  year: number;
  month: number;
  day: number;
};
export type ListAutoRecipeStrategiesApiResponse =
  /** status 200 Successful Response */ AutoRecipeStrategySpecs;
export type ListAutoRecipeStrategiesApiArg = {
  tenantName: string;
};
export type ListAutoRecipeEstimatorsApiResponse =
  /** status 200 Successful Response */ AutoRecipeEstimatorsSpecs;
export type ListAutoRecipeEstimatorsApiArg = {
  tenantName: string;
};
export type ListSimulatorDataLoadersApiResponse =
  /** status 200 Successful Response */ SimulatorDataLoadersSpecs;
export type ListSimulatorDataLoadersApiArg = {
  tenantName: string;
};
export type ListSimulatorRunsApiResponse =
  /** status 200 Successful Response */ SimulatorRunList;
export type ListSimulatorRunsApiArg = {
  tenantName: string;
};
export type SimulateApiResponse =
  /** status 200 Successful Response */ SimulatorRunRead;
export type SimulateApiArg = {
  tenantName: string;
  simulatorRunCreate: SimulatorRunCreate;
};
export type GetSimulatorRunApiResponse =
  /** status 200 Successful Response */ SimulatorRunRead;
export type GetSimulatorRunApiArg = {
  tenantName: string;
  simulatorRunId: number;
};
export type SummarisePlanMixesApiResponse =
  /** status 200 Successful Response */ SummarisedPlanMixes;
export type SummarisePlanMixesApiArg = {
  tenantName: string;
  unsummarisedPlanMixes: UnsummarisedPlanMixes;
};
export type GetStrategyDashboardInventoryApiResponse =
  /** status 200 Successful Response */ StrategyDashboardInventoryData;
export type GetStrategyDashboardInventoryApiArg = {
  tenantName: string;
};
export type GetStrategyDashboardConsumptionApiResponse =
  /** status 200 Successful Response */ StrategyDashboardConsumptionData;
export type GetStrategyDashboardConsumptionApiArg = {
  tenantName: string;
};
export type GetStrategyDashboardFailureRateApiResponse =
  /** status 200 Successful Response */ StrategyDashboardFailureRateData;
export type GetStrategyDashboardFailureRateApiArg = {
  tenantName: string;
};
export type GetStrategyDashboardPricesApiResponse =
  /** status 200 Successful Response */ StrategyDashboardPriceData;
export type GetStrategyDashboardPricesApiArg = {
  tenantName: string;
};
export type GetLabelProbabilitiesApiResponse =
  /** status 200 Successful Response */ LabelProbabilitiesData;
export type GetLabelProbabilitiesApiArg = {
  tenantName: string;
  start: number;
  end: number;
};
export type GetScaleWeightsApiResponse =
  /** status 200 Successful Response */ ScaleWeightsData;
export type GetScaleWeightsApiArg = {
  tenantName: string;
  start: number;
  end: number;
};
export type GetScrapCarLoadingStatusApiResponse =
  /** status 200 Successful Response */ ScrapCarLoadingStatusData;
export type GetScrapCarLoadingStatusApiArg = {
  tenantName: string;
  start: number;
  end: number;
};
export type GetCameraUptimePlotApiResponse =
  /** status 200 Successful Response */ PlotData;
export type GetCameraUptimePlotApiArg = {
  tenantName: string;
  startTimestamp: number;
  endTimestamp: number;
};
export type GetMockTimelineApiResponse =
  /** status 200 Successful Response */ PlotData;
export type GetMockTimelineApiArg = {
  tenantName: string;
  startTimestamp: number;
  endTimestamp: number;
};
export type GetScaleWeightDropEventsApiResponse =
  /** status 200 Successful Response */ DropEvents;
export type GetScaleWeightDropEventsApiArg = {
  tenantName: string;
  start: number;
  end: number;
};
export type GetVisionModelDropEventsApiResponse =
  /** status 200 Successful Response */ DropEvents;
export type GetVisionModelDropEventsApiArg = {
  tenantName: string;
  start: number;
  end: number;
};
export type GetHeatSummariesApiResponse =
  /** status 200 Successful Response */ HeatSummaries;
export type GetHeatSummariesApiArg = {
  tenantName: string;
  start: number;
  end: number;
};
export type GetHeatSummaryApiResponse =
  /** status 200 Successful Response */ HeatSummary;
export type GetHeatSummaryApiArg = {
  tenantName: string;
  heatId: string;
};
export type GetLabelSourcesApiResponse =
  /** status 200 Successful Response */ LabelSourcesData;
export type GetLabelSourcesApiArg = {
  tenantName: string;
  start: number;
  end: number;
};
export type GetScrapCarCyclesApiResponse =
  /** status 200 Successful Response */ ScrapCarCycles;
export type GetScrapCarCyclesApiArg = {
  tenantName: string;
  start: number;
  end: number;
};
export type ListPurchasingPlansApiResponse =
  /** status 200 Successful Response */ PurchasingPlanSummary[];
export type ListPurchasingPlansApiArg = {
  tenantName: string;
};
export type CreatePurchasingPlanApiResponse =
  /** status 200 Successful Response */ PurchasingPlanSummary;
export type CreatePurchasingPlanApiArg = {
  tenantName: string;
  purchasingPlanCreate: PurchasingPlanCreate;
};
export type GetPurchasingPlanApiResponse =
  /** status 200 Successful Response */ PurchasingPlanRead;
export type GetPurchasingPlanApiArg = {
  tenantName: string;
  purchasingPlanId: number;
  revisionId: number | Latest;
};
export type AddQuotesApiResponse =
  /** status 200 Successful Response */ PurchasingPlanRead;
export type AddQuotesApiArg = {
  tenantName: string;
  purchasingPlanId: number;
  purchasingPlanQuotesAdd: PurchasingPlanQuotesAdd;
};
export type ListSuppliersApiResponse =
  /** status 200 Successful Response */ SupplierList;
export type ListSuppliersApiArg = {
  tenantName: string;
};
export type GetMeApiResponse =
  /** status 200 Successful Response */ UserReadAll;
export type GetMeApiArg = {
  tenantName: string;
};
export type GetUsersApiResponse =
  /** status 200 Successful Response */ UserReadAll[];
export type GetUsersApiArg = {
  tenantName: string;
};
export type GetUserByEmailApiResponse =
  /** status 200 Successful Response */ UserReadAll;
export type GetUserByEmailApiArg = {
  tenantName: string;
  email: string;
};
export type UpdateUserApiResponse =
  /** status 200 Successful Response */ UserReadAll;
export type UpdateUserApiArg = {
  tenantName: string;
  userUpdate: UserUpdate;
};
export type SyncWithFirebaseApiResponse = unknown;
export type SyncWithFirebaseApiArg = {
  tenantName: string;
};
export type MixBreakdown = "percentage" | "mass";
export type ProductionPlanSource = "product_groups" | "steel_grades";
export type SearchType =
  | "charging"
  | "medium_term_charging"
  | "purchasing"
  | "simulator";
export type FeatureFlag =
  | "material_availability_import"
  | "search_context_groups"
  | "electrical_energy_price"
  | "plan_excel_download"
  | "inventory_estimate_at_timestamp"
  | "manual_entry_crane_ui"
  | "auto_backups";
export type DeployProcess =
  | "sync_and_sms"
  | "edit_and_send"
  | "message"
  | "none";
export type BasketTabType = "basket_plot" | "layering_table";
export type UnitType =
  | "mass"
  | "cost"
  | "specific_cost"
  | "electrical_energy_consumption"
  | "mass_fraction"
  | "yield"
  | "volume"
  | "density"
  | "energy"
  | "specific_energy_cost";
export type UnitSpec = {
  unit_type: UnitType;
  units: string;
};
export type Tenant = {
  tenant_name: string;
  long_name: string;
  typical_heats_per_day: number;
  created_at: string;
  default_mix_breakdown: MixBreakdown;
  default_production_plan_source: ProductionPlanSource;
  default_language: string;
  auto_search_types: SearchType[];
  feature_flags: FeatureFlag[];
  deploy_process: DeployProcess;
  basket_tab_type: BasketTabType;
  units: UnitSpec[];
  translation_overrides: {
    [key: string]: string;
  };
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type SimilarChargedRecipeSummary = {
  price_per_tonne_tapped: number;
  recipe_mass: number;
  tapped_mass: number;
  total_cost_per_heat: number;
};
export type SimilarChargedRecipeMaterial = {
  material_name: string;
  mass: number;
  basket_layer_name: string;
};
export type SimilarChargedRecipeChemistry = {
  chemical_element_symbol: string;
  weight_percent: number;
  sample_ordinal: number;
  factory_entity: string;
};
export type SimilarChargedRecipe = {
  customer_heat_id: string;
  start_time: string;
  steel_grade_name: string;
  summary: SimilarChargedRecipeSummary;
  materials: SimilarChargedRecipeMaterial[];
  chemistry: SimilarChargedRecipeChemistry[];
};
export type DistanceMetricEnum = "euclidean" | "manhattan" | "cosine";
export type SimilarChargedRecipesParams = {
  optimisation_result_set_id: number;
  chef_group_id: number;
  mix_number: number;
  period?: number;
  n_recipes: number;
  metric?: DistanceMetricEnum;
  steel_grade_name?: string | null;
};
export type MassDisplaySummaryType = "tapped" | "billet";
export type MiscParams = {
  /** Whether to allow obtainable scrap to be used during the 'planned' production. If `False`, obtainable scrap will only be used during the 'forecast' production. */
  allow_obtainable_during_plan: boolean;
  recipe_decimal_places: number;
  /** What type of summary to show in the UI for steel mass, yield, price per tonne, etc. */
  mass_display_summary_type: MassDisplaySummaryType;
  integer_solution: boolean;
  uniform_deployable_mixes: boolean;
  optimisation_time_limit: number | null;
};
export type PhysicalParameters = {
  target_tapped_mass_lower: number;
  target_tapped_mass_upper: number;
  tapped_to_billet_yield: number;
  max_distinct_materials_per_mix: number | null;
  typical_hot_heel_mass: number;
  target_charged_mass_lower: number | null;
  target_charged_mass_upper: number | null;
};
export type InventoryItem = {
  material_id: number;
  quantity: number | null;
  specific_price: number | null;
  previous_quantity: number | null;
  deliveries_since_previous: number | null;
  consumption_since_previous: number | null;
  projected_deliveries_since_previous: number | null;
  projected_consumption_since_previous: number | null;
};
export type MaterialChemistry = {
  chemical_element_id: number;
  material_id: number;
  loc: number;
  scale: number;
};
export type MixBasketLayer = {
  material_name: string;
  mass: number;
  layer_number: number;
};
export type MixBasket = {
  basket_number: number;
  layers: MixBasketLayer[];
};
export type MixCreate = {
  baskets: MixBasket[];
};
export type FixedMixOverride = {
  steel_grade_name: string;
  mix: MixCreate | null;
};
export type ProductGroupPlanItem = {
  product_group_id: number;
  num_heats: number;
};
export type SteelGradePlanItem = {
  steel_grade_id: number;
  num_heats: number;
};
export type SearchPeriodProductionPlan = {
  product_group_items: ProductGroupPlanItem[] | null;
  steel_grade_items: SteelGradePlanItem[] | null;
};
export type ObtainableBundleItem = {
  uuid: string;
  material_id: number;
  created_at: string;
  label: string;
  min_obtainable: number | null;
  max_obtainable: number | null;
  specific_price: number | null;
  arrival_probability: number | null;
};
export type TargetInventoryItem = {
  material_id: number;
  min_quantity: number | null;
  max_quantity: number | null;
};
export type TargetBasketItem = {
  chef_group_id: number;
  target_num_baskets: number;
};
export type PeriodMaterialExclusivityConstraintCreate = {
  material_ids: number[];
};
export type SearchPeriod = {
  name: string | null;
  start_timestamp: string | null;
  end_timestamp: string | null;
  is_deployable: boolean;
  production_plan: SearchPeriodProductionPlan;
  obtainable_bundles: ObtainableBundleItem[];
  target_inventory: TargetInventoryItem[];
  target_baskets: TargetBasketItem[];
  material_exclusivity: PeriodMaterialExclusivityConstraintCreate[];
  suppress_mix_material_exclusivity_constraints: boolean;
  suppress_min_feasible_mass_constraints: boolean;
  /** The weighting for the period in the optimiser's objective function. A higher weighting means that the optimiser will prioritise this period over others. */
  optimisation_objective_weighting: number;
  electrical_energy_price: number;
};
export type SearchRead = {
  session_id: number;
  session_name: string;
  session_created_at: string;
  session_created_by_email: string;
  parent_id: number | null;
  search_type: SearchType;
  context_id: number;
  misc_params: MiscParams;
  physical_parameters: PhysicalParameters;
  inventory: InventoryItem[];
  material_chemistry_overrides: MaterialChemistry[];
  fixed_mix_overrides: FixedMixOverride[];
  periods: SearchPeriod[];
  id: number;
};
export type Metadata = {
  id: number;
  name: string;
  created_at: string;
  created_by_id: string;
  default?: boolean | null;
};
export type ChemicalElement = {
  id: number;
  name: string;
  symbol: string;
};
export type ChemicalConstraintCoefficient = {
  chemical_element: ChemicalElement;
  coefficient: number;
};
export type ChemicalConstraint = {
  weight_percent_max: number;
  adjusted_weight_percent_max: number;
  coefficients: ChemicalConstraintCoefficient[];
  name?: string | null;
};
export type SteelGradeSummary = {
  id: number;
  name: string;
};
export type ExclusiveMaterialSet = {
  material_ids: number[];
  violation_cost?: number | null;
};
export type ChefGroup = {
  id?: number | null;
  product_group_id: number;
  name: string;
  fraction_of_heats: number;
  default_target_num_baskets: number;
  max_failure_rate: number;
  chemical_constraints: ChemicalConstraint[];
  steel_grades: SteelGradeSummary[];
  exclusivity_constraints: ExclusiveMaterialSet[];
};
export type ChefGroupingRead = {
  name?: string;
  chef_groups: ChefGroup[];
  steel_grade_set_id: number;
  product_grouping_id: number;
  id: number;
  created_at: string;
  created_by_id: string;
};
export type ChemicalConstraint2 = {
  weight_percent_max: number;
  adjusted_weight_percent_max: number;
  coefficients: ChemicalConstraintCoefficient[];
  name?: string | null;
};
export type ChefGroup2 = {
  id?: number | null;
  product_group_id: number;
  name: string;
  fraction_of_heats: number;
  default_target_num_baskets: number;
  max_failure_rate: number;
  chemical_constraints: ChemicalConstraint2[];
  steel_grades: SteelGradeSummary[];
  exclusivity_constraints: ExclusiveMaterialSet[];
};
export type ChefGroupingCreate = {
  name?: string;
  chef_groups: ChefGroup2[];
  steel_grade_set_id: number;
  product_grouping_id: number;
};
export type Default = "default";
export type SteelGrade = {
  id: number;
  name: string;
  chemical_constraints: ChemicalConstraint[];
  fraction_of_heats: number;
};
export type SteelGradeSetRead = {
  id: number;
  name?: string;
  created_at: string;
  created_by_id: string;
  steel_grades: SteelGrade[];
};
export type SteelGradeCreate = {
  name: string;
  chemical_constraints: ChemicalConstraint2[];
  fraction_of_heats: number;
};
export type SteelGradeSetCreate = {
  name?: string;
  steel_grades: SteelGradeCreate[];
};
export type SteelGradePlanRead = {
  name: string;
  steel_grade_set_id: number;
  items: SteelGradePlanItem[];
  from_product_group_plan_id?: number | null;
  id: number;
  created_at: string;
  created_by_id: string;
};
export type SteelGradePlanCreate = {
  name?: string;
  steel_grade_set_id: number;
  items: SteelGradePlanItem[];
  from_product_group_plan_id?: number | null;
};
export type FormattedBasketRange = {
  minimum: number | null;
  maximum: number | null;
};
export type FormattedBasketConstraint = {
  basket_number: number;
  fill_level: FormattedBasketRange;
  mass: FormattedBasketRange;
};
export type MaterialLimitCoefficientCreate = {
  material_constraint_class_id: number;
  coefficient: number;
};
export type BasketMaterialLimitBoundsCreate = {
  basket_id: number;
  min_mass: number | null;
  max_mass: number | null;
  soft_min_mass: number | null;
  soft_max_mass: number | null;
};
export type BasketMaterialLimitCreate = {
  coefficients: MaterialLimitCoefficientCreate[];
  name?: string | null;
  bounds: BasketMaterialLimitBoundsCreate[];
  hardness: number;
};
export type ChefGroupBasketMaterialLimitCreate = {
  chef_group_id: number;
  basket_material_limit: BasketMaterialLimitCreate;
};
export type ChefGroupBasketMaterialLimitSetCreate = {
  chef_group_basket_material_limits: ChefGroupBasketMaterialLimitCreate[];
};
export type BasketRead = {
  basket_number: number;
  basket_volume: number;
  id: number;
};
export type BasketMaterialLimitBounds = {
  basket_id: number;
  min_mass: number | null;
  max_mass: number | null;
  soft_min_mass: number | null;
  soft_max_mass: number | null;
  basket: BasketRead;
};
export type BasketMaterialLimitRead = {
  id: number;
  bounds: BasketMaterialLimitBounds[];
  coefficients: MaterialLimitCoefficientCreate[];
  hardness: number;
  name: string;
  coefficients_signature: string;
};
export type ChefGroupBasketMaterialLimitRead = {
  id: number;
  chef_group_id: number;
  basket_material_limit: BasketMaterialLimitRead;
};
export type ChefGroupBasketMaterialLimitSetRead = {
  id: number;
  default: boolean;
  chef_group_basket_material_limits: ChefGroupBasketMaterialLimitRead[];
};
export type LoadBasketLayerRead = {
  material_name: string;
  material_id: number;
  mass: number;
  layer_number: number;
};
export type LoadBasketStatus = "new" | "partially_filled" | "filled";
export type LoadBasketRead = {
  load_basket_id: number;
  basket_number: number;
  materials: LoadBasketLayerRead[];
  additions: LoadBasketLayerRead[];
  status: LoadBasketStatus;
  assigned_to_name: string | null;
  assigned_to_uid: string | null;
  steel_grade_name: string;
  steel_grade_id: number;
};
export type MaterialVisibility = "visible" | "hidden";
export type AdditionLocation = "basket" | "hopper";
export type MaterialType = "scrap" | "iron" | "flux";
export type MaterialRead = {
  id: number;
  name: string;
  visibility: MaterialVisibility;
  addition_location: AdditionLocation;
  material_type: MaterialType;
  internal: boolean;
  highlight_safety_stock: boolean;
  highlight_stock_to_drain: boolean;
  level2_id: string;
  level2_name: string;
  erp_id: string;
  erp_name: string;
  ordering: number;
  deployment_ordering: number;
};
export type MaterialMetadataSetRead = {
  id: number;
  created_by_id: string;
  created_at: string;
  materials: MaterialRead[];
};
export type MaterialConstraintClass = {
  id: number;
  name: string;
};
export type MaterialChemistrySetRead = {
  name: string;
  items: MaterialChemistry[];
  source_uris: string[];
  id: number;
  created_at: string;
  created_by_id: string;
};
export type MaterialChemistrySetCreate = {
  name?: string;
  items: MaterialChemistry[];
  source_uris: string[];
};
export type IntercalationRole = "ignored" | "big" | "small";
export type MaterialPhysics = {
  material_id: number;
  density: number;
  yield_percent: number;
  min_feasible_mass: number;
  material_constraint_classes: MaterialConstraintClass[];
  intercalation_role: IntercalationRole;
  empty_space_fraction: number;
  default_substitute_material_id: number;
  electrical_energy_consumption: number;
};
export type MaterialPhysicsSetRead = {
  name: string;
  items: MaterialPhysics[];
  id: number;
  created_at: string;
  created_by_id: string;
};
export type MaterialPhysicsSetCreate = {
  name?: string;
  items: MaterialPhysics[];
};
export type LoadRead = {
  plan_id: number;
  customer_heat_id?: string | null;
  steel_grade_id: number;
  steel_grade_name: string;
  copper_group_weight_percent_max: number;
  mix_id: number;
  load_id: number;
  baskets: {
    [key: string]: LoadBasketRead;
  };
  created_at: string;
  created_by_id: string;
  start_time: string | null;
  end_time: string | null;
};
export type LoadCreate = {
  plan_id: number;
  mix_id: number;
  steel_grade_id: number;
  customer_heat_id?: string | null;
};
export type LoadUpdate = {
  load_id: number;
  plan_id?: number | null;
  steel_grade_id?: number | null;
  customer_heat_id?: string | null;
};
export type Crane = {
  user_uid: string;
  name: string;
};
export type CraneList = {
  cranes: Crane[];
};
export type LoadBasketStatusUpdate = {
  status: LoadBasketStatus;
};
export type LoadBasketAssignmentUpdate = {
  assigned_to_uid: string | null;
};
export type LoadBasketLayerAdditionUpdate = {
  material_id: number;
  mass: number;
  layer_number: number;
};
export type LoadBasketAdditionUpdate = {
  materials: LoadBasketLayerAdditionUpdate[];
};
export type PlanMetadata = {
  base_plan_id: number;
  parent_plan_id: number | null;
  created_by_name: string;
  created_at: string;
};
export type PlanMixBasket = {
  material_masses: {
    [key: string]: number;
  };
  total_mass: number;
};
export type SingleMaterialMass = {
  id: number;
  mass: number;
};
export type PhysicsSummary = {
  recipe_mass: number;
  scrap_mass: number;
  dri_mass: number;
  tapped_mass: number;
  target_tapped_mass_lower: number;
  billet_mass: number;
  yield_percent: number;
  yield_percent_billets: number;
  /** The total mass to display in the frontend. This is either the `tapped_mass` or the `billet_mass` depending on the tenant  */
  display_produced_mass: number;
  /** The yield percentage to display in the frontend. This is either the `yield_percent` or the `yield_percent_billets` depending on the tenant */
  display_yield_percent: number;
};
export type ElementChemistry = {
  element: string;
  weight_percent_loc: number;
  weight_percent_scale: number;
};
export type CostSummary = {
  cost_per_heat_materials: number;
  cost_per_heat_electrical_energy: number;
  cost_per_tonne_tapped_materials: number;
  cost_per_tonne_tapped_electrical_energy: number;
  cost_per_tonne_billets_materials: number;
  cost_per_tonne_billets_electrical_energy: number;
  cost_per_heat: number;
  cost_per_tonne_tapped: number;
  cost_per_tonne_billets: number;
  /** The cost per tonne of materials to display in the frontend. This is either the `cost_per_tonne_billets_materials` or the `cost_per_tonne_tapped_materials` depending on the tenant */
  display_cost_per_tonne_materials: number;
  /** The cost per tonne of electrical energy to display in the frontend. This is either the `cost_per_tonne_billets_electrical_energy` or the `cost_per_tonne_tapped_electrical_energy` depending on the tenant */
  display_cost_per_tonne_electrical_energy: number;
  /** The total cost per tonne to display in the frontend.  */
  display_cost_per_tonne: number;
};
export type SingleMixBasketSummary = {
  period: number;
  mix_id: number;
  chef_group_name: string;
  basket_number: number;
  recipe_mass: number;
  volume: number;
  fullness: number;
  min_fill_level: number;
  max_fill_level: number;
  min_mass: number;
  max_mass: number;
  basket_volume: number;
};
export type SingleMixSummary = {
  mix_id: number;
  period: number;
  physics: PhysicsSummary;
  chemistry: ElementChemistry[];
  cost: CostSummary;
  basket_summaries: SingleMixBasketSummary[];
};
export type NamedPlanMix = {
  /** Unique number for identifying each mix */
  mix_id: number;
  /** Production associated with this mix */
  steel_grade_production: SteelGradePlanItem[];
  /** Baskets by basket number */
  baskets: {
    [key: string]: PlanMixBasket;
  };
  /** Period */
  period: number;
  created_by_name: string;
  created_at: string;
  /** References steel grades from the search context */
  steel_grade_ids: number[];
  /** Maximum tolerance for copper percentage */
  copper_percent_max_tolerance: number;
  /** All material totals */
  totals: PlanMixBasket;
  /** Number of heats */
  number_of_heats: number;
  /** DRI material id and mass */
  dri: SingleMaterialMass | null;
  /** Summary of price, chemistry, physical properties etc */
  summary?: SingleMixSummary | null;
  /** Human-readable name for the mix, like A, B, C... */
  mix_name: string;
};
export type SummaryTagEnum =
  | "not_enough_available_scrap"
  | "challenging_for_scrapyard"
  | "uses_non_default_baskets";
export type TagSentiment = "positive" | "neutral" | "negative" | "terrible";
export type SummaryTag = {
  period: number;
  tag: SummaryTagEnum;
  details: string[];
  sentiment: TagSentiment;
};
export type NullableDatetime = string | null;
export type PeriodSummary = {
  cost_per_heat_materials: number;
  cost_per_heat_electrical_energy: number;
  cost_per_tonne_tapped_materials: number;
  cost_per_tonne_tapped_electrical_energy: number;
  cost_per_tonne_billets_materials: number;
  cost_per_tonne_billets_electrical_energy: number;
  cost_per_heat: number;
  cost_per_tonne_tapped: number;
  cost_per_tonne_billets: number;
  /** The cost per tonne of materials to display in the frontend. This is either the `cost_per_tonne_billets_materials` or the `cost_per_tonne_tapped_materials` depending on the tenant */
  display_cost_per_tonne_materials: number;
  /** The cost per tonne of electrical energy to display in the frontend. This is either the `cost_per_tonne_billets_electrical_energy` or the `cost_per_tonne_tapped_electrical_energy` depending on the tenant */
  display_cost_per_tonne_electrical_energy: number;
  /** The total cost per tonne to display in the frontend.  */
  display_cost_per_tonne: number;
  recipe_mass: number;
  scrap_mass: number;
  dri_mass: number;
  tapped_mass: number;
  target_tapped_mass_lower: number;
  billet_mass: number;
  yield_percent: number;
  yield_percent_billets: number;
  /** The total mass to display in the frontend. This is either the `tapped_mass` or the `billet_mass` depending on the tenant  */
  display_produced_mass: number;
  /** The yield percentage to display in the frontend. This is either the `yield_percent` or the `yield_percent_billets` depending on the tenant */
  display_yield_percent: number;
  period: number;
  name: string | null;
  is_deployable: boolean;
  start_timestamp: NullableDatetime;
  end_timestamp: NullableDatetime;
  num_heats: number;
  total_cost_materials: number;
  total_cost_electrical_energy: number;
  total_cost: number;
};
export type MixSummary = {
  cost_per_heat_materials: number;
  cost_per_heat_electrical_energy: number;
  cost_per_tonne_tapped_materials: number;
  cost_per_tonne_tapped_electrical_energy: number;
  cost_per_tonne_billets_materials: number;
  cost_per_tonne_billets_electrical_energy: number;
  cost_per_heat: number;
  cost_per_tonne_tapped: number;
  cost_per_tonne_billets: number;
  /** The cost per tonne of materials to display in the frontend. This is either the `cost_per_tonne_billets_materials` or the `cost_per_tonne_tapped_materials` depending on the tenant */
  display_cost_per_tonne_materials: number;
  /** The cost per tonne of electrical energy to display in the frontend. This is either the `cost_per_tonne_billets_electrical_energy` or the `cost_per_tonne_tapped_electrical_energy` depending on the tenant */
  display_cost_per_tonne_electrical_energy: number;
  /** The total cost per tonne to display in the frontend.  */
  display_cost_per_tonne: number;
  recipe_mass: number;
  scrap_mass: number;
  dri_mass: number;
  tapped_mass: number;
  target_tapped_mass_lower: number;
  billet_mass: number;
  yield_percent: number;
  yield_percent_billets: number;
  /** The total mass to display in the frontend. This is either the `tapped_mass` or the `billet_mass` depending on the tenant  */
  display_produced_mass: number;
  /** The yield percentage to display in the frontend. This is either the `yield_percent` or the `yield_percent_billets` depending on the tenant */
  display_yield_percent: number;
  period: number;
  num_heats: number;
  num_baskets: number;
  mix_id: number;
  chef_group_name: string;
  chef_group_id: number;
  mix_number: number;
};
export type MixMaterialSummary = {
  period: number;
  material: string;
  recipe_mass: number;
  mix_id: number;
  chef_group_name: string;
  mix_number: number;
};
export type MixBasketSummary = {
  period: number;
  mix_id: number;
  chef_group_name: string;
  basket_number: number;
  recipe_mass: number;
  volume: number;
  fullness: number;
  mix_number: number;
};
export type MixMaterialBasketSummary = {
  period: number;
  material: string;
  recipe_mass: number;
  mix_id: number;
  chef_group_name: string;
  mix_number: number;
  basket_number: number;
  basket_layer: string;
};
export type ProductGroupMixSummary = {
  product_group_name: string;
  cost_per_heat_materials: number;
  cost_per_heat_electrical_energy: number;
  cost_per_tonne_tapped_materials: number;
  cost_per_tonne_tapped_electrical_energy: number;
  cost_per_tonne_billets_materials: number;
  cost_per_tonne_billets_electrical_energy: number;
  cost_per_heat: number;
  cost_per_tonne_tapped: number;
  cost_per_tonne_billets: number;
  /** The cost per tonne of materials to display in the frontend. This is either the `cost_per_tonne_billets_materials` or the `cost_per_tonne_tapped_materials` depending on the tenant */
  display_cost_per_tonne_materials: number;
  /** The cost per tonne of electrical energy to display in the frontend. This is either the `cost_per_tonne_billets_electrical_energy` or the `cost_per_tonne_tapped_electrical_energy` depending on the tenant */
  display_cost_per_tonne_electrical_energy: number;
  /** The total cost per tonne to display in the frontend.  */
  display_cost_per_tonne: number;
  recipe_mass: number;
  scrap_mass: number;
  dri_mass: number;
  tapped_mass: number;
  target_tapped_mass_lower: number;
  billet_mass: number;
  yield_percent: number;
  yield_percent_billets: number;
  /** The total mass to display in the frontend. This is either the `tapped_mass` or the `billet_mass` depending on the tenant  */
  display_produced_mass: number;
  /** The yield percentage to display in the frontend. This is either the `yield_percent` or the `yield_percent_billets` depending on the tenant */
  display_yield_percent: number;
  period: number;
  num_heats: number;
  num_baskets: number;
};
export type ProductGroupMixMaterialSummary = {
  product_group_name: string;
  period: number;
  material: string;
  recipe_mass: number;
};
export type ObtainableResultItem = {
  period: number;
  material_id: number;
  material_name: string;
  bundle_name: string;
  bundle_id: number;
  bundle_index: number;
  required_obtainable: number;
  max_obtainable: number;
  specific_price: number;
};
export type NullableNonNegativeFloat = number | null;
export type PeriodMaterialSummary = {
  period: number;
  /** The name of the material */
  material: string;
  /** The initial inventory at the start of the period, not counting any obtainable acquired during the period. (Can be negative due to unobtainium.) */
  initial_inventory: number;
  /** The inventory at the end of the period, after the obtainable has been added and consumption subtracted. (Can be negative due to unobtainium). */
  final_inventory: number;
  /** The mass consumed of the material in the period */
  mass_consumed: number;
  /** The mass of material obtained during the period */
  obtainable_obtained: number;
  /** The mass of material that could have been obtained in the period but was not. (Can be negative due to unobtainium.) */
  obtainable_remaining: number;
  /** The price per unit mass of the material obtained during the period. Null if no material was obtained */
  obtained_price_per_tonne: NullableNonNegativeFloat;
  /** The material cost per unit mass of the material consumed in the period */
  cost_per_tonne_material: number;
  /** The electrical energy cost per unit mass of the material consumed in the period. (Can be negative due to negative energy prices). */
  cost_per_tonne_electrical_energy: number;
  /** The total material cost in the period (not including electrical energy) */
  cost_consumed: number;
  material_id: number;
};
export type OptimisationResultSetSummary = {
  tags: SummaryTag[];
  period_summary: PeriodSummary[];
  mix_summary: MixSummary[];
  mix_material_summary: MixMaterialSummary[];
  mix_basket_summary: MixBasketSummary[];
  mix_material_basket_summary: MixMaterialBasketSummary[];
  product_group_mix_summary: ProductGroupMixSummary[] | null;
  product_group_mix_material_summary: ProductGroupMixMaterialSummary[] | null;
  obtainable_summary: ObtainableResultItem[];
  period_material_summary: PeriodMaterialSummary[];
  first_period_summary: PeriodSummary;
};
export type ProductGroup = {
  id?: number | null;
  name: string;
  steel_grades: SteelGradeSummary[];
  /** Fraction of historical production. Despite the fact that this should strictly speaking be less than or equal to one, we allow to be greater, since the absolute value is not critically important, and there is some old data with larger values. */
  fraction_of_heats: number;
};
export type MaterialLimitCoefficientRead = {
  material_constraint_class_id: number;
  coefficient: number;
  id: number;
};
export type MixMaterialLimitRead = {
  chef_group_id: number;
  min_mass: number | null;
  max_mass: number | null;
  soft_min_mass: number | null;
  soft_max_mass: number | null;
  hardness: number;
  id: number;
  coefficients: MaterialLimitCoefficientRead[];
  name: string;
  coefficients_signature: string;
};
export type SearchContextData = {
  materials_id: number;
  product_groups_id: number | null;
  steel_grades_id: number;
  material_physics_id: number;
  mix_material_limit_set_id: number;
  chef_group_basket_material_limit_set_id: number;
  fixed_mix_set_id: number;
  /** True if the search uses default sets */
  has_default_sets: boolean;
  product_groups: ProductGroup[];
  chef_groups: ChefGroup[];
  steel_grades: SteelGrade[];
  materials: MaterialRead[];
  material_physics: MaterialPhysics[];
  material_chemistry: MaterialChemistry[];
  mix_material_limits: MixMaterialLimitRead[];
  chef_group_basket_material_limit_set: ChefGroupBasketMaterialLimitSetRead;
  baskets: BasketRead[];
  chemical_elements: ChemicalElement[];
};
export type NamedPlanMixes = {
  plan_id: number;
  metadata: PlanMetadata;
  mixes: NamedPlanMix[];
  start_at: string | null;
  end_at: string | null;
  summary: OptimisationResultSetSummary;
  context: SearchContextData;
};
export type DeployedPlans = {
  latest: number | null;
  next: number | null;
};
export type DeploymentType =
  | "standard"
  | "edited_standard"
  | "backup"
  | "edited_backup";
export type DeployedNamedPlanMixes = {
  plan_id: number;
  metadata: PlanMetadata;
  mixes: NamedPlanMix[];
  deployed_at: string;
  start_at: string;
  end_at: string;
  deployment_type: DeploymentType;
  context: SearchContextData;
  summary: OptimisationResultSetSummary;
};
export type Latest = "latest";
export type Next = "next";
export type SupportedLanguage = "DE" | "EN" | "ES" | "FR" | "PL";
export type DeployParameters = {
  process: DeployProcess;
  start_at: string;
  end_at: string;
  language: SupportedLanguage;
};
export type ChargeSummary = {
  planned: {
    [key: string]: number;
  };
  loaded: {
    [key: string]: number;
  };
  prices: {
    [key: string]: number;
  };
};
export type LiveChargeSummary = {
  scrap_car: number;
  charge_summary: ChargeSummary;
};
export type ObtainableBundleItemSet = {
  items: ObtainableBundleItem[];
  obtainable_bundle_set_id: number;
};
export type ObtainableBundleItemSetCreate = {
  items: ObtainableBundleItem[];
};
export type ProductGroupingRead = {
  name: string;
  product_groups: ProductGroup[];
  steel_grade_set_id: number;
  id: number;
  created_at: string;
  created_by_id: string;
};
export type ProductGroupingCreate = {
  name: string;
  product_groups: ProductGroup[];
  steel_grade_set_id: number;
};
export type ProductGroupPlanRead = {
  name: string;
  product_grouping_id: number;
  items: ProductGroupPlanItem[];
  id: number;
  created_at: string;
  created_by_id: string;
};
export type ProductGroupPlanCreate = {
  name?: string;
  product_grouping_id: number;
  items: ProductGroupPlanItem[];
};
export type SearchStatus = "not_started" | "running" | "success" | "failure";
export type SearchSummary = {
  search_id: number;
  parent_search_id: number | null;
  context_id: number;
  status: SearchStatus;
  created_by_current_user: boolean;
  user_email: string;
  created_at: string;
};
export type Ordering = "asc" | "desc";
export type Deployment = {
  id: number;
  plan_id: number;
  deployed_at: string;
  start_at: string;
  end_at: string;
  user_id: string;
  deployment_type: DeploymentType;
  deployed_by_name: string;
};
export type OptimisationStatus =
  | "not_started"
  | "failed"
  | "failed_suboptimal"
  | "succeeded_suboptimal"
  | "succeeded"
  | "edited";
export type SlackVariableEnum =
  | "unobtainium_purchased"
  | "unobtainium_consumed"
  | "material_period_exclusivity_constraint_violated"
  | "target_inventory_increases"
  | "target_inventory_decreases"
  | "soft_material_max_increases"
  | "soft_material_min_decreases"
  | "soft_material_exclusivity_constraint_violated"
  | "mix_use_count_increases"
  | "impurity_weight_percent_max_increase";
export type OptimisationSlackVariableUsage = {
  details: {
    [key: string]: string | number;
  };
  slack_variable: SlackVariableEnum;
  usage: number;
  period: number;
  cost: number;
};
export type Plan = {
  id: number;
  metadata: PlanMetadata;
  plan_index: number;
  session_id: number;
  search_id: number;
  deployments: Deployment[];
  matches_search: boolean;
  optimisation_status: OptimisationStatus;
  summary: OptimisationResultSetSummary;
  optimisation_slack_variable_usage: OptimisationSlackVariableUsage[];
  time_limit: number;
};
export type OptimisationFailureReason =
  | "Infeasible Optimisation"
  | "Optimisation Timed Out"
  | "Optimisation Error"
  | "unacceptable_slack_variable_usage";
export type SearchResultStatus = "not_started" | "incomplete" | "complete";
export type SearchResult = {
  search_id: number;
  session_id: number;
  plans: Plan[];
  failure_reason: OptimisationFailureReason | null;
  status: SearchResultStatus;
};
export type OptimisationMethod = "classic";
export type RunSearchOptions = {
  /** The optimisation method to use for the auto recipe */
  optimisation_method?: OptimisationMethod | null;
  /** The time limit for the optimisation in seconds. Note that this is the time limit passed to the solver, so does not include time spent loading and saving data. */
  optimisation_time_limit?: number;
  /** If true, then override the parameters in the search and do not use integer solution */
  force_non_integer_solution?: boolean;
  /** Maximum number of tasks to generate. Defaults to None (no limit). */
  task_limit?: number | null;
  /** If true, then create auto backups for the search. */
  create_auto_backups?: boolean;
};
export type MixCreate2 = {
  baskets: MixBasket[];
};
export type FixedMixOverride2 = {
  steel_grade_name: string;
  mix: MixCreate2 | null;
};
export type SearchPeriod2 = {
  name: string | null;
  start_timestamp: string | null;
  end_timestamp: string | null;
  is_deployable: boolean;
  production_plan: SearchPeriodProductionPlan;
  obtainable_bundles: ObtainableBundleItem[];
  target_inventory: TargetInventoryItem[];
  target_baskets: TargetBasketItem[];
  material_exclusivity: PeriodMaterialExclusivityConstraintCreate[];
  suppress_mix_material_exclusivity_constraints: boolean;
  suppress_min_feasible_mass_constraints: boolean;
  /** The weighting for the period in the optimiser's objective function. A higher weighting means that the optimiser will prioritise this period over others. */
  optimisation_objective_weighting: number;
  electrical_energy_price: number;
};
export type SearchCreate = {
  session_id: number;
  session_name: string;
  session_created_at: string;
  session_created_by_email: string;
  parent_id: number | null;
  search_type: SearchType;
  context_id: number;
  misc_params: MiscParams;
  physical_parameters: PhysicalParameters;
  inventory: InventoryItem[];
  material_chemistry_overrides: MaterialChemistry[];
  fixed_mix_overrides: FixedMixOverride2[];
  periods: SearchPeriod2[];
};
export type NameBasedInventoryItem = {
  material_name: string;
  quantity: number | null;
  specific_price: number | null;
  previous_quantity?: number | null;
  deliveries_since_previous?: number | null;
  consumption_since_previous?: number | null;
  projected_deliveries_since_previous?: number | null;
  projected_consumption_since_previous?: number | null;
};
export type NameBasedMaterialChemistry = {
  material_name: string;
  chemical_element_name: string;
  loc: number;
  scale: number;
};
export type NameBasedProductGroupPlanItem = {
  product_group_name: string;
  num_heats: number;
};
export type NameBasedSteelGradePlanItem = {
  steel_grade_name: string;
  num_heats: number;
};
export type NameBasedProductionPlan = {
  product_group_items: NameBasedProductGroupPlanItem[] | null;
  steel_grade_items: NameBasedSteelGradePlanItem[] | null;
};
export type NameBasedObtainableBundleItem = {
  material_name: string;
  label: string;
  min_obtainable: number | null;
  max_obtainable: number | null;
  specific_price: number | null;
  arrival_probability: number | null;
};
export type NameBasedTargetInventoryItem = {
  material_name: string;
  min_quantity: number | null;
  max_quantity: number | null;
};
export type NameBasedTargetBasketItem = {
  chef_group_name: string;
  target_num_baskets: number;
};
export type NameBasedExclusiveMaterials = {
  material_names: string[];
};
export type NameBasedSearchPeriod = {
  name: string | null;
  start_timestamp: string | null;
  end_timestamp: string | null;
  production_plan: NameBasedProductionPlan;
  obtainable_bundles: NameBasedObtainableBundleItem[];
  target_inventory: NameBasedTargetInventoryItem[];
  target_baskets: NameBasedTargetBasketItem[];
  material_exclusivity: NameBasedExclusiveMaterials[];
  is_deployable: boolean;
  suppress_mix_material_exclusivity_constraints: boolean;
  suppress_min_feasible_mass_constraints: boolean;
  optimisation_objective_weighting: number;
  electrical_energy_price: number;
};
export type NameBasedSearch = {
  search_type: SearchType;
  misc_params: MiscParams;
  physical_parameters: PhysicalParameters;
  inventory: NameBasedInventoryItem[];
  material_chemistry_overrides: NameBasedMaterialChemistry[];
  fixed_mix_overrides: FixedMixOverride[];
  periods: NameBasedSearchPeriod[];
};
export type NameBasedSearchPeriod2 = {
  name: string | null;
  start_timestamp: string | null;
  end_timestamp: string | null;
  production_plan: NameBasedProductionPlan;
  obtainable_bundles: NameBasedObtainableBundleItem[];
  target_inventory: NameBasedTargetInventoryItem[];
  target_baskets: NameBasedTargetBasketItem[];
  material_exclusivity: NameBasedExclusiveMaterials[];
  is_deployable: boolean;
  suppress_mix_material_exclusivity_constraints: boolean;
  suppress_min_feasible_mass_constraints: boolean;
  optimisation_objective_weighting: number;
  electrical_energy_price: number;
};
export type NameBasedSearch2 = {
  search_type: SearchType;
  misc_params: MiscParams;
  physical_parameters: PhysicalParameters;
  inventory: NameBasedInventoryItem[];
  material_chemistry_overrides: NameBasedMaterialChemistry[];
  fixed_mix_overrides: FixedMixOverride2[];
  periods: NameBasedSearchPeriod2[];
};
export type GroupedSearchContext = {
  product_grouping_id: number | null;
  chef_grouping_id: number;
  steel_grade_set_id: number;
  material_metadata_set_id: number;
  material_physics_set_id: number;
  material_chemistry_set_id: number;
  basket_constraint_set_id: number;
  mix_material_limit_set_id: number;
  chef_group_basket_material_limit_set_id: number;
  fixed_mix_set_id: number;
  id: number;
  parent_search_context_id: number;
  search_context_group_id: number;
  created_at: string;
};
export type SearchContextGroup = {
  id: number;
  name: string;
  user_id: string;
  user_email: string;
  created_at: string;
  updated_at: string;
  latest_search_context_id: number;
  contexts: GroupedSearchContext[];
};
export type SearchContextGroupCreate = {
  name: string;
  context_id: number;
};
export type SearchContextGroupSummary = {
  id: number;
  name: string;
  user_id: string;
  user_email: string;
  created_at: string;
  updated_at: string;
  latest_search_context_id: number;
};
export type MixMaterialLimitCreate = {
  chef_group_id: number;
  min_mass: number | null;
  max_mass: number | null;
  soft_min_mass: number | null;
  soft_max_mass: number | null;
  hardness: number;
  coefficients: MaterialLimitCoefficientCreate[];
  name?: string | null;
  reason?: string | null;
};
export type DeployedPlanRead = {
  session_id: number;
  search_id: number;
  plan_id: number;
  session_name: string;
  deployed_at: string;
  deployed_by_email: string;
  start_at: string;
  end_at: string;
  deployment_type: DeploymentType;
  first_period_summary: PeriodSummary;
  parent_id: number | null;
};
export type PlanMixBasketUpdate = {
  material_masses: {
    [key: string]: number;
  };
};
export type PlanMixUpdate = {
  steel_grade_production: SteelGradePlanItem[];
  period: number;
  baskets: {
    [key: string]: PlanMixBasketUpdate;
  };
};
export type PlanMixUpdates = {
  period: number;
  /** Mixes by mix id */
  mixes: {
    [key: string]: PlanMixUpdate;
  };
  /** Mapping from existing mix IDs to ones to replace them with */
  replacements: {
    [key: string]: number;
  };
};
export type DeployedPlanMixUpdates = {
  period: number;
  /** Mixes by mix id */
  mixes: {
    [key: string]: PlanMixUpdate;
  };
  /** Mapping from existing mix IDs to ones to replace them with */
  replacements: {
    [key: string]: number;
  };
  start_at: string;
  end_at: string;
  deployment_type: DeploymentType;
};
export type MixDifference = {
  source_mix_id: number;
  target_mix_id: number;
  basket_number: number;
  material_name: string;
  addition_location: string;
  source_mass: number;
  target_mass: number;
  mass_difference: number;
};
export type MixDifferences = {
  source_mix_id: number;
  target_mix_id: number;
  differences: MixDifference[];
};
export type PlanDifferenceSummary = {
  source_display_cost_per_tonne: number;
  target_display_cost_per_tonne: number;
};
export type PlanDifferences = {
  source_plan_id: number;
  target_plan_id: number;
  differences: MixDifference[];
  target_created_by_name: string;
  target_created_at: string;
  target_deployments: Deployment[];
  summary: PlanDifferenceSummary;
};
export type PlanAncestorDiffs = {
  plan_id: number;
  ancestors: PlanDifferences[];
};
export type PlanBackupSummary = {
  session_id: number;
  session_name: string;
  display_name: string;
  search_id: number;
  plan_id: number | null;
  status: OptimisationStatus;
  display_cost_per_tonne: number | null;
};
export type PlanBackupSummaries = {
  backups: PlanBackupSummary[];
};
export type DeploymentHistory = {
  session_id: number;
  search_id: number;
  username: string;
  email: string;
  deployed_at: string;
  plan_id: number;
};
export type SessionSummary = {
  session_id: number;
  name: string;
  created_by_current_user: boolean;
  user_email: string;
  created_at: string;
  search_count: number;
  searches_not_started: number;
  searches_running: number;
  searches_failed: number;
  searches_succeeded: number;
  deployment_history: DeploymentHistory[];
  last_search_id: number;
};
export type SessionCreate = {
  name: string;
  search_type: SearchType;
  deleted?: boolean;
};
export type RenameSession = {
  name: string;
};
export type CopySearch = {
  name: string | null;
};
export type MixRead = {
  baskets: MixBasket[];
  id: number;
};
export type ProductionMixResultRead = {
  production: NameBasedSteelGradePlanItem[];
  mix: MixRead;
};
export type MaterialResult = {
  material: string;
  label: string;
  quantity: number;
  min_quantity: number;
  max_quantity: number | null;
  specific_price: number;
};
export type ResultsPeriodRead = {
  period_index: number;
  mixes: ProductionMixResultRead[];
  materials: MaterialResult[];
};
export type NameBasedPeriodMaterialSummary = {
  period: number;
  /** The name of the material */
  material: string;
  /** The initial inventory at the start of the period, not counting any obtainable acquired during the period. (Can be negative due to unobtainium.) */
  initial_inventory: number;
  /** The inventory at the end of the period, after the obtainable has been added and consumption subtracted. (Can be negative due to unobtainium). */
  final_inventory: number;
  /** The mass consumed of the material in the period */
  mass_consumed: number;
  /** The mass of material obtained during the period */
  obtainable_obtained: number;
  /** The mass of material that could have been obtained in the period but was not. (Can be negative due to unobtainium.) */
  obtainable_remaining: number;
  /** The price per unit mass of the material obtained during the period. Null if no material was obtained */
  obtained_price_per_tonne: NullableNonNegativeFloat;
  /** The material cost per unit mass of the material consumed in the period */
  cost_per_tonne_material: number;
  /** The electrical energy cost per unit mass of the material consumed in the period. (Can be negative due to negative energy prices). */
  cost_per_tonne_electrical_energy: number;
  /** The total material cost in the period (not including electrical energy) */
  cost_consumed: number;
};
export type NameBasedSummary = {
  period_summary: PeriodSummary[];
  period_material_summary: NameBasedPeriodMaterialSummary[];
};
export type ResultsRead = {
  plan_id: number;
  optimisation_status: OptimisationStatus;
  periods: {
    [key: string]: ResultsPeriodRead;
  };
  slack_variable_usage: OptimisationSlackVariableUsage[];
  summary: NameBasedSummary;
};
export type UploadedFileState = "new" | "parsed";
export type FileMeta = {
  name?: string;
  updated_at?: string | null;
};
export type UploadedFileMeta = {
  id: number;
  uploaded_at: string;
  uploaded_by_id: string;
  type: string;
  deleted: boolean;
  state: UploadedFileState;
  file: FileMeta;
};
export type File = {
  name?: string;
  updated_at?: string | null;
  content: string;
};
export type UploadedFileCreate = {
  type: string;
  file: File;
};
export type UploadedFileType =
  | "unknown"
  | "recipes"
  | "fdm_recipes"
  | "melt_plan"
  | "order_book"
  | "twenty_six_week_schedule"
  | "scrap_plan"
  | "inventory_report"
  | "inventory_measurement"
  | "rail_car_movements"
  | "contracted_buy"
  | "scrap_composite"
  | "ship_deliveries"
  | "truck_deliveries"
  | "deliveries"
  | "purchased_scrap"
  | "billet_stock"
  | "material_stock"
  | "scrap_usage"
  | "chemical_samples"
  | "heat_mass"
  | "steel_grade_billet_size"
  | "steel_grade_chemistry"
  | "scrap_types"
  | "heat_process_data"
  | "heat_scrap_data"
  | "material_metadata"
  | "other_material_additions"
  | "product_chemical_analysis"
  | "quality_codes"
  | "slag_analysis"
  | "sap_billet_orders"
  | "sap_rolled_orders"
  | "drop_weights"
  | "scrap_car_weight"
  | "live_heat_data"
  | "live_charge_buckets";
export type UploadedFileRead = {
  type: string;
  file: File;
  id: number;
  uploaded_at: string;
  uploaded_by_id: string;
  deleted: boolean;
  state: UploadedFileState;
};
export type UploadedFileOutputType =
  | "steel_grade_plan"
  | "product_group_plan"
  | "purchasing_plan"
  | "material_inputs";
export type UploadedFileOutputTypeList = {
  items: UploadedFileOutputType[];
};
export type MaterialAvailability = {
  material_id: number;
  inventory?: number | null;
  obtainable_min?: number | null;
  obtainable_max?: number | null;
  inventory_target_min?: number | null;
  inventory_target_max?: number | null;
  price?: number | null;
};
export type UploadedSearchParameters = {
  unrecognised_names?: string[] | null;
  steel_grade_plan?: SteelGradePlanItem[] | null;
  availabilities?: MaterialAvailability[] | null;
};
export type ObtainableEstimate = {
  material_id: number;
  obtainable_max: number | null;
};
export type PublishedChargeScheduleLayeringItem = {
  ChargeNum: 1 | 2 | 3;
  LayerNum: number;
  Weight: number;
  ScrapID: number;
  Description: string;
};
export type PublishedChargeScheduleLayering = {
  plan_id: number;
  period: number;
  chef_group_id: number;
  mix_number: number;
  layering: PublishedChargeScheduleLayeringItem[];
};
export type CmcChargeScheduleType = 1000 | 2000 | 3000;
export type PublishedChargeScheduleSteelGrade = {
  BilletSize: string;
  MeltGrade: string;
  FinishGoodCode: string;
};
export type PlanPublishedChargeScheduleRead = {
  /** Internal ID used by FDM for a charge schedule */
  fdm_charge_schedule_id: number;
  /** Code indicating the type of charge_schedule. 1000 = Rebar, 2000 = MBQ, 3000 = SBQ */
  cmc_charge_schedule_type: CmcChargeScheduleType;
  steel_grades: PublishedChargeScheduleSteelGrade[];
  layering: PublishedChargeScheduleLayeringItem[];
  period: number;
  plan_id: number;
};
export type PublishPlanCreate = {
  plan_id: number;
  period: number;
};
export type PublishPlans = {
  plans: PublishPlanCreate[];
};
export type PublishedChargeScheduleRead = {
  /** Internal ID used by FDM for a charge schedule */
  fdm_charge_schedule_id: number;
  /** Code indicating the type of charge_schedule. 1000 = Rebar, 2000 = MBQ, 3000 = SBQ */
  cmc_charge_schedule_type: CmcChargeScheduleType;
  steel_grades: PublishedChargeScheduleSteelGrade[];
  layering: PublishedChargeScheduleLayeringItem[];
};
export type CustomerPublishedChargeScheduleLayer = {
  id: number;
  customer_material_id: number;
  customer_material_name: string;
  layer_num: number;
  basket_num: number;
  mass_lbs: number;
  publish_charge_schedule_id: number;
};
export type ChargeScheduleConfirm = {
  /** Code indicating the type of charge_schedule. 1000 = Rebar, 2000 = MBQ, 3000 = SBQ */
  fdm_charge_schedule_id: number;
  /** Charge schedule ID in CMC level 2 database */
  cmc_charge_schedule_id: string;
};
export type ConfirmedChargeScheduleMeta = {
  fdm_charge_schedule_id: number;
  cmc_charge_schedule_id: string;
  plan_id: number;
  period: number;
  chef_group_id: number;
  mix_number: number;
};
export type ChargeScheduleMessage = {
  text: string;
  plan_id: number;
  period: number;
};
export type ChargeScheduleMessages = {
  charge_schedule_messages: ChargeScheduleMessage[];
};
export type Message = {
  id: number;
  message: string;
  sent: boolean;
};
export type TargetBasketSetRead = {
  items: TargetBasketItem[];
  id: number;
};
export type TargetBasketSetCreate = {
  items: TargetBasketItem[];
};
export type Permission =
  | "review"
  | "publish"
  | "edit"
  | "read"
  | "review_mix"
  | "video_labelling"
  | "crane_driver"
  | "crane_supervisor"
  | "crane_supervisor_viewer"
  | "scrap_tracking_dashboard_viewer"
  | "plan_recipient";
export type UserInvite = {
  emails: string[];
  permission: Permission;
};
export type InvitedUser = {
  email: string;
  permission: Permission;
  is_active: boolean;
  invited_at: string;
  accepted_at?: string | null;
};
export type VideoLabels = {
  key: string;
  user: string;
  description: string;
  labels: {
    [key: string]: any;
  }[];
  invalid?: boolean;
};
export type PendingDelivery = {
  order_id: string | null;
  source_file_name: string;
  supplier: string;
  material: string | null;
  mass: number | null;
  price: number | null;
  mode_of_transport: "truck" | "train" | "ship";
  dispatch_address: string;
  dispatch_time: string;
  arrival_time: string;
};
export type UploadedFileNames = {
  file_names: string[];
};
export type MixMaterialLimitSetRead = {
  id: number;
  default: boolean;
  mix_material_limits: MixMaterialLimitRead[];
};
export type RealisedPick = {
  material: string;
  quantity: number;
  timestamp: string;
};
export type PlannedPick = {
  material: string;
  quantity: number;
};
export type PlannedHeat = {
  start: string | null;
  end: string | null;
  steel_grade: string;
  ratio: PlannedPick[];
  fill: PlannedPick[];
};
export type JsonValue = any;
export type ParameterisedStrategySpec = {
  name: string;
  params: {
    [key: string]: JsonValue;
  };
};
export type AutoRecipeStrategySpecs = {
  period_splitting_strategies: ParameterisedStrategySpec[];
  period_weighting_strategies: ParameterisedStrategySpec[];
  period_constraint_suppression_strategies: ParameterisedStrategySpec[];
  target_inventory_strategies: ParameterisedStrategySpec[];
  material_exclusivity_strategies: ParameterisedStrategySpec[];
};
export type AutoRecipeEstimatorsSpecs = {
  initial_inventory_estimators: ParameterisedStrategySpec[];
  production_estimators: ParameterisedStrategySpec[];
  deliveries_estimators: ParameterisedStrategySpec[];
};
export type SimulatorDataLoadersSpecs = {
  deliveries_loaders: ParameterisedStrategySpec[];
};
export type SimulatorRunStatus =
  | "not_started"
  | "running"
  | "success"
  | "failure";
export type SimulatorFailureReason =
  | "no_deployable_periods"
  | "search_error"
  | "search_timeout"
  | "optimisation_infeasible"
  | "optimisation_timeout"
  | "optimisation_error"
  | "optimisation_unacceptable_slack_variable_usage";
export type UtcTimestamp = string;
export type AutoRecipeStrategySpec = {
  period_splitting_strategy: ParameterisedStrategySpec;
  period_weighting_strategy: ParameterisedStrategySpec;
  period_constraint_suppression_strategy: ParameterisedStrategySpec;
  target_inventory_strategy: ParameterisedStrategySpec;
  material_exclusivity_strategy: ParameterisedStrategySpec;
  delivery_aggregation_strategy: ParameterisedStrategySpec;
};
export type AutoRecipeEstimatorsSpec = {
  initial_inventory_estimator: ParameterisedStrategySpec;
  production_estimator: ParameterisedStrategySpec;
  deliveries_estimator: ParameterisedStrategySpec;
};
export type SimulatorDataLoadersSpec = {
  deliveries_loader: ParameterisedStrategySpec;
};
export type SimulatorRunMetadata = {
  id: number;
  created_by_id: string;
  status: SimulatorRunStatus;
  failure_reason: SimulatorFailureReason | null;
  start_timestamp: UtcTimestamp;
  end_timestamp: UtcTimestamp;
  auto_recipe_strategy: AutoRecipeStrategySpec;
  auto_recipe_estimators: AutoRecipeEstimatorsSpec;
  simulator_data_loaders: SimulatorDataLoadersSpec;
  search_timeout_seconds: number;
};
export type SimulatorRunList = {
  runs: SimulatorRunMetadata[];
};
export type SimulatorStepResult = {
  session_id: number;
  search_id: number;
  plan_id: number;
  start_timestamp: UtcTimestamp;
  end_timestamp: UtcTimestamp;
};
export type DatedInventory = {
  on_timestamp: UtcTimestamp;
  material_name: string;
  inventory: number;
};
export type SimulatorRunRead = {
  id: number;
  created_by_id: string;
  status: SimulatorRunStatus;
  failure_reason: SimulatorFailureReason | null;
  start_timestamp: UtcTimestamp;
  end_timestamp: UtcTimestamp;
  auto_recipe_strategy: AutoRecipeStrategySpec;
  auto_recipe_estimators: AutoRecipeEstimatorsSpec;
  simulator_data_loaders: SimulatorDataLoadersSpec;
  search_timeout_seconds: number;
  results: SimulatorStepResult[];
  inventory: DatedInventory[];
};
export type AutoRecipeStrategySpec2 = {
  period_splitting_strategy: ParameterisedStrategySpec;
  period_weighting_strategy: ParameterisedStrategySpec;
  period_constraint_suppression_strategy: ParameterisedStrategySpec;
  target_inventory_strategy: ParameterisedStrategySpec;
  material_exclusivity_strategy: ParameterisedStrategySpec;
  delivery_aggregation_strategy: ParameterisedStrategySpec;
};
export type AutoRecipeEstimatorsSpec2 = {
  initial_inventory_estimator: ParameterisedStrategySpec;
  production_estimator: ParameterisedStrategySpec;
  deliveries_estimator: ParameterisedStrategySpec;
};
export type SimulatorDataLoadersSpec2 = {
  deliveries_loader: ParameterisedStrategySpec;
};
export type SimulatorRunCreate = {
  start_timestamp: UtcTimestamp;
  end_timestamp: UtcTimestamp;
  auto_recipe_strategy?: AutoRecipeStrategySpec2 | null;
  auto_recipe_estimators?: AutoRecipeEstimatorsSpec2 | null;
  simulator_data_loaders?: SimulatorDataLoadersSpec2 | null;
  search_timeout_seconds?: number;
};
export type SummarisedPlanMix = {
  /** Unique number for identifying each mix */
  mix_id: number;
  /** Production associated with this mix */
  steel_grade_production: SteelGradePlanItem[];
  /** Baskets by basket number */
  baskets: {
    [key: string]: PlanMixBasket;
  };
  /** Period */
  period: number;
  created_by_name: string;
  created_at: string;
  /** References steel grades from the search context */
  steel_grade_ids: number[];
  /** Maximum tolerance for copper percentage */
  copper_percent_max_tolerance: number;
  /** All material totals */
  totals: PlanMixBasket;
  /** Number of heats */
  number_of_heats: number;
  /** DRI material id and mass */
  dri: SingleMaterialMass | null;
  /** Summary of price, chemistry, physical properties etc */
  summary?: SingleMixSummary | null;
};
export type SummarisedPlanMixes = {
  plan_id: number;
  mixes: SummarisedPlanMix[];
};
export type UnsummarisedPlanMix = {
  /** Unique number for identifying each mix */
  mix_id: number;
  /** Production associated with this mix */
  steel_grade_production: SteelGradePlanItem[];
  /** Baskets by basket number */
  baskets: {
    [key: string]: PlanMixBasket;
  };
  /** Period */
  period: number;
};
export type UnsummarisedPlanMixes = {
  plan_id: number;
  mixes: UnsummarisedPlanMix[];
};
export type MaterialGroupingName = string;
export type MaterialGroupName = string;
export type InventoryMassSeries = {
  material_grouping_name: MaterialGroupingName;
  material_group_name: MaterialGroupName;
  inventory_date: string;
  inventory_mass: number;
  /** Fraction of total inventory. */
  inventory_fraction: number;
};
export type StrategyDashboardInventoryData = {
  series: InventoryMassSeries[];
};
export type TimeAggregation = "daily" | "monthly";
export type ConsumptionSourceName = string;
export type ConsumptionSeries = {
  time_aggregation: TimeAggregation;
  consumption_source: ConsumptionSourceName;
  material_grouping_name: MaterialGroupingName;
  material_group_name: MaterialGroupName;
  consumption_date: string;
  /** Mass consumed. Note this can be negative. */
  consumed_mass: number;
  /** Fraction of material consumed. Note this can be negative or greater than 1. */
  consumed_fraction: number;
};
export type StrategyDashboardConsumptionData = {
  series: ConsumptionSeries[];
};
export type ChemicalSampleLocation = "eaf" | "lf" | "caster";
export type ChemicalSamplePositionType = "first" | "last";
export type ChemicalElementSymbol = "Cu" | "Sn" | "Ni" | "Cr" | "Mo";
export type FailureMetadata = {
  heat_id: string;
  sample_timestamp: UtcTimestamp;
  steel_grade_name: string;
  product_group_name: string;
  weight_percent: number;
  weight_percent_max: number;
  violation_fraction: number;
  expected_weight_percent: number;
  expected_weight_percent_violation: number;
  expected_failure_rate: number;
};
export type FailureRateSeries = {
  time_aggregation: TimeAggregation;
  sample_location: ChemicalSampleLocation;
  sample_position_type: ChemicalSamplePositionType;
  chemical_element_symbol: ChemicalElementSymbol;
  sample_date: string;
  num_failures: number;
  failure_rate: number;
  expected_num_failures: number;
  expected_failure_rate: number;
  failures_metadata: FailureMetadata[];
};
export type StrategyDashboardFailureRateData = {
  series: FailureRateSeries[];
};
export type PriceSourceName = string;
export type PriceSeries = {
  price_source: PriceSourceName;
  material_name: string;
  price_valid_from: UtcTimestamp;
  specific_price: number;
};
export type StrategyDashboardPriceData = {
  series: PriceSeries[];
};
export type TimestampValue = {
  timestamp: number;
  value: number;
};
export type LabelProbabilities = {
  label: string;
  probabilities: TimestampValue[];
};
export type LabelProbabilitiesData = {
  data: LabelProbabilities[];
};
export type ScaleWeights = {
  bucket: string;
  weights: TimestampValue[];
};
export type ScaleWeightsData = {
  data: ScaleWeights[];
};
export type TimestampString = {
  timestamp: number;
  value: string;
};
export type ScrapCarLoadingStatus = {
  scrap_car: number;
  loading_status: TimestampString[];
};
export type ScrapCarLoadingStatusData = {
  scrap_cars: ScrapCarLoadingStatus[];
};
export type PlotData = {
  plot: string;
};
export type DropEvent = {
  start: number;
  end: number;
  material_id: number | null;
  labelled_material: string;
  load_id: number | null;
  new_load_id: number | null;
  scrap_car_id: number;
  drop_weight: number;
  charge_schedule_id: string;
  charge_schedule_bucket_number: number;
  status: string;
};
export type DropEvents = {
  drop_events: DropEvent[];
};
export type HeatSummary = {
  heat_id: string;
  start: number;
  end: number;
  shift: string;
  charges?: ChargeSummary[] | null;
};
export type HeatSummaries = {
  heat_summaries: HeatSummary[];
};
export type SourcePeriod = {
  start: number;
  end: number;
  colour: string;
  text: string;
};
export type LabelSourcesData = {
  periods: SourcePeriod[];
};
export type ScrapCarCycle = {
  start: number;
  end: number;
  scrap_car: number;
  charge_schedule: string;
  charge_number: number;
};
export type ScrapCarCycles = {
  scrap_car_cycles: ScrapCarCycle[];
};
export type PurchasingPlanSummary = {
  name: string;
  plan_date: string;
  purchasing_plan_id: number;
  created_at: string;
  user_email: string;
};
export type PurchasingPlanCreate = {
  name: string;
  plan_date: string;
  deleted?: boolean;
};
export type PurchasingPlanMaterial = {
  material_name: string;
  demand: number;
  current_inventory: number;
};
export type PurchasingLineItem = {
  material_name: string;
  quantity: number;
  unit_price: number;
};
export type PurchasingQuoteRead = {
  supplier_name: string;
  line_items: PurchasingLineItem[];
  id: number;
};
export type PurchasingPlanRead = {
  name: string;
  plan_date: string;
  id: number;
  created_at: string;
  created_by_email: string;
  materials: PurchasingPlanMaterial[];
  quotes: PurchasingQuoteRead[];
};
export type PurchasingQuoteBase = {
  supplier_name: string;
  line_items: PurchasingLineItem[];
};
export type PurchasingPlanQuotesAdd = {
  quotes: PurchasingQuoteBase[];
};
export type Supplier = {
  name: string;
};
export type SupplierList = {
  suppliers: Supplier[];
};
export type UserPermission = {
  user_uid: string;
  permission: Permission;
};
export type UserReadAll = {
  uid: string;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
  is_enabled: boolean;
  is_admin: boolean;
  permissions: UserPermission[];
};
export type UserUpdate = {
  uid: string;
  is_admin?: boolean | null;
  is_enabled?: boolean | null;
  permissions?: {
    [key: string]: Permission[];
  } | null;
};
export const {
  useGetTenantMetadataQuery,
  useGetSimilarChargedRecipesQuery,
  useCreateAutoRecipeMutation,
  useListChefGroupsQuery,
  useCreateChefGroupingMutation,
  useGetChefGroupingQuery,
  useSetDefaultChefGroupingMutation,
  useListSteelGradesQuery,
  useCreateSteelGradeSetMutation,
  useGetSteelGradeSetQuery,
  useSetDefaultSteelGradeSetMutation,
  useListSteelGradePlansQuery,
  useCreateSteelGradePlanMutation,
  useGetSteelGradePlanQuery,
  useSetDefaultSteelGradePlanMutation,
  useGetTypicalSteelGradePlanQuery,
  useGetSteelGradePlanItemsFromProductGroupPlanItemsMutation,
  useGetFormattedBasketConstraintsQuery,
  useUpdateSearchChefGroupBasketMaterialLimitsMutation,
  useGetChefGroupBasketMaterialLimitSetQuery,
  useListChemicalElementsQuery,
  useGetCraneAssignedBasketQuery,
  useGetMaterialMetadataSetQuery,
  useListMaterialConstraintClassesQuery,
  useCreateMaterialChemistrySetMutation,
  useListMaterialChemistrySetsQuery,
  useGetMaterialChemistrySetQuery,
  useSetDefaultMaterialChemistrySetMutation,
  useCreateMaterialPhysicsSetMutation,
  useListMaterialPhysicsSetsQuery,
  useGetMaterialPhysicsSetQuery,
  useSetDefaultMaterialPhysicsSetMutation,
  useGetMaterialPricesQuery,
  useListLoadsQuery,
  useCreateLoadMutation,
  useUpdateLoadMutation,
  useListCranesQuery,
  useGetLoadQuery,
  useDeleteLoadMutation,
  useGetLoadBasketQuery,
  useUpdateLoadBasketStatusMutation,
  useUpdateLoadBasketAssignmentMutation,
  useUpdateLoadBasketAdditionsMutation,
  useGetPlanMixesQuery,
  useGetDeployedPlansQuery,
  useGetDeployedPlanMixesQuery,
  useGetPlanMixesExcelQuery,
  useDeployPlanMutation,
  useGetActiveLoadDropWeightsAtQuery,
  useGetObtainableBundleItemSetQuery,
  useCreateObtainableBundleItemSetMutation,
  useListProductGroupingsQuery,
  useCreateProductGroupingMutation,
  useGetProductGroupingQuery,
  useSetDefaultProductGroupingMutation,
  useListProductGroupPlansQuery,
  useCreateProductGroupPlanMutation,
  useGetProductGroupPlanQuery,
  useSetDefaultProductGroupPlanMutation,
  useGetTypicalProductGroupPlanQuery,
  useGetProductGroupPlanItemsFromSteelGradePlanItemsMutation,
  useListSearchesQuery,
  useSendSearchNotificationMutation,
  useCreateSearchFromContextMutation,
  useRunSearchMutation,
  useGetSearchQuery,
  useCreateSearchMutation,
  useGetSearchContextQuery,
  useGetNameBasedSearchQuery,
  useOverwriteFromNameBasedSearchMutation,
  useCreateSearchContextGroupMutation,
  useListSearchContextGroupsQuery,
  useGetSearchContextGroupQuery,
  useUpdateSearchContextGroupMixMaterialLimitsMutation,
  useListDeployedPlansQuery,
  useSendPlanNotificationMutation,
  useUpdatePlanMixesMutation,
  useUpdateAndDeployPlanMixesMutation,
  useCompareMixesQuery,
  useComparePlansQuery,
  useGetPlanAncestorsQuery,
  useGetBackupsQuery,
  useGetPlanQuery,
  useListSessionsQuery,
  useCreateSessionMutation,
  useRenameSessionMutation,
  useDeleteSessionMutation,
  useCopySearchMutation,
  useGetResultQuery,
  useGetNameBasedResultsQuery,
  useSaveUploadedFileMutation,
  useListUploadedFilesQuery,
  useGetUploadedFileQuery,
  useReassignUploadedFileMutation,
  useListUploadedFileTypesQuery,
  useGetParsedSearchParametersQuery,
  useGetPerHeatObtainableEstimateFromTypicalConsumptionQuery,
  useGetObtainableEstimateFromPurchasingPlanQuery,
  useGetLayeringMutation,
  usePublishPlanChargeScheduleMutation,
  useListNewPublishedChargeSchedulesQuery,
  useListAllPublishedChargeSchedulesQuery,
  useListCustomerPublishedChargeScheduleLayersQuery,
  useListCustomerPublishedChargeScheduleLayersRecentQuery,
  useConfirmPublishedChargeSchedulesMutation,
  useListConfirmedChargeScheduleMetaMutation,
  useGetChargeScheduleMessageTemplateQuery,
  useSendChargeScheduleMessageListMutation,
  useSendPlanDeployedMessageMutation,
  useListQueuedMessagesQuery,
  useConfirmMessageSentMutation,
  useGetInventoryEstimateAtTimestampQuery,
  useGetRecipesImpuritiesPlotRiskQuery,
  useCreateTargetBasketSetMutation,
  useGetTargetBasketSetQuery,
  useInviteUsersMutation,
  useGetPendingInvitesQuery,
  useDeleteUserInviteMutation,
  useGetVideoUrlQuery,
  useSuggestVideoQuery,
  useReadVideoLabelsQuery,
  useWriteVideoLabelsMutation,
  useGetRailcarsQuery,
  useGetPendingDeliveriesQuery,
  useUploadPendingDeliveriesMutation,
  useClearUploadedPendingDeliveriesMutation,
  useGetPendingDeliveriesMapMutation,
  useGetMixMaterialLimitSetQuery,
  useUpdateSearchMixMaterialLimitSetMutation,
  useGetPicksQuery,
  useGetHeatsQuery,
  useListAutoRecipeStrategiesQuery,
  useListAutoRecipeEstimatorsQuery,
  useListSimulatorDataLoadersQuery,
  useListSimulatorRunsQuery,
  useSimulateMutation,
  useGetSimulatorRunQuery,
  useSummarisePlanMixesMutation,
  useGetStrategyDashboardInventoryQuery,
  useGetStrategyDashboardConsumptionQuery,
  useGetStrategyDashboardFailureRateQuery,
  useGetStrategyDashboardPricesQuery,
  useGetLabelProbabilitiesQuery,
  useGetScaleWeightsQuery,
  useGetScrapCarLoadingStatusQuery,
  useGetCameraUptimePlotQuery,
  useGetMockTimelineQuery,
  useGetScaleWeightDropEventsQuery,
  useGetVisionModelDropEventsQuery,
  useGetHeatSummariesQuery,
  useGetHeatSummaryQuery,
  useGetLabelSourcesQuery,
  useGetScrapCarCyclesQuery,
  useListPurchasingPlansQuery,
  useCreatePurchasingPlanMutation,
  useGetPurchasingPlanQuery,
  useAddQuotesMutation,
  useListSuppliersQuery,
  useGetMeQuery,
  useGetUsersQuery,
  useGetUserByEmailQuery,
  useUpdateUserMutation,
  useSyncWithFirebaseMutation,
} = injectedRtkApi;
