import { SxProps } from "@mui/material";

export const defaultTableSx: SxProps = {
  width: "100%",
  mt: 2,
  // remove padding from the coloured cells
  // so the colour fills the entire cell
  "& .coloured-cell": {
    paddingLeft: 0,
    paddingRight: 0,
  },
};
