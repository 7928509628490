import {
  ArrowBack,
  ArrowForward,
  CheckCircle,
  Error,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DateRange, DateTimeRangePicker } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

import { PlanId } from "components/common/boundary/PlanId";
import { useTenantTranslation } from "hooks/formatters";
import { useShortLanguage, useTenant } from "hooks/settings";
import { backendApi } from "src/store/api/enhancedApi";
import {
  DeployParameters,
  NamedPlanMixes,
  SupportedLanguage,
  useGetPlanMixesQuery,
} from "src/store/api/generatedApi";

type Props = {
  toNextState: () => void;
  toPreviousState: () => void;
  toInitialState: () => void;
  planId: PlanId;
};

type BodyProps = {
  toNextState: () => void;
  toPreviousState: () => void;
  toInitialState: () => void;
  planId: PlanId;
  startAt: Dayjs;
  endAt: Dayjs;
};

enum State {
  Review,
  Sending,
  Success,
  Error,
}

const getPlanStartAt = (planMixes: NamedPlanMixes) => {
  if (planMixes.start_at) {
    return dayjs(new Date(planMixes.start_at)).subtract(2, "hours");
  } else {
    return dayjs(new Date());
  }
};

const getPlanEndAt = (planMixes: NamedPlanMixes, startAt: Dayjs) => {
  if (planMixes.end_at) {
    return dayjs(new Date(planMixes.end_at));
  } else {
    return startAt.add(6, "hours");
  }
};

export const SendToScrapyard = ({
  toNextState,
  toPreviousState,
  toInitialState,
  planId,
}: Props) => {
  const tenantName = useTenant();
  const planMixes = useGetPlanMixesQuery({ tenantName, planId, period: 1 });
  if (planMixes.data) {
    const startAt = getPlanStartAt(planMixes.data);
    const endAt = getPlanEndAt(planMixes.data, startAt);
    return (
      <Body
        toNextState={toNextState}
        toPreviousState={toPreviousState}
        toInitialState={toInitialState}
        planId={planId}
        startAt={startAt}
        endAt={endAt}
      />
    );
  } else {
    return null;
  }
};

export const Body = ({
  toNextState,
  toPreviousState,
  toInitialState,
  planId,
  startAt,
  endAt,
}: BodyProps) => {
  const { t } = useTenantTranslation();
  const [state, setState] = React.useState(State.Review);
  const theme = useTheme();
  const tenantName = useTenant();
  const language = useShortLanguage() as SupportedLanguage;
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([
    startAt,
    endAt,
  ]);
  const [startDate, endDate] = dateRange;

  const [deployPlan] = backendApi.endpoints.deployPlan.useMutation();

  const handleSendToScrapyard = async () => {
    if (startDate !== null && endDate !== null) {
      setState(State.Sending);
      try {
        const deployParameters: DeployParameters = {
          process: "edit_and_send",
          language: language,
          start_at: startDate.toISOString(),
          end_at: endDate.toISOString(),
        };
        await deployPlan({ tenantName, planId, deployParameters }).unwrap();
        setState(State.Success);
      } catch (e) {
        setState(State.Error);
      }
    }
  };

  const handleDateRangeChange = (newDateRange: DateRange<Dayjs>) => {
    setDateRange(newDateRange);
  };

  switch (state) {
    case State.Review: {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            margin: "0 auto",
            width: "500px",
            marginTop: 10,
          }}
        >
          <Typography variant="h6">{t("whenPlanActiveInScrapyard")}</Typography>
          <DateTimeRangePicker
            localeText={{ start: t("planStart"), end: t("planEnd") }}
            value={[startDate, endDate]}
            onChange={handleDateRangeChange}
            ampm={false}
          />
          <Box sx={{ gap: 1, alignItems: "center", display: "flex" }}>
            <Button
              variant="outlined"
              sx={{ alignSelf: "start" }}
              onClick={toPreviousState}
            >
              {t("back")}
            </Button>
            <Button
              variant="contained"
              sx={{ alignSelf: "start" }}
              onClick={handleSendToScrapyard}
            >
              {t("sendToScrapyardButton")}
            </Button>
          </Box>
        </Stack>
      );
    }
    case State.Sending: {
      return (
        <Stack
          sx={{
            height: "100%",
            margin: "0 auto",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            {t("sendingToScrapyard")}...
          </Typography>
          <Typography variant="body1">
            {t("pleaseDoNotCloseThisWindow")}
          </Typography>
        </Stack>
      );
    }
    case State.Success: {
      return (
        <Stack
          sx={{
            height: "100%",
            margin: "0 auto",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <CheckCircle color="success" />
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            {t("successfullyDeployedToScrapyard")}
          </Typography>
          <Button
            variant="contained"
            onClick={toNextState}
            endIcon={<ArrowForward />}
          >
            {t("next")}
          </Button>
        </Stack>
      );
    }
    case State.Error: {
      return (
        <Stack
          sx={{
            height: "100%",
            margin: "0 auto",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Error color="error" fontSize="large" />
          <Typography variant="body1">{t("unknownError")}</Typography>
          <Button
            variant="outlined"
            onClick={toInitialState}
            startIcon={<ArrowBack />}
          >
            {t("back")}
          </Button>
        </Stack>
      );
    }
  }
};
