import { useTheme } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridColumnGroupingModel,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useLanguage } from "hooks/settings";
import { useMemo } from "react";
import { SupportedLanguage } from "src/store/api/generatedApi";
import { formatNumber } from "src/utils/formatNumber";
import { BarCell } from "../BarCell";
import { defaultTableSx } from "../tableFormatting";
import { GroupedPriceSeries } from "../types";

type PricesTableProps = {
  priceSeries: GroupedPriceSeries;
  selectedDate: Date | null;
};

type PriceRecord = {
  materialName: string;
  priceSource: string;
  specificPrice: number;
};

type PriceRow = {
  // a price record that has been pivoted to have
  // columns for each price source
  id: number;
  materialName: string;
} & {
  [key: string]: number | string | null;
};

export const PricesTable = ({
  priceSeries,
  selectedDate,
}: PricesTableProps) => {
  const records = useMemo(
    () => buildRecords(priceSeries, selectedDate),
    [priceSeries, selectedDate]
  );

  const rows = useMemo(() => buildRowsFromRecords(records), [records]);

  const { columns, columnGroupingModel } = buildColumns(rows);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DataGridPremium
        rows={rows}
        columns={columns}
        columnGroupingModel={columnGroupingModel}
        sx={defaultTableSx}
        initialState={{
          sorting: { sortModel: [{ field: "materialName", sort: "asc" }] },
        }}
      />
    </div>
  );
};

const buildRecords = (
  priceSeries: GroupedPriceSeries,
  selectedDate: Date | null
): PriceRecord[] => {
  const records: PriceRecord[] = [];

  for (const [key, series] of priceSeries.items) {
    // Find the index of the validFrom that is largest and less than or equal to selectedDate
    const filteredValidFroms = selectedDate
      ? series.validFrom.filter((validFrom) => validFrom.isBefore(selectedDate))
      : series.validFrom;

    if (filteredValidFroms.length === 0) {
      continue;
    }

    const maxValidFrom = filteredValidFroms.reduce((max, validFrom) =>
      validFrom.isAfter(max) ? validFrom : max
    );

    const index = series.validFrom.findIndex((validFrom) =>
      validFrom.isSame(maxValidFrom)
    );

    const specificPrice = series.specificPrice[index];

    if (specificPrice) {
      records.push({
        materialName: key.materialName,
        priceSource: key.priceSource,
        specificPrice: specificPrice,
      });
    }
  }

  return records;
};

const buildRowsFromRecords = (records: PriceRecord[]): PriceRow[] => {
  const rowsByMaterialName = new Map<string, PriceRow>();
  let rowId = 0;

  // Create the rows for each material, with price fields
  // for every price source
  for (const record of records) {
    const materialNameKey = String(record.materialName);

    if (!rowsByMaterialName.has(materialNameKey)) {
      const sourceFields: Record<string, number | null> = {};

      // Get all unique price sources from the records
      const priceSources = [...new Set(records.map((r) => r.priceSource))];

      for (const source of priceSources) {
        sourceFields[source] = null;
      }

      rowsByMaterialName.set(materialNameKey, {
        id: rowId++,
        materialName: record.materialName,
        ...sourceFields,
      });
    }
  }

  // Fill in the values for each material
  for (const record of records) {
    const materialNameKey = String(record.materialName);
    const row = rowsByMaterialName.get(materialNameKey)!;
    row[record.priceSource] = record.specificPrice;
  }

  return Array.from(rowsByMaterialName.values());
};

const buildColumns = (
  rows: PriceRow[]
): {
  columns: GridColDef<PriceRow>[];
  columnGroupingModel: GridColumnGroupingModel;
} => {
  const { t } = useTenantTranslation();
  const language = useLanguage();
  const theme = useTheme();
  const units = useUnitsFormatter(true);

  const formatNumberLocal = formatNumber(language as SupportedLanguage);

  const columns: GridColDef<PriceRow>[] = [
    { field: "materialName", headerName: t("material_name"), flex: 3 },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [];
  const priceGroupChildren: { field: string }[] = [];

  // Get unique price sources from all rows
  const priceSources: string[] = [];
  if (rows.length > 0) {
    for (const row of rows) {
      Object.keys(row).forEach((key) => {
        if (
          key !== "id" &&
          key !== "materialName" &&
          !priceSources.includes(key)
        ) {
          priceSources.push(key);
        }
      });
    }
  }

  // Find the maximum price value to use for bar scaling
  const maxPrice = rows.reduce((max, row) => {
    return Math.max(
      max,
      ...priceSources.map((source) => {
        const value = row[source];
        return typeof value === "number" ? value : 0;
      })
    );
  }, 0);

  // Create columns for each price source
  for (const source of priceSources) {
    columns.push({
      field: source,
      headerName: source,
      type: "number",
      flex: 2,
      align: "right",
      renderCell: (params: GridRenderCellParams<PriceRow, number | null>) => {
        if (params.value === null) return null;

        return !params.value ? null : (
          <BarCell
            value={params.value}
            formattedValue={formatNumberLocal(2, true)(params.value)}
            // only use at most 2/3 the cell width for the bar
            maxValue={maxPrice * 1.5}
            color={theme.palette.data.blue}
          />
        );
      },
    });

    priceGroupChildren.push({ field: source });
  }

  // Add the column grouping model
  if (priceGroupChildren.length > 0) {
    columnGroupingModel.push({
      groupId: "price",
      headerName: `${t("price")} ${units("specific_cost")}`,
      children: priceGroupChildren,
    });
  }

  return { columns, columnGroupingModel };
};
