import { useState } from "react";
import { Queue } from "@mui/icons-material";
import { Button } from "@mui/material";

import { DisableHotkeysInChildren } from "contexts/hotkeys";

import { useTenantTranslation } from "hooks/formatters";
import { useBackups, resolvedBackupStatuses } from "hooks/useBackups";

import { BackupModal } from "./backupModal";

type Props = {
  planId: number;
};

export const BackupButton = ({ planId }: Props) => {
  const { t } = useTenantTranslation();

  const [open, setOpen] = useState(false);
  const backups = useBackups(planId);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        loading={backups.isLoading}
        loadingPosition="end"
        startIcon={<Queue />}
        onClick={handleOpen}
        disabled={
          !(
            backups.isSuccess &&
            backups.data.backups.length > 0 &&
            backups.data.backups.every((backup) =>
              resolvedBackupStatuses.includes(backup.status)
            )
          )
        }
      >
        {t("backups")}
      </Button>

      {open && backups.isSuccess ? (
        <DisableHotkeysInChildren>
          <BackupModal
            open={open}
            close={handleClose}
            backups={backups.data.backups}
          />
        </DisableHotkeysInChildren>
      ) : null}
    </>
  );
};

export const LoadingBackupButton = () => {
  const { t } = useTenantTranslation();
  return (
    <Button
      variant="contained"
      loading
      loadingPosition="end"
      startIcon={<Queue />}
    >
      {t("backups")}
    </Button>
  );
};
