import React from "react";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { Box, Typography, useTheme } from "@mui/material";
import { BucketLayer } from "./loadsView";

type BarCellProps = {
  theme: "success" | "warning";
  maximum: number;
  current: number;
};

const BarCell = ({ maximum, current, theme }: BarCellProps) => {
  const appTheme = useTheme();
  const color: string = (() => {
    switch (theme) {
      case "success": {
        return appTheme.palette.info.light;
      }
      case "warning": {
        return appTheme.palette.error.light;
      }
    }
  })();
  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: `${(1 - current / maximum) * 100}%`,
          transition: "right 300ms",
          backgroundColor: color,
        }}
      />
    </Box>
  );
};

const columns = (maximumScore: number): GridColDef<BucketLayer>[] => [
  {
    field: "material",
    headerName: "Material",
    display: "flex",
    flex: 1,
    cellClassName: "material_name",
  },
  {
    field: "planned",
    headerName: "Planned",
    display: "flex",
    flex: 0.7,
    align: "right",
    cellClassName: "weight",
    valueFormatter: (_: unknown, row: BucketLayer) => row.planned.toFixed(0),
  },
  {
    field: "actual",
    headerName: "Loaded",
    display: "flex",
    flex: 0.7,
    align: "right",
    cellClassName: "weight",
    valueFormatter: (_: unknown, row: BucketLayer) => row.actual.toFixed(0),
  },
  {
    field: "scoreAchieved",
    headerName: "Score achieved",
    display: "flex",
    cellClassName: "horizontal_bar",
    flex: 1,
    renderCell: ({ row: { scoreAchieved } }) => {
      return (
        <BarCell
          theme="success"
          maximum={maximumScore}
          current={scoreAchieved}
        />
      );
    },
  },
  {
    field: "scoreAvailable",
    headerName: "Score remaining",
    display: "flex",
    cellClassName: "horizontal_bar",
    flex: 1,
    renderCell: ({ row: { scoreAvailable } }) => {
      return (
        <BarCell
          theme="warning"
          maximum={maximumScore}
          current={scoreAvailable}
        />
      );
    },
  },
];

const TotalScoreBar = ({
  current,
  maximum,
}: {
  current: number;
  maximum: number;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const progress = current / maximum;
  const right = `${(1 - progress) * 100}%`;

  const left = `${progress * 100}%`;
  return (
    <Box sx={{ display: "flex", gap: 1 }} alignItems="center">
      <Typography variant="h6">Score</Typography>
      <Box
        ref={containerRef}
        sx={{
          position: "relative",
          height: "30px",
          width: "100%",
          outline: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.error.light,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            position: "absolute",
            borderRadius: 1,
            left,
            top: -42,
            transform: "translate(-50%,-0%)",
            backgroundColor: theme.palette.info.light,
            color: theme.palette.common.white,
            outline: `1px solid ${theme.palette.grey[400]}`,
            padding: 0.5,
            transition: "left 300ms",
          }}
        >
          ${current.toFixed(0)}
        </Typography>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: right,
            backgroundColor: theme.palette.info.light,
            borderRight:
              progress < 1 ? `1px solid ${theme.palette.grey[900]}` : "unset",
            transition: "left 300ms",
          }}
        />
      </Box>
    </Box>
  );
};

export const LoadedBucket = ({ layers }: { layers: BucketLayer[] }) => {
  const theme = useTheme();

  const rowHeight = 40;
  const headerHeight = 40;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 1, marginTop: 3 }}
    >
      <TotalScoreBar
        current={layers
          .map((layer) => layer.scoreAchieved)
          .reduce((left, right) => left + right, 0)}
        maximum={layers
          .map((layer) => layer.scoreAchieved + layer.scoreAvailable)
          .reduce((left, right) => left + right, 0)}
      />
      <DataGridPremium
        columns={columns(
          Math.max(
            ...layers.map((layer) => layer.scoreAchieved + layer.scoreAvailable)
          )
        )}
        rows={layers.map((layer, index) => ({ ...layer, id: index }))}
        rowHeight={rowHeight}
        columnHeaderHeight={headerHeight}
        sx={{
          marginTop: 2,
          // ...sx,
          [".horizontal_bar"]: {
            padding: 0.1,
          },
          [".MuiDataGrid-columnHeaderTitle"]: {
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.h5.fontWeight,
          },
          [".material_name"]: {
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.h5.fontWeight,
          },
          [".weight"]: {
            fontSize: theme.typography.h5.fontSize,
          },
        }}
      />
    </Box>
  );
};
