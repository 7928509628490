import { Button } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { deployPlanFormSlice } from "src/store/slices/deployPlanFormSlice";
import { useAppDispatch } from "src/store/store";

type Props = {
  planId: number;
  doClose: () => void;
};

export const CancelButton = ({ planId, doClose }: Props) => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const handleOnClick = () => {
    doClose();
    dispatch(deployPlanFormSlice.actions.cancelFlow(planId));
  };
  return (
    <Button variant="outlined" onClick={handleOnClick}>
      {t("cancel")}
    </Button>
  );
};
