import { CloudDownload } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { usePermission } from "hooks/permissions";
import { useCanDeploy } from "hooks/useCanDeploy";
import { useCanDownloadExcel } from "hooks/useCanDownloadExcel";
import { useDownloadPlanMixes } from "hooks/useDownloadPlanMixes";
import { useIsBehindFeatureFlag } from "hooks/useFeatureFlag";
import { useLocation } from "react-router";
import { usePlanId } from "../../common/boundary/PlanId";
import { BackupButton, LoadingBackupButton } from "../backups/backupButton";
import { useShouldFetchBackups } from "../../../hooks/useBackups";
import { ExportPlan } from "./exportPlan/button";
import { OpenInPage } from "./openInPage";

export const PlanActionButtons = () => {
  const currentLocation = useLocation();
  const isPlanPeek = currentLocation.pathname.endsWith("/search");

  const { t } = useTenantTranslation();
  const planId = usePlanId();
  const canDeploy = useCanDeploy();
  const publisher = usePermission(["publish"]);
  const canDownloadExcel = useCanDownloadExcel();
  const areAutoBackupsEnabled = useIsBehindFeatureFlag("auto_backups");
  const shouldFetchBackups = useShouldFetchBackups();

  const downloadPlanMixes = useDownloadPlanMixes(planId, null);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {isPlanPeek ? <OpenInPage /> : null}
      {canDownloadExcel ? (
        <Button startIcon={<CloudDownload />} onClick={downloadPlanMixes}>
          {t("download")}
        </Button>
      ) : null}

      {areAutoBackupsEnabled ? (
        <Box>
          {shouldFetchBackups ? (
            <BackupButton planId={planId} />
          ) : (
            <LoadingBackupButton />
          )}
        </Box>
      ) : null}

      {canDeploy && publisher ? (
        <Box>
          <ExportPlan planId={planId} />
        </Box>
      ) : null}
    </Stack>
  );
};
