import { Typography, useTheme } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { BasketRow, ProfileRow, Row } from "./Table";

type Props = {
  materialId: number;
  inventoryAmount: number;
};

export const TotalConsumptionCell = ({
  materialId,
  inventoryAmount,
}: Props) => {
  const gridApi = useGridApiContext();
  const theme = useTheme();
  const total = gridApi.current
    .getAllRowIds()
    .map((rowId) => gridApi.current.getRow<Row>(rowId))
    .filter((row: Row | null): row is ProfileRow | BasketRow => {
      return row !== null && (row.type === "profile" || row.type === "basket");
    })
    .reduce(
      (total, row) =>
        total + row.numberOfHeats * (row.materialMasses[materialId] ?? 0),
      0
    );

  const backgroundColor =
    total > inventoryAmount
      ? theme.palette.error.main
      : theme.palette.common.white;
  const color =
    total > inventoryAmount
      ? theme.palette.common.white
      : theme.palette.common.black;

  return (
    <Typography
      variant="body1Mono"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor,
        color,
      }}
    >
      {total.toFixed(0)}
    </Typography>
  );
};
