import { Stack, Typography, useTheme } from "@mui/material";
import { useMaterials } from "contexts/search/provider.tsx";
import { useTenantTranslation } from "src/hooks/formatters";
import { Plan } from "store/api/generatedApi";
import { PlanCard } from "./planCard/card";
import { PlanCardData, planCards } from "./planCard/data";
import { PlanCardSkeleton } from "./planCard/skeleton";

export const SuccessfulSearch = ({
  plans,
  primary,
  incompleteResults,
  onClickPlan,
}: {
  plans: Plan[];
  primary: boolean;
  incompleteResults?: boolean;
  onClickPlan: (planCard: PlanCardData) => void;
}) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const searchMaterials = useMaterials();
  const materials =
    searchMaterials.status === "success" ? searchMaterials.data.byIndex : [];

  const planResults = planCards(plans, materials).map(
    ({
      planId,
      planIndex,
      planName,
      basePlanId,
      searchId,
      tags,
      metrics,
      optimisationSlackVariableUsage,
      optimisationStatus,
      deployments,
    }) => (
      <PlanCard
        key={planIndex}
        planId={planId}
        planIndex={planIndex}
        planName={planName}
        basePlanId={basePlanId}
        searchId={searchId}
        tags={tags}
        metrics={metrics}
        optimisationStatus={optimisationStatus}
        optimisationSlackVariableUsage={optimisationSlackVariableUsage}
        deployments={deployments}
        onClick={onClickPlan}
      />
    )
  );

  return (
    <>
      {!primary && (
        <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
          {t("similarResults")}
        </Typography>
      )}
      <Stack spacing={1}>
        {planResults}
        {incompleteResults ? <PlanCardSkeleton /> : null}
      </Stack>
    </>
  );
};
