import { useTenant } from "hooks/settings";
import React, { useEffect, useState } from "react";
import { useGetBackupsQuery } from "src/store/api/generatedApi";

const checkBackupsPollMS = 3000;

export const resolvedBackupStatuses = [
  "failed",
  "failed_suboptimal",
  "succeeded_suboptimal",
  "succeeded",
];

export const useBackups = (planId: number) => {
  const tenant = useTenant();
  const backups = useGetBackupsQuery({
    tenantName: tenant,
    planId: planId,
  });

  const timeoutRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);

  React.useEffect(() => {
    if (
      backups.isSuccess &&
      backups.currentData?.backups.some(
        (backup) => !resolvedBackupStatuses.includes(backup.status)
      )
    ) {
      timeoutRef.current = setTimeout(() => {
        void backups.refetch();
      }, checkBackupsPollMS);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [backups.isSuccess, backups.currentData, backups.refetch]);

  return backups;
};

// Backups are added to the database very quickly (a few seconds), but if someone
// runs a search and then clicks on a result _extremely_ quickly, the backups may not
// be there yet and the query will return an empty array.
// This hook is used to delay the fetching of backups for a few seconds to give time
// for them to be added.
const checkBackupsInitialDelay = 4000;

export const useShouldFetchBackups = (): boolean => {
  const [shouldFetchBackups, setShouldFetchBackups] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldFetchBackups(true);
    }, checkBackupsInitialDelay);

    return () => clearTimeout(timer);
  }, []);

  return shouldFetchBackups;
};
