import { Theme, Typography, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import {
  GridColDef,
  GridPinnedRowsProp,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { PlainCell, StyledCell } from "components/plan/table/common/Cell";
import { TitleColumnHeader } from "components/plan/table/common/TitleColumnHeader";
import { UnitSuffix } from "components/plan/table/common/UnitSuffix";
import { useTenantTranslation } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";

export interface MaterialRow {
  material: string;
  planned: number;
  actual: number;
  total: number;
  id: string;
  isTotal: false;
}

export interface TotalRow {
  material: string;
  planned: number;
  actual: number;
  total: number;
  id: "total";
  isTotal: true;
}

export type MaterialComparison = MaterialRow | TotalRow;

export const isTotalRow = (row: MaterialComparison): row is TotalRow => {
  return row.isTotal;
};

export type LoadMap = { [material: string]: number };

export interface BaseTableProps {
  sx?: SxProps<Theme>;
}

export interface VisualisedCellProps {
  value: number;
  alpha: number;
}

export const formatUnitSuffix = (units: string, bracketed = false) => {
  if (bracketed) {
    return `\xa0(${units})`;
  } else {
    return `\xa0${units}`;
  }
};

export const unitSuffix = (units: string, bracketed = false) => (
  <UnitSuffix>{formatUnitSuffix(units, bracketed)}</UnitSuffix>
);

export const VisualisedCell = ({ value, alpha = 0 }: VisualisedCellProps) => {
  const theme = useTheme();
  const { format } = useNumberSerialiser({ decimalPlaces: 0 });

  return (
    <StyledCell
      value={format(value)}
      suffix={unitSuffix("lbs")}
      alpha={alpha}
      color={theme.palette.data.blue}
    />
  );
};

export type ColumnWidths = {
  material: number | { flex: number; minWidth: number };
  planned: number;
  actual: number;
  diff?: number;
};

type TableLayoutProps = {
  plannedLoad: LoadMap;
  actualLoad: LoadMap;
  includeDiffColumn?: boolean;
  columnWidths?: ColumnWidths;
};

export const useTableLayout = ({
  plannedLoad,
  actualLoad,
  includeDiffColumn = true,
  columnWidths = {
    material: 158,
    planned: 130,
    actual: 130,
    diff: 130,
  },
}: TableLayoutProps) => {
  const { t } = useTenantTranslation();

  const materials = new Set<string>();
  Object.keys(plannedLoad).forEach((mat) => materials.add(mat));
  Object.keys(actualLoad).forEach((mat) => materials.add(mat));

  const rows: MaterialRow[] = Array.from(materials).map((material) => {
    const planned = plannedLoad[material] ?? 0;
    const actual = actualLoad[material] ?? 0;
    const total = Math.max(planned, actual);

    return {
      material,
      planned,
      actual,
      total,
      id: material,
      isTotal: false,
    };
  });

  const totalPlanned = Object.values(plannedLoad).reduce(
    (sum, val) => sum + val,
    0
  );
  const totalActual = Object.values(actualLoad).reduce(
    (sum, val) => sum + val,
    0
  );
  const totalMax = Math.max(totalPlanned, totalActual);

  const materialColumn: GridColDef<MaterialComparison> = {
    field: "material",
    headerName: t("material", { postProcess: "capitalise" }),
    minWidth:
      typeof columnWidths.material === "number"
        ? columnWidths.material
        : columnWidths.material.minWidth,
    flex:
      typeof columnWidths.material === "number"
        ? undefined
        : columnWidths.material.flex,
    renderHeader: () => <TitleColumnHeader title={t("material")} />,
    headerClassName: "group--header",
    cellClassName: "group--header",
    renderCell: ({ value }) => <Typography variant="body2">{value}</Typography>,
  };

  const plannedColumn: GridColDef<MaterialComparison> = {
    field: "planned",
    headerName: `${t("planned", {
      postProcess: "capitalise",
    })}${formatUnitSuffix("lbs", true)}`,
    minWidth: columnWidths.planned,
    headerClassName: "group--header",
    renderCell: (params: GridRenderCellParams<MaterialComparison>) => {
      const row = params.row;
      if (!row) return null;
      const { format } = useNumberSerialiser({ decimalPlaces: 0 });
      if (isTotalRow(row)) {
        return <PlainCell value={format(row.planned)} />;
      }
      return (
        <VisualisedCell
          value={row.planned}
          alpha={row.planned / totalPlanned}
        />
      );
    },
  };

  const actualColumn: GridColDef<MaterialComparison> = {
    field: "actual",
    headerName: `${t("actual", {
      postProcess: "capitalise",
    })}${formatUnitSuffix("lbs", true)}`,
    minWidth: columnWidths.actual,
    headerClassName: "group--header",
    renderCell: (params: GridRenderCellParams<MaterialComparison>) => {
      const row = params.row;
      if (!row) return null;
      const { format } = useNumberSerialiser({ decimalPlaces: 0 });
      if (isTotalRow(row)) {
        return <PlainCell value={format(row.actual)} />;
      }
      const theme = useTheme();
      return (
        <StyledCell
          value={format(row.actual)}
          alpha={row.actual / totalPlanned}
          color={theme.palette.data.blue}
        />
      );
    },
  };

  const diffColumn: GridColDef<MaterialComparison> = {
    field: "diff",
    headerName: `${t("difference", {
      postProcess: "capitalise",
    })}${formatUnitSuffix("lbs", true)}`,
    minWidth: columnWidths.diff,
    headerClassName: "group--header",
    renderCell: (params: GridRenderCellParams<MaterialComparison>) => {
      const row = params.row;
      if (!row) return null;
      const { format } = useNumberSerialiser({ decimalPlaces: 0 });
      const diff = row.actual - row.planned;
      const theme = useTheme();
      const text =
        format(Math.abs(diff)) + (diff > 0 ? " ↑" : diff < 0 ? " ↓" : "");
      return (
        <StyledCell
          value={text}
          alpha={diff === 0 ? 0.0 : 0.15}
          color={diff < 0 ? theme.palette.data.blue : theme.palette.data.orange}
        />
      );
    },
  };

  const pinnedRows: GridPinnedRowsProp<MaterialComparison> = {
    bottom: [
      {
        id: "total",
        material: `${t("totalMass")}${formatUnitSuffix("lbs", true)}`,
        planned: totalPlanned,
        actual: totalActual,
        total: totalMax,
        isTotal: true,
      } as TotalRow,
    ],
  };

  const columns = [materialColumn, plannedColumn, actualColumn];
  if (includeDiffColumn) {
    columns.push(diffColumn);
  }

  return {
    columns,
    rows,
    pinnedRows,
    pinnedColumns: {
      left: ["material"],
    },
    rowHeight: 32,
    columnHeaderHeight: 32,
  };
};

export const commonDataGridSx = {
  "& .MuiDataGrid-cell": {
    paddingLeft: 0,
    paddingRight: 0,
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    whiteSpace: "normal",
  },
  "& .group--header": {
    py: 0.5,
    px: 1,
  },
};
