import {
  GridCellEditStopParams,
  useGridApiContext,
  useGridApiEventHandler,
} from "@mui/x-data-grid-premium";
import { useTenant } from "hooks/settings";
import React from "react";
import {
  SteelGradePlanItem,
  useSummarisePlanMixesMutation,
} from "src/store/api/generatedApi";
import { z } from "zod";
import {
  BasketRow,
  ProfileRow,
  resetEventParam,
  Row,
  updateRowEventParam,
} from "./Table";
import { makePostDataGridProcessingCallback } from "./utils";

type Arg = {
  planId: number;
  mixId: number;
  steelGradeProduction: SteelGradePlanItem[];
  period: number;
  originalBasketsMaterialMasses: Record<number, Record<number, number>>;
};

export const useSummaryMutation = ({
  planId,
  mixId,
  steelGradeProduction,
  period,
  originalBasketsMaterialMasses,
}: Arg) => {
  const tenantName = useTenant();
  const [areOriginalBasketMaterialMasses, setAreOriginalBasketMaterialMasses] =
    React.useState(false);
  const [doSummarisePlanMixesMutation, summarisePlanMixesMutationResult] =
    useSummarisePlanMixesMutation({
      fixedCacheKey: [planId, mixId].join("."),
    });
  const gridApi = useGridApiContext();

  const doSummaryMutation = React.useCallback(
    (mixId: number) => {
      const rows = gridApi.current
        .getAllRowIds()
        .map((rowId) => gridApi.current.getRow<Row>(rowId))
        // Type guard for only the rows we are interested in
        .filter((row: Row | null): row is ProfileRow | BasketRow => {
          return (
            row !== null &&
            row.mixId === mixId &&
            (row.type === "basket" || row.type === "profile")
          );
        });

      // Check there even is a difference
      const basketMaterialMasses = rows.reduce((basketMaterialMasses, row) => {
        const basketNumber = row.type === "profile" ? 1 : row.basketNumber;
        return {
          ...basketMaterialMasses,
          [basketNumber]: {
            ...row.materialMasses,
          },
        };
      }, {});

      const areOriginalBasketMaterialMasses =
        JSON.stringify(basketMaterialMasses) ===
        JSON.stringify(originalBasketsMaterialMasses);

      setAreOriginalBasketMaterialMasses(areOriginalBasketMaterialMasses);

      const baskets = rows.reduce((baskets, row) => {
        const basketNumber = row.type === "profile" ? 1 : row.basketNumber;
        const totalMass = Object.values(row.materialMasses).reduce(
          (sum, mass) => sum + mass,
          0
        );
        return {
          ...baskets,
          [basketNumber]: {
            material_masses: row.materialMasses,
            total_mass: totalMass,
          },
        };
      }, {});

      // Make the request
      void doSummarisePlanMixesMutation({
        tenantName,
        unsummarisedPlanMixes: {
          plan_id: planId,
          mixes: [
            {
              period,
              steel_grade_production: steelGradeProduction,
              mix_id: mixId,
              baskets,
            },
          ],
        },
      });
    },
    [
      doSummarisePlanMixesMutation,
      gridApi,
      period,
      steelGradeProduction,
      setAreOriginalBasketMaterialMasses,
      planId,
    ]
  );

  const postDataGridProcessingDoSummaryMutation = React.useMemo(
    () => makePostDataGridProcessingCallback(doSummaryMutation),
    [doSummaryMutation]
  );

  const handleCellEditStopEvent = React.useCallback(
    (params: GridCellEditStopParams<Row>) => {
      if (params.row.mixId === mixId) {
        // Wait for the table to do the necessary updates internally before running mutation
        void postDataGridProcessingDoSummaryMutation(mixId);
      }
    },
    [gridApi, mixId]
  );

  const handleStateChangeEvent = React.useCallback(
    (params: unknown) => {
      if (
        z
          .literal(updateRowEventParam)
          .or(z.literal(resetEventParam))
          .safeParse(params).success
      ) {
        void postDataGridProcessingDoSummaryMutation(mixId);
      }
    },
    [mixId, postDataGridProcessingDoSummaryMutation]
  );

  useGridApiEventHandler(gridApi, "cellEditStop", handleCellEditStopEvent);
  useGridApiEventHandler(gridApi, "stateChange", handleStateChangeEvent);

  React.useEffect(() => {
    doSummaryMutation(mixId);
  }, [mixId, doSummaryMutation]);

  return [
    areOriginalBasketMaterialMasses,
    summarisePlanMixesMutationResult,
  ] as const;
};
