import { sorted } from "helpers";
import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useShiftScores } from "./hooks";

export const ShiftLeaderboard = ({
  shiftHeats,
}: {
  shiftHeats: { heatId: string; shift: string }[];
}) => {
  const { loadedCount, totalCount, shiftScores } = useShiftScores(shiftHeats);
  const { format } = useNumberSerialiser({ decimalPlaces: 2 });

  const loading = loadedCount < totalCount;

  return (
    <Stack gap={8}>
      <Typography variant="h1">Shift leaderboard</Typography>

      <Stack
        sx={{
          width: 0.5,
          marginLeft: "auto",
          marginRight: "auto",
          opacity: loading ? 0.3 : undefined,
        }}
      >
        {loading ? (
          <LinearProgress
            variant="determinate"
            value={(loadedCount / totalCount) * 100}
          />
        ) : null}

        {sorted(Object.entries(shiftScores), ([, score]) => -score).map(
          ([shift, score]) => (
            <Stack
              key={shift}
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              gap={2}
            >
              <Button sx={{ fontSize: 20 }}>Shift {shift}</Button>
              <Typography fontSize={16} fontFamily="monospace">
                ${format(score)}
              </Typography>
            </Stack>
          )
        )}
      </Stack>

      <Typography variant="h4">
        Tap a heat to see a breakdown of the score for that heat.
      </Typography>
    </Stack>
  );
};
