import { Typography } from "@mui/material";

type Props = {
  amount: number;
};

export const InventoryCell = ({ amount }: Props) => {
  return (
    <Typography variant="body1Mono" sx={{ width: "100%" }}>
      {amount.toFixed(0)}
    </Typography>
  );
};
