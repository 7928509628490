import { useTheme } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useLanguage } from "hooks/settings";
import { useMemo } from "react";
import {
  MaterialGroupingName,
  SupportedLanguage,
} from "src/store/api/generatedApi";
import { formatNumber } from "src/utils/formatNumber";
import { BarCell } from "../BarCell";
import { defaultTableSx } from "../tableFormatting";
import { GroupedInventorySeries } from "../types";

type InventoryRow = {
  id: number;
  materialGroup: string;
  inventory_mass: number;
  inventory_fraction: number;
};

type InventoryTableProps = {
  inventorySeries: GroupedInventorySeries;
  selectedDate: Date | null;
  selectedMaterialGrouping: MaterialGroupingName | null;
};

export const InventoryTable = ({
  inventorySeries,
  selectedDate,
  selectedMaterialGrouping,
}: InventoryTableProps) => {
  const rows = useMemo(
    () => buildRows(inventorySeries, selectedDate, selectedMaterialGrouping),
    [inventorySeries, selectedDate, selectedMaterialGrouping]
  );

  const totalsRow = useMemo(() => buildTotalsRow(rows), [rows]);

  const columns = buildColumns(selectedMaterialGrouping, rows, totalsRow);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DataGridPremium
        sx={defaultTableSx}
        rows={rows}
        pinnedRows={{
          bottom: [totalsRow],
        }}
        columns={columns}
        initialState={{
          sorting: { sortModel: [{ field: "materialGroup", sort: "asc" }] },
        }}
      />
    </div>
  );
};

const buildRows = (
  inventorySeries: GroupedInventorySeries,
  selectedDate: Date | null,
  selectedMaterialGrouping: MaterialGroupingName | null
): InventoryRow[] => {
  const rows: InventoryRow[] = [];

  const filteredSeries = inventorySeries.values.filter(
    (item) => item[0].materialGroupingName === selectedMaterialGrouping
  );

  let rowId = 0;

  for (const [key, series] of filteredSeries) {
    for (const [index, dateString] of series.date.entries()) {
      const date = new Date(dateString);
      if (!selectedDate || date.toISOString() === selectedDate.toISOString()) {
        const inventoryMass = series.inventory_mass[index];
        const inventoryFraction = series.inventory_fraction[index];

        if (inventoryMass && inventoryFraction) {
          rows.push({
            id: rowId,
            materialGroup: key.materialGroupName,
            inventory_mass: inventoryMass,
            inventory_fraction: inventoryFraction,
          });
          rowId++;
        }
      }
    }
  }
  return rows;
};

const buildTotalsRow = (rows: InventoryRow[]): InventoryRow => {
  const { t } = useTenantTranslation();

  const totalInventoryMass = rows.reduce(
    (sum, row) => sum + row.inventory_mass,
    0
  );
  const totalInventoryFraction = rows.reduce(
    (sum, row) => sum + row.inventory_fraction,
    0
  );
  return {
    id: rows.length,
    materialGroup: t("Total"),
    inventory_mass: totalInventoryMass,
    inventory_fraction: totalInventoryFraction,
  };
};

const buildColumns = (
  selectedMaterialGrouping: MaterialGroupingName | null,
  rows: InventoryRow[],
  totalsRow: InventoryRow
): GridColDef[] => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);
  const language = useLanguage();
  const theme = useTheme();

  const formatNumberLocal = formatNumber(language as SupportedLanguage);

  const columns: GridColDef[] = [
    {
      field: "materialGroup",
      headerName: t(selectedMaterialGrouping ?? ""),
      flex: 2,
    },
    {
      field: "inventory_mass",
      type: "number",
      headerName: `${t("inventory")}${units("mass")}`,
      flex: 2,
      align: "right",
      renderCell: (params: GridRenderCellParams<InventoryRow, number>) =>
        params.row.id == rows.length ? (
          <div>{formatNumberLocal(0, true)(totalsRow.inventory_mass)}</div>
        ) : (
          <BarCell
            value={params.value!}
            formattedValue={formatNumberLocal(0, true)(params.value!)}
            maxValue={totalsRow.inventory_mass}
            color={theme.palette.data.blue}
          />
        ),
    },
    {
      field: "inventory_fraction",
      type: "number",
      headerName: `${t("inventory")} (%)`,
      flex: 1,
      align: "right",
      valueFormatter: (value?: number) =>
        !value ? "" : `${formatNumberLocal(1, true)(value * 100)} %`,
    },
  ];

  return columns;
};
