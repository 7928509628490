import { Typography } from "@mui/material";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);

type TableHeaderProps = {
  titlePrefix: string;
  subtitleText: string;
  selectedDate: Date | null;
  formatDateMonthly: boolean;
};

export const TableHeader = ({
  titlePrefix,
  subtitleText,
  selectedDate,
  formatDateMonthly,
}: TableHeaderProps) => {
  const formattedDate = selectedDate
    ? formatDateMonthly
      ? `${dayjs(selectedDate).format("MMM YYYY")}`
      : `${dayjs(selectedDate).format("DD MMM YYYY")}`
    : "all time";

  return (
    <div>
      <Typography variant="h5">{`${titlePrefix} ${formattedDate}`}</Typography>
      <Typography variant="body1">{subtitleText}</Typography>
    </div>
  );
};
