import { useTenant } from "hooks/settings";
import { useCallback, useMemo } from "react";
import Plot from "react-plotly.js";
import { backendApi } from "src/store/api/enhancedApi";
import { Chunk, ChunkData, quantisedWindow, useLoadedWindow } from "../helpers";
import { defaultLayout, loadingChunks } from "../plotting";
import { OnRelayout } from "../viewportControls";

type SourcePeriod = {
  start: number;
  end: number;
  colour: string;
  text: string;
};

const useLabelSources = (
  start: Date,
  end: Date
): ChunkData<{ [key: string]: SourcePeriod }>[] => {
  const [getLabelSources] = backendApi.endpoints.getLabelSources.useLazyQuery();
  const tenant = useTenant();

  const get = useCallback(
    async (chunk: Chunk) =>
      getLabelSources({
        tenantName: tenant,
        ...chunk,
      })
        .unwrap()
        .then((result) =>
          Object.fromEntries(
            result.periods.map((item) => [
              item.start.toString() + "-" + item.text,
              {
                start: item.start,
                end: item.end,
                colour: item.colour,
                text: item.text,
              },
            ])
          )
        ),
    [getLabelSources]
  );

  return useLoadedWindow(
    quantisedWindow(
      { start: start.getTime(), end: end.getTime() },
      300 * 1000,
      2
    ),
    get
  );
};

const useMergeLabelSources = (
  chunks: ChunkData<{ [key: string]: SourcePeriod }>[]
) => {
  return useMemo(() => {
    const loaded_chunks = chunks.flatMap((c) =>
      c.value.status === "success" ? [c.value.data] : []
    );

    const merged: { [key: string]: SourcePeriod } = {};
    for (const chunk of loaded_chunks) {
      Object.entries(chunk).forEach(([key, value]) => {
        const prev = merged[key];
        if (prev) {
          merged[key] = {
            start: Math.min(prev.start, value.start),
            end: Math.max(prev.end, value.end),
            colour: prev.colour,
            text: prev.text,
          };
        } else {
          merged[key] = value;
        }
      });
    }

    return Object.values(merged).map((v) => ({
      start: new Date(v.start),
      end: new Date(v.end),
      colour: v.colour,
      text: v.text,
    }));
  }, [chunks]);
};

export const LabelSourcesPlot = ({
  start,
  end,
  onRelayout,
}: {
  start: Date;
  end: Date;
  onRelayout?: OnRelayout;
}) => {
  const labelSources = useLabelSources(start, end);
  const mergedLabelSources = useMergeLabelSources(labelSources);

  // Get unique text labels and assign them y-values
  const uniqueLabels = [
    ...new Set(mergedLabelSources.map((source) => source.text)),
  ];
  const labelToY = Object.fromEntries(
    uniqueLabels.map((label, index) => [label, index])
  );

  return (
    <Plot
      data={mergedLabelSources.map((source) => ({
        x: [source.start, source.end],
        y: [labelToY[source.text], labelToY[source.text]],
        mode: "lines",
        line: {
          color: source.colour,
          width: 20,
        },
        name: source.text,
        showlegend: true,
        hoverinfo: "x+text" as const,
        text: source.text,
      }))}
      layout={{
        ...defaultLayout(),
        xaxis: {
          range: [start, end],
          type: "date",
          title: "Time",
        },
        yaxis: {
          ticktext: uniqueLabels,
          tickvals: uniqueLabels.map((_, i) => i),
          title: "Label Source",
          showgrid: false,
        },
        shapes: loadingChunks(labelSources),
        height: Math.max(400, uniqueLabels.length * 40), // Adjust height based on number of labels
      }}
      onRelayout={onRelayout}
      style={{ width: "100%" }}
    />
  );
};
