import React from "react";
import { Box, Chip, Stack, useTheme } from "@mui/material";
import { useTenant } from "hooks/settings";

import { SteelGrade, useCreateLoadMutation } from "src/store/api/generatedApi";
import { selectSearchedSteelGradeIds } from "src/store/slices/steelGradeSearchSlice";
import { useAppSelector } from "src/store/store";

type Props = {
  planId: number;
  mixId: number;
  steelGrades: SteelGrade[];
  canClick: boolean;
  view: "deploy" | "backup" | "supervisor";
};

export const SteelGradesCell = ({
  steelGrades,
  canClick,
  planId,
  mixId,
  view,
}: Props) => {
  const theme = useTheme();
  const tenantName = useTenant();

  const selectedSteelGradeIds = useAppSelector((state) =>
    selectSearchedSteelGradeIds(state, planId, view)
  );

  const [doCreateLoadMutation] = useCreateLoadMutation();

  const handleOnClick = React.useCallback(
    async (steelGradeId: number) => {
      await doCreateLoadMutation({
        tenantName,
        loadCreate: {
          steel_grade_id: steelGradeId,
          plan_id: planId,
          mix_id: mixId,
        },
      });
    },
    [mixId, planId]
  );

  const [mixSelectedSteelGrades, notMixSelectedSteelGrades] =
    React.useMemo(() => {
      return [
        steelGrades.filter((steelGrade) =>
          selectedSteelGradeIds.includes(steelGrade.id)
        ),
        steelGrades.filter(
          (steelGrade) => !selectedSteelGradeIds.includes(steelGrade.id)
        ),
      ] as const;
    }, [selectedSteelGradeIds, steelGrades]);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: 0.4,
        flexWrap: "wrap",
        paddingY: 1,
        paddingX: 0.5,
        overflow: "auto",
        height: `calc(100% - ${theme.spacing(2)})`,
        alignItems: "flex-start",
      }}
    >
      {mixSelectedSteelGrades.map((steelGrade) => {
        return (
          <Chip
            key={steelGrade.id}
            label={steelGrade.name}
            size="small"
            onClick={canClick ? () => handleOnClick(steelGrade.id) : undefined}
            sx={{
              backgroundColor: theme.palette.grey[600],
              userSelect: "none",
              color: theme.palette.common.white,
            }}
          />
        );
      })}
      {mixSelectedSteelGrades.length > 0 ? (
        <Box sx={{ flexBasis: "100%", height: theme.spacing(0.5) }} />
      ) : null}
      {notMixSelectedSteelGrades.map((steelGrade) => {
        return (
          <Chip
            key={steelGrade.id}
            label={steelGrade.name}
            size="small"
            onClick={canClick ? () => handleOnClick(steelGrade.id) : undefined}
            sx={{
              userSelect: "none",
            }}
          />
        );
      })}
    </Stack>
  );
};
