import { sorted } from "helpers";
import { useTenant } from "hooks/settings";
import { useCallback } from "react";
import Plot from "react-plotly.js";
import { backendApi } from "src/store/api/enhancedApi";
import {
  Chunk,
  ChunkData,
  quantisedWindow,
  useLoadedWindow,
  useMergedChunks,
} from "../helpers";
import { defaultLayout, linePlot, loadingChunks, Point } from "../plotting";
import { OnRelayout } from "../viewportControls";
import { Stack, Typography } from "@mui/material";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { useLocalStorageState } from "components/videoLabelling/helpers";

const useLoadingStatus = (
  start: Date,
  end: Date
): ChunkData<{ [scrapCar: number]: Point<string>[] }>[] => {
  const [getLoadingStatus] =
    backendApi.endpoints.getScrapCarLoadingStatus.useLazyQuery();
  const tenant = useTenant();

  const get = useCallback(
    async (chunk: Chunk) =>
      getLoadingStatus({ tenantName: tenant, ...chunk })
        .unwrap()
        .then((result) =>
          Object.fromEntries(
            result.scrap_cars.map((item) => [
              item.scrap_car,
              item.loading_status.map((row) => ({
                ...row,
                timestamp: new Date(row.timestamp),
              })),
            ])
          )
        ),
    [getLoadingStatus]
  );

  return useLoadedWindow(
    quantisedWindow(
      { start: start.getTime(), end: end.getTime() },
      300 * 1000,
      2
    ),
    get
  );
};

export const LoadingStatusPlot = ({
  start,
  end,
  onRelayout,
}: {
  start: Date;
  end: Date;
  onRelayout?: OnRelayout;
}) => {
  const loadingStatus = useLoadingStatus(start, end);
  const mergedLoadingStatus = useMergedChunks(loadingStatus);

  const serialiser = useStringSerialiser();

  const [scrapCarsString, setScrapCarsString] = useLocalStorageState(
    "loadingStatusScrapCars",
    "1, 2, 3"
  );
  const scrapCars = scrapCarsString
    .split(",")
    .map((segment) => parseInt(segment.trim()))
    .filter((scrapCar) => !isNaN(scrapCar));
  const statuses = ["Empty", "Loading", "Ready"];

  return (
    <>
      <Stack direction="row" justifyContent="right" alignItems="center" gap={1}>
        <Typography>Scrap cars:</Typography>
        <ValidatedTextField
          value={scrapCarsString}
          setValue={setScrapCarsString}
          serialiser={serialiser}
          textFieldProps={{ sx: { width: 200 } }}
        />
      </Stack>
      <Plot
        data={sorted(scrapCars, (key) => key).map((bucket) => ({
          ...linePlot(
            (mergedLoadingStatus[bucket] ?? []).map((point) => ({
              ...point,
              value: statuses.indexOf(point.value),
            }))
          ),
          name: `Scrap car ${bucket}`,
        }))}
        layout={{
          ...defaultLayout(),
          xaxis: {
            range: [start, end],
            type: "date",
          },
          yaxis: {
            fixedrange: true,
            range: [0, statuses.length - 0.9],
            tickvals: statuses.map((_, index) => index),
            ticktext: statuses,
          },
          shapes: loadingChunks(loadingStatus),
        }}
        onRelayout={onRelayout}
        style={{ width: "100%" }}
      />
    </>
  );
};
