import React from "react";
import { Stack, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { ArrowDropDown, ArrowDropUp, Delete, Edit } from "@mui/icons-material";
import { EditPeriodModal, PeriodMetadata } from "./actions";
import { Period } from "hooks/periodIndex";
import { Admin } from "../boundary/admin";
import { usePeriodNameFormatter } from "hooks/usePeriodNameFormatter";

type Props = {
  index: Period;
  metadata: PeriodMetadata;
  selected: boolean;
  setMetadata: (metadata: PeriodMetadata) => void;
  onDelete: () => void;
};

export const TabHeader = ({
  index,
  selected,
  metadata,
  setMetadata,
  onDelete,
}: Props) => {
  const { t } = useTenantTranslation();

  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  const menuAnchorRef: React.MutableRefObject<HTMLElement | null> =
    React.useRef(null);

  const toggleMenuIsOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      menuAnchorRef.current = event.currentTarget;
      setMenuIsOpen((menuIsOpen) => !menuIsOpen);
    },
    [setMenuIsOpen]
  );

  const handleCloseMenu = React.useCallback(() => {
    setMenuIsOpen(false);
  }, [setMenuIsOpen]);

  const handleSelectEdit = React.useCallback(() => {
    setEditing(true);
    handleCloseMenu();
  }, [setEditing, handleCloseMenu]);

  const handleSelectDelete = React.useCallback(() => {
    handleCloseMenu();
    onDelete();
  }, [onDelete, handleCloseMenu]);

  const nameFormatter = usePeriodNameFormatter();

  return (
    <>
      <Stack flexDirection="row" gap={1}>
        <Typography
          variant="button"
          sx={{
            maxWidth: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textWrap: "nowrap",
            display: "block",
          }}
        >
          {nameFormatter(index, {
            name: metadata.name,
            startTimestamp: metadata.start_timestamp,
            endTimestamp: metadata.end_timestamp,
          })}
        </Typography>
        <Admin>
          {selected ? (
            <IconButton
              onClick={toggleMenuIsOpen}
              sx={{ padding: 0 }}
              size="small"
            >
              {menuIsOpen ? (
                <ArrowDropUp sx={{ height: 22, width: 22 }} />
              ) : (
                <ArrowDropDown sx={{ height: 22, width: 22 }} />
              )}
            </IconButton>
          ) : null}
        </Admin>
      </Stack>
      {menuAnchorRef.current && selected ? (
        <Menu
          open={menuIsOpen}
          onClose={handleCloseMenu}
          anchorEl={menuAnchorRef.current}
        >
          <MenuItem
            sx={{ display: "flex", gap: "6px", paddingX: 1, paddingY: 0.5 }}
            onClick={handleSelectEdit}
          >
            <Edit fontSize="small" />
            <Typography variant="button" fontSize={12}>
              {t("editButton")}
            </Typography>
          </MenuItem>
          <MenuItem
            sx={{ display: "flex", gap: "6px", paddingX: 1, paddingY: 0.5 }}
            onClick={handleSelectDelete}
          >
            <Delete fontSize="small" />
            <Typography variant="button" fontSize={12}>
              {t("delete")}
            </Typography>
          </MenuItem>
        </Menu>
      ) : null}

      {editing ? (
        <EditPeriodModal
          metadata={metadata}
          setMetadata={setMetadata}
          close={() => setEditing(false)}
        />
      ) : null}
    </>
  );
};
