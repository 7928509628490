import UpgradeIcon from "@mui/icons-material/Upgrade";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import { DisableHotkeysInChildren } from "contexts/hotkeys";
import { useTenantTranslation } from "hooks/formatters";
import { useIsDeployablePeriod } from "hooks/periodIndex.tsx";
import { useDeployProcess } from "hooks/useDeployProcess";
import { useState } from "react";
import { Modal as EditAndSendPlanModal } from "../editAndSendPlan/Modal";
import { Modal } from "../messagePlan/modal";
import { SyncAndSMSPlanModal } from "./syncAndSMSPlanModal";

type DeployModalProps = {
  handleClose: () => void;
  planId: number;
};

const DeployModal = ({ handleClose, planId }: DeployModalProps) => {
  const deployProcess = useDeployProcess();
  switch (deployProcess) {
    case "sync_and_sms": {
      return <SyncAndSMSPlanModal open doClose={handleClose} planId={planId} />;
    }
    case "edit_and_send": {
      return <EditAndSendPlanModal open doClose={handleClose} />;
    }
    case "message": {
      return <Modal open doClose={handleClose} planId={planId} />;
    }
    case "none": {
      return null;
    }
  }
};

type Props = {
  planId: number;
};

export const ExportPlan = ({ planId }: Props) => {
  const { t } = useTenantTranslation();
  const [open, setOpen] = useState(false);

  const isDeployablePeriod = useIsDeployablePeriod();

  const buttonDisabled = !isDeployablePeriod;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {buttonDisabled ? (
        <Tooltip title={t("deployMixesOnly")}>
          <span>
            <LoadingButton
              variant="contained"
              startIcon={<UpgradeIcon />}
              disabled
            >
              {t("deploy")}
            </LoadingButton>
          </span>
        </Tooltip>
      ) : (
        <LoadingButton
          variant="contained"
          startIcon={<UpgradeIcon />}
          onClick={handleOpen}
        >
          {t("deploy")}
        </LoadingButton>
      )}
      {open ? (
        <DisableHotkeysInChildren>
          <DeployModal handleClose={handleClose} planId={planId} />
        </DisableHotkeysInChildren>
      ) : null}
    </>
  );
};
