import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import {
  MaterialRead,
  OptimisationSlackVariableUsage,
  OptimisationStatus,
  Plan,
  SummaryTag,
} from "store/api/generatedApi";

export type Deployment = {
  id: number;
  deployed_at: string;
  start_at: string;
  end_at: string;
  deployment_type: string;
  deployed_by_name: string;
};

export type PlanCardData = {
  planId: number;
  planIndex: number;
  planName: string | null;
  basePlanId: number | null;
  searchId: number;
  tags: SummaryTag[];
  metrics: PlanMetrics;
  optimisationSlackVariableUsage: OptimisationSlackVariableUsage[];
  optimisationStatus: OptimisationStatus;
  deployments: Deployment[];
};

export type PlanMetrics = {
  specificPrice: number;
  scrapMass: number;
  safetyStock: { material: string; mass: number }[];
  drainedMaterials: { material: string; mass: number }[];
};

export const planCards = (
  plans: Plan[],
  materials: MaterialRead[]
): PlanCardData[] => {
  return plans
    .map((plan) => planCard(plan, materials))
    .filter(
      (plan, index, allPlans) =>
        allPlans.map(planCardKey).indexOf(planCardKey(plan)) === index
    );
};

const planCard = (plan: Plan, materials: MaterialRead[]): PlanCardData => {
  const { display_cost_per_tonne, scrap_mass } =
    plan.summary.first_period_summary;
  const firstPeriodMaterialSummaries =
    plan.summary.period_material_summary.filter(
      (summary) => summary.period === 1
    );

  const safetyStock = materials
    .filter((material) => material.highlight_safety_stock)
    .map((material) => ({
      material: material.name,
      mass:
        firstPeriodMaterialSummaries.find(
          (summary) => summary.material_id === material.id
        )?.final_inventory ?? 0,
    }));

  const drainedMaterials = materials
    .filter((material) => material.highlight_stock_to_drain)
    .map((material) => ({
      material: material.name,
      mass:
        firstPeriodMaterialSummaries.find(
          (summary) => summary.material_id === material.id
        )?.mass_consumed ?? 0,
    }));

  return {
    optimisationStatus: plan.optimisation_status,
    planId: plan.id,
    planName: null,
    planIndex: plan.plan_index,
    basePlanId:
      plan.metadata.parent_plan_id === null ? null : plan.metadata.base_plan_id,
    searchId: plan.search_id,
    tags: plan.summary.tags,
    metrics: {
      specificPrice: display_cost_per_tonne,
      scrapMass: scrap_mass,
      safetyStock,
      drainedMaterials,
    },
    optimisationSlackVariableUsage: plan.optimisation_slack_variable_usage,
    deployments: plan.deployments,
  };
};

const planCardKey = (card: PlanCardData): string =>
  // Convert everything to arrays to ensure consistent ordering
  JSON.stringify([
    card.metrics.specificPrice,
    card.metrics.scrapMass,
    card.metrics.safetyStock.map((item) => [item.material, item.mass]),
    card.metrics.drainedMaterials.map((item) => [item.material, item.mass]),
    card.tags.map((tag) => [tag.tag, tag.details, tag.sentiment]),
    card.optimisationStatus,
    card.optimisationSlackVariableUsage.map(
      ({ usage, details, period, slack_variable }) => [
        usage,
        details,
        period,
        slack_variable,
      ]
    ),
  ]);

export const usePlanCardMetrics = (
  metrics: PlanMetrics
): { title: string; value: string }[] => {
  const { t } = useTenantTranslation();
  const priceSerialiser = useNumberSerialiser({ decimalPlaces: 2 });
  const integerSerialiser = useNumberSerialiser({ decimalPlaces: 0 });
  const units = useUnitsFormatter(false);

  return [
    {
      title: t("tappedPrice"),
      value: `${units("cost").trim()}${priceSerialiser.format(
        metrics.specificPrice
      )}/${units("mass").trim()}`,
    },
    ...(metrics.safetyStock.length > 0
      ? metrics.safetyStock.map((material) => ({
          title: `${material.material} ${t("inventoryRemainingResultSummary")}`,
          value: `${integerSerialiser.format(material.mass)}${units("mass")}`,
        }))
      : [
          {
            title: t("scrapMassResultSummary"),
            value: `${integerSerialiser.format(metrics.scrapMass)}${units(
              "mass"
            ).trim()}`,
          },
        ]),
    ...metrics.drainedMaterials.map((material) => ({
      title: `${material.material} ${t("scrapConsumedSummary")}`,
      value: `${integerSerialiser.format(material.mass)}${units("mass")}`,
    })),
  ];
};
