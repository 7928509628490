import { DataGridPremium } from "@mui/x-data-grid-premium";
import {
  BaseTableProps,
  LoadMap,
  commonDataGridSx,
  useTableLayout,
} from "./material_table";

type Props = {
  plan: LoadMap;
  actual: LoadMap;
} & BaseTableProps;

export const ChargeSummaryTable = ({ plan, actual, sx }: Props) => {
  const {
    columns,
    rows,
    pinnedRows,
    pinnedColumns,
    rowHeight,
    columnHeaderHeight,
  } = useTableLayout({
    plannedLoad: plan,
    actualLoad: actual,
    columnWidths: {
      material: { flex: 1, minWidth: 110 },
      planned: 85,
      actual: 85,
      diff: 85,
    },
  });

  return (
    <DataGridPremium
      sx={{
        ...commonDataGridSx,
        ...sx,
      }}
      columns={columns}
      rows={rows}
      pinnedRows={pinnedRows}
      pinnedColumns={pinnedColumns}
      rowHeight={rowHeight}
      columnHeaderHeight={columnHeaderHeight}
      cellSelection
      ignoreValueFormatterDuringExport
    />
  );
};
