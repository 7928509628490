import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { PlanName, useDefaultPlanName } from "components/plan/name";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useIsAdmin } from "hooks/settings";
import { ReactNode } from "react";
import {
  OptimisationSlackVariableUsage,
  OptimisationStatus,
  SlackVariableEnum,
  SummaryTag,
} from "src/store/api/generatedApi";
import { groupOptimisationSlackVariableUsage } from "src/utils/groupOptimisationSlackVariableUsage";
import { groupSummaryTags } from "src/utils/groupSummaryTags";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";
import {
  Deployment,
  PlanCardData,
  PlanMetrics,
  usePlanCardMetrics,
} from "./data";
import { PlanGroupedSummaryTagChip } from "./planGroupedSummaryTagChip";

type Props = {
  planId: number;
  planIndex: number;
  planName: string | null;
  basePlanId: number | null;
  searchId: number;
  tags: SummaryTag[];
  metrics: PlanMetrics;
  optimisationSlackVariableUsage: OptimisationSlackVariableUsage[];
  optimisationStatus: OptimisationStatus;
  deployments: Deployment[];
  onClick: (plan: PlanCardData) => void;
};

const slackVariablesWithTooltips: SlackVariableEnum[] = [
  "target_inventory_increases",
  "target_inventory_decreases",
  "unobtainium_purchased",
  "unobtainium_consumed",
  "soft_material_max_increases",
  "soft_material_min_decreases",
  "impurity_weight_percent_max_increase",
  "soft_material_exclusivity_constraint_violated",
];

export const PlanCard = ({
  planId,
  planIndex,
  planName,
  basePlanId,
  searchId,
  tags,
  metrics,
  optimisationSlackVariableUsage,
  optimisationStatus,
  deployments,
  onClick,
}: Props) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const defaultPlanName = useDefaultPlanName()(planIndex);
  const isAdmin = useIsAdmin();

  const planCardMetrics = usePlanCardMetrics(metrics);

  const hasFailed = ["failed", "failed_suboptimal"].includes(
    optimisationStatus
  );
  const hasSlackVariableUsage = optimisationSlackVariableUsage.length > 0;
  const isDeployed = deployments.length > 0;

  const [
    challengingForScrapyardTags,
    notEnoughAvailableScrapTags,
    usesNonDefaultBasketTags,
  ] = groupSummaryTags(tags);

  const groupedOptimisationSlackVariableUsage =
    groupOptimisationSlackVariableUsage(optimisationSlackVariableUsage);

  const SlackVariableTooltip = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    )
  )({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.default,
      maxWidth: 800,
    },
  });

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: theme.palette.background.default,
        p: 2,
        border: 1,
        borderRadius: 1,
        "&:hover": {
          boxShadow: (theme) =>
            `2px 3px 0px 0px #000,inset 5px 0px 0px 0px ${
              hasFailed ? theme.palette.error.main : theme.palette.primary.main
            };`,
          cursor: "pointer",
        },
        borderColor: hasFailed
          ? theme.palette.error.main
          : theme.palette.common.black,
        transition: "box-shadow 0.2s ease-in-out",
      }}
      onClick={() =>
        onClick({
          planId,
          planIndex,
          planName,
          basePlanId,
          searchId,
          tags,
          metrics,
          optimisationSlackVariableUsage,
          optimisationStatus,
          deployments,
        })
      }
    >
      <Box
        sx={{
          border: 1,
          borderColor: "grey.200",
          borderRadius: 1,
          px: 0.5,
          py: 0.25,
          position: "absolute",
          top: 8,
          right: 8,
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Tooltip
          title={isDeployed ? t("deployedHeader") : t("notDeployedHeader")}
        >
          {isDeployed ? (
            <CheckCircleIcon color="success" fontSize="small" />
          ) : (
            <RadioButtonUncheckedIcon color="disabled" fontSize="small" />
          )}
        </Tooltip>
        <PlanName
          planName={planName}
          defaultName={defaultPlanName}
          variant="body2"
          secondary
          maxWidth={100}
        />
      </Box>

      <Stack direction="row" justifyContent="flex-start" gap={4}>
        {planCardMetrics.map((metric) => (
          <Stack key={`${planId}-${metric.title}`}>
            <Typography
              variant="body1Mono"
              sx={
                metric.title == t("tappedPrice")
                  ? {
                      fontWeight: 700,
                    }
                  : undefined
              }
            >
              {metric.value}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.secondary }}
            >
              {metric.title}
            </Typography>
          </Stack>
        ))}
      </Stack>

      <Stack mt={1} flexDirection="row" gap={0.5} flexWrap="wrap">
        {basePlanId !== null ? (
          <Chip color="warning" label={t("edited")} size="small" />
        ) : null}
        {challengingForScrapyardTags[0] ? (
          <PlanGroupedSummaryTagChip
            summaryTags={[
              challengingForScrapyardTags[0],
              ...challengingForScrapyardTags.slice(1),
            ]}
          />
        ) : null}
        {notEnoughAvailableScrapTags[0] ? (
          <PlanGroupedSummaryTagChip
            summaryTags={[
              notEnoughAvailableScrapTags[0],
              ...notEnoughAvailableScrapTags.slice(1),
            ]}
          />
        ) : null}
        {usesNonDefaultBasketTags[0] ? (
          <PlanGroupedSummaryTagChip
            summaryTags={[
              usesNonDefaultBasketTags[0],
              ...usesNonDefaultBasketTags.slice(1),
            ]}
          />
        ) : null}
      </Stack>

      {isAdmin && hasSlackVariableUsage ? (
        <Alert sx={{ mt: 1 }} severity="error">
          <AlertTitle variant="body1">Slack variable use</AlertTitle>
          {typeSafeObjectEntries(groupedOptimisationSlackVariableUsage).map(
            ([slack_variable, usage]) => (
              <Stack
                direction="row"
                gap={1}
                sx={{ alignItems: "center" }}
                key={`${planId}-${slack_variable}-${JSON.stringify(usage)}`}
              >
                {slackVariablesWithTooltips.includes(slack_variable) && (
                  <SlackVariableTooltip
                    title={formatSlackVariableDetails(slack_variable, usage)}
                  >
                    <InfoRoundedIcon fontSize="small" />
                  </SlackVariableTooltip>
                )}
                <Typography key={slack_variable} fontWeight="bold">
                  {slack_variable}
                </Typography>
              </Stack>
            )
          )}
        </Alert>
      ) : null}
    </Box>
  );
};

const formatSlackVariableDetails = (
  slackVariable: SlackVariableEnum,
  usageDetails: OptimisationSlackVariableUsage[]
): ReactNode => {
  const unitsFormatter = useUnitsFormatter(true);
  const overallMassColumns: GridColDef<
    OptimisationSlackVariableUsage & { id: number }
  >[] = [
    { field: "period", headerName: "Period" },
    {
      field: "material",
      headerName: "Material",
      valueGetter: (_, { details }) => {
        return details.material;
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "usage",
      headerName: "Mass" + unitsFormatter("mass"),
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost" + unitsFormatter("cost"),
      flex: 1,
    },
    {
      field: "specific_cost",
      headerName: "Specific cost" + unitsFormatter("specific_cost"),
      flex: 1,
      valueGetter: (_, { usage, cost }) => {
        return cost / usage;
      },
    },
  ];
  const perGroupMassColumns: GridColDef<
    OptimisationSlackVariableUsage & { id: number }
  >[] = [
    { field: "period", headerName: "Period" },
    {
      field: "mix",
      headerName: "Mix",
      valueGetter: (_, { details }) => {
        return (
          details.production_group_name +
          (details.mix_number !== undefined &&
          !isNaN(Number(details.mix_number.toString())) &&
          (details.mix_number as number) > 1
            ? ` ${details.mix_number}`
            : "")
        );
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "constraint_name",
      headerName: "Constraint",
      valueGetter: (_, { details }) => {
        return details.constraint_name;
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "usage",
      headerName: "Mass" + unitsFormatter("mass"),
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost" + unitsFormatter("cost"),
      flex: 1,
    },
  ];
  const perGroupWeightPercentColumns: GridColDef<
    OptimisationSlackVariableUsage & { id: number }
  >[] = [
    { field: "period", headerName: "Period" },
    {
      field: "mix",
      headerName: "Mix",
      valueGetter: (_, { details }) => {
        return (
          details.production_group_name +
          (details.mix_number !== undefined &&
          !isNaN(Number(details.mix_number.toString())) &&
          (details.mix_number as number) > 1
            ? ` ${details.mix_number}`
            : "")
        );
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "constraint_name",
      headerName: "Constraint",
      valueGetter: (_, { details }) => {
        return details.constraint_name;
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost" + unitsFormatter("cost"),
      flex: 1,
    },
  ];
  const perGroupExclusivityColumns: GridColDef<
    OptimisationSlackVariableUsage & { id: number }
  >[] = [
    { field: "period", headerName: "Period" },
    {
      field: "mix",
      headerName: "Mix",
      valueGetter: (_, { details }) => {
        return (
          details.production_group_name +
          (details.mix_number !== undefined &&
          !isNaN(Number(details.mix_number.toString())) &&
          (details.mix_number as number) > 1
            ? ` ${details.mix_number}`
            : "")
        );
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "constraint_name",
      headerName: "Constraint",
      valueGetter: (_, { details }) => {
        return details.constraint_name;
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "usage",
      headerName: "Wt. %",
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost" + unitsFormatter("cost"),
      flex: 1,
    },
  ];

  const slackVariableColumns = (): GridColDef<
    OptimisationSlackVariableUsage & { id: number }
  >[] => {
    switch (slackVariable) {
      case "target_inventory_increases":
      case "target_inventory_decreases":
      case "unobtainium_purchased":
      case "unobtainium_consumed":
        return overallMassColumns;
      case "soft_material_max_increases":
      case "soft_material_min_decreases":
        return perGroupMassColumns;
      case "impurity_weight_percent_max_increase":
        return perGroupWeightPercentColumns;
      case "soft_material_exclusivity_constraint_violated":
        return perGroupExclusivityColumns;
      case "mix_use_count_increases":
      case "material_period_exclusivity_constraint_violated":
        return [];
    }
  };
  return slackVariablesWithTooltips.includes(slackVariable) ? (
    <Box sx={{ maxHeight: 800, width: 800 }}>
      <DataGridPremium
        columns={slackVariableColumns()}
        rows={usageDetails.map((item, index) => {
          return { id: index, ...item };
        })}
        initialState={{
          sorting: {
            sortModel: [{ field: "cost", sort: "desc" }],
          },
        }}
      />
    </Box>
  ) : null;
};
