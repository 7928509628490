import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import { usePresentChemistryGroups } from "./dependencies";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { NumberCell } from "src/components/common/dataGrid/NumberCell";
import { useTenantTranslation } from "hooks/formatters";
import { useHandleTableMutations } from "./hooks";
import React, { useMemo } from "react";
import {
  SearchPeriodProductionPlan,
  TargetBasketItem,
} from "src/store/api/generatedApi";
import {
  SearchChemistryGroups,
  SearchProductGroups,
  SearchSteelGrades,
  SteelGrade,
} from "contexts/search/context";
import { ChefGroupDetailView } from "components/common/panels/chefgroup";
import { SteelGradeChip } from "components/common/chips/steelGrade";
import { OverflowButton } from "components/common/buttons/overflow";
import { productionPlanToSteelGradeIds } from "src/utils/productionPlanToSteelGradeIds";

export type TargetBasketRow = {
  groupId: number;
  groupName: string;
  target: number;
  steelGrades: SteelGrade[];
};

export type Props = {
  productionPlans: SearchPeriodProductionPlan[];
  productGroups: SearchProductGroups;
  chemistryGroups: SearchChemistryGroups;
  targetBaskets: TargetBasketItem[];
  maxBaskets: number;
  steelGrades: SearchSteelGrades;
};

export const TargetBasketsTable = ({
  productionPlans,
  chemistryGroups,
  productGroups,
  targetBaskets,
  maxBaskets,
  steelGrades,
}: Props) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();
  const { handleUpdateItem } = useHandleTableMutations(
    maxBaskets,
    chemistryGroups
  );

  const steelGradesVisible = productionPlans.some(
    (productionPlan) => productionPlan.steel_grade_items !== null
  );
  const presentSteelGrades = useMemo(
    () =>
      new Set(
        productionPlans.flatMap((productionPlan) =>
          productionPlanToSteelGradeIds(productionPlan, productGroups)
        )
      ),
    [productionPlans, productGroups]
  );

  const rows: TargetBasketRow[] = usePresentChemistryGroups(
    productionPlans,
    productGroups,
    chemistryGroups
  ).map((id) => {
    const chemistryGroup = chemistryGroups.byId[id]!;
    const item =
      targetBaskets.find((item) => item.chef_group_id === id) ?? null;
    return {
      steelGrades: Array.from(presentSteelGrades)
        .filter((steel_grade_id) =>
          chemistryGroup.steel_grades
            .map(({ id }) => id)
            .includes(steel_grade_id)
        )
        .map((steel_grade_id) => steelGrades.byId[steel_grade_id]!),
      groupId: chemistryGroup.id,
      groupName: chemistryGroup.name,
      target:
        item?.target_num_baskets ?? chemistryGroup.default_target_num_baskets,
    };
  });

  const columns: GridColDef<TargetBasketRow>[] = [
    {
      field: "groupName",
      headerName: t("chemistryGroup"),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const {
          row: { groupId, groupName },
        } = params;
        return <ChemistryGroup id={groupId} name={groupName} />;
      },
      display: "flex",
    },
    {
      field: "steelGrades",
      headerName: t("steelGrades"),
      sortable: false,
      flex: 1,

      renderCell: (params) => {
        const {
          row: { steelGrades },
        } = params;
        const maxChipsBeforeOverflow = 6;
        const gridSteelGrades = steelGrades.slice(0, maxChipsBeforeOverflow);
        const overflowSteelGrades = steelGrades.slice(maxChipsBeforeOverflow);
        return (
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={0.5}
            sx={{ paddingY: 1 }}
          >
            {gridSteelGrades.map((steelGrade) => (
              <SteelGradeChip
                key={steelGrade.id}
                id={steelGrade.id}
                name={steelGrade.name}
                chemicalConstraints={steelGrade.chemical_constraints}
              />
            ))}
            {overflowSteelGrades.length > 0 ? (
              <OverflowButton>
                <Box flexWrap="wrap" sx={{ p: 1, maxWidth: 300 }}>
                  {overflowSteelGrades.map((steelGrade) => (
                    <SteelGradeChip
                      key={steelGrade.id}
                      id={steelGrade.id}
                      name={steelGrade.name}
                      chemicalConstraints={steelGrade.chemical_constraints}
                    />
                  ))}
                </Box>
              </OverflowButton>
            ) : null}
          </Stack>
        );
      },
      display: "flex",
    },
    {
      field: "target",
      headerName: t("targetBaskets"),
      sortable: false,
      editable: true,
      width: 150,
      renderCell: (params) => <NumberCell value={params.row.target} />,
      display: "flex",
    },
  ];

  return (
    <Box
      // Prevent the modal from closing when pressing Escape key while in edit
      // mode in the data grid
      sx={{ height: "100%" }}
      onKeyDown={(e) => e.key === "Escape" && e.stopPropagation()}
    >
      <DataGridPremium
        rows={rows}
        columns={
          steelGradesVisible ? columns : [columns[0]!, ...columns.slice(2)]
        }
        getRowId={(row: TargetBasketRow) => row.groupId}
        processRowUpdate={handleUpdateItem}
        sx={{
          "& .MuiDataGrid-cell--editing input": {
            textAlign: "right",
            paddingX: 1,
            ...theme.typography.body1Mono,
          },
        }}
        cellSelection
        ignoreValueFormatterDuringExport
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </Box>
  );
};

const CustomNoRowsOverlay = () => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        {t("productionBasketsNoRowsDescription")}
      </Typography>
    </Box>
  );
};

type ChemistryGroupProps = {
  id: number;
  name: string;
};

const ChemistryGroup = ({ id, name }: ChemistryGroupProps) => {
  const theme = useTheme();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Link
        variant="body2"
        color={theme.palette.text.primary}
        fontWeight="bold"
        sx={{ cursor: "pointer" }}
        onClick={() => setShowModal(true)}
      >
        {name}
      </Link>
      <ChefGroupDetailView group={id} open={showModal} setOpen={setShowModal} />
    </>
  );
};
