import { createSlice } from "@reduxjs/toolkit";
import { captureException } from "@sentry/react";
import i18n from "i18next";
import { Permission } from "store/api/generatedApi";
import { AppThunk } from "store/store";
import { dumpLanguage, loadLanguage } from "../persistence";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/en-gb";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/pl";

export type TenantPermissions = {
  [tenant_name: string]: Permission[];
};

export type SettingsState = {
  locale: string;
  displayName: string;
  userUid: string | null;
  email: string | null;
  tenantPermissions: TenantPermissions;
  isAdmin: boolean;
};

export type AuthUpdate = {
  tenantPermissions: TenantPermissions;
  isAdmin: boolean;
  userUid: string;
  displayName: string;
  email: string;
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    locale: loadLanguage() ?? navigator.language,
    tenantPermissions: {},
    displayName: "",
  } as SettingsState,
  reducers: {
    languageSelected: (state, action: { payload: string }) => {
      state.locale = action.payload;
    },
    authUpdate: (state, action: { payload: AuthUpdate }) => {
      state.tenantPermissions = action.payload.tenantPermissions;
      state.isAdmin = action.payload.isAdmin;
      state.userUid = action.payload.userUid;
      state.displayName = action.payload.displayName;
      state.email = action.payload.email;
    },
  },
});

export const { languageSelected, authUpdate } = settingsSlice.actions;

export const changeLanguage =
  (language: string): AppThunk =>
  async (dispatch) => {
    dumpLanguage(language);
    try {
      await i18n.changeLanguage(language);
      dayjs.locale(language);
      dispatch(languageSelected(language));
    } catch (error) {
      captureException(error);
    }
  };
